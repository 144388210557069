import React from "react";
import { useDispatch } from "react-redux";
import { ModalDeleteWrapper } from "../../../shared/components/modal_field_controls/ModalDeleteWrapper";
import { TableAPIRequestParams } from "../../../api/types";
import { QueryInvoiceLinesApiItem } from "../../../api/invoice/invoice-lines";
import { DELETE_INVOICE_LINE } from "../../../store/queryInvoiceLines/types";

interface ModalDeleteInvoiceLineProps {
  rowData: any;
  invoiceId: number;
  tableParams: TableAPIRequestParams<QueryInvoiceLinesApiItem>;
  filterParams: any;
}
export const ModalDeleteInvoiceLine = ({
  rowData,
  invoiceId,
  tableParams,
  filterParams,
}: ModalDeleteInvoiceLineProps) => {
  const dispatch = useDispatch();
  const { invoiceLineNo } = rowData;

  return (
    <ModalDeleteWrapper
      label="Delete Self Bill Line"
      buttonName="Delete"
      message={() => <p>Do you really wish to delete self bill line?</p>}
      onDelete={() => {
        return new Promise((resolve) => {
          dispatch({
            type: DELETE_INVOICE_LINE,
            payload: {
              invoiceLineNo: invoiceLineNo,
              invoiceId: invoiceId,
              tableParams: tableParams,
              filterParams: filterParams,
            },
          });
        });
      }}
      id="delete-self-bill-line"
    />
  );
};
