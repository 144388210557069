import { request } from "../..";

export type AddPartnerNoteParams = {
  partnerId: number;
  description: string;
};

export function postAddPartnerNote(params: AddPartnerNoteParams) {
  return request(`note/add-partner-note/${params.partnerId}`, {
    method: "POST",
    body: JSON.stringify({ description: params.description }),
  });
}
