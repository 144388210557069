import {
  QueryPostedDocumentLinesApiItem,
  QueryInvoiceLinesRequestParams,
} from "../../api/posted-document/posted-invoice-lines";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  REQUEST_DATA,
  SET_DATA,
  SET_TABLE_CONTROLS,
  SET_API_ERROR,
} from "./types";

export function requestPostedDocumentLinesData(
  params: QueryInvoiceLinesRequestParams
) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setPostedDocumentLinesData(
  data: PaginatedResult<QueryPostedDocumentLinesApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setPostedDocumentLinesTableControls(
  data: TableAPIRequestParams<QueryPostedDocumentLinesApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: data,
  };
}

export function setPostedDocumentLinesError(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}
