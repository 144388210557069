import { PermissionApiItem } from "../../api/permission";
import { REQUEST_DATA, SET_DATA } from "./types";

export function requestPermissions() {
  return {
    type: REQUEST_DATA,
  };
}

export function setPermissions(permissions: PermissionApiItem[]) {
  return {
    type: SET_DATA,
    payload: {
      permissions,
    },
  };
}
