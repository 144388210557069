import { request } from "../..";

export interface SpAccountTypeOptionApiItem {
  readonly serviceProviderAccountTypeId: number;
  readonly name: string;
  readonly description: string;
}

export function getServiceProviderAccountTypeOptions(
  partnerId: number,
  serviceProviderId: number,
  partnerServiceProviderId?: number
) {
  return request<SpAccountTypeOptionApiItem[]>(
    "partner-service-provider/get-sp-account-type-options",
    {
      method: "POST",
      body: JSON.stringify({
        partnerId,
        serviceProviderId,
        partnerServiceProviderId,
      }),
    }
  );
}
