import { CreditNoteLinesHeaderAPIItem } from "../../api/posted-document/credit-note-lines-header/index";
import { RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestCreditNoteLinesHeader(postedDocumentId: number) {
  return {
    type: REQUEST_DATA,
    payload: {
      postedDocumentId,
    },
  };
}

export function setCreditNoteLinesHeader(
  data: CreditNoteLinesHeaderAPIItem
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setCreditNoteLinesHeaderError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
