import {
  BulkEditPartnerRateFormState,
  RESET_FORM_VALUES,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  BulkEditPartnerRateActions,
  SET_FORM_CLIENT_ERROR,
} from "./types";

const initialState: BulkEditPartnerRateFormState = {
  error: undefined,
  clientError: undefined,
  isFetching: false,
  values: {
    adjEndDate: "",
    adjStartDate: "",
  },
};

export function bulkEditPartnerRateFormReducer(
  state = initialState,
  action: BulkEditPartnerRateActions
): BulkEditPartnerRateFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case SET_FORM_CLIENT_ERROR: {
      return {
        ...state,
        clientError: action.payload.clientError,
      };
    }

    case RESET_FORM_VALUES: {
      return initialState;
    }

    default:
      return state;
  }
}
