import { AssetNoteApiItem } from "../../api/note/get-asset-notes";
import { RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, TOGGLE_COMMENTS } from "./types";

export function requestCommentsData(assetId: number) {
  return {
    type: REQUEST_DATA,
    payload: {
      assetId,
    },
  };
}

export function setCommentsData(data: AssetNoteApiItem[]) {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setCommentsErrorForDisplay(error: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  };
}

export function toggleComments(show: boolean) {
  return {
    type: TOGGLE_COMMENTS,
    payload: { show },
  };
}
