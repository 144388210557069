import * as yup from "yup";

export function getImportPaymentsSchema() {
  return yup.object().shape({
    serviceProvider: yup.string().label("File Type").required(),
    file: yup.mixed().label("Choose a file").required(),
    month: yup.string().label("Month").required(),
    year: yup.string().label("Year").required(),
    userReference: yup.string().max(8).label("User Reference"),
    paymentType: yup.string().required().label("Import Type"),
  });
}
