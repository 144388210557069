import {
  PostedCreditListAPIItem,
  PostedCreditListParams,
} from "../../api/posted-document/posted-credit-list";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "postedCreditList.REQUEST_DATA";
export const SET_DATA = "postedCreditList.SET_DATA";
export const SET_ERROR = "postedCreditList.SET_ERROR";

export type PostedCreditListState = {
  items: PostedCreditListAPIItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: PostedCreditListParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<PostedCreditListAPIItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PostedCreditListActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
