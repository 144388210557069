import { Box, Typography } from "@mui/material";
import { SentimentVeryDissatisfied } from "@mui/icons-material";
import React from "react";

export const AccessDenied = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ height: "100vh" }}
    >
      <Box style={{ fontSize: 100, width: "50%", textAlign: "center" }}>
        <SentimentVeryDissatisfied color="primary" fontSize="inherit" />
      </Box>
      <Typography variant="h3" style={{ width: "50%", textAlign: "center" }}>
        Sorry, you do not have access to Commissions.
      </Typography>
    </Box>
  );
};
