import React, { useEffect, useState } from "react";
import FileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import { DataTable } from "../../shared/components/DataTable";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../shared/helpers/queryParsing";
import {
  requestQueryAssetsData,
  setQueryAssetsFilters,
  setQueryAssetsTableControls,
} from "../../store/queryAssets/actions";
import { RootState } from "../../store/rootReducer";
import {
  AssetStatus,
  InvoiceStatus,
  ServiceProviderStatusChangeType,
} from "../../shared/enum/asset";
import { ContractType, contractTypes } from "../../shared/enum/asset";
import { FiltersWrapper } from "../../shared/components/filter_controls/FiltersWrapper";
import { Box, Button, Typography } from "@mui/material";
import { formatISOForDisplay } from "../../shared/helpers/dates";
import { QueryAssetApiItem, QueryAssetFilterParams } from "../../api/asset";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Enum, getEnumNames } from "../../shared/helpers/enum";
import { MultiSelect } from "../../shared/components/filter_controls/MultiSelect";
import { DateRangeControl } from "../../shared/components/filter_controls/DateRangeControl";
import { MultiSelectSuggestions } from "../../shared/components/filter_controls/MultiSelectSuggestions";
import { MultiTextField } from "../../shared/components/filter_controls/MultiTextField";
import { getSuggestions } from "../../api/asset/search/suggestions";
import { AppliedFilters } from "../../shared/components/filter_controls/AppliedFilters";
import { ModalCreateAsset } from "./components/ModalCreateAsset";
import { getQueryAssetExcel } from "../../api/asset/index";
import MoreFilters, {
  Filter,
} from "../../shared/components/filter_controls/MoreFilters";
import { MultilineTextArea } from "../../shared/components/filter_controls/MultilineTextArea";
import {
  ServiceProvider,
  serviceProviders,
} from "../../shared/enum/serviceProvider";

type Props = { title: string };

export const QueryAssets = ({ title }: Props) => {
  // routing
  const { path } = useRouteMatch();
  const history = useHistory();

  const navToAssetCard = (assetId: string | number | null) => {
    history.push(`${path}${assetId}`, {
      filterSearch: history.location.search,
    });
  };

  // redux
  const dispatch = useDispatch();
  const {
    items,
    count,
    fetching,
    error,
    searchTerm,
    tableControls,
  } = useSelector((state: RootState) => state.queryAssets);

  // filters state management

  const [filters, setFilters] = useQueryParams({
    assetId: withDefault(CommaDelimitedNumericArrayParam, []),
    contractType: {
      encode: (val: ContractType[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, contractTypes.slice()),
    },
    customerName: withDefault(CommaDelimitedArrayParam, []),
    productName: withDefault(CommaDelimitedArrayParam, []),
    dateCreated: dateRangeParser,
    endDate: dateRangeParser,
    identifier: withDefault(CommaDelimitedArrayParam, []),
    previousIdentifier: withDefault(CommaDelimitedArrayParam, []),
    thirdPartyId: withDefault(CommaDelimitedArrayParam, []),
    sourceRef: withDefault(CommaDelimitedArrayParam, []),
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    status: withDefault(CommaDelimitedNumericArrayParam, []),
    invoiceStatus: withDefault(CommaDelimitedNumericArrayParam, []),
    startDate: dateRangeParser,
    spAccountRef: withDefault(CommaDelimitedArrayParam, []),
    createdByName: withDefault(CommaDelimitedArrayParam, []),
    spAssetStatusChangeType: withDefault(CommaDelimitedNumericArrayParam, []),
    spAssetStatusChangeDate: dateRangeParser,
    outstandingAdvance: withDefault(CommaDelimitedNumericArrayParam, []),
    subscriberId: withDefault(CommaDelimitedArrayParam, []),
  });

  const updateFilters = (filters: any) => {
    setFilters(filters);
    dispatch(
      setQueryAssetsTableControls({
        ...tableControls,
        pageNumber: 1,
      })
    );
  };

  // propagate changes to store
  useEffect(() => {
    dispatch(setQueryAssetsFilters(filters));
  }, [filters, dispatch]);

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const handleExport = () => {
    getQueryAssetExcel({
      tableParams: tableControls,
      filterParams: filters,
      searchTerm,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  const cleanseRequestQueryAssetsData = (filters: any): any => {
    const assetId = filters["assetId"] as string[];
    const assetIdParam = assetId.filter((x) => x !== null);

    return {
      ...filters,
      assetId: assetIdParam,
    };
  };

  // watch for query changes and refetch table data from API
  useDeepCompareEffect(() => {
    dispatch(
      requestQueryAssetsData({
        tableParams: tableControls,
        filterParams: cleanseRequestQueryAssetsData(filters),
        searchTerm,
      })
    );
  }, [tableControls, filters, searchTerm]);

  const columns: {
    [key: string]: any;
    name: keyof QueryAssetApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "dateCreated",
      label: "Date Created",
      customBodyRender: formatISOForDisplay,
      hidden: true,
      isDateRange: true,
    },
    { name: "customerName", label: "Customer Name" },
    { name: "thirdPartyId", label: "Partner Code" },
    { name: "status", label: "Status", enum: AssetStatus },
    { name: "identifier", label: "Identifier" },
    { name: "sourceRef", label: "Source Ref", align: "right" },
    { name: "serviceProvider", label: "Provider" },
    { name: "contractType", label: "Contract Type" },
    { name: "productName", label: "Product Name" },
  ];

  const additionalFilters: Filter[] = [
    { name: "invoiceStatus", label: "Invoice Status", show: false },
    { name: "spAccountRef", label: "SP Account Ref", show: false },
    { name: "createdByName", label: "Created By", show: false },
    { name: "spAssetStatusChangeType", label: "SP Change Type", show: false },
    {
      name: "spAssetStatusChangeDate",
      label: "SP Change Date",
      show: false,
    },
    { name: "subscriberId", label: "Subscriber Id", show: false },
    { name: "previousIdentifier", label: "Previous Identifier", show: false },
    // { name: "outstandingAdvance", label: "Outstanding Advance", show: false },
  ];

  const appliedFiltersMeta: {
    [key: string]: any;
    name:
      | keyof QueryAssetApiItem
      | "spAssetStatusChangeType"
      | "subscriberId"
      | "previousIdentifier";
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    ...columns,
    {
      name: "spAssetStatusChangeDate",
      label: "SP Change Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "spAssetStatusChangeType",
      label: "SP Change Type",
      enum: ServiceProviderStatusChangeType,
    },
    {
      name: "subscriberId",
      label: "Subscriber Id",
    },
    {
      name: "previousIdentifier",
      label: "Previous Identifier",
    },
  ];

  const [filtersMeta, setFiltersMeta] = useState<Filter[]>([
    ...columns.map((col) => ({
      name: col.name,
      label: col.label,
      show: true,
    })),
    ...additionalFilters,
  ]);

  const handleUpdateFilterVisibility = (filterName: string, value: boolean) => {
    const index = filtersMeta.findIndex((filter) => filter.name === filterName);
    filtersMeta[index].show = value;

    setFiltersMeta([...filtersMeta]);
  };

  const isFilterShown = (filterName: string) => {
    return filtersMeta.find((filter) => filter.name === filterName)?.show;
  };

  return (
    <React.Fragment>
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">{title}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Box marginRight={1}>
            <Button size="large" variant="outlined" onClick={handleExport}>
              Export
            </Button>
          </Box>
          <Box>
            <ModalCreateAsset />
          </Box>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        search={() => (
          <>
            {/* <FormSearch
              placeholder="Search Identifier, Customer Name, Product Name"
              initialValue=""
              id="assets-text-search"
              onSubmit={(searchTerm) =>
                dispatch(setQueryAssetsSearchTerm(searchTerm))
              }
            /> */}
            <MoreFilters
              filters={filtersMeta}
              updateFilterVisibility={handleUpdateFilterVisibility}
            />
          </>
        )}
        controls={() => (
          <React.Fragment>
            {isFilterShown("assetId") && (
              <MultilineTextArea
                id="asset-id-filter-control"
                label={
                  columns.find((label) => label.name === "assetId")?.label ||
                  "assetId"
                }
                value={filters.assetId.join("\n")}
                helperText="Enter one Asset Id per line. Tip: you can paste a column from a spreadsheet here."
                onChange={(selected) => {
                  const assetId = selected.filter((id) => /^$|\d+$/.test(id)); // allow for only positive numbers and empty strings
                  updateFilters({ assetId });
                }}
              />
            )}
            {isFilterShown("startDate") && (
              <DateRangeControl
                name={`${
                  columns.find((label) => label.name === "startDate")?.label ||
                  "startDate"
                } `}
                id="start-date-range-filter-control"
                selected={filters.startDate || [null, null]}
                onChange={(startDate) => updateFilters({ startDate })}
              />
            )}
            {isFilterShown("endDate") && (
              <DateRangeControl
                name={`${
                  columns.find((label) => label.name === "endDate")?.label ||
                  "endDate"
                }`}
                id="end-date-range-filter-control"
                selected={filters.endDate || [null, null]}
                onChange={(endDate) => updateFilters({ endDate })}
              />
            )}
            {isFilterShown("dateCreated") && (
              <DateRangeControl
                name={`${
                  columns.find((label) => label.name === "dateCreated")
                    ?.label || "dateCreated"
                } Range`}
                id="date-created-range-filter-control"
                selected={filters.dateCreated || [null, null]}
                onChange={(dateCreated) => updateFilters({ dateCreated })}
              />
            )}
            {isFilterShown("customerName") && (
              <MultiSelectSuggestions
                suggestionServiceColumn="customerName"
                name={
                  columns.find((label) => label.name === "customerName")
                    ?.label || "customerName"
                }
                id="customer-name-filter-control"
                selected={filters.customerName || []}
                onChange={(customerName) => updateFilters({ customerName })}
                suggestionsService={getSuggestions}
              />
            )}
            {isFilterShown("thirdPartyId") && (
              <MultiTextField
                type="text"
                name={
                  columns.find((label) => label.name === "thirdPartyId")
                    ?.label || "thirdPartyId"
                }
                id="third-party-id-filter-control"
                selected={filters.thirdPartyId || []}
                onChange={(thirdPartyId) => updateFilters({ thirdPartyId })}
              />
            )}
            {isFilterShown("status") && (
              <MultiSelect
                name={
                  columns.find((label) => label.name === "status")?.label ||
                  "status"
                }
                id="status-filter-control"
                options={getEnumNames(AssetStatus)}
                selected={
                  filters.status
                    ?.filter((status) => status !== null)
                    .map((status) => AssetStatus[status as number]) || []
                }
                onChange={(status) =>
                  updateFilters({
                    status: status?.map(
                      (status) =>
                        AssetStatus[status as keyof typeof AssetStatus]
                    ),
                  })
                }
              />
            )}
            {isFilterShown("identifier") && (
              <MultilineTextArea
                id="identifier-filter-control"
                label={
                  columns.find((label) => label.name === "identifier")?.label ||
                  "identifier"
                }
                value={filters.identifier.join("\n")}
                helperText="Enter one identifier per line. Tip: you can paste a column from a spreadsheet here."
                onChange={(selected) => {
                  updateFilters({ identifier: selected });
                }}
              />
            )}
            {isFilterShown("previousIdentifier") && (
              <MultilineTextArea
                id="identifier-filter-control"
                label="Previous Identifier"
                value={filters.previousIdentifier.join("\n")}
                helperText="Enter one identifier per line. Tip: you can paste a column from a spreadsheet here."
                onChange={(selected) => {
                  updateFilters({ previousIdentifier: selected });
                }}
              />
            )}
            {isFilterShown("sourceRef") && (
              <MultiTextField
                type="text"
                name={
                  columns.find((label) => label.name === "sourceRef")?.label ||
                  "sourceRef"
                }
                id="source-reference-id-filter-control"
                selected={filters.sourceRef || []}
                onChange={(sourceRef) => updateFilters({ sourceRef })}
              />
            )}
            {isFilterShown("serviceProvider") && (
              <MultiSelect
                name={
                  columns.find((label) => label.name === "serviceProvider")
                    ?.label || "serviceProvider"
                }
                id="service-provider-filter-control"
                options={serviceProviders.slice()}
                selected={filters.serviceProvider || []}
                onChange={(serviceProvider) =>
                  updateFilters({ serviceProvider })
                }
              />
            )}
            {isFilterShown("contractType") && (
              <MultiSelect
                name={
                  columns.find((label) => label.name === "contractType")
                    ?.label || "contractType"
                }
                id="contract-type-filter-control"
                options={contractTypes.slice()}
                selected={filters.contractType || []}
                onChange={(contractType) => updateFilters({ contractType })}
              />
            )}
            {isFilterShown("invoiceStatus") && (
              <MultiSelect
                name="Invoice Status"
                id="invoice-status-control"
                options={getEnumNames(InvoiceStatus)}
                selected={
                  filters.invoiceStatus
                    ?.filter((status) => status !== null)
                    .map((status) => InvoiceStatus[status as number]) || []
                }
                onChange={(status) =>
                  updateFilters({
                    invoiceStatus: status?.map(
                      (status) =>
                        InvoiceStatus[status as keyof typeof InvoiceStatus]
                    ),
                  })
                }
              />
            )}
            {isFilterShown("spAccountRef") && (
              <MultiTextField
                type="text"
                name="SP Account Ref"
                id="sp-account-ref-filter-control"
                selected={filters.spAccountRef || []}
                onChange={(spAccountRef) => updateFilters({ spAccountRef })}
              />
            )}
            {isFilterShown("createdByName") && (
              <MultiTextField
                type="text"
                name="Created By"
                id="created-by-filter-control"
                selected={filters.createdByName || []}
                onChange={(createdByName) => updateFilters({ createdByName })}
              />
            )}
            {isFilterShown("spAssetStatusChangeType") && (
              <MultiSelect
                name="SP Change Type"
                id="sp-type-filter-control"
                options={getEnumNames(ServiceProviderStatusChangeType)}
                selected={
                  filters.spAssetStatusChangeType
                    ?.filter(
                      (spAssetStatusChangeType) =>
                        spAssetStatusChangeType !== null
                    )
                    .map(
                      (spAssetStatusChangeType) =>
                        ServiceProviderStatusChangeType[
                          spAssetStatusChangeType as number
                        ]
                    ) || []
                }
                onChange={(spAssetStatusChangeType) =>
                  updateFilters({
                    spAssetStatusChangeType: spAssetStatusChangeType?.map(
                      (spAssetStatusChangeType) =>
                        ServiceProviderStatusChangeType[
                          spAssetStatusChangeType as keyof typeof ServiceProviderStatusChangeType
                        ]
                    ),
                  })
                }
              />
            )}
            {isFilterShown("spAssetStatusChangeDate") && (
              <DateRangeControl
                name="SP Change Date"
                id="sp-date-filter-control"
                selected={filters.spAssetStatusChangeDate || [null, null]}
                onChange={(spAssetStatusChangeDate) =>
                  updateFilters({ spAssetStatusChangeDate })
                }
              />
            )}
            {isFilterShown("subscriberId") && (
              <MultilineTextArea
                id="subscriber-id-input"
                label="Subscriber Id"
                value={filters.subscriberId.join("\n")}
                helperText="Enter one subscriber id per line. Tip: you can paste a column from a spreadsheet here."
                onChange={(selected) => {
                  updateFilters({ subscriberId: selected });
                }}
              />
            )}
            {isFilterShown("productName") && (
              <MultiTextField
                type="text"
                name="Product Name"
                id="product-name-input"
                selected={filters.productName || []}
                onChange={(productName) => updateFilters({ productName })}
              />
            )}
            {/* {isFilterShown("outstandingAdvance") && (
              <MultiTextField
                type="number"
                name="Outstanding Advance"
                id="outstanding-advance-filter-control"
                selected={filters.outstandingAdvance || []}
                onChange={(outstandingAdvance) =>
                  updateFilters({ outstandingAdvance })
                }
              />
            )} */}
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryAssetFilterParams}
            meta={appliedFiltersMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        limitHeight
        stickyHeader
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) =>
          dispatch(setQueryAssetsTableControls(tableControls))
        }
        tableControls={tableControls}
        onRowClick={(row) => navToAssetCard(row.assetId)}
      />
    </React.Fragment>
  );
};
