import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  PostCreateProductRateParams,
  postCreateProductRate,
} from "../../api/product/product-rate";
import { RequestError } from "../../api/types";
import { requestDetailProductData } from "../detailProducts/actions";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  CreateProductRateFormState,
  FormSubmitAction,
  FORM_SUBMIT,
} from "./types";

function* submitCreateProductRateForm(action: FormSubmitAction) {
  try {
    const formState: CreateProductRateFormState = yield select(
      (state: RootState) => state.createProductRateForm
    );

    const { productId, parentProductId } = action.payload;

    const params: PostCreateProductRateParams = {
      ...formState.baseValues,
      productId,
      parentId: parentProductId,
    };

    yield call(postCreateProductRate, params);
    yield put(requestDetailProductData(parentProductId || productId));

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e) {
    const error: RequestError = e;

    yield put(setError(error));
    yield call(() => action.payload.done(error));
  }
}

export function* watchSubmitCreateProductRateForm() {
  yield takeLatest(FORM_SUBMIT, submitCreateProductRateForm);
}
