import React, { useEffect } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { ProductTypeOption } from "../../../api/product/product-types";
import {
  initializeFormStart,
  resetForm,
  setFormValues,
  submitForm,
} from "../../../store/createPrimaryProductForm/actions";
import {
  getServiceProviderId,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import {
  productVariants,
  ProductVariant,
  getProductVariantId,
  ProductCustomerType,
} from "../../../shared/enum/product";
import { getEnumNames } from "../../../shared/helpers/enum";
import { RequestError } from "../../../api/types";

export const CreatePrimaryProductModal = () => {
  const dispatch = useDispatch();

  const {
    values,
    productTypeOptions,
    productClassOptions,
    productGroupOptions,
    fetching,
    requestError,
  } = useSelector((state: RootState) => state.createPrimaryProductForm);

  const disableSubmit =
    !values.productName ||
    !values.productCode ||
    !values.productTypeId ||
    !values.serviceProviderId ||
    !values.productClassName ||
    !values.duration ||
    !values.productVariantId ||
    !values.customerType ||
    (values.serviceProviderId === 1 && !values.productGroupId);

  useEffect(() => {
    dispatch(initializeFormStart());
  }, [dispatch]);

  return (
    <ModalFieldWrapper
      apiFetching={fetching}
      icon={false}
      label="Create Primary Product"
      buttonLabel="Create Primary Product"
      buttonVariant="contained"
      disableSave={disableSubmit}
      onCancel={() => dispatch(resetForm())}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              done: (err?: RequestError) => {
                err ? reject(err) : resolve(null);
              },
            })
          );
        });
      }}
      id="create-primary-product"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Product Name"
            variant="outlined"
            type="text"
            value={values.productName}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  productName: event.target.value,
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Product Code"
            variant="outlined"
            type="text"
            value={values.productCode}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  productCode: event.target.value,
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-product-type"
            select
            label="Select Product Type"
            value={values.productTypeId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productTypeId = parseInt(event.target.value);
              dispatch(setFormValues({ productTypeId }));
            }}
          >
            {productTypeOptions.map((option: ProductTypeOption) => (
              <MenuItem key={option.productTypeId} value={option.productTypeId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider"
            select
            label="Select Service Provider"
            value={values.serviceProviderId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProviderId = parseInt(event.target.value);
              dispatch(setFormValues({ serviceProviderId }));
            }}
          >
            {serviceProviders.map((option) => (
              <MenuItem key={option} value={getServiceProviderId(option)}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            disabled={!values.serviceProviderId || !values.productTypeId}
            size="small"
            variant="outlined"
            id="select-product-class"
            select
            label="Select Product Class"
            value={values.productClassName}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productClassName = event.target.value;
              dispatch(setFormValues({ productClassName }));
            }}
          >
            {productClassOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-duration"
            label="Select Duration"
            value={values.duration}
            type="number"
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const duration = parseInt(event.target.value);
              dispatch(setFormValues({ duration }));
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-variant"
            select
            label="Select Product Variant"
            value={values.productVariantId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productVariant = event.target.value as ProductVariant;
              dispatch(
                setFormValues({
                  productVariantId: getProductVariantId(productVariant),
                })
              );
            }}
          >
            {productVariants.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-variant"
            select
            label="Select Customer Type"
            value={values.customerType}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const customerType = parseInt(event.target.value);
              dispatch(setFormValues({ customerType }));
            }}
          >
            {getEnumNames(ProductCustomerType)
              .filter((option) => option !== "NotSet")
              .map((option) => (
                <MenuItem
                  key={option}
                  value={
                    ProductCustomerType[
                      option as keyof typeof ProductCustomerType
                    ]
                  }
                >
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-group"
            select
            label="Select Product Group"
            value={values.productGroupId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productGroupId = parseInt(event.target.value);
              dispatch(setFormValues({ productGroupId }));
            }}
          >
            {productGroupOptions.map((option) => (
              <MenuItem
                key={option.productGroupId}
                value={option.productGroupId}
              >
                {option.name}
              </MenuItem>
            ))}
            {productGroupOptions.length === 0 && (
              <MenuItem key="N/A" value="" disabled>
                N/A
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
