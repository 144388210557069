import { OocPaymentTermItem } from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";

export const SET_FORM_VALUES = "createOocRevenueShareForm.SET_FORM_VALUES";
export const SET_PAYMENT_TERM_OPTIONS =
  "createOocRevenueShareForm.SET_PAYMENT_TERM_OPTIONS";
export const SET_FORM_ERROR = "createOocRevenueShareForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "createOocRevenueShareForm.FORM_SUBMIT";
export const FORM_RESET = "createOocRevenueShareForm.FORM_RESET";

export type CreateOocRevenueShareFormValues = {
  assetStartDateFrom: string;
  assetStartDateTo: string;
  oocPaymentTermId: number;
};

export interface CreateOocRevenueShareFormState {
  values: CreateOocRevenueShareFormValues;
  paymentTermOptions: OocPaymentTermItem[];
  fetching: boolean;
  requestError?: RequestError;
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreateOocRevenueShareFormValues>;
  };
}

export interface SetPaymentTermOptionsAction {
  type: typeof SET_PAYMENT_TERM_OPTIONS;
  payload: {
    values: OocPaymentTermItem[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    partnerServiceProviderId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type CreateOocRevenueShareActions =
  | SetFormValuesAction
  | SetPaymentTermOptionsAction
  | FormSubmitAction
  | SetFormErrorAction
  | ResetFormAction;
