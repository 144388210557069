import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  ConnectionCommissionsForAccrualsReportApiItem,
  postGenerateConnectionCommissionsForAccrualsReport,
} from "../../api/account-report/generate-connection-commission-for-accruals-report";
import {
  setConnectionCommissionsForAccrualsReportData,
  setConnectionCommissionsForAccrualsReportError,
} from "./actions";

function* fetchConnectionCommissionsForAccrualsReport(
  action: RequestDataAction
) {
  try {
    const data: PaginatedResult<ConnectionCommissionsForAccrualsReportApiItem> = yield call(
      postGenerateConnectionCommissionsForAccrualsReport,
      action.payload.params
    );

    yield put(setConnectionCommissionsForAccrualsReportData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setConnectionCommissionsForAccrualsReportError(error));
  }
}

export function* watchConnectionCommissionsForAccrualsReport() {
  yield takeLatest(REQUEST_DATA, fetchConnectionCommissionsForAccrualsReport);
}
