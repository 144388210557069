import { Chip, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import React, { useState } from "react";
import { FilterControlWrapper } from "./FilterControlWrapper";

type Props<T> = {
  name: string;
  id: string;
  selected: (T | null)[];
  onChange: (selected: T[] | undefined) => any;
  type?: "number" | "text" | "decimal number";
};

export function MultiTextField<T extends string | number>({
  name,
  id,
  selected,
  onChange,
  type = "text",
}: Props<T>) {
  const handleReset = () => {
    setInput("");
    onChange(undefined);
  };
  const [input, setInput] = useState<string>("");

  const handleSetInput = (input: string) => {
    const val =
      type === "number"
        ? input.replace(/[^0-9]/gi, "")
        : type === "decimal number"
        ? input
            .replace(/[^0-9.-]/gi, "")
            .replace(/(\..*)\./g, "$1")
            .replace(/(?!^)-/g, "")
        : input;
    setInput(val);
  };

  return (
    <FilterControlWrapper
      count={selected.length}
      id={id}
      name={name}
      onReset={handleReset}
      type={type}
    >
      <Autocomplete
        freeSolo
        fullWidth
        multiple
        size="small"
        id={id}
        options={[] as T[]}
        inputValue={input}
        onInputChange={(e, value) => {
          handleSetInput(value);
        }}
        value={selected}
        onChange={(e, selected) => {
          if (!selected.length) {
            return handleReset();
          }
          const val = selected as T[];
          onChange(val);
        }}
        renderTags={(value: any, getTagProps) =>
          value.map((option: any, index: any) => (
            <Chip size="small" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            variant="outlined"
            label="Type to add values"
          />
        )}
      />
    </FilterControlWrapper>
  );
}
