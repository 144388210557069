import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Cancel } from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
  input: {
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
  },
  button: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    height: 40,
    boxShadow: "unset !important",
  },
  deleteIcon: {
    width: 16,
    height: 16,
    marginLeft: -4,
    marginRight: -4,
    color: "rgba(39, 29, 62, 0.26)",
    "&:hover": {
      color: "rgba(39, 29, 62, 0.4)",
      cursor: "pointer",
    },
  },
}));

type Props = {
  placeholder: string;
  initialValue?: string;
  onSubmit: (value: string) => any;
  id: string;
};

export const FormSearch = ({
  placeholder,
  initialValue,
  onSubmit,
  id,
}: Props) => {
  const { classes } = useStyles();
  const [input, setInput] = useState(initialValue || "");

  return (
    <form
      style={{ width: "50%" }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(input);
      }}
    >
      <Box display="flex">
        <FormControl fullWidth variant="outlined" size="small">
          <OutlinedInput
            fullWidth
            placeholder={placeholder}
            id={id}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={classes.input}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              input && (
                <InputAdornment position="end">
                  <Cancel
                    onClick={() => {
                      setInput("");
                      onSubmit("");
                    }}
                    fontSize="small"
                    className={classes.deleteIcon}
                  />
                </InputAdornment>
              )
            }
          />
        </FormControl>
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >
          Go
        </Button>
      </Box>
    </form>
  );
};
