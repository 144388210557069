import React, { useEffect, useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { Box, Checkbox, FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  resetForm,
  setFormValues,
  submitForm,
  setAccountTypeOptions,
} from "../../../store/editPartnerServiceProviderForm/actions";
import {
  getServiceProviderId,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { RequestError } from "../../../api/types";
import { getServiceProviderAccountTypeOptions } from "../../../api/partner-service-provider/get-sp-account-type-options";
import { EditableField } from "../../../shared/components/EditableField";
import { PartnerServiceProvider } from "../../../api/partner";
import {
  EditPartnerServiceProviderFormState,
  EditPartnerServiceProviderFormValues,
} from "../../../store/editPartnerServiceProviderForm/types";

interface Props {
  readonly partnerServiceProvider: PartnerServiceProvider;
}

export const EditPartnerServiceProviderModal = ({
  partnerServiceProvider,
}: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { values, accountTypeOptions, fetching, requestError } = useSelector<
    RootState,
    EditPartnerServiceProviderFormState
  >((state: RootState) => state.editPartnerServiceProviderForm);

  const disableSubmit =
    !values.spDealerCode ||
    !values.serviceProviderId ||
    !values.serviceProviderAccountTypeId ||
    values.serviceProviderAccountTypeId === 0 ||
    values.serviceProviderId === 0 ||
    values.spDealerCode === "";

  useEffect(() => {
    if (!open) return;

    if (!!values.serviceProviderId && values.serviceProviderId !== 0) {
      getServiceProviderAccountTypeOptions(
        partnerServiceProvider.partnerId,
        values.serviceProviderId,
        partnerServiceProvider.partnerServiceProviderId
      ).then((result) => {
        dispatch(setAccountTypeOptions(result));
      });
    }
  }, [
    values.serviceProviderId,
    partnerServiceProvider.partnerServiceProviderId,
    partnerServiceProvider.partnerId,
    dispatch,
    open,
  ]);

  useEffect(() => {
    if (open) {
      dispatch(setFormValues(partnerServiceProvider));
    }
  }, [partnerServiceProvider, open, dispatch]);

  return (
    <ModalFieldWrapper
      apiFetching={fetching}
      icon
      label="Edit Service Provider"
      buttonLabel="Edit"
      buttonVariant="contained"
      disableSave={disableSubmit}
      onOpen={() => setOpen(true)}
      onCancel={() => {
        setOpen(false);
        dispatch(resetForm());
      }}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId: partnerServiceProvider.partnerId,
              done: (err?: RequestError) => {
                err ? reject(err) : resolve(null);
              },
            })
          );
        });
      }}
      id="edit-partner-service-provider"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider"
            select
            label="Service Provider"
            value={values.serviceProviderId}
            disabled
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProviderId = parseInt(event.target.value);
              const updates: Partial<EditPartnerServiceProviderFormValues> = {
                serviceProviderId,
                serviceProviderAccountTypeId:
                  accountTypeOptions.length === 0
                    ? 0
                    : values.serviceProviderAccountTypeId,
                serviceProviderAccountTypeName:
                  accountTypeOptions.length === 0
                    ? ""
                    : values.serviceProviderAccountTypeName,
              };

              dispatch(setFormValues(updates));
            }}
          >
            {serviceProviders.map((option) => (
              <MenuItem key={option} value={getServiceProviderId(option)}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-account-type"
            select
            label="Account Type"
            value={values.serviceProviderAccountTypeId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProviderAccountTypeId = parseInt(event.target.value);
              const serviceProviderAccountTypeName = accountTypeOptions.find(
                (at) =>
                  at.serviceProviderAccountTypeId ===
                  serviceProviderAccountTypeId
              )?.name;
              dispatch(
                setFormValues({
                  serviceProviderAccountTypeId,
                  serviceProviderAccountTypeName,
                })
              );
            }}
          >
            {accountTypeOptions.map((option) => (
              <MenuItem
                key={option.name}
                value={option.serviceProviderAccountTypeId}
              >
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Service Provider Code"
            variant="outlined"
            type="text"
            value={values.spDealerCode}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  spDealerCode: event.target.value,
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <Box style={{ width: 96, marginTop: -20 }}>
            <EditableField label="Enabled" value={false}>
              <Checkbox
                color="primary"
                checked={values.enabled}
                onChange={(event) => {
                  dispatch(
                    setFormValues({
                      enabled: event.target.checked,
                    })
                  );
                }}
              />
            </EditableField>
          </Box>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
