import { PartnerApi } from "../../api/partner";
import { ProductClassApi } from "../../api/product/product-class";
import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { ProductTypeOption } from "../../api/product/product-types";
import { RequestError } from "../../api/types";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import { ProductClass, ProductVariant } from "../../shared/enum/product";

export const SET_FORM_ERROR = "createAssetForm.SET_FORM_ERROR";
export const SET_FORM_VALUES = "createAssetForm.SET_FORM_VALUES";
export const SET_VALUE_PRIMARY = "createAssetForm.SET_VALUE_PRIMARY";
export const SET_VALUE_SECONDARY = "createAssetForm.SET_VALUE_SECONDARY";
export const SET_OPTIONS_SECONDARY = "createAssetForm.SET_OPTIONS_SECONDARY";
export const SET_OPTIONS_PRIMARY = "createAssetForm.SET_OPTIONS_PRIMARY";
export const FORM_SUBMIT = "createAssetForm.FORM_SUBMIT";
export const FORM_RESET = "createAssetForm.FORM_RESET";
export const FORM_INITIALIZE_START = "createAssetForm.FORM_INITIALIZE_START";
export const FORM_INITIALIZE_FINISH = "createAssetForm.FORM_INITIALIZE_FINISH";

export type CreateAssetFormValues = {
  publicIdentifier?: string;
  customerName?: string;
  duration?: string;
  isAdvanceTaken?: boolean;
  startDate?: string;
  additionalMonths?: number;
  serviceProvider?: ServiceProvider;
  productClass?: ProductClass;
  productType?: ProductTypeOption;
  productVariant?: ProductVariant;
  contractTypeId?: number;
  partnerId?: number;
  primaryProduct?: PrimaryOption;
  selectedSecondaryOptions: SecondaryProductValue[];
};

export type CreateAssetFormState = {
  base: CreateAssetFormValues;
  productTypeOptions: ProductTypeOption[];
  primaryProductOptions: PrimaryOption[];
  secondaryProductOptions: ProductRateByIdApiItem[];
  durationOptions: string[];
  productClassOptions: ProductClassApi[];
  partnerOptions: PartnerApi[];
  requestError?: RequestError;
  isFetching: boolean;
};

export type PrimaryOption = {
  productCode: string;
  productName: string;
  productRateId: number;
  productId: number;
  startDate: string;
  endDate: string;
  duration: number;
};

export type SecondaryProductValue = {
  productId: number;
  duration: number;
  isPrimary: boolean;
};

export type InitializeCreateAssetFormPayload = {
  productTypes: ProductTypeOption[];
  productClasses: ProductClassApi[];
  partners: PartnerApi[];
};

export interface InitializeFormStartAction {
  type: typeof FORM_INITIALIZE_START;
}

export interface InitializeFormFinishAction {
  type: typeof FORM_INITIALIZE_FINISH;
  payload: InitializeCreateAssetFormPayload;
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreateAssetFormValues>;
  };
}

export interface SetFormPrimaryProductAction {
  type: typeof SET_VALUE_PRIMARY;
  payload: {
    primaryProduct: PrimaryOption;
  };
}

export interface SetFormSecondaryProductsAction {
  type: typeof SET_VALUE_SECONDARY;
  payload: {
    secondaryProducts: SecondaryProductValue[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    done: (err?: RequestError) => any;
  };
}

export interface SetPrimaryRatesOptionsAction {
  type: typeof SET_OPTIONS_PRIMARY;
  payload: PrimaryOption[];
}

export interface SetFormSecondaryOptionsAction {
  type: typeof SET_OPTIONS_SECONDARY;
  payload: ProductRateByIdApiItem[];
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type CreateAssetActions =
  | InitializeFormStartAction
  | InitializeFormFinishAction
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetPrimaryRatesOptionsAction
  | ResetFormAction
  | SetFormPrimaryProductAction
  | SetFormSecondaryProductsAction
  | SetFormSecondaryOptionsAction;
