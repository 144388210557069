import { OocPaymentTermItem } from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";

export const SET_FORM_VALUES = "editOocRevenueShareForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "editOocRevenueShareForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "editOocRevenueShareForm.FORM_SUBMIT";
export const FORM_RESET = "editOocRevenueShareForm.FORM_RESET";
export const SET_PAYMENT_TERM_OPTIONS =
  "editOocRevenueShareForm.SET_PAYMENT_TERM_OPTIONS";

export type EditOocRevenueShareFormValues = {
  oocPaymentTermId: number;
  assetStartDateFrom: string;
  assetStartDateTo: string;
};

export interface EditOocRevenueShareFormState {
  values: EditOocRevenueShareFormValues;
  paymentTermOptions: OocPaymentTermItem[];
  fetching: boolean;
  errors: ({ id: number } & RequestError)[];
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<EditOocRevenueShareFormValues>;
  };
}

export interface SetPaymentTermOptionsAction {
  type: typeof SET_PAYMENT_TERM_OPTIONS;
  payload: {
    values: OocPaymentTermItem[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    partnerServiceProviderOOCPaymentTermId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: { id: number } & RequestError;
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type EditOocRevenueShareActions =
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetPaymentTermOptionsAction
  | ResetFormAction;
