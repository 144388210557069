import { TextField } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";

interface DecimalInputProps {
  value: number;
  label?: string;
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

interface CustomFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  name: string;
}

export const CustomNumberFormat = (props: CustomFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        props.onChange({
          target: { value: values.value },
        });
      }}
      thousandSeparator
      isNumericString
      fixedDecimalScale
      decimalScale={2}
    />
  );
};

export const DecimalInput = (props: DecimalInputProps) => {
  return (
    <TextField
      size={props.size}
      variant={props.variant}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      InputProps={{
        inputComponent: CustomNumberFormat as any,
      }}
    />
  );
};
