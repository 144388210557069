import { OocPaymentTermItem } from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_RESET,
  ResetFormAction,
  EditOocRevenueShareFormValues,
  SET_PAYMENT_TERM_OPTIONS,
  SetPaymentTermOptionsAction,
} from "./types";

export function setFormValues(
  values: Partial<EditOocRevenueShareFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function setPaymentTermOptions(
  values: OocPaymentTermItem[]
): SetPaymentTermOptionsAction {
  return {
    type: SET_PAYMENT_TERM_OPTIONS,
    payload: {
      values,
    },
  };
}

export function submitForm(payload: {
  partnerId: number;
  partnerServiceProviderOOCPaymentTermId: number;
  done: () => any;
}): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function resetForm(): ResetFormAction {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: { id: number } & RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: error,
  };
}
