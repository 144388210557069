import { stringify } from "query-string";
import { request, requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type CreditNoteLinesAPIItem = {
  assetId: number;
  identifier: string;
  customer: string;
  startDate: DateString | null;
  serviceProvider: string;
  productCode: string;
  description: string | null;
  glCode: string;
  partnerShareAmount: number | null;
  monthlyAdvanceRepayment: number | null;
  totalExcVat: number | null;
};

export interface CreditNoteLinesParams {
  readonly tableControls: TableAPIRequestParams<CreditNoteLinesAPIItem>;
  export?: boolean;
  returnAll?: boolean;
  postedCreditNoteId: number;
}

export function getCreditNoteLines(params: CreditNoteLinesParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);

  return request<PaginatedResult<CreditNoteLinesAPIItem>>(
    `posteddocument/self-bill-credit-note-lines/${
      params.postedCreditNoteId
    }${combine([tableControlsQS])}`
  );
}

export function getCreditNoteLinesExcel(params: CreditNoteLinesParams) {
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `posteddocument/self-bill-credit-note-lines/${
      params.postedCreditNoteId
    }${combine([exportFlag])}`
  );
}
