import { LoaderButton } from "./../LoaderButton";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { RequestError } from "../../../api/types";
import { APIFetchingWrapper } from "../APIFetchingWrapper";
import { DraggableModal } from "../DraggableModal";

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  label: string;
  onOpen?: () => any;
  onCancel?: () => any;
  onRefresh: () => Promise<any>;
  id: string;
  apiFetching?: boolean;
  message: () => React.ReactNode;
  buttonName?: string;
};

export const ModalRefreshWrapper = ({
  label,
  onOpen,
  onCancel,
  onRefresh,
  message,
  id,
  apiFetching,
  buttonName,
}: Props) => {
  const { classes } = useStyles();
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [apiError, setApiError] = useState<RequestError>();

  const handleOpenClick = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleSaveClick = () => {
    if (!!apiError) {
      handleCancelClick();
      return;
    }

    setLoading(true);
    onRefresh().then((err: RequestError) => {
      if (err) {
        setLoading(false);
        setHasError(true);
        setApiError(err);
        return;
      }

      if (mounted) {
        setOpen(false);
        setLoading(false);
      }
    });
  };

  const handleCancelClick = () => {
    if (!loading) {
      setOpen(false);
      setHasError(false);
      setApiError(undefined);
      onCancel && onCancel();
    }
  };

  const title = `${id}-title`;
  const description = `${id}-title`;

  return (
    <React.Fragment>
      <DraggableModal
        aria-describedby={description}
        aria-labelledby={title}
        open={open}
        onClose={handleCancelClick}
      >
        <form
          style={{ maxHeight: 600, overflowY: "auto" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveClick();
          }}
        >
          <Card className={classes.card} id={description}>
            <Box mt={2} mb={1}>
              <Typography variant="h2" align="center" gutterBottom id={title}>
                {label}
              </Typography>
              <Box display="flex" justifyContent="center" marginX={3}>
                <APIFetchingWrapper loading={apiFetching} error={apiError}>
                  {!hasError && message()}
                </APIFetchingWrapper>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              {hasError && (
                <Button
                  disabled={loading}
                  size="large"
                  variant="outlined"
                  onClick={handleCancelClick}
                  className={classes.buttonCancel}
                >
                  OK
                </Button>
              )}
              {!hasError && (
                <>
                  <LoaderButton
                    size="large"
                    loading={loading}
                    variant="contained"
                    type="submit"
                    disabled={hasError || !!apiError}
                  >
                    Refresh
                  </LoaderButton>
                  <Button
                    disabled={loading}
                    size="large"
                    variant="outlined"
                    onClick={handleCancelClick}
                    className={classes.buttonCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          </Card>
        </form>
      </DraggableModal>
      <IconButton size="small" color="primary" onClick={handleOpenClick}>
        <Refresh /> {buttonName}
      </IconButton>
    </React.Fragment>
  );
};
