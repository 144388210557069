import {
  REQUEST_SELF_BILL_TO_PARTNERS_DATA,
  SET_SELF_BILL_TO_PARTNERS_DATA,
  SET_SELF_BILL_TO_PARTNERS_ERROR,
  SelfBillToPartnersState as UpdateSelfBillToPartnersState,
  PartnerActionTypes,
} from "./types";

const initialState: UpdateSelfBillToPartnersState = {
  selfBillPartners: [],
  selfBillToPartnersLoading: false,
};

export function selfBillToPartnersReducer(
  state = initialState,
  action: PartnerActionTypes
): UpdateSelfBillToPartnersState {
  switch (action.type) {
    case REQUEST_SELF_BILL_TO_PARTNERS_DATA:
      return {
        ...state,
        selfBillToPartnersLoading: true,
      };

    case SET_SELF_BILL_TO_PARTNERS_DATA: {
      return {
        ...state,
        selfBillPartners: action.payload,
        selfBillToPartnersLoading: false,
      };
    }
    case SET_SELF_BILL_TO_PARTNERS_ERROR: {
      return {
        ...state,
        selfBillToPartnersError: action.payload,
        selfBillToPartnersLoading: false,
      };
    }
    default:
      return state;
  }
}
