import { requestFile } from "../..";

export type SpendCapReportParams = {
  serviceProviderIds: number[];
};

export function postGenerateSpendCapReport(params: SpendCapReportParams) {
  return requestFile(
    "spend-cap/generate-spend-cap-report?export=true&returnAll=true",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}
