import { call, put, takeLatest } from "redux-saga/effects";
import {
  AssetNoteApiItem,
  getAssetNotes,
} from "../../api/note/get-asset-notes";
import { RequestError } from "../../api/types";
import { setCommentsData, setCommentsErrorForDisplay } from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchComments(action: RequestDataAction) {
  try {
    const data: AssetNoteApiItem[] = yield call(
      getAssetNotes,
      action.payload.assetId
    );

    yield put(setCommentsData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setCommentsErrorForDisplay(error));
  }
}

export function* watchRequestCommentsData() {
  yield takeLatest(REQUEST_DATA, fetchComments);
}
