import {
  QueryProductApiItem,
  QueryProductRequestParams,
} from "../../api/product";
import {
  ProductTypeOption,
  ProductTypesApi,
} from "../../api/product/product-types";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import { ProductClass, ProductVariant } from "../../shared/enum/product";

export const SET_DATA = "queryProducts.SET_DATA";
export const REQUEST_DATA = "queryProducts.REQUEST_DATA";
export const INITIALIZE_START = "queryProducts.INITIALIZE_START";
export const INITIALIZE_FINISH = "queryProducts.INITIALIZE_FINISH";
export const SET_API_ERROR = "queryProducts.SET_API_ERROR";
export const SET_TABLE_CONTROLS = "queryProducts.SET_TABLE_CONTROLS";

export type QueryProductsState = {
  filterOptions: QueryProductsFilterOptions;
  items: QueryProductApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
  tableControls: TableAPIRequestParams<QueryProductApiItem>;
};

export type QueryProductsFilterOptions = {
  duration: string[];
  type: ProductTypeOption[];
  class: ProductClass[];
  variant: ProductVariant[];
};

export interface InitializeFiltersAction {
  type: typeof INITIALIZE_FINISH;
  payload: {
    duration: string[];
    productTypes: ProductTypesApi;
  };
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryProductRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryProductApiItem>;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<QueryProductApiItem>;
  };
}

export type QueryProductsActionTypes =
  | SetTableControlsAction
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction
  | InitializeFiltersAction;
