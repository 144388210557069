import { request } from "../../..";

export interface AddOocPaymentTermParams {
  readonly partnerServiceProviderId: number;
  readonly oocPaymentTermId: number;
  readonly assetStartDateFrom: string;
  readonly assetStartDateTo: string;
}

export function postAddOocPaymentTerm(params: AddOocPaymentTermParams) {
  return request("partner-service-provider/ooc-payment-term/add", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
