import { request } from "../..";

export type SecondaryProductApiItem = {
  productId: number;
  parentId: number | null;
  productCode: string | null;
  productName: string | null;
  description: string | null;
  productClass: string | null;
  productType: string | null;
  serviceProvider: string | null;
  duration: number;
  upgrade: string | null;
  productGroup: string | null;
  customerType: string | null;
};

export type SecondaryProductsApi = {
  items: SecondaryProductApiItem[];
};

export function getSecondaryProductsbyPrimaryProductId(
  primaryProductId: number
) {
  return request<SecondaryProductsApi>(
    `product/secondary-products/${primaryProductId}`
  );
}
