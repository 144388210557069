import { call, put, takeLatest } from "redux-saga/effects";
import {
  DeferredCostReportApiItem,
  getDeferredCostReport,
} from "../../api/asset-report";
import { RequestError, PaginatedResult } from "../../api/types";
import {
  setDeferredCostReportData,
  setDeferredCostReportErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchDeferredCostReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<DeferredCostReportApiItem> = yield call(
      getDeferredCostReport,
      action.payload.params
    );
    yield put(setDeferredCostReportData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setDeferredCostReportErrorForDisplay(error));
  }
}

export function* watchDeferredCostReportData() {
  yield takeLatest(REQUEST_DATA, fetchDeferredCostReport);
}
