import { call, put, takeLatest } from "redux-saga/effects";
import { postUpdatePartnerGeneralInfo } from "../../api/partner/update-partner-general-info";
import { RequestError } from "../../api/types";
import { requestDetailPartnerData } from "../detailPartners/actions";
import {
  setUpdatePartnerGeneralInfoSuccess,
  setUpdatePartnerGeneralInfoError,
} from "./actions";
import {
  REQUEST_UPDATE_PARTNER_GENERAL_INFO,
  RequestUpdatePartnerGeneralInfoAction,
} from "./types";

function* updatePartnerGeneralInfo(
  action: RequestUpdatePartnerGeneralInfoAction
) {
  try {
    yield call(postUpdatePartnerGeneralInfo, action.payload);
    yield put(requestDetailPartnerData(action.payload.partnerId));
    yield put(setUpdatePartnerGeneralInfoSuccess());
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setUpdatePartnerGeneralInfoError(error));
  }
}

export function* watchUpdatePartnerGeneralInfo() {
  yield takeLatest(
    REQUEST_UPDATE_PARTNER_GENERAL_INFO,
    updatePartnerGeneralInfo
  );
}
