import React from "react";
import { useDispatch } from "react-redux";
import { ModalRefreshWrapper } from "../../../shared/components/modal_field_controls/ModalRefreshWrapper";
import { SelfBillListParams } from "../../../api/invoice/self-bill-list";
import { REFRESH_INVOICE } from "../../../store/selfBillList/types";

interface ModalRefreshInvoiceLineProps {
  rowData: any;
  params: SelfBillListParams;
}

export const ModalRefreshInvoice = ({
  rowData,
  params,
}: ModalRefreshInvoiceLineProps) => {
  const dispatch = useDispatch();
  const { invoiceId } = rowData;

  return (
    <ModalRefreshWrapper
      label="Refresh Self Bill Invoice"
      buttonName="Refresh"
      message={() => (
        <p>
          Do you really wish to refresh invoice id: <b>{invoiceId}</b>?
        </p>
      )}
      onRefresh={() => {
        return new Promise((resolve) => {
          dispatch({
            type: REFRESH_INVOICE,
            payload: {
              invoiceId: invoiceId,
              params: params,
            },
          });
        });
      }}
      id="refresh-self-bill-invoice-id"
    />
  );
};
