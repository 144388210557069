import React from "react";
import { FormControl, TextField } from "@mui/material";
import { FilterControlWrapper } from "./FilterControlWrapper";

type Props = {
  id: string;
  label: string;
  value: string;
  helperText?: string;
  onChange: (values: string[]) => any;
};

export const MultilineTextArea = ({
  id,
  label,
  value,
  helperText,
  onChange,
}: Props) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const values = event.target.value?.split(/\r?\n/);
    onChange(values);
  };

  const handleReset = () => onChange([]);

  return (
    <FilterControlWrapper
      count={value === "" ? 0 : value.split(/\r?\n/).filter((v) => !!v).length}
      id={id}
      name={label}
      onReset={handleReset}
      type="textarea"
    >
      <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
        <TextField
          value={value}
          onChange={(event) => handleChange(event)}
          fullWidth
          id={id}
          label={label}
          multiline
          rows={5}
          variant="outlined"
          helperText={helperText}
        />
      </FormControl>
    </FilterControlWrapper>
  );
};
