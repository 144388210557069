export enum ServiceProvider {
  All = 0,
  EE = 1,
  O2 = 2,
  Vodafone = 3,
}

export enum PaymentTerms {
  Unknown = 0,
  D12 = 1,
  D30 = 2,
  D5 = 3,
  INTERCO = 4,
  MONTHLY = 5,
}
export const paymentTerms = [
  "Unknown",
  "D12",
  "D30",
  "D5",
  "INTERCO",
  "MONTHLY",
] as const;
export type PaymentTermsType = typeof paymentTerms[number];

export enum PartnerStatus {
  Active = 0,
  Inactive = 1,
}
export const partnerStatuses = ["Active", "Inactive"] as const;
export type PartnerStatusType = typeof partnerStatuses[number];
