import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Collapse,
  alpha,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

type Props = {
  icon?: React.ReactNode;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  menuLevel?: 0 | 1 | 2;
};

export const NavGroup = ({
  icon,
  title,
  disabled,
  menuLevel = 0,
  children,
}: Props) => {
  // TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
  const useStyles = makeStyles()((theme) => ({
    collapse: {
      background: theme.palette.grey[400],
    },
    icon: {
      color: theme.palette.common.white,
    },
    button: {
      backgroundColor:
        menuLevel === 0
          ? "transparent"
          : alpha(theme.palette.action.selected, 0.3 / menuLevel),
      paddingLeft:
        menuLevel === 0 ? theme.spacing(1) : theme.spacing(menuLevel * 1.75),
      "&:hover": {
        backgroundColor: alpha(theme.palette.action.selected, 0.25),
      },
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  }));

  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <ListItem
        button
        disabled={disabled}
        onClick={() => setOpen(!open)}
        disableRipple
        disableTouchRipple
        classes={{
          button: classes.button,
          divider: classes.divider,
        }}
        divider
      >
        <ListItemIcon className={classes.icon}>
          {icon || <StarIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" color="inherit" noWrap>
              {title}
            </Typography>
          }
        />
        {open ? (
          <KeyboardArrowDown fontSize="small" />
        ) : (
          <KeyboardArrowRight fontSize="small" />
        )}
      </ListItem>
      <Collapse
        className={classes.collapse}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </React.Fragment>
  );
};
