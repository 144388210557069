import { call, put, takeLatest } from "redux-saga/effects";
import { checkEnableCreateEntities } from "../../api/asset-ledger-entry/check-enable-create-entities";
import { getBatchStatus } from "../../api/payment-data/batches/batch-status";
import {
  getPaymentSummary,
  PaymentDataSummaryApi,
} from "../../api/payment-data/summary";
import { RequestError } from "../../api/types";
import {
  setPaymentDataSummaryErrorForDisplay,
  setPaymentDataSummaryData,
  setEnableCreateEntities,
  setBatchStatus,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchPaymentDataSummary(action: RequestDataAction) {
  try {
    const data: PaymentDataSummaryApi = yield call(
      getPaymentSummary,
      action.payload
    );

    const enableCreateEntities: boolean = yield call(
      checkEnableCreateEntities,
      action.payload
    );

    const batchStatus: number = yield call(getBatchStatus, action.payload);

    yield put(setPaymentDataSummaryData(data));
    yield put(setEnableCreateEntities(enableCreateEntities));
    yield put(setBatchStatus(batchStatus));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setPaymentDataSummaryErrorForDisplay(error));
  }
}

export function* watchRequestPaymentDataSummary() {
  yield takeLatest(REQUEST_DATA, fetchPaymentDataSummary);
}
