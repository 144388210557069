import {
  EditProductRateActions,
  EditProductRateFormState,
  INITIALIZE_FINISH,
  INITIALIZE_START,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
} from "./types";

const initialState: EditProductRateFormState = {
  errors: {},
  isFetching: false,
  isLinkedToAsset: false,
  baseValues: {
    startDate: "",
    endDate: "",
    payServiceCommission: 0,
    payServiceAdvance: 0,
    expServiceCommission: 0,
    expServiceAdvance: 0,
    serviceLineRental: 0,
    expTariffAdvance: 0,
    expTariffCommission: 0,
    payTariffAdvance: 0,
    payTariffCommission: 0,
    lineRental: 0,
    payVolumeBonus: 0,
    expVolumeBonus: 0,
    productRateId: 0,
    parentId: 0,
    productId: 0,
  },
};

export function editProductRateFormReducer(
  state = initialState,
  action: EditProductRateActions
): EditProductRateFormState {
  switch (action.type) {
    case INITIALIZE_START: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case INITIALIZE_FINISH: {
      return {
        ...initialState,
        isLinkedToAsset: action.payload.isLinkedToAsset,
        baseValues: {
          ...action.payload.productRate,
        },
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        baseValues: {
          ...state.baseValues,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.productRateId]: action.payload.error,
        },
      };
    }

    default:
      return state;
  }
}
