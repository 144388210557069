import { ProductChangeALEApiItem } from "../../api/asset-ledger-entry/product-change";
import { ProductRateApiItem } from "../../api/product/product-rates";
import { ProductTypeOption } from "../../api/product/product-types";
import { RequestError } from "../../api/types";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import { ProductClass, ProductVariant } from "../../shared/enum/product";
import { DateString } from "../../shared/helpers/dates";

export const INITIALISE = "formAssetProductChange.INITIALIZE";
export const INITAILISE_COMPLETE = "formAssetProductChange.INITALISE_COMPLETE";
export const SET_OPTIONS_BASE = "formAssetProductChange.SET_OPTIONS_BASE";
export const SET_OPTIONS_PRIMARY = "formAssetProductChange.SET_OPTIONS_PRIMARY";
export const SET_OPTIONS_ALE = "formAssetProductChange.SET_OPTIONS_ALE";
export const SET_VALUES_BASE = "formAssetProductChange.SET_VALUES_INITIAL";
export const SET_VALUE_PRIMARY = "formAssetProductChange.SET_VALUE_PRIMARY";
export const SET_VALUES_ALE = "formAssetProductChange.SET_VALUES_ALE";
export const REQUEST_SUBMIT = "formAssetProductChange.REQUEST_SUBMIT";
export const SET_API_ERROR = "formAssetProductChange.SET_ERROR";

export type BaseValues = {
  productType: ProductTypeOption;
  productVariant: ProductVariant;
  productClass: ProductClass;
  duration: string;
  serviceProvider: ServiceProvider;
  assetId: number;
  assetStartDate: DateString;
};

export type PrimaryOption = Pick<
  ProductRateApiItem,
  "productId" | "productName" | "productRateId" | "productCode"
>;

export type AssetProductChangeState = {
  requestError?: RequestError;
  fetchingInitial: boolean;
  fetchingPrimaryProductRates: boolean;
  durationOptions: string[];
  productTypeOptions: ProductTypeOption[];
  primaryProductOptions: PrimaryOption[];
  assetLedgerEntryOptions: ProductChangeALEApiItem[];
  ales: ProductChangeALEApiItem[];
  base: Partial<BaseValues>;
  primary?: PrimaryOption;
};

export interface InitialiseAction {
  type: typeof INITIALISE;
  payload: {
    assetId: number;
    currentProductId: number;
    assetStartDate: DateString;
  };
}

export interface InitialiseCompleteAction {
  type: typeof INITAILISE_COMPLETE;
}

export interface SetBaseOptionsAction {
  type: typeof SET_OPTIONS_BASE;
  payload: {
    durationOptions: string[];
    productTypeOptions: ProductTypeOption[];
  };
}

export interface SetPrimaryRatesOptionsAction {
  type: typeof SET_OPTIONS_PRIMARY;
  payload: PrimaryOption[];
}

export interface SetALEOptionsAction {
  type: typeof SET_OPTIONS_ALE;
  payload: ProductChangeALEApiItem[];
}

export interface SetALEsAction {
  type: typeof SET_VALUES_ALE;
  payload: ProductChangeALEApiItem[];
}

export interface SetBaseValuesAction {
  type: typeof SET_VALUES_BASE;
  payload: Partial<BaseValues>;
}

export interface SetPrimaryValueAction {
  type: typeof SET_VALUE_PRIMARY;
  payload: PrimaryOption;
}

export interface RequestSubmitAssetProductChangeAction {
  type: typeof REQUEST_SUBMIT;
  payload: { done: () => any };
}

export interface SetError {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type FormAssetProductChangeActionTypes =
  | InitialiseAction
  | InitialiseCompleteAction
  | SetBaseOptionsAction
  | SetPrimaryRatesOptionsAction
  | SetALEOptionsAction
  | SetALEsAction
  | SetBaseValuesAction
  | SetPrimaryValueAction
  | RequestSubmitAssetProductChangeAction
  | SetError;
