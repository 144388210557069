import {
  PartnerRateAdjustmentReportApiItem,
  PartnerRateAdjustmentReportParams,
} from "../../api/partner-report/generate-partner-rate-adjustment-report";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestPartnerRateAdjustmentReport(
  params: PartnerRateAdjustmentReportParams
) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setPartnerRateAdjustmentReportData(
  data: PaginatedResult<PartnerRateAdjustmentReportApiItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setPartnerRateAdjustmentReportError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
