import React from "react";
import { Provider } from "react-redux";
import { store } from "./configureStore";

if ((window as any).Cypress) {
  (window as any).__store__ = store;
}
export const ReduxProvider: React.FC<{}> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
