import { requestFile } from "../..";

export function getSecondaryProductReport(assetIds: number[]) {
  return requestFile(
    "product-report/generate-secondary-product-asset-level-report",
    {
      method: "POST",
      body: JSON.stringify(assetIds),
    }
  );
}
