import React, { createRef, useEffect, useMemo, useState } from "react";

import { FormFieldsWrapper } from "../../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  initaliseFormAssetProductChange,
  requestSubmitAction,
  setALEValues,
  setBaseValuesAction,
  setPrimaryValueAction,
} from "../../../../store/formAssetProductChange/actions";
import { DateString } from "../../../../shared/helpers/dates";
import { RootState } from "../../../../store/rootReducer";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { ProductTypeOption } from "../../../../api/product/product-types";
import {
  ProductClass,
  productClasses,
  ProductVariant,
  productVariants,
} from "../../../../shared/enum/product";
import { Autocomplete } from "@mui/lab";
import { PrimaryOption } from "../../../../store/formAssetProductChange/types";
import Search from "@mui/icons-material/Search";
import { ProductChangeALEApiItem } from "../../../../api/asset-ledger-entry/product-change";
import { DeletableChipWrapper } from "../../../../shared/components/DeletableChipWrapper";
import { DeletableChip } from "../../../../shared/components/DeletableChip";
import { AUTOSELECTED_ASSET_PRODUCT_ALE_TYPE_IDS } from "../../../../shared/constants/ALEs";
import { ModalFieldWrapper } from "../../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { getAmendProductSchema } from "./formSchema";

type Props = {
  currentProductId: number;
  assetId: number;
  assetStartDate: DateString;
};

export const ModalAmendProducts = ({
  currentProductId,
  assetId,
  assetStartDate,
}: Props) => {
  const dispatch = useDispatch();
  const {
    requestError,
    fetchingInitial,
    fetchingPrimaryProductRates,
    durationOptions,
    productTypeOptions,
    primaryProductOptions,
    assetLedgerEntryOptions,
    ales,
    base,
    primary,
  } = useSelector((state: RootState) => state.formAssetProductChange);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [noReverseOption, setNoReverseOption] = useState(false);

  const selectRef = createRef<HTMLDivElement>();

  const formSchema = useMemo(() => getAmendProductSchema(), []);

  const handleSave = async () => {
    try {
      await formSchema.validate(
        {
          primary,
          ales,
          noReverseOption,
        },
        {
          abortEarly: false,
        }
      );

      return new Promise((resolve) => {
        dispatch(requestSubmitAction({ done: () => resolve(null) }));
      });
    } catch (error: any) {
      setErrors(error.errors);
      throw new Error();
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(
        initaliseFormAssetProductChange(
          assetId,
          currentProductId,
          assetStartDate
        )
      );
    }
  }, [dispatch, assetId, currentProductId, open, assetStartDate]);

  return (
    <ModalFieldWrapper
      label="Primary Product"
      onOpen={() => setOpen(true)}
      onCancel={() => setOpen(false)}
      onSave={handleSave}
      id="edit-primary-product"
      gutterSize="small"
      apiFetching={fetchingInitial}
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="select-product-type-label">Product Type</InputLabel>
          <Select
            required
            input={<OutlinedInput label="Product Type" />}
            labelId="select-product-type-label"
            id="select-product-type"
            value={base.productType || { name: "", description: "" }}
            onChange={(event) => {
              const value = event.target.value as ProductTypeOption;
              dispatch(setBaseValuesAction({ productType: value }));
            }}
            renderValue={(option) => {
              const _option = option as ProductTypeOption;
              return _option.name;
            }}
          >
            {productTypeOptions.map((option) => (
              <MenuItem value={option as any} key={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="select-product-variant-label">
            Product Variant
          </InputLabel>
          <Select
            input={<OutlinedInput label="Product Variant" />}
            labelId="select-product-variant-label"
            id="select-product-variant"
            value={base.productVariant || ""}
            onChange={(event) => {
              const value = event.target.value as ProductVariant;
              dispatch(setBaseValuesAction({ productVariant: value }));
            }}
          >
            {productVariants.map((variant) => (
              <MenuItem key={variant} value={variant}>
                {variant}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="select-product-class-label">Product Class</InputLabel>
          <Select
            input={<OutlinedInput label="Product Class" />}
            labelId="select-product-class-label"
            id="select-product-class"
            value={base.productClass || ""}
            onChange={(event) => {
              const value = event.target.value as ProductClass;
              dispatch(setBaseValuesAction({ productClass: value }));
            }}
          >
            {productClasses.map((productClass) => (
              <MenuItem key={productClass} value={productClass}>
                {productClass}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="select-product-duration-label">
            Product Duration (months)
          </InputLabel>
          <Select
            input={<OutlinedInput label="Product Duration (months)" />}
            labelId="select-product-duration-label"
            id="select-product-duration"
            value={base.duration || ""}
            onChange={(event) => {
              const value = event.target.value as string;
              dispatch(setBaseValuesAction({ duration: value }));
            }}
          >
            {durationOptions.map((duration) => (
              <MenuItem key={duration} value={duration}>
                {duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          disableClearable
          disabled={fetchingPrimaryProductRates}
          options={primaryProductOptions}
          getOptionLabel={(option: PrimaryOption) => option.productCode || ""}
          value={primary || (null as any)}
          onChange={(event, newValue) => {
            dispatch(setPrimaryValueAction(newValue as PrimaryOption));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.includes("primary")}
              size="small"
              label="Primary Product"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <React.Fragment>
                    {fetchingPrimaryProductRates ? (
                      <CircularProgress size={16} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
          <InputLabel id="select-ale-label">
            {noReverseOption ? "Do not reverse ALEs" : "ALEs To Reverse"}
          </InputLabel>
          <Select
            labelId="select-ale-label"
            id="select-ale-type"
            multiple
            value={ales}
            error={errors.includes("ales")}
            renderValue={(values) => {
              const _values = values as ProductChangeALEApiItem[];
              return (
                <DeletableChipWrapper>
                  {_values.map((value) => (
                    <DeletableChip
                      size="small"
                      key={value.assetLedgerEntryId}
                      label={value.aleTypeName}
                      onDelete={
                        AUTOSELECTED_ASSET_PRODUCT_ALE_TYPE_IDS.includes(
                          value.aleTypeId
                        )
                          ? undefined
                          : () => {
                              dispatch(
                                setALEValues(
                                  _values.filter(
                                    (x) =>
                                      x.assetLedgerEntryId !==
                                      value.assetLedgerEntryId
                                  )
                                )
                              );
                            }
                      }
                    />
                  ))}
                </DeletableChipWrapper>
              );
            }}
            onChange={(event: any) => {
              if (Array.isArray(event.target?.value)) {
                if (event.target.value.find((i: any) => i === "true")) {
                  setNoReverseOption(true);
                  dispatch(setALEValues([]));
                } else {
                  setNoReverseOption(false);
                  dispatch(setALEValues(event.target.value));
                }
              }
            }}
            input={<OutlinedInput label="ALEs To Reverse" />}
            onClose={() => {
              setTimeout(
                () => document.getElementById("select-ale-type")?.blur(),
                100
              );
            }}
          >
            <MenuItem key="label" disabled>
              <div ref={selectRef} style={{ marginLeft: 10 }}>
                <ListItemText primary="Please select ALEs to reverse" />
              </div>
            </MenuItem>
            <MenuItem key="label" value={noReverseOption ? "false" : "true"}>
              <Checkbox color="primary" checked={noReverseOption} />
              <ListItemText primary="Do not reverse ALEs" />
            </MenuItem>
            {assetLedgerEntryOptions.length > 0 ? (
              assetLedgerEntryOptions.map((option) => (
                <MenuItem
                  key={option.assetLedgerEntryId}
                  value={option as any}
                  disabled={AUTOSELECTED_ASSET_PRODUCT_ALE_TYPE_IDS.includes(
                    option.aleTypeId
                  )}
                >
                  <Checkbox
                    color="primary"
                    checked={ales.some(
                      (val) =>
                        option.assetLedgerEntryId === val.assetLedgerEntryId
                    )}
                  />
                  <ListItemText
                    primary={`${option.aleTypeName}: ${option.amount}`}
                  />
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options</MenuItem>
            )}
          </Select>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
