import { call, put, takeLatest } from "redux-saga/effects";
import { getQueryAsset, QueryAssetApiItem } from "../../api/asset";
import { RequestError, PaginatedResult } from "../../api/types";
import { setQueryAssetsData, setQueryAssetsErrorForDisplay } from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchQueryAssets(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryAssetApiItem> = yield call(
      getQueryAsset,
      action.payload
    );
    yield put(setQueryAssetsData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryAssetsErrorForDisplay(error));
  }
}

export function* watchRequestQueryAssetsData() {
  yield takeLatest(REQUEST_DATA, fetchQueryAssets);
}
