import { List, ListItem } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailAssetApiItem } from "../../../api/asset";
import { EditableField } from "../../../shared/components/EditableField";
import { ModalTextField } from "../../../shared/components/modal_field_controls/ModalTextField";
import { ServiceProviderStatusChangeType } from "../../../shared/enum/asset";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { getEnumValues } from "../../../shared/helpers/enum";
import { requestUpdateAssetItem } from "../../../store/detailAssets/actions";
import { getPermission } from "../../../store/permissions/reducers";
import { Permissions } from "../../../store/permissions/types";
import { ModalServiceProviderStatusChangeType } from "./ModalServiceProviderStatusChangeType";

type Props = Pick<
  DetailAssetApiItem,
  | "assetId"
  | "customerName"
  | "customerPostcode"
  | "spAccountRef"
  | "spAssetStatus"
  | "spAssetStatusChangeType"
  | "spAssetStatusChangeDate"
  | "subscriberId"
>;

export const PanelContentCustomer = ({
  assetId,
  customerName,
  customerPostcode,
  spAccountRef,
  spAssetStatus,
  spAssetStatusChangeType,
  spAssetStatusChangeDate,
  subscriberId,
}: Props) => {
  const dispatch = useDispatch();
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  return (
    <List>
      <ListItem divider>
        <EditableField label="Customer Name" value={customerName}>
          {canWriteAssets && (
            <ModalTextField
              label="Customer Name"
              value={customerName || ""}
              id="edit-customer-name"
              onSave={(customerName) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateAssetItem(assetId, { customerName }, () => {
                      resolve(null);
                    })
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Customer Postcode" value={customerPostcode}>
          {canWriteAssets && (
            <ModalTextField
              label="Customer Postcode"
              value={customerPostcode || ""}
              id="edit-customer-postcode"
              onSave={(customerPostcode) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateAssetItem(
                      assetId,
                      { customerPostcode },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Network Customer Account" value={spAccountRef}>
          {canWriteAssets && (
            <ModalTextField
              label="Network Customer Account"
              value={spAccountRef || ""}
              id="edit-sp-account-ref"
              onSave={(spAccountRef) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateAssetItem(assetId, { spAccountRef }, () => {
                      resolve(null);
                    })
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Service Provider Status" value={spAssetStatus}>
          {/* <ModalSelect
            label="Service Provider Status"
            id="edit-sp-asset-status"
            initialValue={0}
            options={getEnumValues(ServiceProviderAssetStatus).map((value) => ({
              label: ServiceProviderAssetStatus[value],
              value,
            }))}
            onSave={(spAssetStatus) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateAssetItem(
                    assetId,
                    {
                      spAssetStatus: ServiceProviderAssetStatus[spAssetStatus],
                    },
                    () => {
                      resolve(null);
                    }
                  )
                );
              });
            }}
          /> */}
        </EditableField>
      </ListItem>

      <ListItem divider>
        <EditableField
          label="Service Provider Status Change Type"
          value={ServiceProviderStatusChangeType[spAssetStatusChangeType]}
        >
          {canWriteAssets && (
            <ModalServiceProviderStatusChangeType
              initialValue={spAssetStatusChangeType}
              options={getEnumValues(ServiceProviderStatusChangeType).map(
                (value) => ({
                  label: ServiceProviderStatusChangeType[value],
                  value,
                })
              )}
              onSave={(values) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateAssetItem(
                      assetId,
                      {
                        spAssetStatusChangeType: values.selected,
                        spAssetStatusChangeDate: values.date,
                      },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>

      {/* <ListItem divider>
        <EditableField
          label="Service Provider Status Change Type"
          value={ServiceProviderStatusChangeType[spAssetStatusChangeType]}
        >
          <ModalSelect
            label="Service Provider Status Change Type"
            id="edit-sp-asset-status-change-type"
            initialValue={0}
            options={getEnumValues(ServiceProviderStatusChangeType).map(
              (value) => ({
                label: ServiceProviderStatusChangeType[value],
                value,
              })
            )}
            onSave={(spAssetStatusChangeType) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateAssetItem(
                    assetId,
                    { spAssetStatusChangeType },
                    () => {
                      resolve(null);
                    }
                  )
                );
              });
            }}
          />
        </EditableField>
      </ListItem> */}
      <ListItem divider>
        <EditableField
          label="Service Provider Status Change Date"
          value={formatISOForDisplay(spAssetStatusChangeDate)}
        />
      </ListItem>
      <ListItem>
        <EditableField label="Subscriber Id" value={subscriberId}>
          {canWriteAssets && (
            <ModalTextField
              label="Subscriber Id"
              value={subscriberId || ""}
              id="edit-subscriber-id"
              onSave={(subscriberId) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateAssetItem(assetId, { subscriberId }, () => {
                      resolve(null);
                    })
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
    </List>
  );
};
