import { call, put, takeLatest } from "redux-saga/effects";
import { postRecalculatePartnerRate } from "../../api/asset-ledger-entry/recalculate-partner-rate";
import { RequestError } from "../../api/types";
import { formSuccess, setRecalculatePartnerRateError } from "./actions";
import { FORM_SUBMIT, RecalculatePartnerRateAction } from "./types";

function* recalculatePartnerRate(action: RecalculatePartnerRateAction) {
  try {
    yield call(postRecalculatePartnerRate, action.payload.params);
    yield put(formSuccess());
  } catch (e) {
    let error: RequestError = e;
    yield put(setRecalculatePartnerRateError(error));
  }
}

export function* watchRecalculatePartnerRate() {
  yield takeLatest(FORM_SUBMIT, recalculatePartnerRate);
}
