import { call, put, select, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  CreatePartnerNoteFormState,
} from "./types";
import {
  AddPartnerNoteParams,
  postAddPartnerNote,
} from "../../api/note/add-partner-note";

function* submitPartnerNoteForm(action: FormSubmitAction) {
  try {
    const formState: CreatePartnerNoteFormState = yield select(
      (state: RootState) => state.createPartnerNoteForm
    );

    const params: AddPartnerNoteParams = {
      ...formState.values,
      partnerId: action.payload.partnerId,
    };

    yield call(postAddPartnerNote, params);

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchSubmitPartnerNoteForm() {
  yield takeLatest(FORM_SUBMIT, submitPartnerNoteForm);
}
