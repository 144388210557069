import { OocPaymentTermItem } from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";

export const FORM_INITIALIZE_START = "outOfContractForm.FORM_INITIALIZE_START";
export const FORM_INITIALIZE_FINISH =
  "outOfContractForm.FORM_INITIALIZE_FINISH";
export const SET_FORM_VALUES = "outOfContractForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "outOfContractForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "outOfContractForm.FORM_SUBMIT";
export const FORM_RESET = "outOfContractForm.FORM_RESET";
export const SET_PAYMENT_TERM_ID = "outOfContractForm.SET_PAYMENT_TERM_ID";

export type OutOfContractFormValues = {
  partnerId: number;
  serviceProviderId: number;
  oocPaymentTermId: number;
  serviceProviderName: string;
  oocPaymentTermName: string;
  oocPaymentTermDescription: string;
};

export type OutOfContractFormFormState = {
  values: OutOfContractFormValues;
  oocPaymentTermOptions: OocPaymentTermItem[];
  fetching: boolean;
  requestError?: RequestError;
};

export interface FormInitializeStartAction {
  type: typeof FORM_INITIALIZE_START;
}

export interface FormInitializeFinishAction {
  type: typeof FORM_INITIALIZE_FINISH;
  payload: {
    oocPaymentTermOptions: OocPaymentTermItem[];
  };
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<OutOfContractFormValues>;
  };
}

export interface SetPaymentTermIdAction {
  type: typeof SET_PAYMENT_TERM_ID;
  payload: {
    partnerId: number;
    serviceProviderId: number;
    oocPaymentTermId: number;
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type OutOfContractFormActions =
  | FormInitializeStartAction
  | FormInitializeFinishAction
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetPaymentTermIdAction
  | ResetFormAction;
