import { request } from "../..";

export type AddAssetNoteParams = {
  assetId: number;
  description: string;
};

export function postAddAssetNote(params: AddAssetNoteParams) {
  return request(`note/add-asset-note/${params.assetId}`, {
    method: "POST",
    body: JSON.stringify({ description: params.description }),
  });
}
