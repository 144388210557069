import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  PostedInvoiceListAPIItem,
  getPostedInvoiceList,
} from "../../api/posted-document/posted-invoice-list";
import { setPostedInvoiceListData, setPostedInvoiceListError } from "./actions";

function* fetchPostedInvoiceList(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PostedInvoiceListAPIItem> = yield call(
      getPostedInvoiceList,
      action.payload.params
    );

    yield put(setPostedInvoiceListData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setPostedInvoiceListError(error));
  }
}

export function* watchPostedInvoiceList() {
  yield takeLatest(REQUEST_DATA, fetchPostedInvoiceList);
}
