import FileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import React from "react";
import { DataTable } from "../../../shared/components/DataTable";
import { formatISOForDisplayDateTime } from "../../../shared/helpers/dates";
import { Enum } from "../../../shared/helpers/enum";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import {
  getServiceProviderIdReturnZeroIfUndefined,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { formatDecimal } from "../../../shared/helpers/numbers";
import {
  dateRangeParser,
  decodePermittedArrayParam,
  CommaDelimitedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import {
  getOutstandingCommissionsReportExcel,
  OutstandingCommissionsApiItem,
} from "../../../api/asset-report/generate-outstanding-commissions-report";
import {
  requestOutstandingCommissionsReport,
  setOutstandingCommissionsReportTableControls,
  clearOutstandingCommissionsReportTableData,
} from "../../../store/outstandingCommissionsReport/actions";
import { Select } from "../../../shared/components/filter_controls/Select";

export const OutstandingCommissionsReport = () => {
  const { count, fetching, error, items, tableControls } = useSelector(
    (state: RootState) => state.outstandingCommissionsReport
  );

  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    dateRange: dateRangeParser,
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    thirdPartyId: withDefault(CommaDelimitedArrayParam, []),
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
    dispatch(clearOutstandingCommissionsReportTableData());
  };

  const handleSubmit = () => {
    dispatch(
      requestOutstandingCommissionsReport({
        tableControls,
        startDate: filters.dateRange[0],
        endDate: filters.dateRange[1],
        serviceProviderId: getServiceProviderIdReturnZeroIfUndefined(
          filters.serviceProvider[0]
        ),
        thirdPartyIds: filters.thirdPartyId,
      })
    );
  };

  /*
  useEffect(() => {
    if (!filters.dateRange[0] || !filters.dateRange[1]) return;

    dispatch(
      requestOutstandingCommissionsReport({
        tableControls,
        startDate: filters.dateRange[0],
        endDate: filters.dateRange[1],
        serviceProviderId: getServiceProviderIdReturnZeroIfUndefined(
          filters.serviceProvider[0]
        ),
        thirdPartyId: filters.thirdPartyId[0],
      })
    );
  }, [dispatch, filters, tableControls]);
  */

  const handleExport = () => {
    getOutstandingCommissionsReportExcel({
      startDate: filters.dateRange[0],
      endDate: filters.dateRange[1],
      tableControls,
      serviceProviderId: getServiceProviderIdReturnZeroIfUndefined(
        filters.serviceProvider[0]
      ),
      thirdPartyIds: filters.thirdPartyId,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  let columns: {
    [key: string]: any;
    name: keyof OutstandingCommissionsApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "identifier", label: "Identifier", align: "right" },
    { name: "assetId", label: "Asset Id", align: "right" },
    {
      name: "previousIdentifier",
      label: "Previous Identifier",
      align: "right",
    },
    { name: "contractType", label: "Contract Type", align: "right" },
    { name: "status", label: "Status", align: "right" },
    { name: "productType", label: "Product Type", align: "right" },
    { name: "serviceProvider", label: "Service Provider", align: "right" },
    { name: "productCode", label: "Product Code", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    { name: "partner", label: "Partner", align: "right" },
    { name: "bdm", label: "BDM", align: "right" },
    { name: "ibdm", label: "Network Billed Partner Manager", align: "right" },
    { name: "region", label: "Region", align: "right" },
    { name: "territory", label: "Territory", align: "right" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplayDateTime,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplayDateTime,
    },
    { name: "spAccountRef", label: "SP Account Ref", align: "right" },
    { name: "sourceRef", label: "Source Ref", align: "right" },
    {
      name: "payTariffCommission",
      label: "Pay Tariff Commission",
      customBodyRender: formatDecimal,
    },
    {
      name: "payTariffAdvance",
      label: "Pay Tariff Advance",
      customBodyRender: formatDecimal,
    },
    {
      name: "payServiceCommission",
      label: "Pay Service Commission",
      customBodyRender: formatDecimal,
    },
    {
      name: "payServiceAdvance",
      label: "Pay Service Advance",
      customBodyRender: formatDecimal,
    },
    {
      name: "payAFCommission",
      label: "Pay AF Commission",
      customBodyRender: formatDecimal,
    },
    {
      name: "payAdditionalFundingAdvance",
      label: "Pay Additional Funding Advance",
      customBodyRender: formatDecimal,
    },
    {
      name: "payVolumeBonus",
      label: "Pay Volume Bonus",
      customBodyRender: formatDecimal,
    },
    {
      name: "payAdditionalFundingDWS",
      label: "Pay Additional Funding DWS",
      customBodyRender: formatDecimal,
    },
    {
      name: "payBcadFund",
      label: "Pay BCAD Fund",
      customBodyRender: formatDecimal,
    },
    {
      name: "payDa",
      label: "Pay Da",
      customBodyRender: formatDecimal,
    },
    {
      name: "monthlyAdvanceRepayment",
      label: "MAR",
      customBodyRender: formatDecimal,
    },
    {
      name: "payAirQrQn",
      label: "Pay AIR QR QN",
      customBodyRender: formatDecimal,
    },

    {
      name: "paySideLetterAdditionalFundingNetwork",
      label: "Pay Side Letter Additional Funding Network",
      customBodyRender: formatDecimal,
    },
    { name: "signatureProof", label: "Signature Proof", align: "right" },
    { name: "contractProof", label: "Contract Proof", align: "right" },
    { name: "invoiceStatus", label: "Invoice Status", align: "right" },
    {
      name: "outstandingAmount",
      label: "Outstanding Amount",
      customBodyRender: formatDecimal,
    },
    { name: "paymentTerms", label: "Payment Terms", align: "right" },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Outstanding Commissions Report
        </Typography>
        <Box>
          <LoaderButton
            style={{ marginRight: 8 }}
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            color="primary"
            size="large"
            variant="contained"
            loading={fetching}
            onClick={handleSubmit}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
          >
            Submit
          </LoaderButton>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={count === 0 ? undefined : count}
        controls={() => (
          <>
            <DateRangeControl
              name="Date Range"
              id="date-range-filter-control"
              selected={filters.dateRange}
              onChange={(dateRange) => updateFilters({ dateRange })}
            />
            <Select
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="third-party-id-filter-control"
              selected={filters.thirdPartyId || []}
              onChange={(thirdPartyId) => updateFilters({ thirdPartyId })}
            />
          </>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={[
              {
                name: "dateRange",
                label: "Date Range",
                isDateRange: true,
              },
              {
                name: "serviceProvider",
                label: "Service Provider",
              },

              {
                name: "thirdPartyId",
                label: "Partner Code",
              },
            ]}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          dispatch(setOutstandingCommissionsReportTableControls(tableControls));
          if (filters.dateRange[0] && filters.dateRange[1]) {
            dispatch(
              requestOutstandingCommissionsReport({
                tableControls,
                startDate: filters.dateRange[0],
                endDate: filters.dateRange[1],
                serviceProviderId: getServiceProviderIdReturnZeroIfUndefined(
                  filters.serviceProvider[0]
                ),
                thirdPartyIds: filters.thirdPartyId,
              })
            );
          }
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
