import { request } from "../..";

export function getServiceProviderDistinctTiers(
  serviceProviderId: number,
  productTypeId: number
): Promise<number[]> {
  return request<number[]>(
    "service-provider/distinct-tiers/" + serviceProviderId + "/" + productTypeId
  );
}
