import { RequestError } from "../../api/types";

export const RESET_FORM_VALUES = "editPartnerRateForm.RESET_FORM_VALUES";
export const SET_FORM_VALUES = "editPartnerRateForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "editPartnerRateForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "editPartnerRateForm.FORM_SUBMIT";

export type EditPartnerRateFormValues = {
  adjustment: number;
  adjStartDate: string;
  adjEndDate: string;
};

export type EditPartnerRateFormState = {
  values: EditPartnerRateFormValues;
  errors: {
    [partnerRateAdjustmentId: number]: RequestError;
  };
  isFetching: boolean;
};

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<EditPartnerRateFormValues>;
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    partnerRateAdjustmentId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    partnerRateAdjustmentId: number;
    error: RequestError;
  };
}

export interface ResetFormValuesAction {
  type: typeof RESET_FORM_VALUES;
}

export type EditPartnerRateActions =
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | ResetFormValuesAction;
