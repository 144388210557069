import { request } from "../..";

export interface BulkUpdatePartnerRateAdjustmentParams {
  partnerId: number;
  adjStartDate: string;
  adjEndDate: string;
  adjustment: number;
  partnerRateAdjustmentId: number;
}

export function postBulkUpdatePartnerRateAdjustments(
  params: BulkUpdatePartnerRateAdjustmentParams[]
) {
  return request("partner/bulk-update-partner-rate-adjustments", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
