import { OocPaymentTermItem } from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_RESET,
  ResetFormAction,
  FORM_INITIALIZE_START,
  FORM_INITIALIZE_FINISH,
  SET_PAYMENT_TERM_ID,
  FormInitializeStartAction,
  FormInitializeFinishAction,
  OutOfContractFormValues,
  SetPaymentTermIdAction,
} from "././types";

export function formInitializeStart(): FormInitializeStartAction {
  return {
    type: FORM_INITIALIZE_START,
  };
}

export function formInitializeFinish(
  oocPaymentTermOptions: OocPaymentTermItem[]
): FormInitializeFinishAction {
  return {
    type: FORM_INITIALIZE_FINISH,
    payload: {
      oocPaymentTermOptions,
    },
  };
}

export function setFormValues(
  values: Partial<OutOfContractFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function setPaymentTypeId(
  partnerId: number,
  serviceProviderId: number,
  oocPaymentTermId: number
): SetPaymentTermIdAction {
  return {
    type: SET_PAYMENT_TERM_ID,
    payload: {
      partnerId,
      serviceProviderId,
      oocPaymentTermId,
    },
  };
}

export function submitForm(payload: { done: () => any }): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function resetForm(): ResetFormAction {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}
