import { request } from "../..";

export interface AddPartnerServiceProviderParams {
  readonly partnerId: number;
  readonly serviceProviderId: number;
  readonly serviceProviderAccountTypeId: number;
  readonly spDealerCode: string;
  readonly enabled: boolean;
}

export function postAddPartnerServiceProvider(
  params: AddPartnerServiceProviderParams
) {
  return request("partner-service-provider/add", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
