import {
  UnraisedRevshareApiItem,
  UnraisedRevshareReportParams,
} from "../../api/asset-report/generate-unraised-revshare-report";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";

export const REQUEST_DATA = "unraisedRevshareReport.REQUEST_DATA";
export const SET_DATA = "unraisedRevshareReport.SET_DATA";
export const SET_ERROR = "unraisedRevshareReport.SET_ERROR";
export const SET_TABLE_CONTROLS = "unraisedRevshareReport.SET_TABLE_CONTROLS";
export const CLEAR_DATA_TABLE = "unraisedRevshareReport.CLEAR_DATA_TABLE";

export type UnraisedRevshareReportState = {
  items: UnraisedRevshareApiItem[];
  fetching: boolean;
  error?: RequestError;
  count: number;
  tableControls: TableAPIRequestParams<UnraisedRevshareApiItem>;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: UnraisedRevshareReportParams;
  };
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<UnraisedRevshareApiItem>;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<UnraisedRevshareApiItem>;
  };
}

interface ClearTableAction {
  type: typeof CLEAR_DATA_TABLE;
}

export type UnraisedRevshareReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | SetTableControlsAction
  | ClearTableAction;
