import { makeStyles } from "tss-react/mui";
import { Button, MenuItem, TextField, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Batch,
  getPaymentBatchesByType,
} from "../../../../api/payment-data/batches";
import { FormFieldsWrapper } from "../../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../../shared/components/APIFetchingWrapper";
import { RequestError } from "../../../../api/types";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import { PaymentType } from "../../../../shared/enum/payments";
import { months, monthsShort } from "../../../../shared/helpers/dates";

type Props = {
  onSelect: (batch: Batch) => any;
  onSubmit: () => any;
  selectedBatch: Batch | null;
  paymentType: PaymentType;
};

const useStyles = makeStyles()((theme) => ({
  hidden: {
    display: "none",
  },
  batchOptions: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  month: {
    paddingLeft: theme.spacing(1),
  },
}));

export const StepChooseBatch = ({
  onSelect,
  onSubmit,
  selectedBatch,
  paymentType,
}: Props) => {
  const [batches, setBatches] = useState<Batch[]>([]);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState<RequestError>();
  const { classes } = useStyles();

  // const mockBatches = [
  //   {
  //     batchId: 2,
  //     batchRef: "EEAUG2021REVhh",
  //     status: "Loaded",
  //     paymentDataProcessed: true,
  //     dateReceived: "2021-05-18T13:20:02.818674",
  //   },
  //   {
  //     batchId: 21,
  //     batchRef: "O2JUL2021REVE05main",
  //     status: "Loaded",
  //     paymentDataProcessed: true,
  //     dateReceived: "2021-09-03T15:43:45.337608",
  //   },
  //   {
  //     batchId: 26,
  //     batchRef: "O2JUL2020REVE05main",
  //     status: "Loaded",
  //     paymentDataProcessed: true,
  //     dateReceived: "2020-09-03T15:43:45.337608",
  //   },
  // ];

  useEffect(() => {
    let mounted = true;
    setFetching(true);
    getPaymentBatchesByType(paymentType)
      .then((batches) => {
        if (mounted) {
          setBatches(batches);
        }
      })
      .catch((e: RequestError) => {
        if (mounted) {
          setError(e);
        }
      })
      .finally(() => {
        if (mounted) {
          setFetching(false);
        }
      });

    return () => {
      mounted = false;
    };
  }, [paymentType]);

  const handleChangeSelected = (batch: Batch) => {
    if (batch) {
      onSelect(batch);
    }
  };

  const renderBatchOptions = () => {
    const batchOptions = _.groupBy(
      batches.filter(
        (batch) =>
          ["Loaded", "Journaled"].includes(batch.status) &&
          batch.paymentDataProcessed
      ),
      (n) => {
        // month 3-5
        // year 6-9
        // const date = new Date(n.dateReceived);
        return `${n.batchRef.slice(5, 9)}-${
          monthsShort.findIndex((x) => n.batchRef.slice(2, 5) === x) + 1
        }`;
      }
    );

    const yearsGroup = _.groupBy(Object.keys(batchOptions), (n) => {
      return new Date(n).getFullYear();
    });

    return (
      <Box className={classes.batchOptions}>
        {Object.keys(yearsGroup)
          .sort(function (a: string, b: string) {
            return Number(b) - Number(a);
          })
          .map((year) => (
            <Box key={year}>
              <Typography>{year}</Typography>
              <Box>
                {yearsGroup[year]
                  .sort(function (a: string, b: string) {
                    return new Date(b).getMonth() - new Date(a).getMonth();
                  })
                  .map((yearMonth) => (
                    <Box className={classes.month} key={yearMonth}>
                      <Typography>
                        {months[new Date(yearMonth).getMonth()]}
                      </Typography>
                      {batchOptions[yearMonth].map((batch) => (
                        <MenuItem
                          onClick={() => handleChangeSelected(batch)}
                          key={batch.batchId}
                          value={batch.batchId}
                        >
                          {batch.batchRef}
                        </MenuItem>
                      ))}
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Page
      title={`View Existing ${paymentType} Payments`}
      actions={
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!selectedBatch}
          size="large"
        >
          Get Summary
        </Button>
      }
    >
      <APIFetchingWrapper loading={fetching} error={error}>
        {batches.length > 0 && (
          <form>
            <FormFieldsWrapper>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="select-reference"
                select
                label="Select reference"
                defaultValue={selectedBatch ? selectedBatch.batchId : ""}
                value={selectedBatch ? selectedBatch.batchId : ""}
              >
                {renderBatchOptions()}

                {/* below are shadow batches rendered again for the select to work properly */}
                {batches
                  .filter(
                    (batch) =>
                      ["Loaded", "Journaled"].includes(batch.status) &&
                      batch.paymentDataProcessed
                  )
                  .map((batch) => (
                    <MenuItem
                      className={classes.hidden}
                      key={batch.batchId}
                      value={batch.batchId}
                      onClick={() => handleChangeSelected(batch)}
                    >
                      {batch.batchRef}
                    </MenuItem>
                  ))}
              </TextField>
            </FormFieldsWrapper>
          </form>
        )}
        {batches.length === 0 && (
          <CustomStatusChip
            type="info"
            message="No existing payment batches found"
          />
        )}
      </APIFetchingWrapper>
    </Page>
  );
};
