import * as React from "react";

// Generated with https://react-svgr.com/playground/?typescript=true

export function AffinityLogomark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={1506}
      height={346}
      viewBox="0 0 1506 346"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{"Brandmarks/Affinity/Logos/0.Affinity New Light"}</title>
      <defs>
        <path
          d="M149.528.644H75.53C33.816.644 0 34.46 0 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM75.47 12.1h74.118c35.355 0 64.016 28.66 64.016 64.015v74.118c0 35.355-28.661 64.016-64.016 64.016H75.47c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__a"
        />
        <path
          d="M150.074.644H76.077C34.362.644.546 34.46.546 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM76.016 12.1h74.118c35.355 0 64.015 28.66 64.015 64.015v74.118c0 35.355-28.66 64.016-64.015 64.016H76.016c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__d"
        />
        <path
          d="M150.074.644H76.077C34.362.644.546 34.46.546 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM76.016 12.1h74.118c35.355 0 64.015 28.66 64.015 64.015v74.118c0 35.355-28.66 64.016-64.015 64.016H76.016c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__f"
        />
        <path
          d="M150.074.644H76.077C34.362.644.546 34.46.546 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM76.016 12.1h74.118c35.355 0 64.015 28.66 64.015 64.015v74.118c0 35.355-28.66 64.016-64.015 64.016H76.016c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__h"
        />
        <path
          d="M150.074.644H76.077C34.362.644.546 34.46.546 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM76.016 12.1h74.118c35.355 0 64.015 28.66 64.015 64.015v74.118c0 35.355-28.66 64.016-64.015 64.016H76.016c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__j"
        />
        <path
          d="M150.074.644H76.077C34.362.644.546 34.46.546 76.174v73.998c0 41.714 33.816 75.53 75.53 75.53h73.998c41.714 0 75.53-33.816 75.53-75.53V76.175c0-41.715-33.816-75.53-75.53-75.53zM76.016 12.1h74.118c35.355 0 64.015 28.66 64.015 64.015v74.118c0 35.355-28.66 64.016-64.015 64.016H76.016c-35.354 0-64.015-28.661-64.015-64.016V76.114c0-35.354 28.66-64.015 64.015-64.015z"
          id="prefix__l"
        />
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="prefix__b">
          <stop stopColor="#FFF" offset="64.202%" />
          <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M445.989 222.748h-12.744l46.72-102.911h10.642l46.72 102.911h-12.744l-11.84-27.047H457.83l-11.84 27.047zm15.963-36.632h46.561l-23.227-54.069-23.334 54.07zM623.504 222.748H612.57V119.837h64.786v9.585h-53.85v35.026h50.072v9.61h-50.073zM768.48 222.748h-10.934V119.837h64.785v9.585h-53.85v35.026h50.072v9.61h-50.072zM913.59 222.748h-10.908V119.837h10.908zM1081.102 207.045h.319v-87.208h10.908v102.911h-13.649l-64.04-88.1h-.293v88.1h-10.935V119.837h13.675zM1193.086 222.748h-10.935V119.837h10.935zM1317.337 222.748h-10.935v-93.326h-35.945v-9.585h82.85v9.585h-35.97zM1465.107 222.748h-10.909v-44.33l-40.202-58.632h13.65l31.926 48.435 31.928-48.435h13.968l-40.228 58.632z"
          fill="#FFF"
        />
        <g transform="translate(.01 -.635)">
          <mask id="prefix__c" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill="#9790C6" xlinkHref="#prefix__a" />
        </g>
        <g transform="translate(23.721 23.623)">
          <mask id="prefix__e" fill="#fff">
            <use xlinkHref="#prefix__d" />
          </mask>
          <use fill="#E080BF" xlinkHref="#prefix__d" />
        </g>
        <g transform="translate(47.305 47.207)">
          <mask id="prefix__g" fill="#fff">
            <use xlinkHref="#prefix__f" />
          </mask>
          <use fill="#FFD183" xlinkHref="#prefix__f" />
        </g>
        <g transform="translate(71.563 71.465)">
          <mask id="prefix__i" fill="#fff">
            <use xlinkHref="#prefix__h" />
          </mask>
          <use fill="#84E396" fillRule="nonzero" xlinkHref="#prefix__h" />
        </g>
        <g transform="translate(95.147 95.049)">
          <mask id="prefix__k" fill="#fff">
            <use xlinkHref="#prefix__j" />
          </mask>
          <use fill="#71D0C0" xlinkHref="#prefix__j" />
        </g>
        <g transform="translate(119.405 119.307)">
          <mask id="prefix__m" fill="#fff">
            <use xlinkHref="#prefix__l" />
          </mask>
          <use fill="#56B9F1" xlinkHref="#prefix__l" />
        </g>
      </g>
    </svg>
  );
}
