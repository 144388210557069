import { call, put, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import {
  setQueryAssetChangesData,
  setQueryAssetChangesErrorForDisplay,
  setTotalSBAmountOfAssetChangesDueData,
  setTotalSBAmountOfAssetChangesDueError,
} from "./actions";
import {
  RequestDataAction,
  REQUEST_DATA,
  RequestTotalSBAmountOfAssetChangesDueAction,
  REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
} from "./types";
import {
  getQueryAssetChanges,
  getTotalSBAmountOfAssetChangesDue,
  PaginatedChangesResult,
  QueryAssetChangesApiItem,
} from "../../api/asset-changes-due";

function* fetchQueryAssetChanges(action: RequestDataAction) {
  try {
    const data: PaginatedChangesResult<QueryAssetChangesApiItem> = yield call(
      getQueryAssetChanges,
      action.payload
    );
    yield put(setQueryAssetChangesData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryAssetChangesErrorForDisplay(error));
  }
}
function* fetchTotalSBAmountOfAssetChangesDue(
  action: RequestTotalSBAmountOfAssetChangesDueAction
) {
  try {
    const sbAmount: number = yield call(
      getTotalSBAmountOfAssetChangesDue,
      action.payload
    );
    yield put(setTotalSBAmountOfAssetChangesDueData(sbAmount));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setTotalSBAmountOfAssetChangesDueError(error));
  }
}

export function* watchRequestQueryAssetChangesData() {
  yield takeLatest(REQUEST_DATA, fetchQueryAssetChanges);
}
export function* watchRequestSbAmountSumOfAssetChangesDue() {
  yield takeLatest(
    REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
    fetchTotalSBAmountOfAssetChangesDue
  );
}
