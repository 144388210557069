import { format, formatISO } from "date-fns";
import { parseISO } from "date-fns/fp";

enum DateStringBrand {
  _ = "",
}
// use this instead
export type DateString = string & DateStringBrand;

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;
export type MonthString = typeof months[number];

export const monthsShort = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
] as const;

const currentYear = new Date().getFullYear();

export const years = ["2020", "2021"] as const;
export const lastFourYears = [
  currentYear - 3,
  currentYear - 2,
  currentYear - 1,
  currentYear,
] as const;
export type YearString = typeof years[number];

export function checkValidDateStr(val: string): val is DateString {
  if (typeof val !== "string") {
    return false;
  }
  return val.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

export function toDateString(date: Date | string): DateString {
  if (typeof date === "string") {
    if (checkValidDateStr(date)) {
      return date;
    } else {
      // attempt to trim the date eg. "2020-11-13 AM"
      let trimmed = date.split(" ")[0];
      if (checkValidDateStr(trimmed)) {
        return trimmed;
      }
      // attempt to trim the time eg. "2020-11-13T00:00:00"

      let trimmedTime = date.split("T")[0];
      if (checkValidDateStr(trimmedTime)) {
        return trimmedTime;
      }
      throw new Error(`Invalid date string: ${date}`);
    }
  } else {
    try {
      const dateString = formatISO(date, {
        representation: "date",
      });
      if (checkValidDateStr(dateString)) {
        return dateString;
      } else {
        throw new Error(`invalid toDateStr provided: ${date}`);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }
}

export function formatISOForDisplay(ISODateString: string): string {
  try {
    return format(parseISO(ISODateString), "dd/MM/yyyy");
  } catch (e) {
    console.warn(e);
    return "";
  }
}

export function formatISOForDisplayDateTime(ISODateString: string): string {
  try {
    if (ISODateString === "-") {
      return "";
    }

    return format(parseISO(ISODateString), "dd/MM/yyyy h:mm a");
  } catch (e) {
    console.warn(e);
    return "";
  }
}

export function formatISOForDisplayDateTimeHandlingBST(
  ISODateString: string
): string {
  try {
    if (ISODateString === "-") {
      return "";
    }
    if (!ISODateString.endsWith("Z")) {
      ISODateString = ISODateString + "Z";
    }

    const d = parseISO(ISODateString);

    let text = d.toLocaleDateString();
    let moretext = d.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return text + " " + moretext;
  } catch (e) {
    console.warn(e);
    return "";
  }
}

export function getMonthIndex(month: MonthString): number {
  return months.indexOf(month) + 1;
}
