import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { encodeArray, useQueryParams } from "use-query-params";
import { QuerySelfBillApiItem } from "../../../api/self-bill/query";
import { TableAPIRequestParams } from "../../../api/types";
import { DataTable } from "../../../shared/components/DataTable";
import { PaymentType, paymentTypes } from "../../../shared/enum/payments";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import {
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { requestQuerySelfBillData } from "../../../store/querySelfBill/actions";
import { RootState } from "../../../store/rootReducer";
import { FilterControls } from "./FilterControls";

export function ReviewSelfBill() {
  // redux
  const dispatch = useDispatch();
  const { items, count, fetching } = useSelector(
    (state: RootState) => state.querySelfBill
  );

  // table controls state - sort, pagination etc.
  // need to hold on to this in state to combine with filter changes
  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<QuerySelfBillApiItem>
  >({
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 0,
    sortBy: "dateProcessed",
  });

  // filters state management

  const [filters, updateFilters] = useQueryParams({
    dateProcessed: dateRangeParser,
    type: {
      encode: (val: PaymentType[]) => encodeArray(val),
      decode: (input) => decodePermittedArrayParam(input, paymentTypes.slice()),
    },
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  // watch for filters and tableControls and refetch table data from API
  useDeepCompareEffect(() => {
    dispatch(
      requestQuerySelfBillData({
        tableParams: tableControls,
        filterParams: filters,
      })
    );
  }, [tableControls, filters]);

  const hasItems = items.length > 0;
  const countLabel = hasItems && (
    <span style={{ marginLeft: 18 }}>{count} total</span>
  );

  const columns: {
    name: keyof QuerySelfBillApiItem;
    label: string;
    customBodyRender?: (input: string | number) => React.ReactNode;
    align?: "left" | "right";
  }[] = [
    { name: "type", label: "Type" },
    {
      name: "dateProcessed",
      label: "Run Processed Date",
      customBodyRender: (input) => formatISOForDisplay(input as string),
    },
    { name: "status", label: "Status" },
    { name: "totalCreated", label: "Self Bills Created", align: "right" },
    { name: "totalPartners", label: "Number of Partners", align: "right" },
    {
      name: "totalAmount",
      label: "Total Financial Amount of Run",
      customBodyRender: (input) => formatCurrencyGBP(input as number),
      align: "right",
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" alignItems="baseline">
        <Typography variant="h2" gutterBottom>
          View Existing Self Bill Runs
        </Typography>
        {countLabel}
      </Box>
      <FilterControls
        labels={columns}
        filters={filters}
        updateFilters={updateFilters}
        onResetAll={handleResetAllFilters}
      />
      {hasItems ? (
        <DataTable
          title="Payment Runs"
          loading={fetching}
          data={items}
          columns={columns}
          rowsPerPageOptions={[10, 15, 30]}
          recordsCount={count}
          onTableControlsChange={setTableControls}
          tableControls={tableControls}
        />
      ) : (
        "No items"
      )}
    </React.Fragment>
  );
}
