import React, { createRef, useEffect, useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { DatePicker } from "@mui/x-date-pickers";
import { RequestError } from "../../../api/types";
import { DecimalInput } from "../../../shared/components/DecimalInput";
import { toDateString } from "../../../shared/helpers/dates";
import { usePrevious } from "../../../shared/helpers/usePrevious";
import { PartnerRate } from "../../../api/partner";
import {
  setFormValues,
  submitForm,
  resetFormValues,
} from "../../../store/editPartnerRateForm/actions";

type Props = {
  partnerId: number;
  partnerRate: PartnerRate;
  disabled?: boolean;
};

export const EditPartnerRateModal = ({
  partnerRate,
  partnerId,
  disabled,
}: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formRef = createRef<HTMLFormElement>();

  const { isFetching, errors, values } = useSelector(
    (state: RootState) => state.editPartnerRateForm
  );

  const apiError = errors[partnerRate.partnerRateAdjustmentId];
  const prevApiError = usePrevious(apiError);

  useEffect(() => {
    if (apiError !== prevApiError) {
      formRef.current?.scrollTo(0, 0);
    }
  }, [prevApiError, apiError, formRef]);

  useEffect(() => {
    if (open) {
      dispatch(setFormValues(partnerRate));
    }
  }, [partnerRate, open, dispatch]);

  const onCancel = () => {
    setOpen(false);
    dispatch(resetFormValues());
  };

  return (
    <ModalFieldWrapper
      label="Edit Partner Rate"
      onOpen={() => setOpen(true)}
      onCancel={onCancel}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              partnerRateAdjustmentId: partnerRate.partnerRateAdjustmentId,
              done: (err?: RequestError) =>
                err ? reject(err) : resolve(window.location.reload()),
            })
          );
        });
      }}
      id={`edit-partner-rate-${partnerRate.partnerRateAdjustmentId}`}
      gutterSize="small"
      disableSave={values.adjStartDate === null || values.adjEndDate === null}
      buttonDisable={disabled}
      apiError={apiError}
      apiFetching={isFetching}
      formRef={formRef}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <DecimalInput
            size="small"
            label="Adjustment Amount"
            variant="outlined"
            value={values.adjustment}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  adjustment: parseFloat(event.target.value),
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Adjustment Start Date"
            format="dd/MM/yyyy"
            value={!!values.adjStartDate ? new Date(values.adjStartDate) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  adjStartDate: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Adjustment End Date"
            format="dd/MM/yyyy"
            value={!!values.adjEndDate ? new Date(values.adjEndDate) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  adjEndDate: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
