import { RequestError } from "../../api/types";
import {
  SelfBillToParnersParams,
  SelfBillToParnerApi,
} from "../../api/partner/self-bill-to-partner-code";

export type SelfBillToPartnersState = {
  selfBillPartners: SelfBillToParnerApi[];
  selfBillToPartnersLoading: boolean;
  selfBillToPartnersError?: RequestError;
};

export const REQUEST_SELF_BILL_TO_PARTNERS_DATA =
  "getSelfBillToPartnersREQUEST_DATA";
export const SET_SELF_BILL_TO_PARTNERS_DATA = "getSelfBillToPartnersSET_DATA";
export const SET_SELF_BILL_TO_PARTNERS_ERROR = "getSelfBillToPartnersSET_ERROR";

export interface RequestSelfBillToPartnersDataAction {
  type: typeof REQUEST_SELF_BILL_TO_PARTNERS_DATA;
  payload: SelfBillToParnersParams;
}

export interface SetSelfBillToPartnersDataAction {
  type: typeof SET_SELF_BILL_TO_PARTNERS_DATA;
  payload: SelfBillToParnerApi[];
}

export interface SetSelfBillToPartnersErrorAction {
  type: typeof SET_SELF_BILL_TO_PARTNERS_ERROR;
  payload: RequestError;
}

export type PartnerActionTypes =
  | RequestSelfBillToPartnersDataAction
  | SetSelfBillToPartnersDataAction
  | SetSelfBillToPartnersErrorAction;
