import FileDownload from "js-file-download";
import { DataTable } from "../../../shared/components/DataTable";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import { TableAPIRequestParams } from "../../../api/types";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { PartnerStatus, PaymentTerms } from "../../../shared/enum/invoice";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import {
  PartnerAdminInformationReportApiItem,
  postExportPartnerAdminInformationReport,
} from "../../../api/partner-report/generate-partner-admin-info-report";
import { requestPartnerAdminInformationReport } from "../../../store/partnerAdminInformationReport/actions";

export const PartnerAdminInformationReport = () => {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.partnerAdminInformationReport
  );

  const dispatch = useDispatch();
  const [filters, updateFilters] = useQueryParams({
    partnerStatus: withDefault(CommaDelimitedNumericArrayParam, []),
    paymentTerms: withDefault(CommaDelimitedNumericArrayParam, []),
    bdm: withDefault(CommaDelimitedArrayParam, []),
    networkBilledPartnerManager: withDefault(CommaDelimitedArrayParam, []),
    territory: withDefault(CommaDelimitedArrayParam, []),
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
  });

  const [isExporting, setIsExporting] = useState(false);
  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<PartnerAdminInformationReportApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "partnerId",
  });

  const handleSubmit = (
    tableControlsOverride?: TableAPIRequestParams<
      PartnerAdminInformationReportApiItem
    >
  ) => {
    const serviceProviderIds = filters.serviceProvider.map(
      getServiceProviderId
    );

    dispatch(
      requestPartnerAdminInformationReport({
        partnerStatuses: filters.partnerStatus,
        paymentTerms: filters.paymentTerms,
        bdms: filters.bdm,
        networkBilledPartnerManagers: filters.networkBilledPartnerManager,
        territories: filters.territory,
        serviceProviderIds,
        tableControls: tableControlsOverride ?? tableControls,
      })
    );
  };

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );

    dispatch(
      requestPartnerAdminInformationReport({
        partnerStatuses: [],
        paymentTerms: [],
        bdms: [],
        networkBilledPartnerManagers: [],
        territories: [],
        serviceProviderIds: [],
        tableControls,
      })
    );
  };

  const handleExport = () => {
    setIsExporting(true);
    postExportPartnerAdminInformationReport({
      partnerStatuses: filters.partnerStatus,
      paymentTerms: filters.paymentTerms,
      bdms: filters.bdm,
      networkBilledPartnerManagers: filters.networkBilledPartnerManager,
      territories: filters.territory,
      serviceProviderIds: filters.serviceProvider.map(getServiceProviderId),
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const filterMeta = [
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
    {
      name: "partnerStatus",
      label: "Partner Status",
      enum: PartnerStatus,
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      enum: PaymentTerms,
      align: "right",
    },
    {
      name: "networkBilledPartnerManager",
      label: "Network Billed Partner Manager",
    },
    {
      name: "bdm",
      label: "BDM",
    },
    {
      name: "territory",
      label: "Territory",
    },
  ];

  let columns: {
    [key: string]: any;
    name: keyof PartnerAdminInformationReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    {
      name: "partnerName",
      label: "Partner Name",
      align: "right",
    },
    {
      name: "thirdPartyId",
      label: "Partner Code",
      align: "right",
    },
    {
      name: "partnerStatus",
      label: "Partner Status",
      align: "right",
      customBodyRender: (value) => PartnerStatus[parseInt(value)],
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      enum: PaymentTerms,
      align: "right",
    },
    {
      name: "bdm",
      label: "BDM",
      align: "right",
    },
    {
      name: "networkBilledPartnerManager",
      label: "Network Billed Partner Manager",
      align: "right",
    },
    {
      name: "region",
      label: "Region",
      align: "right",
    },
    {
      name: "territory",
      label: "Territory",
      align: "right",
    },
    {
      name: "eeSmePartnerCode",
      label: "EE SME Service Provider Code",
      align: "right",
    },
    {
      name: "eeCorpPartnerCode",
      label: "EE Corp Service Provider Code",
      align: "right",
    },
    {
      name: "o2PartnerCode",
      label: "O2 Service Provider Code",
      align: "right",
    },
    {
      name: "vodafonePartnerCode",
      label: "Vodafone Service Provider Code",
      align: "right",
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Partner Admin Information Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
          >
            Export
          </LoaderButton>
          <Button
            style={{ marginLeft: 8 }}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name="Partner Status"
              id="partner-status-filter-control"
              options={getEnumNames(PartnerStatus)}
              selected={
                filters.partnerStatus
                  ?.filter((status) => status !== null)
                  .map((status) => PartnerStatus[status as number]) || []
              }
              onChange={(status) =>
                updateFilters({
                  partnerStatus: status?.map(
                    (status) =>
                      PartnerStatus[status as keyof typeof PartnerStatus]
                  ),
                })
              }
            />
            <MultiSelect
              name="Payment Terms"
              id="payment-terms-filter-control"
              options={getEnumNames(PaymentTerms)}
              selected={
                filters.paymentTerms
                  ?.filter((paymentTerms) => paymentTerms !== null)
                  .map(
                    (paymentTerms) => PaymentTerms[paymentTerms as number]
                  ) || []
              }
              onChange={(paymentTerms) =>
                updateFilters({
                  paymentTerms: paymentTerms?.map(
                    (paymentTerms) =>
                      PaymentTerms[paymentTerms as keyof typeof PaymentTerms]
                  ),
                })
              }
            />
            <MultiTextField
              type="text"
              name="BDM"
              id="bdm-filter-control"
              selected={filters.bdm || []}
              onChange={(bdm) => updateFilters({ bdm })}
            />
            <MultiTextField
              type="text"
              name="Network Billed Partner Manager"
              id="nbpm-filter-control"
              selected={filters.networkBilledPartnerManager || []}
              onChange={(networkBilledPartnerManager) =>
                updateFilters({ networkBilledPartnerManager })
              }
            />
            <MultiTextField
              type="text"
              name="Territory"
              id="territory-filter-control"
              selected={filters.territory || []}
              onChange={(territory) => updateFilters({ territory })}
            />
            <MultiSelect
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
          handleSubmit(tableControls);
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
