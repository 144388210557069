import {
  AssetReportActionTypes,
  AssetReportState,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
} from "./types";

const initialState: AssetReportState = {
  items: [],
  count: 0,
  fetching: false,
};

export function assetReportReducer(
  state = initialState,
  action: AssetReportActionTypes
): AssetReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_ERROR:
      return { ...state, error: action.payload.error, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        count: action.payload.data.count,
        items: action.payload.data.items,
      };

    default:
      return state;
  }
}
