import { QueryAleAPiItem } from "../../api/asset-ledger-entry";
import { RevshareReceivedApiItem } from "../../api/asset-ledger-entry/revshare-received";
import { RequestError, PaginatedResult } from "../../api/types";

export const SET_DATA = "queryAles.SET_DATA";
export const REQUEST_DATA = "queryAles.REQUEST_DATA";
export const REQUEST_REVSHARE_RECEIVED_DATA =
  "queryAles.REQUEST_REVSHARE_RECEIVED_DATA";
export const SET_REVSHARE_RECEIVED_DATA =
  "queryAles.SET_REVSHARE_RECEIVED_DATA";
export const SET_API_ERROR = "queryAles.SET_API_ERROR";
export const CANCEL_ALES_VIEW = "queryAles.CANCEL_ALES_VIEW";

export type QueryAlesState = {
  aleTypeId: number[];
  items: QueryAleAPiItem[];
  revshareReceived: RevshareReceivedApiItem[];
  fetching: boolean;
  error?: RequestError;
  show: boolean;
  label?: string;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: { assetId: number[]; aleTypeId: number[]; label: string };
}

export interface RequestRevshareReceivedDataAction {
  type: typeof REQUEST_REVSHARE_RECEIVED_DATA;
  payload: { assetId: number; label: string };
}

interface CancelAlesView {
  type: typeof CANCEL_ALES_VIEW;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: { items: PaginatedResult<QueryAleAPiItem>; aleTypeId: number[] };
}

interface SetRevshareReceivedDataAction {
  type: typeof SET_REVSHARE_RECEIVED_DATA;
  payload: RevshareReceivedApiItem[];
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type QueryAlesActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction
  | CancelAlesView
  | RequestRevshareReceivedDataAction
  | SetRevshareReceivedDataAction;
