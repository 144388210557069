import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  Card,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestError } from "../../../../api/types";
import { FormFieldsWrapper } from "../../../../layout/components/FormFieldsWrapper";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import { setPartnerId } from "../../../../store/interfaceAssetReport/actions";
import { RootState } from "../../../../store/rootReducer";

type Props = {
  open: boolean;
  error: RequestError | null;
  onClose: () => void;
  onConfirm: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

export const ResubmitWithPartnerModal = ({
  open,
  error,
  onClose,
  onConfirm,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { resubmitWithPartnerFormValue } = useSelector(
    (state: RootState) => state.assetInterfaceReport
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Card className={classes.card}>
          <Box mt={2} mb={1}>
            <Typography variant="h2" align="center" gutterBottom>
              Set Partner Code
            </Typography>
            {error && (
              <Box mb={2}>
                <CustomStatusChip
                  type="error"
                  title={error.type}
                  message={error.message}
                />
              </Box>
            )}
            <FormFieldsWrapper>
              <FormControl variant="outlined" size="small" fullWidth>
                <TextField
                  size="small"
                  variant="outlined"
                  id="select-partner-third-party-id"
                  label="Set Partner Code"
                  value={resubmitWithPartnerFormValue}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    const partnerThirdPartyId = event.target.value;
                    dispatch(setPartnerId(partnerThirdPartyId));
                  }}
                />
              </FormControl>
            </FormFieldsWrapper>
          </Box>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              color="primary"
              size="large"
              variant="contained"
              type="submit"
              disabled={resubmitWithPartnerFormValue === ""}
              onClick={onConfirm}
            >
              Confirm
            </Button>
            <Button
              size="large"
              variant="outlined"
              onClick={onClose}
              className={classes.buttonCancel}
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};
