import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { DetailPartnerApiItem } from "../../../api/partner";
import { Panel } from "../../../shared/components/tab_panels/Panel";
import { EditOocRevenueShareModal } from "./EditOocRevenueShareModal";
import { CreateOocRevenueShareModal } from "./CreateOocRevenueShareModal";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { CHANGE_TAB } from "../../../store/detailPartners/types";
import { RootState } from "../../../store/rootReducer";
import { DetailPartnersState } from "../../../store/detailPartners/types";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  data: DetailPartnerApiItem;
};

export const OutOfContractTable = ({ data }: Props) => {
  const dispatch = useDispatch();
  const { oocTabIndex } = useSelector<RootState, DetailPartnersState>(
    (state: RootState) => state.detailPartners
  );

  const handleTabChange = (_: React.ChangeEvent<{}>, tabIndex: number) => {
    dispatch({
      type: CHANGE_TAB,
      payload: { tabIndex, tabName: "ooc" },
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1} mt={1.5} pl={1}>
        <Typography variant="h3">
          Out of Contract (OOC) Revenue Share
        </Typography>
        <CreateOocRevenueShareModal
          partnerId={data.partnerId}
          partnerServiceProviderId={oocTabIndex}
        />
      </Box>
      <>
        <Tabs
          onChange={handleTabChange}
          indicatorColor="primary"
          value={oocTabIndex}
          variant="fullWidth"
        >
          {data.partnerServiceProviders.map((sp) => {
            return (
              <Tab
                sx={{ maxWidth: "25%" }}
                value={sp.partnerServiceProviderId}
                label={`${sp.serviceProviderName} ${sp.serviceProviderAccountTypeName}`}
              />
            );
          })}
        </Tabs>

        {data.partnerServiceProviders.map((sp) => {
          const oocRevenueShares = data.oocRevenueShares.filter(
            (ooc) =>
              sp.serviceProviderId === ooc.serviceProviderId &&
              sp.serviceProviderAccountTypeId ===
                ooc.serviceProviderAccountTypeId
          );

          return (
            <Panel value={oocTabIndex} index={sp.partnerServiceProviderId}>
              {oocRevenueShares.length === 0 && (
                <Box textAlign="center" py={2}>
                  <Typography variant="body2">
                    No records available to display.
                  </Typography>
                </Box>
              )}
              {oocRevenueShares.length > 0 && (
                <Box py={2}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset Start Date From</TableCell>
                        <TableCell>Asset Start Date To</TableCell>
                        <TableCell>OOC Paid For (Days)</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {oocRevenueShares.map((ooc) => {
                        return (
                          <TableRow>
                            <TableCell>
                              {formatISOForDisplay(ooc.assetStartDateFrom)}
                            </TableCell>
                            <TableCell>
                              {formatISOForDisplay(ooc.assetStartDateTo)}
                            </TableCell>
                            <TableCell>
                              {ooc.unlimited
                                ? "Unlimited"
                                : ooc.permittedDaysOoc}
                            </TableCell>
                            <TableCell align="right" sx={{ paddingRight: 0 }}>
                              <EditOocRevenueShareModal
                                partnerId={data.partnerId}
                                oocRevenueShare={ooc}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Panel>
          );
        })}
      </>
    </Box>
  );
};
