import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { DateString, formatISOForDisplay } from "../../../shared/helpers/dates";
import { CreateProductRateModal } from "./CreateProductRateModal";
import { DeleteProductRateModal } from "./DeleteProductRateModal";
import { EditProductRateModal } from "./EditProductRateModal";

type Props = {
  productId: number;
  data: ProductRateByIdApiItem[];
};

export const PanelContentFinancial = ({ data, productId }: Props) => {
  const columns: {
    label: string;
    name: keyof ProductRateByIdApiItem;
    align?: "left" | "right";
    customBodyRender?: (input: string | number | null) => React.ReactNode;
  }[] = [
    { name: "productId", label: "Product Id" },
    { name: "serviceProvider", label: "Service Provider" },
    {
      name: "payTariffCommission",
      label: "Pay - Tariff Commission",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "payTariffAdvance",
      label: "Pay - Tariff Advance",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "expTariffCommission",
      label: "Exp - Tariff Commission",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "expTariffAdvance",
      label: "Exp - Tariff Advance",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "lineRental",
      label: "Line Rental",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "payVolumeBonus",
      label: "Pay VB",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "expVolumeBonus",
      label: "Exp VB",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
    },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: (val) => formatISOForDisplay(val as DateString),
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: (val) => formatISOForDisplay(val as DateString),
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell key={i} align={column.align || "left"}>
                {column.label}
              </TableCell>
            ))}
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, i) => (
              <TableRow key={i}>
                {columns.map((col, i) => {
                  let val: string | number | null = row[col.name];
                  if (val === undefined || val === null) {
                    val = "N/A";
                  }

                  return (
                    <TableCell key={i} align={col.align || "left"}>
                      {col.customBodyRender ? col.customBodyRender(val) : val}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <EditProductRateModal productRate={row} />
                </TableCell>
                <TableCell>
                  <DeleteProductRateModal
                    productId={row.productId}
                    productRateId={row.productRateId}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <span>No matching records found</span>
              </TableCell>
            </TableRow>
          )}
          <TableCell colSpan={11}>
            <Box textAlign="center" width="100%">
              <CreateProductRateModal productId={productId} />
            </Box>
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
