import { FormControl, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { toDateString } from "../../../shared/helpers/dates";

type Option<T> = {
  label: string;
  value: T;
};

type Props<T extends number> = {
  options: Option<T>[];
  initialValue: T;
  onCancel?: () => any;
  onSave: (values: { selected: number; date: string }) => Promise<any>;
};

export function ModalServiceProviderStatusChangeType<T extends number>({
  options,
  onCancel,
  onSave,
  initialValue,
}: Props<T>) {
  const [selected, setSelected] = useState(initialValue);
  const [input, setInput] = useState(new Date().toISOString());

  const handleSetInput = (input: string) => {
    setInput(input);
  };

  return (
    <ModalFieldWrapper
      label="Service Provider Status Change Type"
      id="edit-sp-asset-status-change-type"
      onCancel={onCancel}
      onSave={() =>
        onSave({
          selected,
          date: input,
        })
      }
    >
      <FormFieldsWrapper>
        <TextField
          autoFocus
          select
          fullWidth
          variant="outlined"
          label="Please select an option"
          value={selected}
          onChange={(event) => setSelected(parseInt(event.target.value) as T)}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Service Provider Status Change Date"
            format="dd/MM/yyyy"
            value={input ? new Date(input) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              handleSetInput(value ? toDateString(value) : "");
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
}
