import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { NoteApiItem } from "../../../api/note";

type Props = {
  data: NoteApiItem[];
};

export const CommentsTable = ({ data }: Props) => {
  return (
    <Table>
      <TableBody>
        {data.map((note) => (
          <TableRow>
            <TableCell>{note.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
