import { NewProductApiItem } from "../../api/product";
import { ProductGroupApi } from "../../api/product/product-group";
import { BusinessResponseObject, RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_NEW_PRODUCT,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_RESET,
  ResetFormAction,
  CreatePrimaryProductFormValues,
  CreatePrimaryProductsOptions,
  FORM_INITIALIZE_START,
  FORM_INITIALIZE_FINISH,
  SET_PRODUCT_CLASS_OPTIONS,
  SET_PRODUCT_GROUP_OPTIONS,
} from "./types";

export function initializeFormStart() {
  return {
    type: FORM_INITIALIZE_START,
  };
}

export function initializeFormFinish(options: CreatePrimaryProductsOptions) {
  return {
    type: FORM_INITIALIZE_FINISH,
    payload: {
      ...options,
    },
  };
}

export function setFormValues(
  values: Partial<CreatePrimaryProductFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function setProductClassOptions(productClassOptions: string[]) {
  return {
    type: SET_PRODUCT_CLASS_OPTIONS,
    payload: {
      productClassOptions,
    },
  };
}

export function setProductGroupOptions(productGroupOptions: ProductGroupApi[]) {
  return {
    type: SET_PRODUCT_GROUP_OPTIONS,
    payload: {
      productGroupOptions,
    },
  };
}

export function setProduct(product: BusinessResponseObject<NewProductApiItem>) {
  return {
    type: SET_NEW_PRODUCT,
    payload: {
      product,
    },
  };
}

export function submitForm(payload: { done: () => any }): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function resetForm(): ResetFormAction {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}
