import {
  decodeBoolean,
  decodeArray,
  decodeDelimitedArray,
  decodeDelimitedNumericArray,
  encodeArray,
  encodeDelimitedArray,
  encodeDelimitedNumericArray,
  encodeBoolean,
} from "use-query-params";
import { DateString } from "../helpers/dates";
import { toDateString } from "./dates";

export type ParsedQueryParam = string | (string | null)[] | null | undefined;

export const dateRangeParser = {
  encode: (val: [DateString | null, DateString | null]) => encodeArray(val),
  decode: (input: ParsedQueryParam) => decodeDateStringRangeArrayParam(input),
};

export const CommaDelimitedArrayParam = {
  encode: (val: any) => encodeDelimitedArray(val, ","),
  decode: (val: any) => decodeDelimitedArray(val, ","),
};

export const CommaDelimitedNumericArrayParam = {
  encode: (val: any) => encodeDelimitedNumericArray(val, ","),
  decode: (val: any) => decodeDelimitedNumericArray(val, ","),
};

export const BooleanParam = {
  encode: (val: any) => encodeBoolean(val),
  decode: (val: any) => decodeBoolean(val),
};

export function decodePermittedArrayParam<T extends string[]>(
  input: ParsedQueryParam,
  permittedValues: T
) {
  const decoded = (decodeArray(input) || []).filter(
    (x) => x != null
  ) as string[];
  return permittedValues.filter((type) => decoded.includes(type)) as T;
}

function decodeDateStringRangeArrayParam(
  input: ParsedQueryParam
): [DateString | null, DateString | null] {
  if (!Array.isArray(input)) {
    return [null, null];
  }

  if (input.length !== 2) {
    return [null, null];
  }

  const rv = input.map((val) => {
    let rv = null;
    if (typeof val === "string") {
      try {
        rv = toDateString(val);
      } catch (e) {
        console.warn("invalid date string");
      }
    }
    return rv;
  });
  // we have to cast this as (correclty) considers array lenth to be mutable
  return rv as [DateString | null, DateString | null];
}
