import { call, put, select, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  CreatePartnerServiceProviderFormState,
} from "./types";
import { postAddPartnerServiceProvider } from "../../api/partner-service-provider/add";
import { requestDetailPartnerData } from "../detailPartners/actions";

function* submitCreatePartnerServiceProvider(action: FormSubmitAction) {
  try {
    const formState: CreatePartnerServiceProviderFormState = yield select(
      (state: RootState) => state.createPartnerServiceProviderForm
    );

    yield call(postAddPartnerServiceProvider, {
      partnerId: action.payload.partnerId,
      ...formState.values,
    });

    yield put(requestDetailPartnerData(action.payload.partnerId));

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchCreatePartnerServiceProvider() {
  yield takeLatest(FORM_SUBMIT, submitCreatePartnerServiceProvider);
}
