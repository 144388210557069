import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useQueryParams, withDefault } from "use-query-params";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
} from "../../shared/helpers/queryParsing";
import { RootState } from "../../store/rootReducer";
import { FiltersWrapper } from "../../shared/components/filter_controls/FiltersWrapper";
import { Box, Typography, Modal, Button } from "@mui/material";
import { Enum, getEnumNames } from "../../shared/helpers/enum";
import { MultiSelect } from "../../shared/components/filter_controls/MultiSelect";
import { MultiSelectSuggestions } from "../../shared/components/filter_controls/MultiSelectSuggestions";
import { getSuggestions } from "../../api/partner/search";
import { AppliedFilters } from "../../shared/components/filter_controls/AppliedFilters";
import {
  QueryPartnerApiItem,
  QueryPartnersFilterParams,
  PartnerApi,
  PartnerUpdateInfo,
} from "../../api/partner";
import { PartnerStatus, PaymentTerms } from "../../shared/enum/invoice";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  requestQueryPartnersData,
  setQueryPartnersTableControls,
} from "../../store/queryPartners/actions";
import { DataTable } from "../../shared/components/DataTable";
import { MultiTextField } from "../../shared/components/filter_controls/MultiTextField";
import { ModalTextField } from "../../shared/components/modal_field_controls/ModalTextField";
import { fetchExternalPartner } from "../../api/partner/fetch-external-partner";
import { fetchAllExternalPartnersWithDetails } from "../../api/partner/fetch-all-external-partners-detailed";
import { RequestError } from "../../api/types";
import { CustomStatusChip } from "../../shared/components/CustomStatusChip";
import { setIsLoading } from "../../store/ui/actions";
import { LoaderButton } from "../../shared/components/LoaderButton";
import {
  CHANGE_TAB,
  DetailPartnersState,
} from "../../store/detailPartners/types";
import { setPartnerDetailRefreshData } from "../../store/detailPartners/actions";

type Props = { title: string };
interface FetchResponse {
  failedPartnersList: PartnerApi[];
  partnersList: PartnerUpdateInfo[];
}

// Modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const QueryPartners = ({ title }: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items: detailPartners } = useSelector<RootState, DetailPartnersState>(
    (state: RootState) => state.detailPartners
  );

  const navToPartnerCard = (partnerId: number) => {
    const loadedPartner = detailPartners[partnerId];

    if (loadedPartner && loadedPartner.partnerServiceProviders.length > 0) {
      dispatch({
        type: CHANGE_TAB,
        payload: {
          tabIndex:
            loadedPartner.partnerServiceProviders[0].partnerServiceProviderId ||
            0,
          tabName: "ooc",
        },
      });
    } else {
      dispatch({
        type: CHANGE_TAB,
        payload: { tabIndex: 0, tabName: "ooc" },
      });
    }

    history.push(`${path}${partnerId}`, {
      filterSearch: history.location.search,
    });
  };

  // States for refresh logic
  const [refreshSuccess, setRefreshSuccess] = useState(false);
  const [refreshError, setRefreshError] = useState<RequestError>();
  const [failedPartnersCodes, setFailedPartnersCodes] = useState<string[]>([]);
  const [showFailedDetails, setShowFailedDetails] = useState(false);
  const [partnersRefreshedCount, setPartnersRefreshedCount] = useState<
    number
  >();

  const [importSuccess, setImportSuccess] = useState(false);
  const [importError, setImportError] = useState<RequestError | null>(null);
  const { isLoading } = useSelector((state: RootState) => state.ui);
  const { count, fetching, error, items, tableControls } = useSelector(
    (state: RootState) => state.queryPartners
  );

  // filters state management
  const [filters, updateFilters] = useQueryParams({
    partnerId: withDefault(CommaDelimitedNumericArrayParam, []),
    thirdPartyId: withDefault(CommaDelimitedArrayParam, []),
    partnerName: withDefault(CommaDelimitedArrayParam, []),
    paymentTerms: withDefault(CommaDelimitedNumericArrayParam, []),
    partnerStatus: withDefault(CommaDelimitedNumericArrayParam, []),
  });

  const toggleFailedDetails = () => {
    setShowFailedDetails((prev) => !prev);
  };

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  // watch for query changes and refetch table data from API
  useDeepCompareEffect(() => {
    dispatch(
      requestQueryPartnersData({
        tableParams: tableControls,
        filterParams: filters,
      })
    );
  }, [tableControls, filters]);

  const handleRefresh = async () => {
    dispatch(setIsLoading(true));
    setRefreshError(undefined);
    setRefreshSuccess(false);

    try {
      const response = (await fetchAllExternalPartnersWithDetails()) as FetchResponse;

      const failedPartnersList = response.failedPartnersList;
      const partnersCodesList = failedPartnersList.map(
        (partner: PartnerApi) => partner.thirdPartyId
      );
      setPartnersRefreshedCount(response.partnersList.length);

      dispatch(setPartnerDetailRefreshData());
      dispatch(
        requestQueryPartnersData({
          tableParams: tableControls,
          filterParams: filters,
        })
      );

      setRefreshSuccess(true);

      if (partnersCodesList.length > 0) {
        setFailedPartnersCodes(partnersCodesList);
        setRefreshSuccess(false);
      }
    } catch (error) {
      setRefreshError(error as RequestError);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleImport = (partnerCode: string) => {
    return new Promise((resolve, reject) => {
      setImportSuccess(false);
      setImportError(null);
      fetchExternalPartner(partnerCode)
        .then(() => {
          dispatch(
            requestQueryPartnersData({
              tableParams: tableControls,
              filterParams: filters,
            })
          );
          setImportSuccess(true);
          resolve(null);
        })
        .catch((error: RequestError) => {
          setImportError(error);
          reject(error);
        });
    });
  };

  const columns: {
    name: keyof QueryPartnerApiItem;
    label: string;
    align?: "left" | "right";
    enum?: Enum<any>;
  }[] = [
    { name: "partnerId", label: "Partner Id", align: "left" },
    { name: "thirdPartyId", label: "Partner Code", align: "right" },
    { name: "partnerName", label: "Partner Name", align: "right" },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      enum: PaymentTerms,
      align: "right",
    },
    {
      name: "partnerStatus",
      label: "Partner Status",
      enum: PartnerStatus,
      align: "right",
    },
  ];

  return (
    <React.Fragment>
      {refreshSuccess && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Success"
            message="All partners have been refreshed."
            type="success"
          />
        </Box>
      )}
      {failedPartnersCodes.length > 0 && partnersRefreshedCount && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Error"
            message={
              <>
                {partnersRefreshedCount} partners have been refreshed. <br />
                {partnersRefreshedCount - failedPartnersCodes.length} partners
                were successful. <br />
                {failedPartnersCodes.length} partners were unsuccessful. <br />
                <button
                  style={{
                    textDecoration: "underline",
                    background: "none",
                    border: "none",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={toggleFailedDetails}
                >
                  More details
                </button>
              </>
            }
            type="error"
          />

          <Modal
            open={showFailedDetails}
            onClose={toggleFailedDetails}
            aria-labelledby="failed-partners-modal-title"
            aria-describedby="failed-partners-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="failed-partners-modal-title"
                variant="h6"
                component="h2"
              >
                Partner codes which were unsuccessful to refresh
              </Typography>
              <Box
                id="failed-partners-modal-description"
                sx={{
                  mt: 2,
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {failedPartnersCodes.map((code, index) => (
                  <Typography key={index} variant="body1">
                    {code}
                  </Typography>
                ))}
              </Box>
              <Button
                onClick={toggleFailedDetails}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Close
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
      {refreshError && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Error"
            message="Partners could not be refreshed."
            type="error"
          />
        </Box>
      )}
      {importSuccess && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Success"
            message="Partner has been successfully imported."
            type="success"
          />
        </Box>
      )}
      {importError && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Error"
            message="Failed to import partner."
            type="error"
          />
        </Box>
      )}
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">{title}</Typography>
        <Box>
          <LoaderButton
            color="primary"
            variant="outlined"
            loading={isLoading}
            disabled={false}
            onClick={handleRefresh}
            style={{ marginRight: 16 }}
          >
            Refresh All
          </LoaderButton>
          <ModalTextField
            buttonVariant="contained"
            submitButtonLabel="Import"
            buttonLabel="Import Partner"
            label="Partner Code"
            value=""
            id="import-partner"
            onSave={(partnerCode) => handleImport(partnerCode)}
          />
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        controls={() => (
          <React.Fragment>
            <MultiTextField
              type="number"
              name={
                columns.find((label) => label.name === "partnerId")?.label ||
                "partnerId"
              }
              id="partner-id-filter-control"
              selected={filters.partnerId || []}
              onChange={(partnerId) => updateFilters({ partnerId })}
            />
            <MultiSelectSuggestions
              name={
                columns.find((label) => label.name === "thirdPartyId")?.label ||
                "thirdPartyId"
              }
              id="partner-code-filter-control"
              selected={filters.thirdPartyId || []}
              onChange={(thirdPartyId) => updateFilters({ thirdPartyId })}
              suggestionsService={getSuggestions}
              suggestionServiceColumn="partnerCode"
            />
            <MultiSelectSuggestions
              name={
                columns.find((label) => label.name === "partnerName")?.label ||
                "partnerName"
              }
              id="partner-name-filter-control"
              selected={filters.partnerName || []}
              onChange={(partnerName) => updateFilters({ partnerName })}
              suggestionsService={getSuggestions}
              suggestionServiceColumn="partnerName"
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "partnerStatus")
                  ?.label || "partnerStatus"
              }
              id="payment-terms-filter-control"
              options={getEnumNames(PartnerStatus)}
              selected={
                filters.partnerStatus
                  ?.filter((partnerStatus) => partnerStatus !== null)
                  .map(
                    (partnerStatus) => PartnerStatus[partnerStatus as number]
                  ) || []
              }
              onChange={(partnerStatus) =>
                updateFilters({
                  partnerStatus: partnerStatus?.map(
                    (partnerStatus) =>
                      PartnerStatus[partnerStatus as keyof typeof PartnerStatus]
                  ),
                })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "paymentTerms")?.label ||
                "paymentTerms"
              }
              id="payment-terms-filter-control"
              options={getEnumNames(PaymentTerms)}
              selected={
                filters.paymentTerms
                  ?.filter((paymentTerms) => paymentTerms !== null)
                  .map(
                    (paymentTerms) => PaymentTerms[paymentTerms as number]
                  ) || []
              }
              onChange={(paymentTerms) =>
                updateFilters({
                  paymentTerms: paymentTerms?.map(
                    (paymentTerms) =>
                      PaymentTerms[paymentTerms as keyof typeof PaymentTerms]
                  ),
                })
              }
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryPartnersFilterParams}
            meta={columns}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) =>
          dispatch(setQueryPartnersTableControls(tableControls))
        }
        tableControls={tableControls}
        onRowClick={(row) => navToPartnerCard(row.partnerId)}
      />
    </React.Fragment>
  );
};
