import { QueryAssetApiItem, QueryAssetRequestParams } from "../../api/asset";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_SEARCH_TERM,
  SET_TABLE_CONTROLS,
  SET_TABLE_FILTERS,
} from "./types";

export function requestQueryAssetsData(params: QueryAssetRequestParams) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQueryAssetsErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryAssetsData(data: PaginatedResult<QueryAssetApiItem>) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setQueryAssetsTableControls(
  data: TableAPIRequestParams<QueryAssetApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: data,
  };
}

export function setQueryAssetsFilters(data: any) {
  return {
    type: SET_TABLE_FILTERS,
    payload: data,
  };
}

export function setQueryAssetsSearchTerm(data: string) {
  return {
    type: SET_SEARCH_TERM,
    payload: data,
  };
}
