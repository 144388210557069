import React, { useEffect } from "react";
import { Box, FormControl, MenuItem, TextField } from "@mui/material";
import { FormFieldsWrapper } from "../../../../layout/components/FormFieldsWrapper";
import { FileDrop } from "../../../../shared/components/FileDrop";
import {
  ServiceProvider,
  serviceProviders,
} from "../../../../shared/enum/serviceProvider";
import {
  lastFourYears,
  months,
  MonthString,
  YearString,
} from "../../../../shared/helpers/dates";
import { useDispatch } from "react-redux";
import { updateImportPaymentDataForm } from "../../../../store/importPaymentData/actions";
import { ImportPaymentDataForm } from "../../../../store/importPaymentData/types";
import { PaymentType, UpfrontFileType } from "../../../../shared/enum/payments";

type Props = {
  form: ImportPaymentDataForm;
  paymentType: PaymentType;
};

export const ImportForm = ({ form, paymentType }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    updateImportPaymentDataForm({
      paymentType,
    });
  }, [paymentType]);

  const handleFileTypeSelectChange = (
    serviceProvider: ServiceProvider,
    fileType?: UpfrontFileType
  ) => {
    if (paymentType === "REVSHARE") {
      dispatch(updateImportPaymentDataForm({ serviceProvider }));
    }

    if (paymentType === "UPFRONT") {
      dispatch(
        updateImportPaymentDataForm({
          serviceProvider,
          fileType,
        })
      );
    }
  };

  return (
    <form>
      <FormFieldsWrapper>
        {paymentType === "REVSHARE" && (
          <RevShareFileTypeSelect
            value={form.serviceProvider || ""}
            onChange={handleFileTypeSelectChange}
          />
        )}
        {paymentType === "UPFRONT" && (
          <UpfrontFileTypeSelect
            value={form.fileType || ""}
            onChange={handleFileTypeSelectChange}
          />
        )}

        {paymentType === "REVSHARE" && (
          <FormControl variant="outlined" size="small" fullWidth>
            <TextField
              size="small"
              variant="outlined"
              id="select-import-type"
              select
              label="Import Type*"
              value={form.paymentType}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                const importType = event.target.value;
                const paymentType: PaymentType =
                  importType === "Standard" ? "REVSHARE" : "UPLIFT";
                dispatch(updateImportPaymentDataForm({ paymentType }));
              }}
            >
              <MenuItem key="Standard" value="Standard">
                Standard
              </MenuItem>
              <MenuItem key="Uplift" value="Uplift">
                Uplift
              </MenuItem>
            </TextField>
          </FormControl>
        )}

        <FileDrop
          acceptedFileTypes={[".xlsx"]}
          onAcceptedDrop={(file) =>
            dispatch(updateImportPaymentDataForm({ file }))
          }
        />
        <Box display="flex">
          <Box width={150} mr={1}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              select
              label="Month*"
              value={form.month || ""}
              onChange={(e) => {
                const month = e.target.value as MonthString | undefined;
                dispatch(updateImportPaymentDataForm({ month }));
              }}
            >
              {months.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box width={150}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              select
              label="Year*"
              value={form.year || ""}
              onChange={(e) => {
                const year = e.target.value as YearString | undefined;
                dispatch(updateImportPaymentDataForm({ year }));
              }}
            >
              {lastFourYears.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          label="User Reference"
          inputProps={{ maxLength: 8 }}
          helperText="Max 8 characters"
          value={form.userReference || ""}
          onChange={(event) =>
            dispatch(
              updateImportPaymentDataForm({
                userReference: event.target.value || "",
              })
            )
          }
        />
      </FormFieldsWrapper>
    </form>
  );
};

function RevShareFileTypeSelect({
  value,
  onChange,
}: {
  value: ServiceProvider | "";
  onChange: (
    serviceProvider: ServiceProvider,
    fileType?: UpfrontFileType
  ) => any;
}) {
  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      id="select-file-type"
      select
      label="Select File Type*"
      value={value || ""}
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        const serviceProvider = event.target.value as ServiceProvider;
        onChange(serviceProvider);
      }}
    >
      {serviceProviders.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

function UpfrontFileTypeSelect({
  value,
  onChange,
}: {
  value: UpfrontFileType | "";
  onChange: (
    serviceProvider: ServiceProvider,
    fileType?: UpfrontFileType
  ) => any;
}) {
  const fileTypeToServiceProviderMap: {
    [key in UpfrontFileType]: ServiceProvider;
  } = {
    O2Advance: "O2",
    O2VAS: "O2",
    O2GCB: "O2",
    EEUpfront: "EE",
    VodafoneCashAdvance: "Vodafone",
    VodafoneQueries: "Vodafone",
    VodafoneSupplementary: "Vodafone",
  };

  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      id="select-file-type"
      select
      label="Select File Type*"
      value={value}
      onChange={(event) => {
        const fileType = event.target.value as UpfrontFileType;
        const serviceProvider = fileTypeToServiceProviderMap[fileType];

        onChange(serviceProvider, fileType);
      }}
    >
      {Object.keys(fileTypeToServiceProviderMap).map((fileType) => (
        <MenuItem key={fileType} value={fileType}>
          {fileType}
        </MenuItem>
      ))}
    </TextField>
  );
}
