import { Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { QueryProductApiItem, ReadProductApiItem } from "../../../api/product";
import { ProductGroupApi } from "../../../api/product/product-group";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";
import { ProductTypeOption } from "../../../api/product/product-types";
import { Panel } from "../../../shared/components/tab_panels/Panel";
import { TabPanelWrapper } from "../../../shared/components/tab_panels/TabPanelWrapper";
import { setActiveTab } from "../../../store/detailProducts/actions";
import { RootState } from "../../../store/rootReducer";
import { PanelContentFinancial } from "./PanelContentFinancial";
import { PanelContentGeneral } from "./PanelContentGeneral";
import { PanelContentSecondary } from "./PanelContentSecondary";
import { PanelContentSpendCaps } from "./PanelContentSpendCaps";

type Props = {
  data: {
    primaryProduct: QueryProductApiItem;
    secondaryProducts: ReadProductApiItem[];
    primaryRates: ProductRateByIdApiItem[];
    spendCaps: any;
    productGroupOptions: ProductGroupApi[];
    productTypeOptions: ProductTypeOption[];
    durationOptions: string[];
    linkedToAsset: boolean;
  };
};

export const PrimaryProductCard = ({ data }: Props) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: RootState) => state.detailProducts.tabIndex
  );

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    selectedTab: number
  ) => {
    dispatch(setActiveTab(selectedTab));
  };

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  return (
    <TabPanelWrapper>
      <Tabs
        onChange={handleTabChange}
        aria-label="view product tabs"
        indicatorColor="primary"
        value={selectedTab}
        variant="fullWidth"
      >
        <Tab label="General" {...a11yProps} />
        <Tab label="Financials" {...a11yProps} />
        <Tab label="Secondary Products" {...a11yProps} />
        <Tab label="Spend Caps" {...a11yProps} />
      </Tabs>
      <Divider />
      <Panel value={selectedTab} index={0}>
        <PanelContentGeneral
          {...data.primaryProduct}
          linkedToAsset={data.linkedToAsset}
          durationOptions={data.durationOptions}
          productTypeOptions={data.productTypeOptions}
          productGroupOptions={data.productGroupOptions}
        />
      </Panel>
      <Panel value={selectedTab} index={1}>
        <PanelContentFinancial
          productId={data.primaryProduct.productId}
          data={data.primaryRates.filter(
            (r) => r.productId === data.primaryProduct.productId
          )}
        />
      </Panel>
      <Panel value={selectedTab} index={2}>
        <PanelContentSecondary
          data={data.secondaryProducts}
          primaryProductId={data.primaryProduct.productId}
        />
      </Panel>
      <Panel value={selectedTab} index={3}>
        <PanelContentSpendCaps
          data={data.spendCaps}
          primaryProductId={data.primaryProduct.productId}
        />
      </Panel>
    </TabPanelWrapper>
  );
};
