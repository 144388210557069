import { request } from "../..";

export interface SelfBillToParnerApi {
  partnerCode: string;
  partnerName: string;
  partnerId: number;
}
export type SelfBillToParnersParams = {
  partnerId: number;
};
export type UpdateSelfBillToPartnerParams = {
  partnerId: number;
  selfBillToPartnerId: number | null;
};

export function getSelfBillToPartners(params: SelfBillToParnersParams) {
  const partnerId = `${params.partnerId.toString()}`;
  return request<SelfBillToParnerApi[]>(
    `partner/get-self-bill-to-partners/${partnerId}`
  );
}
export function postUpdateSelfBillToPartner(
  params: UpdateSelfBillToPartnerParams
) {
  return request("partner/update-self-bill-to-partner-id", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
