import { request } from "../..";

export type ServiceProviderRate = {
  serviceProviderRateId: number;
  serviceProviderId: number;
  tier: number;
  receivedBillRate: number;
  standardRate: number;
  lowerLimit: number;
  upperLimit: number;
  startDate: string;
  endDate: string;
  productTypeId: number;
};

export function getServiceProviderProduct(
  serviceProviderId: number,
  productTypeId: number,
  tier: number
): Promise<ServiceProviderRate[]> {
  return request<ServiceProviderRate[]>(
    "service-provider/service-provider-rate-values/" +
      serviceProviderId +
      "/" +
      productTypeId +
      "/" +
      tier
  );
}
