import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";

const useStyles = makeStyles()(() => ({
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100vw",
    height: "100vh",
    zIndex: 100,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
}));

export const LoadingOverlay = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.overlay}>
      <Box height={40} width={40}>
        <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
          <circle
            className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            stroke-width="3.6"
          ></circle>
        </svg>
      </Box>
    </Box>
  );
};
