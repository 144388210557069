import React from "react";
import { Chip, Grid } from "@mui/material";
import { DateString, formatISOForDisplay } from "../../helpers/dates";
import { Enum } from "../../helpers/enum";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  chipWrapper: {
    background: theme.palette.grey[100],
    padding: 6,
    borderRadius: 16, // this is the border radius of the contained "chips".  Can't do pill shaped as multiple lines need to be supported.
    width: "100%",
  },
  controls: {
    marginTop: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

type Props = {
  filters: { [key: string]: any };
  meta: {
    name: string;
    label: string;
    enum?: Enum<any>;
    isDateRange?: boolean;
  }[];
  updateFilters: (filters: { [key: string]: any }) => any;
};

export function AppliedFilters({ filters, meta, updateFilters }: Props) {
  const { classes } = useStyles();
  const chips: React.ReactNode[] = [];

  for (let filterKey in filters) {
    const filterCategory = filters[filterKey as keyof typeof filters];
    const filterMeta = meta.find((meta) => meta.name === filterKey);
    const label = filterMeta?.label;

    if (typeof filterCategory === "boolean") {
      chips.push(
        <Chip
          onDelete={() => {
            updateFilters({
              [filterKey]: undefined,
            });
          }}
          key={`${filterKey}-${filterCategory}`}
          label={`${label || filterKey}: ${filterCategory}`}
        />
      );
    }

    if (Array.isArray(filterCategory) && filterCategory.length > 0) {
      if (filterMeta?.isDateRange) {
        // handle eg. Date ranges
        const dateParam = (filterCategory as (DateString | null)[]).filter(
          (x) => x !== null
        );
        if (dateParam.length > 0) {
          const from =
            dateParam[0] !== null &&
            `From: ${formatISOForDisplay(dateParam[0])}`;
          const to = dateParam[1] && `To: ${formatISOForDisplay(dateParam[1])}`;
          const labelDates =
            from && to ? [from, to].join(", ") : [from, to].join("");

          chips.push(
            <Chip
              label={`${label || filterKey} ${labelDates}`}
              key={filterKey}
              onDelete={() => {
                updateFilters({ [filterKey]: undefined });
              }}
            />
          );
        }
      } else {
        // handle everything else

        const isEnum = filterMeta?.enum;
        (filterCategory as (string | number)[]).forEach((filterValue) => {
          // 0 = !filterValue which isn't great for a 0 based enum.  This is to code around that
          if (!filterValue) {
            if (isEnum) {
              const moreDisplayValue = filterMeta?.enum
                ? filterMeta.enum[filterValue].toString()
                : filterValue;
              if (moreDisplayValue === filterValue) {
                return;
              }
            } else {
              return;
            }
          }

          const displayValue = filterMeta?.enum
            ? filterMeta.enum[filterValue].toString()
            : filterValue;

          chips.push(
            <Chip
              onDelete={() => {
                if (filterCategory.length <= 1) {
                  updateFilters({ [filterKey]: undefined });
                } else {
                  updateFilters({
                    [filterKey]: (filterCategory as (string | number)[]).filter(
                      (value) => value !== filterValue
                    ),
                  });
                }
              }}
              key={`${filterKey}-${filterValue}`}
              label={`${label || filterKey}: ${displayValue}`}
            />
          );
        });
      }
    }
  }

  if (chips.length === 0) {
    return null;
  }

  return (
    <Grid className={classes.container} container>
      <div className={classes.chipWrapper}>
        <div className={classes.controls}>{chips}</div>
      </div>
    </Grid>
  );
}
