import FileDownload from "js-file-download";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { encodeArray, useQueryParams } from "use-query-params";
import { AssetFinancialReportParams } from "../../../api/asset-report";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import {
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import {
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { getPrimaryProductReport } from "../../../api/product-report/generate-primary-product-report";
import { Select } from "../../../shared/components/filter_controls/Select";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { RequestError } from "../../../api/types";

export const PrimaryProductReport = () => {
  const [filters, updateFilters] = useQueryParams({
    date: dateRangeParser,
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
  });

  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<RequestError | undefined>(undefined);

  const disabled =
    filters.serviceProvider.length === 0 ||
    filters.date.some((d) => d === null);

  const filterMeta = [
    {
      name: "date",
      label: "Date Range",
      isDateRange: true,
    },
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
  ];

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const handleExport = () => {
    setIsExporting(true);
    setError(undefined);

    const startDate = filters.date[0];
    const endDate = filters.date[1];
    const serviceProvider = filters.serviceProvider[0];

    getPrimaryProductReport({
      startDate,
      endDate,
      serviceProvider,
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .catch((error: RequestError) => {
        setError(error);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Primary Product Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
            disabled={disabled}
          >
            Export
          </LoaderButton>
        </Box>
      </Box>
      {error && (
        <Box display="flex" justifyContent="space-between">
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        </Box>
      )}
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={undefined}
        controls={() => (
          <React.Fragment>
            <Select
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <DateRangeControl
              name="Date Range"
              id="start-date-range-filter-control"
              selected={filters.date}
              onChange={(date) => updateFilters({ date })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as AssetFinancialReportParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
    </React.Fragment>
  );
};
