import { request } from "../..";

export type CreditNoteLinesHeaderAPIItem = {
  selfBillReference: string;
  partnerCode: string;
  partnerName: string;
  postingDate: string;
  reference: string;
  sbTotalExcVat: number;
  sbTotalIncVat: number;
  paymentType: string;
};

export function getCreditNoteLinesHeader(postedDocumentId: number) {
  return request<CreditNoteLinesHeaderAPIItem>(
    `posteddocument/self-bill-credit-note-lines-header/${postedDocumentId}`
  );
}
