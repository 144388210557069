import { RecalculatePartnerRateParams } from "../../api/asset-ledger-entry/recalculate-partner-rate";
import { RequestError } from "../../api/types";

export const FORM_SUBMIT = "formRecalculatePartnerRate.FORM_SUBMIT";
export const FORM_SUCCESS = "formRecalculatePartnerRate.FORM_SUCCESS";
export const FORM_RESET = "formRecalculatePartnerRate.FORM_RESET";
export const SET_ERROR = "formRecalculatePartnerRate.SET_ERROR";

export type FormRecalculatePartnerRateState = {
  success: boolean;
  fetching: boolean;
  error?: RequestError;
};

export interface RecalculatePartnerRateAction {
  type: typeof FORM_SUBMIT;
  payload: {
    params: RecalculatePartnerRateParams;
  };
}

export interface FormSuccessAction {
  type: typeof FORM_SUCCESS;
}

export interface FormResetAction {
  type: typeof FORM_RESET;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    error: RequestError;
  };
}

export type RecalculatePartnerRateActionTypes =
  | RecalculatePartnerRateAction
  | FormSuccessAction
  | SetErrorAction
  | FormResetAction;
