import React from "react";
import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material";

export function formatTrueMeansSuccessBooleanAsIcon(value: boolean) {
  if (value) {
    return <CheckCircleOutline color="success" fontSize="small" />;
  } else {
    return <CancelOutlined color="error" fontSize="small" />;
  }
}

export function formatFalseMeansSuccessBooleanAsIcon(value: boolean) {
  return formatTrueMeansSuccessBooleanAsIcon(!value);
}

export function anyToBoolean(value: string | number | boolean | null): boolean {
  if (value === "true" || value === 1 || value === true) {
    return true;
  }
  return false;
}
