import {
  QuerySelfBillApiItem,
  QuerySelfBillRequestParams,
} from "../../api/self-bill/query";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_API_ERROR, SET_DATA } from "./types";

export function requestQuerySelfBillData(params: QuerySelfBillRequestParams) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQuerySelfBillErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQuerySelfBillData(
  data: PaginatedResult<QuerySelfBillApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
