import { List, ListItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailAssetApiItem, UpdateAssetParams } from "../../../api/asset";
import { RequestError } from "../../../api/types";
import { EditableField } from "../../../shared/components/EditableField";
import { ModalDateField } from "../../../shared/components/modal_field_controls/ModalDateField";
import { ModalSelect } from "../../../shared/components/modal_field_controls/ModalSelect";
import { ModalTextField } from "../../../shared/components/modal_field_controls/ModalTextField";
import { AssetStatus } from "../../../shared/enum/asset";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { getEnumValues } from "../../../shared/helpers/enum";
import {
  requestUpdateAssetItem,
  resetDetailAssetRequestError,
} from "../../../store/detailAssets/actions";
import { getPermission } from "../../../store/permissions/reducers";
import { Permissions } from "../../../store/permissions/types";
import { ModalIdentifier } from "./ModalIdentifier";

type Props = {
  error?: RequestError;
} & Pick<
  DetailAssetApiItem,
  | "assetId"
  | "identifier"
  | "assetInfos"
  | "status"
  | "startDate"
  | "endDate"
  | "dateCreated"
  | "serviceType"
  | "contractName"
  | "thirdPartyId"
  | "serviceProvider"
>;

export const PanelContentConnection = ({
  assetId,
  identifier,
  assetInfos,
  status,
  startDate,
  endDate,
  dateCreated,
  serviceType,
  contractName,
  thirdPartyId,
  serviceProvider,
  error,
}: Props) => {
  const dispatch = useDispatch();
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  type AssetParamsProperty = keyof UpdateAssetParams;
  const [fieldLastEdited, setFieldLastEdited] = useState<
    AssetParamsProperty | undefined
  >();

  return (
    <List>
      <ListItem divider>
        <EditableField label="Asset Id" value={assetId}></EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Identifier" value={identifier}>
          {canWriteAssets && (
            <ModalIdentifier
              value={identifier}
              assetId={assetId}
              assetInfos={assetInfos}
            />
          )}
        </EditableField>
      </ListItem>
      {assetInfos
        .sort((prev, next) => prev.fieldName.localeCompare(next.fieldName))
        .map((assetInfo) => (
          <ListItem divider key={assetInfo.fieldName}>
            <EditableField
              label={assetInfo.fieldName}
              value={assetInfo.fieldValue}
            >
              {canWriteAssets && assetInfo.isEditable && (
                <ModalTextField
                  value={assetInfo.fieldValue || ""}
                  label={assetInfo.fieldName}
                  id={`edit-asset-info-${assetInfo.fieldName}`}
                  onSave={(fieldValue) => {
                    return new Promise((resolve) => {
                      const newAssetInfos = [
                        ...assetInfos.filter(
                          (ai) => ai.fieldName !== assetInfo.fieldName
                        ),
                        {
                          ...assetInfo,
                          fieldValue,
                        },
                      ].sort((prev, next) => prev.id - next.id);

                      dispatch(
                        requestUpdateAssetItem(
                          assetId,
                          { assetInfos: newAssetInfos },
                          () => {
                            resolve(null);
                          }
                        )
                      );
                    });
                  }}
                />
              )}
            </EditableField>
          </ListItem>
        ))}
      <ListItem divider>
        <EditableField
          label="Asset Status"
          value={AssetStatus[status] || status}
          colorValue={status === 0 ? "success" : undefined}
        >
          {canWriteAssets && (
            <ModalSelect
              label="Asset Status"
              id="edit-asset-status"
              initialValue={status}
              options={getEnumValues(AssetStatus).map((enumVal) => ({
                label: AssetStatus[enumVal],
                value: enumVal,
              }))}
              onSave={(status) => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    requestUpdateAssetItem(
                      assetId,
                      { status: Number(status) },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Start Date"
          value={formatISOForDisplay(startDate)}
        >
          {canWriteAssets && (
            <ModalDateField
              label="Start Date"
              type="number"
              id="edit-asset-start-date"
              value={startDate}
              error={(fieldLastEdited === "startDate" && error) || undefined}
              onCancel={() => dispatch(resetDetailAssetRequestError(assetId))}
              onSave={(startDate) => {
                return new Promise((resolve, reject) => {
                  setFieldLastEdited("startDate");
                  dispatch(
                    requestUpdateAssetItem(
                      assetId,
                      { startDate },
                      (err?: RequestError) =>
                        err ? reject(err) : resolve(null)
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="End Date" value={formatISOForDisplay(endDate)}>
          {canWriteAssets && (
            <ModalDateField
              label="End Date"
              type="number"
              id="edit-asset-end-date"
              onCancel={() => dispatch(resetDetailAssetRequestError(assetId))}
              value={endDate}
              error={(fieldLastEdited === "endDate" && error) || undefined}
              onSave={(endDate) => {
                return new Promise((resolve, reject) => {
                  setFieldLastEdited("endDate");
                  dispatch(
                    requestUpdateAssetItem(
                      assetId,
                      { endDate },
                      (err?: RequestError) =>
                        err ? reject(err) : resolve(null)
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Date Created"
          value={formatISOForDisplay(dateCreated)}
        />
      </ListItem>
      <ListItem divider>
        <EditableField label="Service Provider" value={serviceProvider} />
      </ListItem>
      <ListItem divider>
        <EditableField label="Service Type" value={serviceType} />
      </ListItem>
      <ListItem divider>
        <EditableField label="Contract Type" value={contractName} />
      </ListItem>
      <ListItem>
        <EditableField label="Partner Code" value={thirdPartyId} />
      </ListItem>
    </List>
  );
};
