import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(-0.5),
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      marginTop: theme.spacing(0.5),
    },
  },
}));

type Props = {
  children: React.ReactNode;
};

export const DeletableChipWrapper = ({ children }: Props) => {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
};
