import React from "react";
import { ImportSingleFilePage } from "../components/ImportSingleFilePage";
import { postAssetNetworkStatus } from "../../../api/asset/update-assets-network-status";

export const ImportAssetStatus = () => (
  <React.Fragment>
    <ImportSingleFilePage
      uploadService={postAssetNetworkStatus}
      title="Update Asset Network Status"
      acceptedFiles={[".csv"]}
    />
  </React.Fragment>
);
