import {
  PaymentDataApiItem,
  PaymentDataRequestParams,
} from "../../api/payment-data";
import { RequestError, PaginatedResult } from "../../api/types";
import { REQUEST_DATA, SET_API_ERROR, SET_DATA } from "./types";

export function requestPaymentData(params: PaymentDataRequestParams) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setPaymentDataErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setPaymentData(data: PaginatedResult<PaymentDataApiItem>) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
