import { List, ListItem } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { QueryProductApiItem } from "../../../api/product";
import { ProductGroupApi } from "../../../api/product/product-group";
import { ProductTypeOption } from "../../../api/product/product-types";
import { EditableField } from "../../../shared/components/EditableField";
import { ModalSelect } from "../../../shared/components/modal_field_controls/ModalSelect";
import { ModalTextField } from "../../../shared/components/modal_field_controls/ModalTextField";
import {
  getServiceProviderId,
  getServiceProviderName,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { ProductCustomerType } from "../../../shared/enum/product";
import { getEnumValues } from "../../../shared/helpers/enum";
import { requestUpdateProduct } from "../../../store/detailProducts/actions";

type Props = QueryProductApiItem & {
  productTypeOptions: ProductTypeOption[];
  durationOptions: string[];
  productGroupOptions: ProductGroupApi[];
  linkedToAsset: boolean;
};

export const PanelContentGeneral = ({
  productId,
  description,
  productCode,
  serviceProviderProductId,
  name,
  productType,
  serviceProvider,
  productVariant,
  duration,
  productGroup,
  productGroupId,
  customerType,
  productClass,
  durationOptions,
  productTypeOptions,
  productGroupOptions,
  linkedToAsset,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <List>
      <ListItem divider>
        <EditableField label="Product Name" value={name}>
          <ModalTextField
            label="Product Name"
            value={name}
            id="edit-name"
            onSave={(name) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateProduct(productId, { productName: name }, () => {
                    resolve(null);
                  })
                );
              });
            }}
          />
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Product Code" value={productCode}>
          <ModalTextField
            label="Product Code"
            value={productCode}
            id="edit-code"
            onSave={(productCode) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateProduct(productId, { productCode }, () => {
                    resolve(null);
                  })
                );
              });
            }}
          />
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Service Provider Product Code"
          value={serviceProviderProductId}
        >
          {/* <ModalTextField
            label="Service Provider Product Code"
            value={serviceProviderProductId}
            id="edit-sp-product-code"
            onSave={(serviceProviderProductId) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateProduct(productId, { serviceProviderProductId }, () => {
                    resolve(null);
                  })
                );
              });
            }}
          /> */}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Product Type" value={productType}>
          {!linkedToAsset && (
            <ModalSelect
              label="Product Type"
              id="edit-product-type"
              initialValue={
                productTypeOptions.find((option) => option.name === productType)
                  ?.productTypeId || 0
              }
              options={productTypeOptions?.map((option) => ({
                label: option.name,
                value: option.productTypeId,
              }))}
              onSave={(productTypeId) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateProduct(
                      productId,
                      {
                        productTypeId,
                        productType: productTypeOptions.find(
                          (type) => type.productTypeId === productTypeId
                        )?.name,
                      },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Product Class" value={productClass} />
      </ListItem>
      <ListItem divider>
        <EditableField label="Service Provider" value={serviceProvider}>
          {!linkedToAsset && (
            <ModalSelect
              label="Service Provider"
              id="edit-asset-status"
              initialValue={getServiceProviderId(serviceProvider)}
              options={serviceProviders.map((value) => ({
                label: value,
                value: getServiceProviderId(value),
              }))}
              onSave={(serviceProviderId) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateProduct(
                      productId,
                      {
                        serviceProviderId,
                        serviceProvider: getServiceProviderName(
                          serviceProviderId
                        ),
                      },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Duration" value={duration}>
          {!linkedToAsset && (
            <ModalSelect
              label="Duration"
              id="edit-duration"
              initialValue={duration}
              options={durationOptions?.map((option) => ({
                label: option,
                value: parseInt(option),
              }))}
              onSave={(duration) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateProduct(productId, { duration }, () => {
                      resolve(null);
                    })
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Description" value={description}>
          <ModalTextField
            label="Description"
            value={description}
            id="edit-description"
            onSave={(description) => {
              return new Promise((resolve) => {
                dispatch(
                  requestUpdateProduct(productId, { description }, () => {
                    resolve(null);
                  })
                );
              });
            }}
          />
        </EditableField>
      </ListItem>
      <ListItem divider>
        <EditableField label="Product Variant" value={productVariant} />
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Customer Type"
          value={ProductCustomerType[customerType]}
        >
          {!linkedToAsset && (
            <ModalSelect
              label="Customer Type"
              id="edit-customer-type"
              initialValue={customerType}
              options={getEnumValues(ProductCustomerType).map((value) => ({
                label: ProductCustomerType[value],
                value,
              }))}
              onSave={(customerType) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateProduct(productId, { customerType }, () => {
                      resolve(null);
                    })
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
      <ListItem>
        <EditableField label="Product Group" value={productGroup}>
          {!linkedToAsset && (
            <ModalSelect
              label="Product Group"
              id="edit-product-group"
              initialValue={
                productGroupOptions.find(
                  (option) => option.productGroupId === productGroupId
                )?.productGroupId || 0
              }
              options={productGroupOptions?.map((option) => ({
                label: option.name,
                value: option.productGroupId,
              }))}
              onSave={(productGroupId) => {
                return new Promise((resolve) => {
                  dispatch(
                    requestUpdateProduct(
                      productId,
                      {
                        productGroupId,
                        productGroup: productGroupOptions.find(
                          (option) => option.productGroupId === productGroupId
                        )?.name,
                      },
                      () => {
                        resolve(null);
                      }
                    )
                  );
                });
              }}
            />
          )}
        </EditableField>
      </ListItem>
    </List>
  );
};
