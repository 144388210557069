import React, { createRef } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  resetForm,
  setFormValues,
  submitForm,
} from "../../../store/createPartnerNoteForm/actions";
import { CreatePartnerNoteFormState } from "../../../store/createPartnerNoteForm/types";
import { RequestError } from "../../../api/types";

type Props = {
  readonly partnerId: number;
};

export const CreatePartnerNoteModal = ({ partnerId }: Props) => {
  const dispatch = useDispatch();
  const formRef = createRef<HTMLFormElement>();

  const { fetching, values, requestError } = useSelector<
    RootState,
    CreatePartnerNoteFormState
  >((state: RootState) => state.createPartnerNoteForm);

  const onCancel = () => {
    dispatch(resetForm());
  };

  return (
    <ModalFieldWrapper
      label="Add comment"
      onCancel={onCancel}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              done: (err?: RequestError) =>
                err ? reject(err) : resolve(window.location.reload()),
            })
          );
        });
      }}
      id="create-partner-note"
      gutterSize="small"
      disableSave={values.description === ""}
      apiError={requestError}
      apiFetching={fetching}
      formRef={formRef}
      icon={false}
      buttonLabel="Add"
      buttonVariant="contained"
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="description"
            label="Text"
            value={values.description}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              dispatch(setFormValues({ description: event.target.value }));
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
