import { DetailAssetApiItem, UpdateAssetParams } from "../../api/asset";
import { FinancialOverview } from "../../api/asset-ledger-entry/financial-overview";
import { ProofApiItem } from "../../api/proof";
import { RequestError } from "../../api/types";

export const SET_DATA = "detailAssets.SET_DATA";
export const REQUEST_DATA = "detailAssets.REQUEST_DATA";
export const REQUEST_TRUE_UP = "detailAssets.REQUEST_TRUE_UP";
export const SET_API_ERROR = "detailAssets.SET_API_ERROR";
export const RESET_API_ERROR = "detailAssets.RESET_API_ERROR";
export const REQUEST_UPDATE_ITEM = "detailAssets.REQUEST_UPDATE_ITEM";
export const UPDATE_ITEM_DATA = "detailAssets.UPDATE_DATA";
export const CHANGE_TAB = "detailAssets.CHANGE_TAB";

export interface DetailAssetsState {
  items: {
    [id: number]: DetailAssetApiItem & FinancialOverview & ProofApiItem;
  };
  fetchingIds: number[];
  tabIndex: number;
  errors: ({ id: number } & RequestError)[];
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: number; // id
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: DetailAssetApiItem & FinancialOverview & ProofApiItem;
}

export interface RequestUpdateItemAction {
  type: typeof REQUEST_UPDATE_ITEM;
  payload: {
    id: number;
    updates: Partial<UpdateAssetParams>;
    done: (error?: RequestError) => any;
  };
}

interface UpdateItemDataAction {
  type: typeof UPDATE_ITEM_DATA;
  payload: { id: number; updates: Partial<DetailAssetApiItem> };
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: { id: number } & RequestError;
}

interface ResetAPIErrorAction {
  type: typeof RESET_API_ERROR;
  payload: { id: number };
}

interface ChangeTabAction {
  type: typeof CHANGE_TAB;
  payload: { tabIndex: number };
}

export interface RequestTrueUpAssetAction {
  type: typeof REQUEST_TRUE_UP;
  payload: { assetId: number };
}

export type DetailAssetsActionType =
  | RequestDataAction
  | SetDataAction
  | ResetAPIErrorAction
  | SetAPIErrorAction
  | UpdateItemDataAction
  | RequestUpdateItemAction
  | ChangeTabAction
  | RequestTrueUpAssetAction;
