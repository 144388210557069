import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomNumberFormat } from "../DecimalInput";
import { ModalFieldWrapper } from "./ModalFieldWrapper";

type Props = {
  iconOnly?: boolean;
  buttonLabel?: string;
  buttonVariant?: "text" | "outlined" | "contained";
  submitButtonLabel?: string;
  label: string;
  value: string;
  onCancel?: () => any;
  onSave: (value: string) => Promise<any>;
  type?: "text" | "number";
  id: string;
};

export const ModalTextField = ({
  iconOnly,
  label,
  buttonLabel,
  buttonVariant,
  submitButtonLabel,
  value,
  onCancel,
  onSave,
  id,
  type = "text",
}: Props) => {
  const [input, setInput] = useState("");

  useEffect(() => {
    setInput(value?.toString());
  }, [value]);

  const handleSetInput = (input: string) => {
    const val = type === "number" ? input.replace(/[^0-9]/gi, "") : input;
    setInput(val);
  };

  const inputProps =
    type === "number"
      ? {
          inputComponent: CustomNumberFormat as any,
        }
      : {};

  return (
    <ModalFieldWrapper
      id={id}
      iconOnly={iconOnly}
      label={label}
      buttonLabel={buttonLabel}
      submitButtonLabel={submitButtonLabel}
      buttonVariant={buttonVariant}
      onCancel={onCancel}
      onSave={() => onSave(input || "")}
    >
      <TextField
        autoFocus
        id={id}
        fullWidth
        label={label}
        variant="outlined"
        size="small"
        value={input}
        InputProps={inputProps}
        onChange={(event) => {
          handleSetInput(event.target.value.trim());
        }}
      />
    </ModalFieldWrapper>
  );
};
