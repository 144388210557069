import { stringify } from "query-string";
import { request } from "../..";
import { UploadTemplateAmendmentsParams } from "../../../store/uploadTemplateAmendments/types";

export function postTemplateAmendments(params: UploadTemplateAmendmentsParams) {
  let body = new FormData();
  body.append("file", params.file);

  return request(
    `asset-ledger-entry/asset-financials?${stringify({
      serviceProviderId: params.serviceProviderId,
      postingDate: params.postingDate,
      documentDate: params.postingDate,
      journalsOnly: params.journalsOnly,
      unallocatedPosting: params.unallocatedPosting,
    })}`,
    { method: "POST", body },
    false
  );
}
