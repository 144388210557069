import { call, put, takeLatest } from "redux-saga/effects";
import {
  getOutstandingCommissionsReport,
  OutstandingCommissionsApiItem,
} from "../../api/asset-report/generate-outstanding-commissions-report";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setOutstandingCommissionsReportData,
  setOutstandingCommissionsReportErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchOutstandingCommissionsReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<OutstandingCommissionsApiItem> = yield call(
      getOutstandingCommissionsReport,
      action.payload.params
    );

    yield put(setOutstandingCommissionsReportData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setOutstandingCommissionsReportErrorForDisplay(error));
  }
}

export function* watchRequestOutstandingCommissionsReportData() {
  yield takeLatest(REQUEST_DATA, fetchOutstandingCommissionsReport);
}
