import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
  CLEAR_DATA_TABLE,
  OutstandingCommissionsReportActionTypes,
  OutstandingCommissionsReportState,
} from "./types";

const initialState: OutstandingCommissionsReportState = {
  filters: {},
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  },
  items: [],
  count: 0,
  fetching: false,
};

export function outstandingCommissionsReportReducer(
  state = initialState,
  action: OutstandingCommissionsReportActionTypes
): OutstandingCommissionsReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload.tableControls,
      };
    }

    case CLEAR_DATA_TABLE: {
      return {
        ...state,
        items: [],
        count: 0,
        fetching: false,
        filters: {},
      };
    }

    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };
    }

    case SET_DATA: {
      return { ...state, fetching: false, ...action.payload };
    }

    default:
      return state;
  }
}
