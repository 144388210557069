import { request } from "../..";

export function postResubmitInterfaceAssetWithPartner(
  interfaceAssetIds: number[],
  partnerThirdPartyId: string
) {
  return request(
    `interface-asset/resubmit-with-partner?partnerThirdPartyId=${partnerThirdPartyId}`,
    {
      method: "POST",
      body: JSON.stringify(interfaceAssetIds),
    }
  );
}
