import { Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkEnableAdvanceTaken,
  DetailAssetApiItem,
} from "../../../api/asset";
import { FinancialOverview } from "../../../api/asset-ledger-entry/financial-overview";
import { ProofApiItem } from "../../../api/proof";
import { Panel } from "../../../shared/components/tab_panels/Panel";
import { TabPanelWrapper } from "../../../shared/components/tab_panels/TabPanelWrapper";
import {
  CHANGE_TAB,
  DetailAssetsState,
} from "../../../store/detailAssets/types";
import { FinancialBreakdownType } from "../../../store/financialBreakdown/types";
import { RootState } from "../../../store/rootReducer";
import { PanelContentConnection } from "./PanelContentConnection";
import { PanelContentCustomer } from "./PanelContentCustomer";
import { PanelContentFinancials } from "./PanelContentFinancials";
import { PanelContentMisc } from "./PanelContentMisc";
import { PanelContentProduct } from "./PanelContentProduct";
import { PanelContentRevShare } from "./PanelContentRevShare";

type Props = {
  data: DetailAssetApiItem & FinancialOverview & ProofApiItem;
  onUpdateData: (updates: Partial<DetailAssetApiItem>) => Promise<any>;
  onRequestRevshareReceived: (assetId: number) => any;
  onRequestDrillDown: (aleTypeId: number[], label: string) => any;
  onRequestBreakdown: (
    assetId: number,
    breakdownType: FinancialBreakdownType
  ) => any;
};

export const AssetCard = ({
  data,
  onRequestDrillDown,
  onRequestBreakdown,
  onRequestRevshareReceived,
}: Props) => {
  const dispatch = useDispatch();
  const { tabIndex, errors } = useSelector<RootState, DetailAssetsState>(
    (state: RootState) => state.detailAssets
  );

  const { assetId } = data;
  const [isAdvanceEnabled, setIsAdvanceEnabled] = useState(false);

  useEffect(() => {
    checkEnableAdvanceTaken(assetId).then((response) => {
      setIsAdvanceEnabled(response);
    });
  }, [assetId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    dispatch({
      type: CHANGE_TAB,
      payload: { tabIndex },
    });
  };

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  return (
    <TabPanelWrapper>
      <Tabs
        onChange={handleTabChange}
        aria-label="view asset tabs"
        indicatorColor="primary"
        value={tabIndex}
        variant="fullWidth"
      >
        <Tab label="Connection" {...a11yProps} />
        <Tab label="Financials" {...a11yProps} />
        <Tab label="Product" {...a11yProps} />
        <Tab label="Revshare" {...a11yProps} />
        <Tab label="Customer" {...a11yProps} />
        <Tab label="Misc" {...a11yProps} />
      </Tabs>
      <Divider />
      <Panel value={tabIndex} index={0}>
        <PanelContentConnection
          error={errors.find((e) => e.id === assetId)}
          assetId={data.assetId}
          identifier={data.identifier}
          assetInfos={data.assetInfos}
          status={data.status}
          startDate={data.startDate}
          endDate={data.endDate}
          dateCreated={data.dateCreated}
          serviceType={data.serviceType}
          contractName={data.contractName}
          thirdPartyId={data.thirdPartyId}
          serviceProvider={data.serviceProvider}
        />
      </Panel>
      <Panel value={tabIndex} index={1}>
        <PanelContentFinancials
          assetId={data.assetId}
          onRequestDrillDown={onRequestDrillDown}
          onRequestBreakdown={onRequestBreakdown}
          tariffCommission={data.tariffCommission}
          tariffAdvance={data.tariffAdvance}
          serviceCommission={data.serviceCommission}
          serviceAdvance={data.serviceAdvance}
          afCommission={data.afCommission}
          afAdvance={data.afAdvance}
          invoiceStatus={data.invoiceStatus}
          totalInvoice={data.totalInvoice}
          totalClawback={data.totalClawback}
        />
      </Panel>
      <Panel value={tabIndex} index={2}>
        <PanelContentProduct
          advanceTaken={data.advanceTaken}
          productDuration={data.productDuration}
          productName={data.productName}
          secondaryProducts={data.secondaryProducts}
          secondaryProductOptions={data.secondaryProductOptions}
          productCode={data.productCode}
          productId={data.productId}
          assetId={data.assetId}
          assetStartDate={data.startDate}
          isAdvanceEnabled={isAdvanceEnabled}
        />
      </Panel>
      <Panel value={tabIndex} index={3}>
        <PanelContentRevShare
          assetId={data.assetId}
          onRequestDrilldown={onRequestDrillDown}
          onRequestRevshareReceived={onRequestRevshareReceived}
          revShareReceived={data.revShareReceived}
          outstandingAdvance={data.outstandingAdvance}
          monthlyAdvanceRepayment={data.monthlyAdvanceRepayment}
          collectedAdvance={data.collectedAdvance}
          revsharePaid={data.revsharePaid}
        />
      </Panel>
      <Panel value={tabIndex} index={4}>
        <PanelContentCustomer
          assetId={data.assetId}
          customerName={data.customerName}
          customerPostcode={data.customerPostcode}
          spAccountRef={data.spAccountRef}
          spAssetStatus={data.spAssetStatus}
          spAssetStatusChangeType={data.spAssetStatusChangeType}
          spAssetStatusChangeDate={data.spAssetStatusChangeDate}
          subscriberId={data.subscriberId}
        />
      </Panel>
      <Panel value={tabIndex} index={5}>
        <PanelContentMisc
          proofId={data.proofId}
          assetId={data.assetId}
          sourceRef={data.sourceRef}
          sourceRefAssetId={data.sourceRefAssetId}
          signatureProofStatus={data.signatureProofStatus}
          contractProofStatus={data.contractProofStatus}
          signatureProof={data.signatureProof}
          contractProof={data.contractProof}
          sourceCustomerId={data.sourceCustomerId}
          dateCreated={data.dateCreated}
          createdByName={data.createdByName}
        />
      </Panel>
    </TabPanelWrapper>
  );
};
