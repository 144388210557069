import { request } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import { RequestError } from "../../types";

export type UnprocessedEntity = {
  paymentDataId: number;
  assetId: number;
  identifier: string;
  customerBillValue: number;
  postingValue: number;
  billDate: DateString;
  partnerRate: number;
};

export type PostProcessRequestParams = {
  batchId: number;
  items: { paymentDataId: number; partnerRate: number }[];
};

// Typically reponds 200 OK or 422 with a list of unprocessible entities
// If responds 422, the strategy is to respond with a body of partner rates corresponding to each unprocessable entity.
export function postProcessPayment(
  params: PostProcessRequestParams
): Promise<UnprocessedEntity[] | void> {
  return new Promise((resolve, reject) => {
    request(`asset-ledger-entry/payment-data/${params.batchId}`, {
      method: "POST",
      body: JSON.stringify({ items: params.items }),
    })
      .then(() => {
        resolve();
      })
      .catch((e: RequestError) => {
        if (e.status === 422 && e.message.items?.length > 0) {
          return resolve(e.message.items as UnprocessedEntity[]);
        }
        reject(e);
      });
  });
}
