import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import {
  requestQueryInvoiceLinesData,
  setQueryInvoiceLinesTableControls,
} from "../../../store/queryInvoiceLines/actions";
import { useHistory, useLocation } from "react-router-dom";
import { DataTable } from "../../../shared/components/DataTable";
import {
  getQueryInvoiceLinesExcel,
  QueryInvoiceLinesApiItem,
  QueryInvoiceLinesFilterParams,
} from "../../../api/invoice/invoice-lines";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import {
  AssetStatus,
  ContractType,
  contractTypes,
} from "../../../shared/enum/asset";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import {
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultilineTextArea } from "../../../shared/components/filter_controls/MultilineTextArea";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { getSuggestions } from "../../../api/invoice/invoice-lines/search/index";
import { MultiSelectSuggestions } from "../../../shared/components/filter_controls/MultiSelectSuggestions";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import useDeepCompareEffect from "use-deep-compare-effect";
import FileDownload from "js-file-download";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { ModalDeleteInvoiceLine } from "./ModalDeleteInvoiceLine";

type Props = { title: string };
interface LocationState {
  invoiceId: number;
}

export const InvoiceLines = ({ title }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { invoiceId } = location.state || {};
  //redux
  const dispatch = useDispatch();
  const { items, fetching, error, tableControls, count } = useSelector(
    (state: RootState) => state.queryInvoiceLines
  );

  const columns: {
    [key: string]: any;
    name: keyof QueryInvoiceLinesApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id", disableSort: true },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
      disableSort: true,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
      disableSort: true,
    },
    { name: "customerName", label: "Customer Name", disableSort: true },
    { name: "status", label: "Status", enum: AssetStatus, disableSort: true },
    { name: "identifier", label: "Identifier", disableSort: true },
    { name: "serviceProvider", label: "Provider", disableSort: true },
    { name: "contractType", label: "Type", disableSort: true },
    { name: "productCode", label: "Product Code", disableSort: true },
    {
      name: "amount",
      label: "SB Amount",
      align: "right",
      disableSort: true,
      customBodyRender: (value) => formatCurrencyGBP(Number(value)),
    },
    { name: "aleTypeDescription", label: "ALE Type", disableSort: true },
  ];
  //filters

  const [filters, setFilters] = useQueryParams({
    assetId: withDefault(CommaDelimitedNumericArrayParam, []),
    startDate: dateRangeParser,
    endDate: dateRangeParser,
    customerName: withDefault(CommaDelimitedArrayParam, []),
    status: withDefault(CommaDelimitedNumericArrayParam, []),
    identifier: withDefault(CommaDelimitedArrayParam, []),
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    contractType: {
      encode: (val: ContractType[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, contractTypes.slice()),
    },

    productCode: withDefault(CommaDelimitedArrayParam, []),
    amount: withDefault(CommaDelimitedArrayParam, []),
    aleTypeDescription: withDefault(CommaDelimitedArrayParam, []),
  });
  const handleResetAllFilters = () => {
    setFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
    dispatch(
      requestQueryInvoiceLinesData({
        tableParams: tableControls,
        filterParams: {},
        invoiceId: invoiceId,
      })
    );
  };

  const cleanseRequestQueryAssetsData = (filters: any): any => {
    const assetId = filters["assetId"] as string[] | undefined;
    const assetIdParam = assetId ? assetId.filter((x) => x !== null) : [];

    return {
      ...filters,
      assetId: assetIdParam,
    };
  };

  const handleExport = () => {
    getQueryInvoiceLinesExcel({
      tableParams: tableControls,
      filterParams: cleanseRequestQueryAssetsData(filters),
      invoiceId: invoiceId,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  const updateFilters = (filters: any) => {
    setFilters(filters);
  };

  const handleNavToSelfBillList = () => {
    history.push(`/self-bill/list`);
  };
  const appliedFiltersMeta: {
    [key: string]: any;
    name: keyof QueryInvoiceLinesApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [...columns];

  useDeepCompareEffect(() => {
    dispatch(
      requestQueryInvoiceLinesData({
        tableParams: tableControls,
        filterParams: cleanseRequestQueryAssetsData(filters),
        invoiceId: invoiceId,
      })
    );
  }, [tableControls, invoiceId]);

  const handleSubmit = () => {
    const activeFilters = Object.entries(filters).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        if (value !== undefined && value.length !== 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    tableControls.pageNumber = 1;
    dispatch(
      requestQueryInvoiceLinesData({
        tableParams: tableControls,
        filterParams: cleanseRequestQueryAssetsData(activeFilters),
        invoiceId: invoiceId,
      })
    );
  };

  const actions = [
    {
      label: "Delete",
      renderComponent: (row: any) => (
        <ModalDeleteInvoiceLine
          rowData={row}
          invoiceId={invoiceId}
          tableParams={tableControls}
          filterParams={cleanseRequestQueryAssetsData(filters)}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">
            Self Bill Asset Changes {invoiceId}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Box marginRight={1}>
            <Button
              size="large"
              variant="contained"
              onClick={handleNavToSelfBillList}
            >
              Back To Self Bill List
            </Button>
          </Box>
          <Box marginRight={1}>
            <Button size="large" variant="outlined" onClick={handleExport}>
              Export
            </Button>
          </Box>
          <Box>
            <Button size="large" variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        controls={() => (
          <React.Fragment>
            <MultilineTextArea
              id="asset-id-filter-control"
              label={
                columns.find((label) => label.name === "assetId")?.label ||
                "assetId"
              }
              value={filters.assetId.join("\n")}
              helperText="Enter one Asset Id per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                const assetId = selected.filter((id) => /^$|\d+$/.test(id)); // allow for only positive numbers and empty strings
                updateFilters({ assetId });
              }}
            />
            <DateRangeControl
              name={`${
                columns.find((label) => label.name === "startDate")?.label ||
                "startDate"
              }`}
              id="start-date-range-filter-control"
              selected={filters.startDate || [null, null]}
              onChange={(startDate) => setFilters({ startDate })}
            />
            <DateRangeControl
              name={`${
                columns.find((label) => label.name === "endDate")?.label ||
                "endDate"
              }`}
              id="end-date-range-filter-control"
              selected={filters.endDate || [null, null]}
              onChange={(endDate) => setFilters({ endDate })}
            />
            <MultiSelectSuggestions
              suggestionServiceColumn="customerName"
              name={
                columns.find((label) => label.name === "customerName")?.label ||
                "customerName"
              }
              id="customer-name-filter-control"
              selected={filters.customerName || []}
              onChange={(customerName) => setFilters({ customerName })}
              suggestionsService={(suggParams) =>
                getSuggestions({
                  ...suggParams,
                  filterParams: { invoiceId: [invoiceId] },
                })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "status")?.label ||
                "status"
              }
              id="status-filter-control"
              options={getEnumNames(AssetStatus)}
              selected={
                filters.status
                  ?.filter((status) => status !== null)
                  .map((status) => AssetStatus[status as number]) || []
              }
              onChange={(status) =>
                setFilters({
                  status: status?.map(
                    (status) => AssetStatus[status as keyof typeof AssetStatus]
                  ),
                })
              }
            />
            <MultilineTextArea
              id="identifier-filter-control"
              label={
                columns.find((label) => label.name === "identifier")?.label ||
                "identifier"
              }
              value={filters.identifier.join("\n")}
              helperText="Enter one identifier per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                setFilters({ identifier: selected });
              }}
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "serviceProvider")
                  ?.label || "serviceProvider"
              }
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) =>
                setFilters({ serviceProvider: serviceProvider })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "contractType")?.label ||
                "contractType"
              }
              id="contract-type-filter-control"
              options={contractTypes.slice()}
              selected={filters.contractType || []}
              onChange={(contractType) =>
                setFilters({ contractType: contractType })
              }
            />
            <MultiSelectSuggestions
              suggestionServiceColumn="productCode"
              name={
                columns.find((label) => label.name === "productCode")?.label ||
                "productCode"
              }
              id="product-name-filter-control"
              selected={filters.productCode || []}
              onChange={(productCode) => setFilters({ productCode })}
              suggestionsService={(suggParams) =>
                getSuggestions({
                  ...suggParams,
                  filterParams: { invoiceId: [invoiceId] },
                })
              }
            />
            <MultiTextField
              type="decimal number"
              name={
                columns.find((label) => label.name === "amount")?.label ||
                "amount"
              }
              id="bAmount-filter-control"
              selected={filters.amount || []}
              onChange={(amount) => updateFilters({ amount })}
            />
            <MultilineTextArea
              id="aleType-filter-control"
              label={
                columns.find((label) => label.name === "aleTypeDescription")
                  ?.label || "aleTypeDescription"
              }
              value={filters.aleTypeDescription.join("\n")}
              helperText="Enter one ALE Type per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                setFilters({ aleTypeDescription: selected });
              }}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryInvoiceLinesFilterParams}
            meta={appliedFiltersMeta}
            updateFilters={setFilters}
          />
        )}
      />

      <DataTable
        title="Asset Changes"
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) =>
          dispatch(setQueryInvoiceLinesTableControls(tableControls))
        }
        tableControls={tableControls}
        hasActions={true}
        actions={actions}
      />
    </React.Fragment>
  );
};
