import { makeStyles } from "tss-react/mui";
import {
  Box,
  Drawer,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { RootState } from "../../../store/rootReducer";
import { AssetNoteApiItem } from "../../../api/note/get-asset-notes";
import { postAddAssetNote } from "../../../api/note/add-asset-note";
import {
  requestCommentsData,
  toggleComments,
} from "../../../store/comments/actions";

const useStyles = makeStyles()((theme) => ({
  section: {
    width: "500px",
    padding: theme.spacing(1.5),
  },
  form: {
    backgroundColor: "#f2f2f2",
  },
}));

interface CommentsModalProps {
  assetId: number;
}

export const CommentsModal = (props: CommentsModalProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [inputClicked, setInputClicked] = useState(false);

  const comments = useSelector((state: RootState) => state.comments);

  useEffect(() => {
    if (comments.show) {
      dispatch(requestCommentsData(props.assetId));
    }
  }, [dispatch, comments.show, props.assetId]);

  const setModalVisibility = (show: boolean) => dispatch(toggleComments(show));

  const _renderComments = () => {
    if (comments.items.length === 0) {
      return <p>There are no comments at this moment.</p>;
    }

    return (
      <div>
        {comments.items.map((comment: AssetNoteApiItem) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              key={comment.noteId}
              style={{ marginBottom: 20 }}
            >
              <div>
                <div style={{ marginBottom: 8 }}>{comment.description}</div>
              </div>
            </Box>
          );
        })}
      </div>
    );
  };

  const _handleSubmit = () => {
    if (description.length === 0) {
      return;
    }

    postAddAssetNote({
      assetId: props.assetId,
      description,
    }).then(() => {
      setDescription("");
      dispatch(requestCommentsData(props.assetId));
    });
  };

  return (
    <Drawer
      anchor="right"
      open={comments.show}
      onClose={() => {
        setModalVisibility(false);
        setInputClicked(false);
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        {comments.fetching && (
          <Box
            className={classes.section}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
        {!comments.fetching && (
          <>
            <Box className={classes.section} style={{ overflowY: "scroll" }}>
              <Typography variant="h3" gutterBottom>
                Comments
              </Typography>
              {_renderComments()}
            </Box>
            <Box className={classes.section + " " + classes.form}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                style={{ backgroundColor: "white" }}
                rows={3}
                error={description.length === 0 && inputClicked}
                value={description}
                onFocus={() => setInputClicked(true)}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "8px" }}
                onClick={() => _handleSubmit()}
              >
                Add Comment
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};
