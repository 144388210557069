import { request } from "../..";

type RequestRematchApiResponse = {
  success: boolean;
  jobId: string;
};

export function requestRematchByBatchId(batchId: number) {
  return request<RequestRematchApiResponse>(`payment-data/rematch/${batchId}`, {
    method: "POST",
  });
}
