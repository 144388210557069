import {
  DetailProductsActionType,
  DetailProductsState,
  REQUEST_DATA,
  SET_ACTIVE_PERIOD,
  SET_API_ERROR,
  SET_DATA,
  SET_SECONDARY_PRODUCT,
  SET_TAB,
  UPDATE_ITEM_DATA,
  UPDATE_PRODUCT_RATE,
} from "./types";

const initialState: DetailProductsState = {
  items: {},
  fetchingIds: [],
  errors: [],
  tabIndex: 0,
};

export function detailProductsReducer(
  state = initialState,
  action: DetailProductsActionType
): DetailProductsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.concat(action.payload),
        errors: state.errors.filter((error) => error.id !== action.payload),
      };
    case SET_API_ERROR:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter((id) => id !== action.payload.id),
        errors: state.errors.concat(action.payload),
      };
    case SET_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter(
          (id) => id !== action.payload.primaryProduct.productId
        ),
        errors: state.errors.filter(
          (error) => error.id !== action.payload.primaryProduct.productId
        ),
        items: {
          ...state.items,
          [action.payload.primaryProduct.productId]: action.payload,
        },
      };

    case UPDATE_ITEM_DATA:
      const item = state.items[action.payload.id];

      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...item,
            primaryProduct: {
              ...item.primaryProduct,
              ...action.payload.updates,
              name:
                action.payload.updates.productName || item.primaryProduct.name,
            },
          },
        },
      };

    case SET_TAB: {
      return {
        ...state,
        tabIndex: action.payload.tabIndex,
      };
    }

    case SET_SECONDARY_PRODUCT: {
      return {
        ...state,
        activeSecondaryProductId: action.payload.activeId,
      };
    }

    case SET_ACTIVE_PERIOD: {
      return {
        ...state,
        activeSpendCapPeriodId: action.payload.activePeriodId,
      };
    }

    case UPDATE_PRODUCT_RATE: {
      const { productRate } = action.payload;
      const { productId, parentId } = productRate;
      const item = state.items[parentId || productId];

      const updateKey = productRate.parentId
        ? "secondaryRates"
        : "primaryRates";

      return {
        ...state,
        items: {
          ...state.items,
          [parentId || productId]: {
            ...item,
            [updateKey]: [
              ...item[updateKey].filter(
                (x) => x.productRateId !== productRate.productRateId
              ),
              productRate,
            ],
          },
        },
      };
    }

    default:
      return state;
  }
}
