import { call, put, takeLatest } from "redux-saga/effects";
import { ImportResult, postPaymentImport } from "../../api/payment-data";
import { RequestError } from "../../api/types";
import {
  setImportPaymentDataErrorForDisplay,
  setImportPaymentDataResult,
} from "./actions";
import { RequestImportAction, REQUEST_IMPORT } from "./types";

function* fetchPostImportPaymentData(action: RequestImportAction) {
  try {
    const data: ImportResult = yield call(postPaymentImport, action.payload);
    yield put(setImportPaymentDataResult(data));
  } catch (e: any) {
    console.error(e);
    let error: RequestError = e;
    yield put(setImportPaymentDataErrorForDisplay(error));
  }
}

export function* watchRequestPostPaymentDataImport() {
  yield takeLatest(REQUEST_IMPORT, fetchPostImportPaymentData);
}
