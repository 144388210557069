import React from "react";
import { makeStyles } from "tss-react/mui";
import { alpha, ListItem, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: "none",
  },
  li: {
    color: theme.palette.common.white,
  },
  button: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.action.selected, 0.25),
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  gutters: {
    paddingLeft: 32,
  },
}));

type Props = {
  children: React.ReactNode;
  to: string;
};

export const NavGroupItem = ({ children, to }: Props) => {
  const { classes } = useStyles();

  return (
    <Link to={to} className={classes.link}>
      <ListItem
        button
        disableRipple
        disableTouchRipple
        divider
        classes={{
          root: classes.li,
          button: classes.button,
          divider: classes.divider,
          gutters: classes.gutters,
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body2" color="inherit">
              {children}
            </Typography>
          }
        />
      </ListItem>
    </Link>
  );
};
