import { stringify } from "query-string";
import { request, requestFile } from "..";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../types";

export type QueryAleAPiItem = {
  assetLedgerEntryId: number;
  dateCreated: string;
  amount: number;
  reference: string;
  eligibleForPayment: boolean;
  billDate: string;
  aleTypeId: number;
  aleTypeName: string;
  assetId: number;
  invoiceLineId: number;
  invoiceId: number;
  thirdPartyId: string;
  createdByName: string;
  thirdInvoicePartyId: string;
};

export function getQueryAle(assetId: number[], aleTypeId: number[]) {
  const tableParams: Omit<
    TableAPIRequestParams<QueryAleAPiItem>,
    "pageSize" | "pageNumber"
  > = {
    returnAll: true,
    sortBy: "dateCreated",
    sortDirection: 0,
  };
  const filterParams = { aleTypeId, assetId };
  const tableParamsQS = stringifyTableParams(tableParams);
  const filterParamsQS = stringifyFilterParams(filterParams);

  return request<PaginatedResult<QueryAleAPiItem>>(
    `asset-ledger-entry${combine([tableParamsQS, filterParamsQS])}`
  );
}

export function getQueryAleExcel(assetId: number[], aleTypeId: number[]) {
  const tableParams: Omit<
    TableAPIRequestParams<QueryAleAPiItem>,
    "pageSize" | "pageNumber"
  > = {
    returnAll: true,
    sortBy: "dateCreated",
    sortDirection: 0,
  };
  const filterParams = { aleTypeId, assetId };
  const tableParamsQS = stringifyTableParams(tableParams);
  const filterParamsQS = stringifyFilterParams(filterParams);
  const exportQS = stringify({ export: true, returnAll: true });

  return requestFile(
    `asset-ledger-entry${combine([tableParamsQS, filterParamsQS, exportQS])}`
  );
}
