import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type PartnerRateAdjustmentReportApiItem = {
  readonly partnerRateAdjustmentId: number;
  readonly thirdPartyId: string;
  readonly partnerName: string;
  readonly partnerStatus: string;
  readonly serviceProviderName: string;
  readonly tier: number;
  readonly receivedBillRate: number;
  readonly standardRate: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly adjustment: number;
  readonly adjStartDate: string;
  readonly adjEndDate: string;
};

export interface PartnerRateAdjustmentReportParams {
  readonly serviceProviderIds: number[];
  readonly startDate: string;
  readonly endDate: string;
  readonly partnerCodes: (string | null)[];
  readonly partnerStatuses: (number | null)[];
  readonly tableControls: TableAPIRequestParams<
    PartnerRateAdjustmentReportApiItem
  >;
}

export function postGeneratePartnerRateAdjustmentReport(
  params: PartnerRateAdjustmentReportParams
) {
  return request<PaginatedResult<PartnerRateAdjustmentReportApiItem>>(
    `partner-report/generate-partner-rate-adjustment-report${combine([
      stringifyTableParams(params.tableControls),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}

export function postExportPartnerRateAdjustmentReport(
  params: Omit<PartnerRateAdjustmentReportParams, "tableControls">
) {
  return requestFile(
    "partner-report/generate-partner-rate-adjustment-report?export=true&returnAll=true",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}
