import { InterfaceReportApiItem } from "../../api/interface-asset";
import { RequestError } from "../../api/types";

export const REQUEST_DATA = "assetInterfaceReport.REQUEST_DATA";
export const SET_DATA = "assetInterfaceReport.SET_DATA";
export const SET_RESIGN_OVERRIDE = "assetInterfaceReport.SET_RESIGN_OVERRIDE";
export const SET_PARTNER_ID = "assetInterfaceReport.SET_PARTNER_ID";
export const SET_ITEM_CHECKED = "assetInterfaceReport.SET_ITEM_CHECKED";
export const RESET_CHECKED_ITEMS = "assetInterfaceReport.RESET_CHECKED_ITEMS";
export const SET_ERROR = "assetInterfaceReport.SET_ERROR";

export type AssetInterfaceReportState = {
  items: InterfaceReportItem[];
  checked: number[];
  resignOverrideFormValue: string;
  resubmitWithPartnerFormValue: string;
  fetching: boolean;
  error?: RequestError;
};

export type InterfaceReportItem = InterfaceReportApiItem & {
  checked: boolean;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: InterfaceReportItem[];
  };
}

export interface SetResignOverrideAction {
  type: typeof SET_RESIGN_OVERRIDE;
  payload: {
    resignOverride: string;
  };
}

export interface SetPartnerIdAction {
  type: typeof SET_PARTNER_ID;
  payload: {
    partnerThirdPartyId: string;
  };
}

export interface SetItemCheckedAction {
  type: typeof SET_ITEM_CHECKED;
  payload: {
    interfaceAssetId: number;
    checked: boolean;
  };
}

export interface ResetCheckedItemsAction {
  type: typeof RESET_CHECKED_ITEMS;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type AssetInterfaceReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetItemCheckedAction
  | ResetCheckedItemsAction
  | SetErrorAction
  | SetResignOverrideAction
  | SetPartnerIdAction;
