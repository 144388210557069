import React, { useState } from "react";
import { Enum } from "../../shared/helpers/enum";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

type Column<T> = {
  name: keyof T;
  label: string | React.ReactNode;
  customBodyRender?: (input: string, row: T) => React.ReactNode;
  enum?: Enum<any>;
  hidden?: boolean;
  align?: "left" | "right" | "center";
  allowControl?: boolean;
};

interface ColumnControlProps<T> {
  columns: Column<T>[];
  selectedColumns: Column<T>[];
  onSelectedColumnsChange: (selectedColumns: Column<T>[]) => void;
}

export default function ColumnControl<T>({
  columns,
  selectedColumns,
  onSelectedColumnsChange,
}: ColumnControlProps<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<typeof columns>(selectedColumns);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetSelectedColumns = (newColumn: any) => {
    setSelected((previousSetColumns) =>
      previousSetColumns.some((column) => column.name === newColumn.name)
        ? previousSetColumns.filter((column) => column.name !== newColumn.name)
        : [...previousSetColumns, newColumn]
    );
  };

  const handleApply = () => {
    onSelectedColumnsChange(selected);
    handleClose();
  };

  const handleReset = () => {
    onSelectedColumnsChange(columns);
    setSelected(columns);
    handleClose();
  };

  return (
    <Box display="flex" justifyContent="flex-end" padding={1}>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Columns
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        {columns.map(
          (column, index) =>
            column.allowControl !== false && (
              <MenuItem key={index} dense={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected.some((c) => c.name === column.name)}
                      onChange={() => handleSetSelectedColumns(column)}
                    />
                  }
                  label={column.label}
                />
              </MenuItem>
            )
        )}
        <Box display="flex" justifyContent="flex-start" p={1}>
          <Box paddingRight={1}>
            <Button variant="outlined" onClick={handleApply}>
              Apply
            </Button>
          </Box>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Menu>
    </Box>
  );
}
