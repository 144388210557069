import { LoaderButton } from "./../LoaderButton";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import { Create } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { RequestError } from "../../../api/types";
import { APIFetchingWrapper } from "../APIFetchingWrapper";
import { DraggableModal } from "../DraggableModal";

const useStyles = makeStyles()((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  icon?: boolean;
  iconOnly?: boolean;
  children: React.ReactElement;
  label: string;
  buttonLabel?: string;
  submitButtonLabel?: string;
  buttonVariant?: "contained" | "outlined" | "text";
  buttonDisable?: boolean;
  onOpen?: () => any;
  onCancel?: () => any;
  onSave: () => Promise<any>;
  id: string;
  disableSave?: boolean;
  gutterSize?: "small" | "large";
  apiFetching?: boolean;
  apiError?: RequestError;
  formRef?: React.Ref<HTMLFormElement>;
};

export const ModalFieldWrapper = ({
  icon = true,
  iconOnly = false,
  children,
  label,
  buttonLabel,
  submitButtonLabel,
  buttonVariant,
  buttonDisable,
  onOpen,
  onCancel,
  onSave,
  disableSave,
  id,
  gutterSize = "large",
  apiFetching,
  apiError,
  formRef,
}: Props) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (apiError || hasError) {
      if (open === false) {
        setOpen(true);
        onOpen && onOpen();
      }
    }
  }, [apiError, hasError, onOpen, open]);

  const handleOpenClick = () => {
    setOpen(true);
    onOpen && onOpen();
  };

  const handleSaveClick = () => {
    setLoading(true);
    onSave()
      .then(() => {
        setHasError(false);
        setLoading(false);
        setOpen(false);
      })
      .catch((err: Error) => {
        if (err) {
          setLoading(false);
          setHasError(true);
          setOpen(true);
        }
      });
  };

  const handleCancelClick = () => {
    if (!loading) {
      setOpen(false);
      setHasError(false);
      onCancel && onCancel();
    }
  };

  const title = `${id}-title`;
  const description = `${id}-title`;

  return (
    <React.Fragment>
      <DraggableModal
        aria-describedby={description}
        aria-labelledby={title}
        open={open}
        onClose={handleCancelClick}
      >
        <form
          ref={formRef}
          style={{ maxHeight: 600, overflowY: "auto" }}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSaveClick();
          }}
        >
          <Card className={classes.card} id={description}>
            <Box mt={2} mb={gutterSize === "large" ? 4 : 1}>
              <Typography variant="h2" align="center" gutterBottom id={title}>
                {label}
              </Typography>
              <Box display="flex" justifyContent="center">
                <APIFetchingWrapper loading={apiFetching} error={apiError}>
                  {children}
                </APIFetchingWrapper>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              <LoaderButton
                size="large"
                loading={loading}
                color="primary"
                variant="contained"
                type="submit"
                disabled={disableSave}
              >
                {submitButtonLabel || "Save"}
              </LoaderButton>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                onClick={handleCancelClick}
                className={classes.buttonCancel}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </form>
      </DraggableModal>
      {iconOnly ? (
        <IconButton size="small" color="primary" onClick={handleOpenClick}>
          <Create />
        </IconButton>
      ) : (
        <Button
          color="primary"
          size="large"
          startIcon={icon && <Create />}
          onClick={handleOpenClick}
          variant={buttonVariant || "text"}
          disabled={buttonDisable || apiFetching}
        >
          {buttonLabel || "Edit"}
        </Button>
      )}
    </React.Fragment>
  );
};
