import {
  AssetFinancialReportParams,
  AssetReportApiItem,
} from "../../api/asset-report";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR } from "./types";

export function requestAssetReportData(params: AssetFinancialReportParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setAssetReportData(data: PaginatedResult<AssetReportApiItem>) {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setAssetReportErrorForDisplay(error: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  };
}
