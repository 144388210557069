import { request } from "../..";

export interface UpdatePartnerRateAdjustmentParams {
  partnerId: number;
  adjStartDate: string;
  adjEndDate: string;
  adjustment: number;
  partnerRateAdjustmentId: number;
}

export function postUpdatePartnerRateAdjustment(
  params: UpdatePartnerRateAdjustmentParams
) {
  return request("partner/update-partner-rate-adjustment", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
