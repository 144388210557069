import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import {
  QueryProductApiItem,
  QueryProductFilterParams,
} from "../../api/product";
import { getSuggestions } from "../../api/product/search";
import { DataTable } from "../../shared/components/DataTable";
import { AppliedFilters } from "../../shared/components/filter_controls/AppliedFilters";
import { FiltersWrapper } from "../../shared/components/filter_controls/FiltersWrapper";
import { MultiSelect } from "../../shared/components/filter_controls/MultiSelect";
import { MultiSelectSuggestions } from "../../shared/components/filter_controls/MultiSelectSuggestions";
import { MultiTextField } from "../../shared/components/filter_controls/MultiTextField";
import {
  ServiceProvider,
  serviceProviders,
} from "../../shared/enum/serviceProvider";
import { ProductCustomerType } from "../../shared/enum/product";
import { Enum } from "../../shared/helpers/enum";
import {
  CommaDelimitedArrayParam,
  decodePermittedArrayParam,
} from "../../shared/helpers/queryParsing";
import {
  initializeQueryProducts,
  requestQueryProductsData,
  setQueryProductsTableControls,
} from "../../store/queryProducts/actions";
import { RootState } from "../../store/rootReducer";
import { CreatePrimaryProductModal } from "./components/CreatePrimaryProductModal";

type Props = { title: string };

const Component = ({ title }: Props) => {
  // routing
  const { path } = useRouteMatch();
  const history = useHistory();

  const navToProductCard = (productId: number) => {
    history.push(`${path}${productId}`);
  };

  //redux
  const dispatch = useDispatch();
  const { items, count, fetching, filterOptions, tableControls } = useSelector(
    (state: RootState) => state.queryProducts
  );

  const { newProduct } = useSelector(
    (state: RootState) => state.createPrimaryProductForm
  );

  // // table controls state - sort, pagination etc.
  // // need to hold on to this in state to combine with filter changes
  // const [tableControls, setTableControls] = useState<
  //   TableAPIRequestParams<QueryProductApiItem>
  // >({
  //   pageNumber: 1,
  //   pageSize: 10,
  //   sortDirection: 0,
  //   sortBy: "serviceProvider",
  // });

  // filters state management
  const [filters, updateFilters] = useQueryParams({
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    productVariant: withDefault(CommaDelimitedArrayParam, []),
    duration: withDefault(CommaDelimitedArrayParam, []),
    productCode: withDefault(CommaDelimitedArrayParam, []),
    productClass: withDefault(CommaDelimitedArrayParam, []),
    productType: withDefault(CommaDelimitedArrayParam, []),
    name: withDefault(CommaDelimitedArrayParam, []),
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  // watch for query changes and refetch table data from API
  useDeepCompareEffect(() => {
    dispatch(
      requestQueryProductsData({
        tableParams: tableControls,
        filterParams: filters,
      })
    );
  }, [tableControls, filters]);

  useEffect(() => {
    if (newProduct) {
      history.push(`${path}${newProduct?.responseObject.productId}`);
    }
  }, [newProduct, history, path]);

  const columns: {
    name: keyof QueryProductApiItem;
    label: string;
    align?: "left" | "right";
    enum?: Enum<any>;
  }[] = [
    { name: "serviceProvider", label: "Service Provider" },
    { name: "productCode", label: "Product Code" },
    { name: "name", label: "Product Name" },
    { name: "productClass", label: "Product Class" },
    { name: "productType", label: "Product Type" },
    { name: "productVariant", label: "Variant" },
    { name: "duration", label: "Duration", align: "right" },
    { name: "customerType", label: "Customer Type", enum: ProductCustomerType },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Box>
          <CreatePrimaryProductModal />
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name={
                columns.find((label) => label.name === "serviceProvider")
                  ?.label || "serviceProvider"
              }
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <MultiTextField
              name={
                columns.find((label) => label.name === "productCode")?.label ||
                "productCode"
              }
              id="product-code-filter-control"
              selected={filters.productCode || []}
              onChange={(productCode) => updateFilters({ productCode })}
            />
            <MultiSelectSuggestions
              suggestionServiceColumn="name"
              name={
                columns.find((label) => label.name === "name")?.label || "name"
              }
              id="product-name-filter-control"
              selected={filters.name || []}
              onChange={(name) => updateFilters({ name })}
              suggestionsService={getSuggestions}
            />
            <MultiSelect
              id="product-class-filter-control"
              name={
                columns.find((label) => label.name === "productClass")?.label ||
                "productClass"
              }
              options={filterOptions.class}
              selected={filters.productClass}
              onChange={(productClass) => updateFilters({ productClass })}
            />
            <MultiSelect
              id="product-type-filter-control"
              name={
                columns.find((label) => label.name === "productType")?.label ||
                "productType"
              }
              options={filterOptions.type.map((t: any) => t.name)}
              selected={filters.productType}
              onChange={(productType) => updateFilters({ productType })}
            />
            <MultiSelect
              id="variant-filter-control"
              name={
                columns.find((label) => label.name === "productVariant")
                  ?.label || "productVariant"
              }
              options={filterOptions.variant}
              selected={filters.productVariant}
              onChange={(productVariant) => updateFilters({ productVariant })}
            />
            <MultiSelect
              options={filterOptions.duration}
              name={
                columns.find((label) => label.name === "duration")?.label ||
                "duration"
              }
              id="duration-filter-control"
              selected={filters.duration}
              onChange={(duration) => updateFilters({ duration })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryProductFilterParams}
            meta={columns}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        limitHeight
        stickyHeader
        hover
        title="Primary Products"
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[10, 15, 30]}
        recordsCount={count}
        onTableControlsChange={(tableControls) =>
          dispatch(setQueryProductsTableControls(tableControls))
        }
        tableControls={tableControls}
        onRowClick={(row) => navToProductCard(row.productId)}
      />
    </React.Fragment>
  );
};

const withInitialize = (WrappedComponent: React.FC<Props>) => {
  const WithInitializeComponent = (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(initializeQueryProducts());
    }, [dispatch]);

    return <WrappedComponent title={props.title} />;
  };

  return WithInitializeComponent;
};

export const QueryPrimaryProducts = withInitialize(Component);
