import {
  PaymentDataRevShareSummaryApi,
  PaymentDataSummaryApi,
  PaymentDataUpfrontSummaryApi,
} from "../../api/payment-data/summary";
import { RequestError } from "../../api/types";
import { RevShareSummaryProductType } from "../../shared/enum/payments";

export const SET_DATA = "paymentDataSummary.SET_DATA";
export const SET_ENABLE_CREATE_ENTITIES =
  "paymentDataSummary.SET_ENABLE_CREATE_ENTITIES";
export const SET_BATCH_STATUS = "paymentDataSummary.SET_BATCH_STATUS";
export const REQUEST_DATA = "paymentDataSummary.REQUEST_DATA";
export const SET_API_ERROR = "paymentDataSummary.SET_API_ERROR";

export enum PaymentDataBatchStatus {
  BaseStatus = 0,
  Loading = 1,
  Loaded = 2,
  Journaled = 3,
}

export type PaymentDataRevShareSummary = Omit<
  PaymentDataRevShareSummaryApi,
  "totalAmountMatchedByProductType"
> & {
  totalsByProductType: {
    productType: keyof typeof RevShareSummaryProductType;
    amount: number;
  }[];
};

export type PaymentDataUpfrontSummary = Omit<
  PaymentDataUpfrontSummaryApi,
  "totalAmountMatchedByPaymentType" | "totalAmountUnMatchedByReason"
> & {
  totalsMatchedByPaymentType: {
    upfrontPaymentType: string;
    key: number;
    amount: number;
  }[];
  totalsUnmatchedByReason: {
    reason: string;
    key: number;
    amount: number;
  }[];
};

export type PaymentDataSummary =
  | PaymentDataRevShareSummary
  | PaymentDataUpfrontSummary;

export type PaymentDataSummaryState = {
  data?: PaymentDataSummary;
  batchStatus: number;
  enableCreateEntities: boolean;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: string;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaymentDataSummaryApi;
}

interface SetEnableCreateEntitiesAction {
  type: typeof SET_ENABLE_CREATE_ENTITIES;
  payload: {
    enable: boolean;
  };
}

interface SetBatchStatusAction {
  type: typeof SET_BATCH_STATUS;
  payload: {
    batchStatus: number;
  };
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type PaymentDataSummaryActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetEnableCreateEntitiesAction
  | SetBatchStatusAction
  | SetAPIErrorAction;
