import { makeStyles } from "tss-react/mui";

import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { DraggableModalCard } from "../../../shared/components/DraggableModalCard";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { cancelBreakdownModal } from "../../../store/financialBreakdown/actions";
import { RootState } from "../../../store/rootReducer";

const useStyles = makeStyles()((theme) => ({
  container: {
    maxHeight: 440,
  },
  linearProgress: {
    width: "100%",
  },
}));

export const FinancialBreakdownModal = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { show, items, fetching, error, label } = useSelector(
    (state: RootState) => state.financialBreakdown
  );
  const handleCancel = () => dispatch(cancelBreakdownModal());

  return (
    <DraggableModalCard open={show} onClose={handleCancel} title={label || ""}>
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Self Bill ID</TableCell>
              <TableCell align="right">Posting Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableCell colSpan={5}>
                <CustomStatusChip
                  type="error"
                  title={error.type}
                  message={error.message}
                />
              </TableCell>
            ) : fetching ? (
              <TableCell colSpan={5}>
                <LinearProgress className={classes.linearProgress} />
              </TableCell>
            ) : items.length > 0 ? (
              items.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{item.selfBillId}</TableCell>
                  <TableCell align="right">
                    {formatISOForDisplay(item.postingDate)}
                  </TableCell>
                  <TableCell>{item.reference}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell> {formatCurrencyGBP(item.amount)} </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={5}>No matching records</TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DraggableModalCard>
  );
};
