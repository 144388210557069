import {
  OutOfContractFormActions,
  OutOfContractFormFormState,
  FORM_INITIALIZE_FINISH,
  FORM_INITIALIZE_START,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_PAYMENT_TERM_ID,
} from "././types";

const initialState: OutOfContractFormFormState = {
  values: {
    partnerId: 0,
    serviceProviderId: 0,
    oocPaymentTermId: 0,
    serviceProviderName: "",
    oocPaymentTermName: "",
    oocPaymentTermDescription: "",
  },
  oocPaymentTermOptions: [],
  requestError: undefined,
  fetching: false,
};

export function outOfContractFormReducer(
  state = initialState,
  action: OutOfContractFormActions
): OutOfContractFormFormState {
  switch (action.type) {
    case FORM_INITIALIZE_START: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case FORM_INITIALIZE_FINISH: {
      return {
        ...state,
        fetching: false,
        oocPaymentTermOptions: action.payload.oocPaymentTermOptions,
      };
    }

    case SET_PAYMENT_TERM_ID: {
      return {
        ...state,
        values: {
          ...state.values,
          partnerId: action.payload.partnerId,
          oocPaymentTermId: action.payload.oocPaymentTermId,
          serviceProviderId: action.payload.serviceProviderId,
        },
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
