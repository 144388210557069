import {
  AssetInterfaceReportActionTypes,
  AssetInterfaceReportState,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  SET_ITEM_CHECKED,
  RESET_CHECKED_ITEMS,
  SET_RESIGN_OVERRIDE,
  SET_PARTNER_ID,
} from "./types";

const initialState: AssetInterfaceReportState = {
  items: [],
  checked: [],
  resignOverrideFormValue: "",
  resubmitWithPartnerFormValue: "",
  fetching: false,
};

export function assetInterfaceReportReducer(
  state = initialState,
  action: AssetInterfaceReportActionTypes
): AssetInterfaceReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };
    }

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.data,
      };

    case SET_RESIGN_OVERRIDE: {
      return {
        ...state,
        resignOverrideFormValue: action.payload.resignOverride,
      };
    }

    case SET_PARTNER_ID: {
      return {
        ...state,
        resubmitWithPartnerFormValue: action.payload.partnerThirdPartyId,
      };
    }

    case SET_ITEM_CHECKED: {
      const { checked, interfaceAssetId } = action.payload;

      return {
        ...state,
        checked: checked
          ? [...state.checked, interfaceAssetId]
          : state.checked.filter((c) => c !== interfaceAssetId),
      };
    }

    case RESET_CHECKED_ITEMS: {
      return {
        ...state,
        checked: [],
      };
    }

    default:
      return state;
  }
}
