import { request } from "../..";

export type ProductTypeOption = {
  productTypeId: number;
  name: string;
  description: string;
};

export type ProductTypesApi = {
  items: ProductTypeOption[];
};

export function getProductTypeOptions(): Promise<ProductTypesApi> {
  return request<ProductTypesApi>("product/product-types");
}
