import {
  EditOocRevenueShareActions,
  EditOocRevenueShareFormState,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_PAYMENT_TERM_OPTIONS,
} from "./types";

const initialState: EditOocRevenueShareFormState = {
  errors: [],
  fetching: false,
  paymentTermOptions: [],
  values: {
    assetStartDateFrom: "",
    assetStartDateTo: "",
    oocPaymentTermId: 0,
  },
};

export function editOocRevenueShareFormReducer(
  state = initialState,
  action: EditOocRevenueShareActions
): EditOocRevenueShareFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_PAYMENT_TERM_OPTIONS: {
      return {
        ...state,
        paymentTermOptions: action.payload.values,
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        errors: state.errors.concat(action.payload),
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
