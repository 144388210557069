import { stringify } from "query-string";
import { request } from "../..";
import { ServiceProvider } from "../../../shared/enum/serviceProvider";
import { ProductClass, ProductVariant } from "../../../shared/enum/product";
import { DateString } from "../../../shared/helpers/dates";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

// @NOTE - these APIs were created with slightly different purposes in mind despite the similar resource locations.  There is some overlapping functionality
// @NOTE - Pay attention to the return types and filtering capabilities when deciding which endpoint to use.

type ProductRateApiBasics = {
  productRateId: number;
  productId: number;
  parentId: number | null;
  productClass: ProductClass;
  productCode: string;
  productName: string;
  serviceProvider: ServiceProvider;
  startDate: DateString;
  endDate: DateString;
  duration: number;
};

export type ProductRateByIdApiItem = ProductRateApiBasics & {
  payTariffCommission: number;
  payTariffAdvance: number;
  expTariffCommission: number;
  expTariffAdvance: number;
  lineRental: number;
  payVolumeBonus: number;
  expVolumeBonus: number;
  payServiceCommission: number;
  payServiceAdvance: number;
  expServiceCommission: number;
  expServiceAdvance: number;
  serviceLineRental: number;
};

export type ProductRateApiItem = ProductRateApiBasics & {
  upgrade: ProductVariant;
  productGroup: string;
  productType: string;
};

export type QueryProductRateFilterParams = {
  serviceProvider: ServiceProvider[];
  startDate: [string | null, string | null];
  endDate: [string | null, string | null];
  upgrade: ProductVariant[];
  duration: string[];
  productClass: ProductClass[];
  productType: string[];
};

export type QueryProductRatesRequestParams = {
  tableParams: TableAPIRequestParams<ProductRateApiItem>;
  filterParams: Partial<QueryProductRateFilterParams>;
};

// Get product rates with abillity to set filters
export function getQueryProductRates({
  tableParams,
  filterParams,
}: QueryProductRatesRequestParams) {
  const tableParamsQS = stringifyTableParams(tableParams);
  const filterParamsQS = stringifyFilterParams(filterParams);
  return request<PaginatedResult<ProductRateApiItem>>(
    `/product/product-rates${combine([tableParamsQS, filterParamsQS])}`
  );
}

export type ProductRatesByIdApi = {
  items: ProductRateByIdApiItem[];
};

// Gets all secondary product rates for a given product and date
export function getSecondaryProductRatesByIdAndStartDate(
  id: number,
  startDate: string
) {
  return request<ProductRatesByIdApi>(
    `/product/product-rates/${id}/${startDate}`
  );
}

// Gets the Product Rates, with values, for a specific Product Id. The default is to get primary product rates but secondary product rates can be retrieved by setting querystring parameter getSecondaryProductRates to true.
export function getProductRatesById(
  id: number,
  getSecondaryProductRates?: boolean
) {
  const qs = getSecondaryProductRates
    ? stringify({ getSecondaryProductRates: true })
    : "";

  return request<ProductRatesByIdApi>(
    `product/product-rates/${id}${combine([qs])}`
  );
}
