import {
  CreditNoteLinesAPIItem,
  CreditNoteLinesParams,
} from "../../api/posted-document/credit-note-lines/index";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "creditNoteLines.REQUEST_DATA";
export const SET_DATA = "creditNoteLines.SET_DATA";
export const SET_ERROR = "creditNoteLines.SET_ERROR";

export type CreditNoteLinesState = {
  items: CreditNoteLinesAPIItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: CreditNoteLinesParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<CreditNoteLinesAPIItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type CreditNoteLinesActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
