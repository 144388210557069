import { call, put, takeLatest } from "redux-saga/effects";
import {
  getJobProgressWithFilter,
  JobProgressApiItem,
} from "../../api/jobprogress/get-with-filter";
import { RequestError } from "../../api/types";
import { setProgressReportData, setProgressReportError } from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchProgressReport(action: RequestDataAction) {
  try {
    const data: JobProgressApiItem[] = yield call(getJobProgressWithFilter);
    yield put(setProgressReportData(data));
  } catch (e: any) {
    console.error(e);
    let error: RequestError = e;
    yield put(setProgressReportError(error));
  }
}

export function* watchRequestProgressReportData() {
  yield takeLatest(REQUEST_DATA, fetchProgressReport);
}
