import React from "react";
import { postUpdateSubscriberId } from "../../../api/asset/update-asset-subscriberids";
import { ImportSingleFilePage } from "../components/ImportSingleFilePage";

export const ImportSubscriberId = () => (
  <React.Fragment>
    <ImportSingleFilePage
      uploadService={postUpdateSubscriberId}
      title="Update Subscriber Id"
      acceptedFiles={[".xlsx"]}
    />
  </React.Fragment>
);
