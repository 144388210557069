import { request, requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import { PaginatedResult, TableAPIRequestParams } from "../../types";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { stringify } from "query-string";

export type QueryPostedDocumentApiItem = {
  selfBillReference: number | null;
  postingDate: DateString | null;
  reference: string;
  partnerCode: number;
  partnerName: string;
  sbTotalExcVat: number;
  sbTotalIncVat: number;
  paymentType: string;
};

export type QueryPostedDocumentLinesApiItem = {
  assetId: number;
  identifier: string;
  customer: string;
  startDate: DateString | null;
  serviceProvider: string;
  productCode: string;
  description: string | null;
  glCode: string;
  partnerShareAmount: number | null;
  monthlyAdvanceRepayment: number | null;
  totalExcVat: number | null;
};

export type QueryInvoiceLinesRequestParams = {
  tableParams: TableAPIRequestParams<QueryPostedDocumentLinesApiItem>;
  postedDocumentId: number;
};


export function getPostedInvoiceLines(params: QueryInvoiceLinesRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  return request<PaginatedResult<QueryPostedDocumentLinesApiItem>>(
    `posteddocument/self-bill-invoice-lines/${params.postedDocumentId}${combine([
      tableParamsQS,
    ])}`
  );
}

export function getPostedInvoiceLinesExcel(
  params: QueryInvoiceLinesRequestParams
) {
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `posteddocument/self-bill-invoice-lines/${params.postedDocumentId}${combine([
      exportFlag,
    ])}`
  );
}
