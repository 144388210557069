import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import { QueryAssetApiItem, QueryAssetRequestParams } from "../../api/asset";

export const SET_DATA = "queryAssets.SET_DATA";
export const SET_TABLE_CONTROLS = "queryAssets.SET_TABLE_CONTROLS";
export const SET_TABLE_FILTERS = "queryAssets.SET_TABLE_FILTERS";
export const SET_SEARCH_TERM = "queryAssets.SET_SEARCH_TERM";
export const REQUEST_DATA = "queryAssets.REQUEST_DATA";
export const SET_API_ERROR = "queryAssets.SET_API_ERROR";

export type QueryAssetsState = {
  items: QueryAssetApiItem[];
  count: number;
  fetching: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  tableControls: TableAPIRequestParams<QueryAssetApiItem>;
  filters: any;
  searchTerm: string;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryAssetRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryAssetApiItem>;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: TableAPIRequestParams<QueryAssetApiItem>;
}

interface SetTableFiltersAction {
  type: typeof SET_TABLE_FILTERS;
  payload: any;
}

interface SetSearchTermAction {
  type: typeof SET_SEARCH_TERM;
  payload: string;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type QueryAssetsActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetTableControlsAction
  | SetTableFiltersAction
  | SetSearchTermAction
  | SetAPIErrorAction;
