import { Chip } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "tss-react/mui";

const useStyles = (size?: "small" | "medium") =>
  makeStyles()((theme) => ({
    chip: {
      marginRight: theme.spacing(0.5),
    },
    deleteIcon: {
      width: size === "small" ? 16 : 22,
      height: size === "small" ? 16 : 22,
      cursor: "pointer",
      color: "rgba(39, 29, 62, 0.26)",
    },
  }))();

export const DeletableChip = (props: {
  onDelete?: () => any;
  value?: string;
  size?: "small" | "medium";
  [x: string]: any;
}) => {
  const { onDelete, size } = props;
  const { classes } = useStyles(size);
  return Boolean(props.onDelete) ? (
    <Chip
      className={classes.chip}
      deleteIcon={
        <div
          onMouseDown={(event) => {
            if (!props.disabled && event.button === 0) {
              event.stopPropagation();
              onDelete && onDelete();
            }
          }}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </div>
      }
      {...props}
    />
  ) : (
    <Chip className={classes.chip} {...props} />
  );
};
