import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  DetailPartnerApiItem,
  getPartnerDetail,
  validateVatRegNo,
  VatValidationResponse,
} from "../../api/partner";
import { RequestDataAction, REQUEST_DATA } from "./types";
import { setDetailPartnerData, setDetailPartnerRequestError } from "./actions";
import { RequestError } from "../../api/types";
import {
  PartnerNoteApiItem,
  getPartnerNotes,
} from "../../api/note/get-partner-notes";

function* fetchDetailPartnerData(action: RequestDataAction) {
  try {
    const data: {
      partnerData: DetailPartnerApiItem;
      partnerNotes: PartnerNoteApiItem[];
    } = yield all({
      partnerData: call(getPartnerDetail, action.payload.partnerId),
      partnerNotes: call(getPartnerNotes, action.payload.partnerId),
    });
    const vatValidation: VatValidationResponse = yield call(
      validateVatRegNo,
      data.partnerData.vatRegNo
    );
    yield put(
      setDetailPartnerData({
        ...data.partnerData,
        notes: data.partnerNotes,
        isVatRegNumnberValid: vatValidation.isValid,
      })
    );
  } catch (e: any) {
    const error: RequestError = e;
    yield put(
      setDetailPartnerRequestError({ id: action.payload.partnerId, ...error })
    );
  }
}

export function* watchRequestDetailPartnerData() {
  yield takeLatest(REQUEST_DATA, fetchDetailPartnerData);
}
