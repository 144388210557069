import React, { createRef, useEffect, useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";
import {
  initalizeStartEditProductRateForm,
  setFormValues,
  submitForm,
} from "../../../store/editProductRateForm/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { RequestError } from "../../../api/types";
import { DecimalInput } from "../../../shared/components/DecimalInput";
import { toDateString } from "../../../shared/helpers/dates";
import { usePrevious } from "../../../shared/helpers/usePrevious";
import { EditProductRateFormState } from "../../../store/editProductRateForm/types";

type Props = {
  productRate: ProductRateByIdApiItem;
};

export const EditProductRateModal = ({ productRate }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formRef = createRef<HTMLFormElement>();

  const { isFetching, errors, isLinkedToAsset, baseValues } = useSelector<
    RootState,
    EditProductRateFormState
  >((state: RootState) => state.editProductRateForm);

  const apiError = errors[productRate.productRateId];
  const prevApiError = usePrevious(apiError);

  useEffect(() => {
    if (apiError !== prevApiError) {
      formRef.current?.scrollTo(0, 0);
    }
  }, [prevApiError, apiError, formRef]);

  useEffect(() => {
    if (open) {
      dispatch(initalizeStartEditProductRateForm(productRate));
    }
  }, [productRate, open, dispatch]);

  return (
    <ModalFieldWrapper
      label="Edit Product Rate"
      onOpen={() => setOpen(true)}
      onCancel={() => setOpen(false)}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              done: (err?: RequestError) => (err ? reject(err) : resolve(null)),
            })
          );
        });
      }}
      id={`edit-product-rate-${baseValues.productRateId}`}
      gutterSize="small"
      disableSave={baseValues.startDate === null || baseValues.endDate === null}
      apiError={apiError}
      apiFetching={isFetching}
      formRef={formRef}
    >
      <FormFieldsWrapper>
        {!productRate.parentId && (
          <>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Tariff Commission"
                variant="outlined"
                value={baseValues.payTariffCommission}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payTariffCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Tariff Advance"
                variant="outlined"
                value={baseValues.payTariffAdvance}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payTariffAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Tariff Commission"
                variant="outlined"
                value={baseValues.expTariffCommission}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expTariffCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Tariff Advance"
                variant="outlined"
                value={baseValues.expTariffAdvance}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expTariffAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Line Rental"
                variant="outlined"
                value={baseValues.lineRental}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      lineRental: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay Volume Bonus"
                variant="outlined"
                value={baseValues.payVolumeBonus}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payVolumeBonus: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp Volume Bonus"
                variant="outlined"
                value={baseValues.expVolumeBonus}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expVolumeBonus: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
          </>
        )}
        {productRate.parentId && (
          <>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Service Commission"
                variant="outlined"
                value={baseValues.payServiceCommission}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payServiceCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Service Advance"
                variant="outlined"
                value={baseValues.payServiceAdvance}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payServiceAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Service Commission"
                variant="outlined"
                value={baseValues.expServiceCommission}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expServiceCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Service Advance"
                variant="outlined"
                value={baseValues.expServiceAdvance}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expServiceAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Service Line Rental"
                variant="outlined"
                value={baseValues.serviceLineRental}
                disabled={isLinkedToAsset}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      serviceLineRental: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
          </>
        )}
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Start Date"
            format="dd/MM/yyyy"
            value={
              !!baseValues.startDate ? new Date(baseValues.startDate) : null
            }
            disabled={isLinkedToAsset}
            onChange={(value, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  startDate: value ? toDateString(value) : "",
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="End Date"
            format="dd/MM/yyyy"
            value={!!baseValues.endDate ? new Date(baseValues.endDate) : null}
            onChange={(event: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  endDate: event?.toISOString(),
                })
              );
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
