import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  AssetProduct,
  CreateAssetParams,
  postCreateAsset,
} from "../../api/asset";
import { getAllPartners, PartnerApi } from "../../api/partner";
import {
  getProductClasses,
  ProductClassApi,
} from "../../api/product/product-class";
import {
  getQueryProductRates,
  getSecondaryProductRatesByIdAndStartDate,
  ProductRateApiItem,
  ProductRatesByIdApi,
  QueryProductRatesRequestParams,
} from "../../api/product/product-rates";
import {
  getProductTypeOptions,
  ProductTypesApi,
} from "../../api/product/product-types";
import { PaginatedResult, RequestError } from "../../api/types";
import { getServiceProviderId } from "../../shared/enum/serviceProvider";
import { requestQueryAssetsData } from "../queryAssets/actions";
import { RootState } from "../rootReducer";
import {
  initializeFormFinish,
  resetForm,
  setError,
  setPrimaryOptions,
  setSecondaryOptions,
} from "./actions";
import {
  CreateAssetFormValues,
  FormSubmitAction,
  FORM_INITIALIZE_START,
  FORM_SUBMIT,
  PrimaryOption,
  SET_FORM_VALUES,
  SET_VALUE_PRIMARY,
} from "./types";

function* initializeAssetForm() {
  try {
    const productTypes: ProductTypesApi = yield call(getProductTypeOptions);
    const productClasses: ProductClassApi[] = yield call(getProductClasses);
    const partners: PartnerApi[] = yield call(getAllPartners);

    yield put(
      initializeFormFinish({
        productTypes: productTypes.items,
        productClasses,
        partners,
      })
    );
  } catch (e) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* submitCreateAssetForm(action: FormSubmitAction) {
  try {
    const base: CreateAssetFormValues = yield select(
      (state: RootState) => state.createAssetForm.base
    );

    const { searchTerm, tableControls } = yield select(
      (state: RootState) => state.queryAssets
    );

    if (
      base.primaryProduct === undefined ||
      base.isAdvanceTaken === undefined ||
      base.publicIdentifier === undefined ||
      base.startDate === undefined ||
      base.customerName === undefined ||
      base.contractTypeId === undefined ||
      base.partnerId === undefined
    ) {
      return;
    }

    const assetProducts: AssetProduct[] = [
      {
        productId: base.primaryProduct.productId,
        productRateId: base.primaryProduct.productRateId,
        duration: base.primaryProduct.duration,
        isPrimary: true,
      },
      ...base.selectedSecondaryOptions.map((o) => ({
        productId: o.productId,
        duration: o.duration,
        isPrimary: false,
      })),
    ];

    const params: CreateAssetParams = {
      isAdvanceTaken: base.isAdvanceTaken,
      serviceProviderId: getServiceProviderId(base.serviceProvider),
      publicIdentifier: base.publicIdentifier,
      startDate: base.startDate,
      customerName: base.customerName,
      contractTypeId: base.contractTypeId,
      partnerId: base.partnerId,
      additionalMonths: base.additionalMonths || 0,
      assetProducts,
    };

    yield call(postCreateAsset, params);
    yield put(resetForm());

    yield put(
      requestQueryAssetsData({
        tableParams: tableControls,
        filterParams: {},
        searchTerm,
      })
    );

    yield call(action.payload.done);
  } catch (e) {
    let error: RequestError = e;
    yield put(setError(error));
    yield call(() => action.payload.done(error));
  }
}

function* fetchPrimaryOptions() {
  try {
    const baseValues: CreateAssetFormValues = yield select(
      (state: RootState) => state.createAssetForm.base
    );

    const params: QueryProductRatesRequestParams = {
      tableParams: {
        pageSize: 10,
        pageNumber: 1,
        sortBy: "productName",
        sortDirection: 0,
        returnAll: true,
      },
      filterParams: {
        serviceProvider: [baseValues.serviceProvider || "EE"],
        productType: [baseValues.productType?.name || ""],
        productClass: [baseValues.productClass || ""],
        upgrade: [baseValues.productVariant || ""],
        duration: [baseValues.duration || ""],
        startDate: [null, baseValues.startDate || null],
        endDate: [baseValues.startDate || null, null],
      },
    };

    const data: PaginatedResult<ProductRateApiItem> = yield call(
      getQueryProductRates,
      params
    );

    yield put(
      setPrimaryOptions(
        data.items.map((item) => ({
          productCode: item.productCode,
          productName: item.productName,
          productRateId: item.productRateId,
          productId: item.productId,
          startDate: item.startDate,
          endDate: item.endDate,
          duration: item.duration,
        }))
      )
    );
  } catch (e) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* fetchSecondaryOptions() {
  try {
    const primaryProduct: PrimaryOption = yield select(
      (state: RootState) => state.createAssetForm.base.primaryProduct
    );

    const data: ProductRatesByIdApi = yield call(
      getSecondaryProductRatesByIdAndStartDate,
      primaryProduct.productId,
      primaryProduct.startDate
    );

    yield put(setSecondaryOptions(data.items));
  } catch (e) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

export function* watchCreateAssetFormSubmit() {
  yield takeLatest(FORM_SUBMIT, submitCreateAssetForm);
}

export function* watchCreateAssetFormInitialize() {
  yield takeLatest(FORM_INITIALIZE_START, initializeAssetForm);
}

export function* watchSetValuesCreateAssetForm() {
  yield takeLatest(SET_FORM_VALUES, fetchPrimaryOptions);
}

export function* watchSetPrimaryProductCreateAssetForm() {
  yield takeLatest(SET_VALUE_PRIMARY, fetchSecondaryOptions);
}
