import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  PostedInvoiceListState,
  PostedInvoiceListActionTypes,
} from "./types";

const initialState: PostedInvoiceListState = {
  items: [],
  count: 0,
  fetching: false,
};

export function postedInvoiceListReducer(
  state = initialState,
  action: PostedInvoiceListActionTypes
): PostedInvoiceListState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.data.items,
        count: action.payload.data.count,
      };

    default:
      return state;
  }
}
