import {
  DeferredCostReportApiItem,
  DeferredCostReportParams,
} from "../../api/asset-report";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  RequestDataAction,
  REQUEST_DATA,
  SET_DATA,
  SET_ERROR,
  CLEAR_DATA_TABLE,
  SET_TABLE_CONTROLS,
} from "./types";

export function requestDeferredCostReport(
  params: DeferredCostReportParams
): RequestDataAction {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setDeferredCostReportData(
  data: PaginatedResult<DeferredCostReportApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setDeferredCostReportErrorForDisplay(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}

export function clearDeferredCostReportTableData() {
  return {
    type: CLEAR_DATA_TABLE,
  };
}

export function setDeferredCostReportTableControls(
  tableControls: TableAPIRequestParams<DeferredCostReportApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: {
      tableControls,
    },
  };
}
