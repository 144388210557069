import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#B0B0B0",
    },
    minHeight: 0,
    minWidth: 0,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
}));

interface ActionItem {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  renderComponent?: (row: any) => React.ReactNode;
}

interface ActionsMenuProps {
  actions: ActionItem[];
  rowData: any;
}

export const ActionsMenu = ({ actions, rowData }: ActionsMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.iconButton}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menuItem}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (action.onClick) {
                action.onClick();
                handleClose();
              }
            }}
            className={classes.menuItem}
          >
            {action.renderComponent ? (
              action.renderComponent(rowData)
            ) : (
              <>
                {action.icon}
                {action.label}
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
