import { stringify } from "query-string";
import { request, requestFile } from "../..";
import { AssetStatus } from "../../../shared/enum/asset";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type DisconRecUpValueReportApiItem = {
  assetId: number;
  identifier: string;
  tempCtn: string;
  startDate: string;
  endDate: string;
  status: string;
  dealer: string;
  customerName: string;
  provider: string;
  productCode: string;
  productName: string;
  serviceProviderStatusChangeType: number;
  serviceProviderStatusChangeDate: string;
  outstandingAdvance: number;
  calculatedCommsClawback: number;
  payTariffCommission: number;
  payServiceCommission: number;
  afCommission: number;
};

export type DisconRecUpValueReportParams = {
  assetStatuses: (AssetStatus | null)[];
  identifiers: (string | null)[];
  tableControls: TableAPIRequestParams<DisconRecUpValueReportApiItem>;
};

export function getDisconRecUpValueReport(
  params: DisconRecUpValueReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return request<PaginatedResult<DisconRecUpValueReportApiItem>>(
    `asset-report/generate-discon-rec-up-value-report${combine([
      tableParamsQS,
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        assetStatus: params.assetStatuses,
        identifiers: params.identifiers,
      }),
    }
  );
}

export function getDisconRecUpValueReportExcel(
  params: DisconRecUpValueReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: true,
  });

  return requestFile(
    `asset-report/generate-discon-rec-up-value-report${combine([
      tableParamsQS,
      stringify({ export: true }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        assetStatus: params.assetStatuses,
        identifiers: params.identifiers,
      }),
    }
  );
}
