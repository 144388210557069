import React from "react";
import Draggable from "react-draggable";
import { makeStyles } from "tss-react/mui";
import { Modal, ModalProps } from "@mui/material";

type Props = ModalProps;

const useStyles = makeStyles()(() => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
}));

export const DraggableModal = (props: Props) => {
  const { classes } = useStyles();

  return (
    <Modal {...props}>
      <div className={classes.container}>
        <Draggable>{props.children}</Draggable>
      </div>
    </Modal>
  );
};
