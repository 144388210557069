import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  PostedCreditListAPIItem,
  getPostedCreditList,
} from "../../api/posted-document/posted-credit-list";
import { setPostedCreditListData, setPostedCreditListError } from "./actions";

function* fetchPostedCreditList(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PostedCreditListAPIItem> = yield call(
      getPostedCreditList,
      action.payload.params
    );

    yield put(setPostedCreditListData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setPostedCreditListError(error));
  }
}

export function* watchPostedCreditList() {
  yield takeLatest(REQUEST_DATA, fetchPostedCreditList);
}
