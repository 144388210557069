import {
  PaginatedChangesResult,
  QueryAssetChangesApiItem,
  QueryAssetChangesRequestParams,
} from "../../api/asset-changes-due";
import { RequestError, TableAPIRequestParams } from "../../api/types";
import {
  REQUEST_DATA,
  REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
  SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR,
  SET_SEARCH_TERM,
  SET_TABLE_CONTROLS,
  SET_TABLE_FILTERS,
} from "./types";

export function requestQueryAssetChangesData(
  params: QueryAssetChangesRequestParams
) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQueryAssetChangesErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryAssetChangesData(
  data: PaginatedChangesResult<QueryAssetChangesApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setQueryAssetChangesTableControls(
  data: TableAPIRequestParams<QueryAssetChangesApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: data,
  };
}

export function setQueryAssetChangesFilters(data: any) {
  return {
    type: SET_TABLE_FILTERS,
    payload: data,
  };
}

export function setQueryAssetChangesSearchTerm(data: string) {
  return {
    type: SET_SEARCH_TERM,
    payload: data,
  };
}

export function requestTotalSBAmountOfAssetChangesDueData(partnerId: number) {
  return {
    type: REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
    payload: partnerId,
  };
}

export function setTotalSBAmountOfAssetChangesDueData(sbAmount: number) {
  return {
    type: SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
    payload: sbAmount,
  };
}

export function setTotalSBAmountOfAssetChangesDueError(error: RequestError) {
  return {
    type: SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR,
    payload: error,
  };
}
