import {
  PostedCreditListAPIItem,
  PostedCreditListParams,
} from "../../api/posted-document/posted-credit-list";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestPostedCreditList(params: PostedCreditListParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setPostedCreditListData(
  data: PaginatedResult<PostedCreditListAPIItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setPostedCreditListError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
