import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    background: "#fff",
  },
}));

export const Wrapper: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
};
