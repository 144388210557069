import { stringify } from "query-string";
import { request, requestFile } from "..";
import { InterfaceAssetProcessingStatus } from "../../shared/enum/interfaceAsset";

export type InterfaceReportApiItem = {
  interfaceAssetId: number;
  sourceRef: number;
  publicIdentifier: string;
  createdBy: string;
  partner: string;
  customerName: string;
  productName: string;
  serviceProvider: string;
  startDate: string;
  dateCreated: string;
  sourceRefAssetId: number;
  name: string;
  resignOverride: string;
  paymentTerms: string;
  addMonths: number;
  contract: string;
  proof: string;
  errorMessage: string;
  status: InterfaceAssetProcessingStatus;
  checked?: boolean;
};

export function getInterfaceReport() {
  return request<InterfaceReportApiItem>("interface-asset/interface-report", {
    method: "GET",
  });
}

export function getInterfaceReportExcel() {
  return requestFile(
    `interface-asset/interface-report?${stringify({ export: true })}`,
    {
      method: "GET",
    }
  );
}
