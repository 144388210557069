import { makeStyles } from "tss-react/mui";
import { Card } from "@mui/material";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 1240,
  },
}));

export const TabPanelWrapper: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return <Card className={classes.root}>{children}</Card>;
};
