import {
  QueryPartnersActionTypes,
  QueryPartnersState,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
} from "./types";

const initialState: QueryPartnersState = {
  filters: {},
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "partnerId",
  },
  items: [],
  count: 0,
  fetching: false,
};

export function queryPartnersReducer(
  state = initialState,
  action: QueryPartnersActionTypes
): QueryPartnersState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload.tableControls,
      };
    }

    case SET_API_ERROR: {
      return { ...state, error: action.payload, fetching: false };
    }

    case SET_DATA: {
      return { ...state, fetching: false, ...action.payload };
    }

    default:
      return state;
  }
}
