import {
  PaymentDataApiItem,
  PaymentDataRequestParams,
} from "../../api/payment-data";
import { RequestError, PaginatedResult } from "../../api/types";

export const SET_DATA = "paymentData.SET_DATA";
export const REQUEST_DATA = "paymentData.REQUEST_DATA";
export const SET_API_ERROR = "paymentData.SET_API_ERROR";

export type PaymentDataItem = Omit<PaymentDataApiItem, "reason" | "status"> & {
  status: string;
  reason: string;
};

export type PaymentDataState = {
  items: PaymentDataItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: PaymentDataRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<PaymentDataApiItem>;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type PaymentDataActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction;
