import {
  DisconRecUpValueReportApiItem,
  DisconRecUpValueReportParams,
} from "../../api/asset-report/generate-discon-rec-up-value-report";
import {
  RequestError,
  TableAPIRequestParams,
  PaginatedResult,
} from "../../api/types";

export const REQUEST_DATA = "disconRecUpValueReport.REQUEST_DATA";
export const SET_DATA = "disconRecUpValueReport.SET_DATA";
export const SET_ERROR = "disconRecUpValueReport.SET_ERROR";
export const CLEAR_DATA_TABLE = "disconRecUpValueReport.CLEAR_DATA_TABLE";
export const SET_TABLE_CONTROLS = "disconRecUpValueReport.SET_TABLE_CONTROLS";

export type DisconRecUpValueReportState = {
  items: DisconRecUpValueReportApiItem[];
  fetching: boolean;
  error?: RequestError;
  count: number;
  tableControls: TableAPIRequestParams<DisconRecUpValueReportApiItem>;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: DisconRecUpValueReportParams;
  };
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<DisconRecUpValueReportApiItem>;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

interface ClearTableAction {
  type: typeof CLEAR_DATA_TABLE;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<DisconRecUpValueReportApiItem>;
  };
}

export type DisconRecUpValueReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | ClearTableAction
  | SetTableControlsAction;
