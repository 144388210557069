import { ServiceProviderDistinctProductTypeOption } from "../../api/service-provider/distinct-products";
import { ServiceProviderRateApiItem } from "../../api/service-provider/service-provider-rates";
import { ServiceProviderRate } from "../../api/service-provider/service-provider-rate-values";
import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_RESET,
  ResetFormAction,
  FORM_INITIALIZE_START,
  FORM_INITIALIZE_FINISH,
  SET_SERVICE_PROVIDER_RATES,
  SET_PRODUCT_TYPE_ID,
  SET_TIERS,
  SET_SERVICE_PROVIDER_RATE,
  SET_SERVICE_PROVIDER_RATE_DETAILS,
  FormInitializeStartAction,
  FormInitializeFinishAction,
  CreatePartnerRateFormValues,
  SetProductTypeIdAction,
  SetServiceProviderRateAction,
} from "./types";

export function formInitializeStart(
  serviceProviderId: number
): FormInitializeStartAction {
  return {
    type: FORM_INITIALIZE_START,
    payload: {
      serviceProviderId,
    },
  };
}

export function formInitializeFinish(
  distinctProductTypeOptions: ServiceProviderDistinctProductTypeOption[]
): FormInitializeFinishAction {
  return {
    type: FORM_INITIALIZE_FINISH,
    payload: {
      distinctProductTypeOptions,
    },
  };
}

export function setFormValues(
  values: Partial<CreatePartnerRateFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function setServiceProviderRate(
  serviceProviderId: number,
  productTypeId: number,
  tier: number
): SetServiceProviderRateAction {
  return {
    type: SET_SERVICE_PROVIDER_RATE,
    payload: {
      serviceProviderId,
      productTypeId,
      tier,
    },
  };
}

export function setProductTypeId(
  productTypeId: number,
  serviceProviderId: number
): SetProductTypeIdAction {
  return {
    type: SET_PRODUCT_TYPE_ID,
    payload: {
      productTypeId,
      serviceProviderId,
    },
  };
}

export function setServiceProviderRates(
  serviceProviderRates: ServiceProviderRateApiItem[]
) {
  return {
    type: SET_SERVICE_PROVIDER_RATES,
    payload: {
      serviceProviderRates,
    },
  };
}

export function setServiceProviderRateDetails(
  serviceProviderRate: ServiceProviderRate[]
) {
  return {
    type: SET_SERVICE_PROVIDER_RATE_DETAILS,
    payload: {
      serviceProviderRate,
    },
  };
}

export function setTiers(tiers: number[]) {
  return {
    type: SET_TIERS,
    payload: {
      tiers,
    },
  };
}

export function submitForm(payload: {
  partnerId: number;
  serviceProviderId: number;
  done: () => any;
}): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function resetForm(): ResetFormAction {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}
