import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailAssetApiItem } from "../../../api/asset";
import { ProofApiItem, putProof } from "../../../api/proof";
import { Dropdown } from "../../../shared/components/Dropdown";
import {
  contractProofStatuses,
  signatureProofStatuses,
} from "../../../shared/enum/asset";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { requestDetailAssetData } from "../../../store/detailAssets/actions";
import { getPermission } from "../../../store/permissions/reducers";
import { Permissions } from "../../../store/permissions/types";

type Props = ProofApiItem &
  Pick<
    DetailAssetApiItem,
    | "sourceRefAssetId"
    | "sourceRef"
    | "sourceCustomerId"
    | "createdByName"
    | "dateCreated"
  >;

export const PanelContentMisc = ({
  assetId,
  proofId,
  signatureProof,
  signatureProofStatus,
  contractProof,
  contractProofStatus,
  sourceRef,
  sourceRefAssetId,
  sourceCustomerId,
  dateCreated,
  createdByName,
}: Props) => {
  const dispatch = useDispatch();
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  const onSignatureProofChange = (value: number) => {
    updateProof({
      signatureProofStatus: value,
    });
  };

  const onContractProofChange = (value: number) => {
    updateProof({
      contractProofStatus: value,
    });
  };

  const updateProof = (proof: Partial<ProofApiItem>) => {
    putProof({
      assetId,
      proofId,
      contractProof,
      contractProofStatus,
      signatureProof,
      signatureProofStatus,
      ...proof,
    }).then(() => {
      dispatch(requestDetailAssetData(assetId));
    });
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ok</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Signature Proof</b>
            </TableCell>
            <TableCell>
              <Dropdown
                disabled={!canWriteAssets}
                label="Signature Proof Status"
                options={signatureProofStatuses}
                value={signatureProofStatus}
                onChange={onSignatureProofChange}
              />
            </TableCell>
            <TableCell>
              <TextField disabled value={signatureProof ? "Yes" : "No"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Contract Proof</b>
            </TableCell>
            <TableCell>
              <Dropdown
                disabled={!canWriteAssets}
                label="Contract Proof Status"
                options={contractProofStatuses}
                value={contractProofStatus}
                onChange={onContractProofChange}
              />
            </TableCell>
            <TableCell>
              <TextField disabled value={contractProof ? "Yes" : "No"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Source Customer ID</b>
            </TableCell>
            <TableCell>{sourceCustomerId}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Source Ref Asset ID</b>
            </TableCell>
            <TableCell>{sourceRefAssetId}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Source Ref</b>
            </TableCell>
            <TableCell>{sourceRef}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Created By</b>
            </TableCell>
            <TableCell>{createdByName}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Date Created</b>
            </TableCell>
            <TableCell>{formatISOForDisplay(dateCreated)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
