import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Batch } from "../../../../api/payment-data/batches";
import { PaymentType } from "../../../../shared/enum/payments";
import { StepChooseBatch } from "./StepChooseBatch";
import { StepPost } from "./StepPost";
import { StepReview } from "./StepReview";

enum Step {
  Choose,
  Summary,
  Post,
}

type Props = {
  paymentType: PaymentType;
};

export const ProcessPayments = ({ paymentType }: Props) => {
  // Handle case where nav menu clicked if already on same route
  const location = useLocation();
  useEffect(() => {
    handleReset();
  }, [location]);

  const [step, setStep] = useState(Step.Choose);
  const [batch, setBatch] = useState<Batch | null>(null);

  const handleReset = () => {
    setStep(Step.Choose);
    setBatch(null);
  };

  switch (step) {
    case Step.Choose:
      return (
        <StepChooseBatch
          paymentType={paymentType}
          onSelect={setBatch}
          onSubmit={() => setStep(Step.Summary)}
          selectedBatch={batch}
        />
      );
    case Step.Summary:
      if (batch) {
        return (
          <StepReview
            batch={batch}
            onBack={() => setStep(Step.Choose)}
            onConfirm={() => setStep(Step.Post)}
          />
        );
      }
      return null;

    case Step.Post:
      if (batch) {
        return <StepPost batch={batch} onReset={handleReset} />;
      }
      return null;
  }
};
