import {
  FormRecalculatePartnerRateState,
  RecalculatePartnerRateActionTypes,
  FORM_SUBMIT,
  SET_ERROR,
  FORM_SUCCESS,
  FORM_RESET,
} from "./types";

const initialState: FormRecalculatePartnerRateState = {
  fetching: false,
  success: false,
};

export function formRecalculatePartnerRateReducer(
  state = initialState,
  action: RecalculatePartnerRateActionTypes
): FormRecalculatePartnerRateState {
  switch (action.type) {
    case FORM_SUBMIT:
      return {
        ...state,
        fetching: true,
        success: false,
        error: undefined,
      };

    case FORM_SUCCESS: {
      return {
        ...state,
        fetching: false,
        success: true,
        error: undefined,
      };
    }

    case SET_ERROR: {
      return {
        ...state,
        fetching: false,
        success: false,
        error: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
