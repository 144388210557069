import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import {
  SpendCapItem,
  SpendCapPeriodItem,
} from "../../../api/spend-cap-period/by-productId-date";
import { useDispatch, useSelector } from "react-redux";
import { DetailProductsState } from "../../../store/detailProducts/types";
import { RootState } from "../../../store/rootReducer";
import { makeStyles } from "tss-react/mui";
import { setActiveSpendCapPeriod } from "../../../store/detailProducts/actions";
import { DeleteSpendCapModal } from "./DeleteSpendCapModal";

type Props = {
  readonly primaryProductId: number;
  readonly data: SpendCapPeriodItem[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  row: {
    cursor: "pointer",
  },
}));

const Row = ({
  item,
  productId,
}: {
  item: SpendCapPeriodItem;
  productId: number;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { activeSpendCapPeriodId } = useSelector<
    RootState,
    DetailProductsState
  >((state: RootState) => state.detailProducts);

  const open = activeSpendCapPeriodId === item.spendCapPeriodId;
  const handleOpen = (activeSpendCapPeriodId: number) => {
    if (open) {
      dispatch(setActiveSpendCapPeriod(undefined));
    } else {
      dispatch(setActiveSpendCapPeriod(activeSpendCapPeriodId));
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.row}
        hover
        onClick={() => handleOpen(item.spendCapPeriodId)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatISOForDisplay(item.startDate)}</TableCell>
        <TableCell>{formatISOForDisplay(item.endDate)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ padding: 0, border: "none" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="medium">
                <TableBody>
                  {item.spendCaps.map((spendCap: SpendCapItem) => (
                    <TableRow key={spendCap.spendCapId}>
                      <TableCell style={{ width: 100 }}></TableCell>
                      <TableCell style={{ width: 200 }}>
                        {spendCap.name}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <DeleteSpendCapModal
                          spendCap={spendCap}
                          productId={productId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <TableRow>
                    <TableCell colSpan={8}>
                      <Box textAlign="center" width="100%">
                        <CreateProductRateModal
                          productId={props.item.productId}
                          parentProductId={props.item.parentId}
                        />
                      </Box>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const PanelContentSpendCaps = ({ data, primaryProductId }: Props) => {
  return (
    <Box pt={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }}></TableCell>
              <TableCell style={{ width: 200 }}>Spend Cap Start Date</TableCell>
              <TableCell>Spend Cap End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((period) => (
                <Row productId={primaryProductId} item={period} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <span>No records found</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
