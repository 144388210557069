import { ImportPaymentParams, ImportResult } from "../../api/payment-data";
import { RequestError } from "../../api/types";
import {
  ImportPaymentDataForm,
  REQUEST_IMPORT,
  RESET_STATE,
  SET_API_ERROR,
  SET_IMPORT_RESULT,
  UPDATE_FORM,
} from "./types";

export function resetImportPaymentDataState() {
  return {
    type: RESET_STATE,
  };
}

export function updateImportPaymentDataForm(
  form: Partial<ImportPaymentDataForm>
) {
  return {
    type: UPDATE_FORM,
    payload: form,
  };
}

export function requestImportPaymentData(params: ImportPaymentParams) {
  return {
    type: REQUEST_IMPORT,
    payload: params,
  };
}

export function setImportPaymentDataErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setImportPaymentDataResult(data: ImportResult) {
  return {
    type: SET_IMPORT_RESULT,
    payload: data,
  };
}
