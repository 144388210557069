import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  postProcessPayment,
  UnprocessedEntity,
} from "../../../../api/asset-ledger-entry/payment-data";
import { Batch } from "../../../../api/payment-data/batches";
import { RequestError } from "../../../../api/types";
import { Page } from "../../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../../shared/components/APIFetchingWrapper";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import { BooleanExceptZero } from "../../../../shared/helpers/BooleanExceptZero";
import { FormPartnerRates } from "./components/FormPartnerRates";

type Props = {
  batch: Batch;
  onReset: () => any;
};

export const StepPost = ({ batch, onReset }: Props) => {
  // Posting Flow:
  // 1. On initial mount, an attempt is made to process the batch via API
  // 2. The API method may resolve with unprocessed entities, if so a form is rendered
  // 3. If the user attempts to submit the form, a check is made that all form fields have been completed
  // 4. If the pass checks, the API method is called again with the contents of the form.

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<RequestError>();
  const [completed, setCompleted] = useState(false);
  const [formUnProcessedEntities, setFormUnprocessedEntities] = useState<
    UnprocessedEntity[]
  >([]);
  const [totalBlankPartnerRates, setTotalBlankPartnerRates] = useState(0);
  const [attempts, setAttempts] = useState([""]);

  const processPayment = useCallback(() => {
    let mounted = true;
    setFetching(true);
    setAttempts(attempts.concat(""));

    // check the form is valid; early return if not valid.
    const totalBlankPartnerRates = formUnProcessedEntities.reduce(
      (acc, curr) => acc + (BooleanExceptZero(curr.partnerRate) ? 0 : 1),
      0
    );
    if (totalBlankPartnerRates > 0) {
      setFetching(false);
      return setTotalBlankPartnerRates(totalBlankPartnerRates);
    }

    // call the API
    postProcessPayment({
      batchId: batch.batchId,
      items: formUnProcessedEntities.map((entity) => ({
        paymentDataId: entity.paymentDataId,
        partnerRate: entity.partnerRate,
      })),
    })
      .then((unprocessedEntites) => {
        if (mounted) {
          setFetching(false);

          if (unprocessedEntites) {
            setFormUnprocessedEntities(unprocessedEntites);
            setTotalBlankPartnerRates(unprocessedEntites.length);
          } else {
            setCompleted(true);
            setFormUnprocessedEntities([]);
          }
        }
      })
      .catch((error: RequestError) => {
        if (mounted) {
          setFetching(false);
          setError(error);
        }
      });
    return () => {
      mounted = false;
    };
  }, [batch.batchId, formUnProcessedEntities, attempts]);

  // make an attempt to post the batch on initial mount.  Further attempts are controlled by the submit button
  const initialProcessAttempted = useRef(false);
  useEffect(() => {
    if (!initialProcessAttempted.current) {
      processPayment();
      initialProcessAttempted.current = true;
    }
  }, [processPayment]);

  const handleUpdateFormEntityById = (
    update: Partial<UnprocessedEntity>,
    id: number
  ) => {
    const index = formUnProcessedEntities.findIndex(
      (entity) => entity.paymentDataId === id
    );
    const newEntities = formUnProcessedEntities.slice();
    const entityToUpdate = newEntities[index];
    newEntities[index] = { ...entityToUpdate, ...update };
    setFormUnprocessedEntities(newEntities);
  };

  return (
    <Page
      title={`Post ${batch.batchRef}`}
      actions={
        <>
          <Button variant="outlined" onClick={onReset} size="large">
            View another batch
          </Button>
          {formUnProcessedEntities.length > 0 && (
            <Button
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              onClick={processPayment}
            >
              Create Entities
            </Button>
          )}
        </>
      }
    >
      <APIFetchingWrapper loading={fetching} error={error}>
        {completed ? (
          <CustomStatusChip
            type="success"
            title="Successfully posted"
            message={`Reference: ${batch.batchRef}`}
            style={{ backgroundColor: "rgb(81, 176, 99) !important" }}
          />
        ) : (
          <React.Fragment>
            <CustomStatusChip
              type="error"
              title="Unprocessable Records"
              message={`Your batch was not posted. A total of ${totalBlankPartnerRates} records do not have a Partner Rate assigned.  Assign a Partner Rate to each entry in the table below and try again.`}
            />
            <Box my={2}>
              <FormPartnerRates
                data={formUnProcessedEntities}
                onUpdate={handleUpdateFormEntityById}
              />
            </Box>
          </React.Fragment>
        )}
      </APIFetchingWrapper>
    </Page>
  );
};
