import { LoaderButton } from "../../../../../shared/components/LoaderButton";
import { makeStyles } from "tss-react/mui";

import {
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Box,
  Button,
} from "@mui/material";

import { Cancel, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PartnerThirdPartyIdApiItem } from "../../../../../api/payment-data/batches/partner-third-party-ids";
import { CustomStatusChip } from "../../../../../shared/components/CustomStatusChip";
import { DraggableModalCard } from "../../../../../shared/components/DraggableModalCard";
import {
  formReset,
  submitRecalculatePartnerRate,
} from "../../../../../store/formRecalculatePartnerRate/actions";
import { RootState } from "../../../../../store/rootReducer";

type Props = {
  batchId: number;
  partnerThirdPartyIds: PartnerThirdPartyIdApiItem[] | undefined;
};

const useStyles = makeStyles()((theme) => ({
  button: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  },
  buttonCancel: {
    marginRight: theme.spacing(0.5),
  },
  deleteIcon: {
    width: 16,
    height: 16,
    marginLeft: -4,
    marginRight: -4,
    color: "rgba(39, 29, 62, 0.26)",
    "&:hover": {
      color: "rgba(39, 29, 62, 0.4)",
      cursor: "pointer",
    },
  },
}));

export const ModalPartnerThirdPartyIds = ({
  batchId,
  partnerThirdPartyIds,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [selected, setSelected] = useState<number[]>([]);

  const { error, fetching, success } = useSelector(
    (state: RootState) => state.formRecalculatePartnerRate
  );
  const dispatch = useDispatch();
  const { classes } = useStyles();

  useEffect(() => {
    if (partnerThirdPartyIds) {
      setOpen(true);
    }
  }, [partnerThirdPartyIds]);

  const handleClose = () => {
    setOpen(false);
    setSelected([]);
    dispatch(formReset());
  };

  const handleSave = () => {
    dispatch(
      submitRecalculatePartnerRate({
        batchId,
        partnerThirdPartyIds:
          (partnerThirdPartyIds &&
            partnerThirdPartyIds.filter((p) =>
              selected.includes(p.partnerId)
            )) ||
          [],
      })
    );
  };

  const handleSelectAll = () => {
    setSelected([...(partnerThirdPartyIds?.map((p) => p.partnerId) ?? [])]);
  };

  const _renderErrorMessage = () => (
    <Paper style={{ marginBottom: 16 }}>
      <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
        <CustomStatusChip
          type="info"
          // title="Info"
          message="The selected batch contains no assets linked to a Partner"
        />
      </Box>
    </Paper>
  );

  const _renderTable = () => (
    <>
      <Paper style={{ marginBottom: 16, boxShadow: "none" }}>
        <Box display="flex">
          <OutlinedInput
            fullWidth
            placeholder="Search partner codes"
            id="search"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            endAdornment={
              input && (
                <InputAdornment position="end">
                  <Cancel
                    onClick={() => {
                      setInput("");
                    }}
                    fontSize="small"
                    className={classes.deleteIcon}
                  />
                </InputAdornment>
              )
            }
          />
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            type="submit"
            onClick={handleSelectAll}
            style={{ marginLeft: 8, width: 150 }}
          >
            Select All
          </Button>
        </Box>
      </Paper>
      <Paper style={{ width: "100%", height: 200, overflow: "auto" }}>
        <List dense component="div" role="list">
          {partnerThirdPartyIds &&
            partnerThirdPartyIds
              .sort((a, b) => a.thirdPartyId.localeCompare(b.thirdPartyId))
              .filter((option) =>
                option.thirdPartyId.toLowerCase().includes(input.toLowerCase())
              )
              .map((option: PartnerThirdPartyIdApiItem) => {
                return (
                  <ListItem
                    style={{ width: "100%" }}
                    key={option.partnerId}
                    button
                    onClick={() => {
                      if (!selected.includes(option.partnerId)) {
                        setSelected([...selected, option.partnerId]);
                      } else {
                        setSelected(
                          selected.filter((s) => s !== option.partnerId)
                        );
                      }
                    }}
                  >
                    <ListItemText
                      id={option.thirdPartyId}
                      primary={option.thirdPartyId}
                    />
                    <ListItemIcon style={{ minWidth: "unset" }}>
                      <Checkbox
                        color="primary"
                        checked={selected.includes(option.partnerId)}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  return (
    <DraggableModalCard
      title="Select Partner Codes"
      open={open}
      onClose={handleClose}
    >
      {error && (
        <Box marginBottom={1}>
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        </Box>
      )}
      {success && (
        <Box marginBottom={1}>
          <CustomStatusChip
            type="success"
            title="Success"
            message="Recalculate Rates will run at a later time. You will receive an email when the process has finished."
          />
        </Box>
      )}
      {partnerThirdPartyIds && partnerThirdPartyIds.length > 0
        ? _renderTable()
        : _renderErrorMessage()}
      <Box display="flex" flexDirection="row-reverse" style={{ marginTop: 16 }}>
        {partnerThirdPartyIds && partnerThirdPartyIds.length > 0 && (
          <LoaderButton
            size="large"
            loading={fetching}
            disabled={success}
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleSave}
          >
            Submit
          </LoaderButton>
        )}
        <Button
          disabled={fetching}
          size="large"
          variant="outlined"
          onClick={handleClose}
          className={classes.buttonCancel}
        >
          {success ? "Close" : "Cancel"}
        </Button>
      </Box>
    </DraggableModalCard>
  );
};
