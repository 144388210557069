import { request } from "../..";

export interface PaymentDataRevShareSummaryApi {
  totalAmountMatched: number;
  totalAmountUnmatched: number;
  totalAmountOriginalValue: number;
  processedLines: number;
  totalAmountMatchedByProductType: { [key: number]: number };
  matchedLines: number;
  unmatchedLines: number;
  totalAmountMatchedInContract: number;
  totalAmountMatchedOoc: number;
  matchedLinesNo?: never;
  unmatchedLinesNo?: never;
  totalAmountMatchedByPaymentType: {
    [key: string]: { key: number; value: number };
  }; // key is number of lines
  totalAmountUnmatchedByReason: {
    [key: string]: { key: number; value: number };
  }; // key is number of lines
}

export interface PaymentDataUpfrontSummaryApi {
  totalAmountMatchedByProductType: { [key: number]: number };
  totalLines: number;
  totalAmountOriginalValue: number;
  unmatchedLinesNo: number;
  totalAmountUnmatched: number;
  matchedLinesNo: number;
  totalAmountMatched: number;
  processedLines: number;
  totalAmountProcessed: number;
  totalAmountMatchedByPaymentType: {
    [key: string]: { key: number; value: number };
  }; // key is number of lines
  totalAmountUnmatchedByReason: {
    [key: string]: { key: number; value: number };
  }; // key is number of lines
}

export type PaymentDataSummaryApi =
  | PaymentDataRevShareSummaryApi
  | PaymentDataUpfrontSummaryApi;

// @INFO returns a different object shapre depending on whether the batchId corresponds to revshare or upfront batch
export function getPaymentSummary(batchId: string) {
  return request<PaymentDataSummaryApi>(`payment-data/summary/${batchId}`);
}
