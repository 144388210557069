import { call, put, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  CreditNoteLinesHeaderAPIItem,
  getCreditNoteLinesHeader,
} from "../../api/posted-document/credit-note-lines-header/index";
import {
  setCreditNoteLinesHeader,
  setCreditNoteLinesHeaderError,
} from "./actions";

function* fetchCreditNoteLinesHeader(action: RequestDataAction) {
  try {
    const data: CreditNoteLinesHeaderAPIItem = yield call(
      getCreditNoteLinesHeader,
      action.payload.postedDocumentId
    );

    yield put(setCreditNoteLinesHeader(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setCreditNoteLinesHeaderError(error));
  }
}

export function* watchCreditNoteLinesHeader() {
  yield takeLatest(REQUEST_DATA, fetchCreditNoteLinesHeader);
}
