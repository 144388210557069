import { productClasses, productVariants } from "../../shared/enum/product";
import {
  INITIALIZE_FINISH,
  QueryProductsActionTypes,
  QueryProductsState,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
} from "./types";

const initialState: QueryProductsState = {
  filterOptions: {
    duration: [],
    type: [],
    class: productClasses,
    variant: productVariants,
  },
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "serviceProvider",
  },
  items: [],
  count: 0,
  fetching: false,
};

export function queryProductsReducer(
  state = initialState,
  action: QueryProductsActionTypes
): QueryProductsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case INITIALIZE_FINISH: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          duration: action.payload.duration,
          type: action.payload.productTypes.items,
        },
      };
    }

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload.tableControls,
      };
    }

    case SET_API_ERROR: {
      return { ...state, error: action.payload, fetching: false };
    }

    case SET_DATA: {
      return { ...state, fetching: false, ...action.payload };
    }

    default:
      return state;
  }
}
