import { DetailAssetApiItem, UpdateAssetParams } from "../../api/asset";
import { RequestError } from "../../api/types";
import {
  REQUEST_DATA,
  REQUEST_UPDATE_ITEM,
  SET_API_ERROR,
  RESET_API_ERROR,
  SET_DATA,
  UPDATE_ITEM_DATA,
  REQUEST_TRUE_UP,
} from "./types";

export function requestDetailAssetData(id: number) {
  return {
    type: REQUEST_DATA,
    payload: id,
  };
}

export function setDetailAssetRequestError(
  error: { id: number } & RequestError
) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function resetDetailAssetRequestError(id: number) {
  return {
    type: RESET_API_ERROR,
    payload: {
      id,
    },
  };
}

export function setDetailAssetData(data: DetailAssetApiItem) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function requestUpdateAssetItem(
  id: number,
  updates: Partial<UpdateAssetParams>,
  done: (error?: RequestError) => any
) {
  return {
    type: REQUEST_UPDATE_ITEM,
    payload: { id, updates, done },
  };
}

export function requestAssetTrueUp(assetId: number) {
  return {
    type: REQUEST_TRUE_UP,
    payload: { assetId },
  };
}

export function updateItemData(
  id: number,
  updates: Partial<DetailAssetApiItem>
) {
  return {
    type: UPDATE_ITEM_DATA,
    payload: { id, updates },
  };
}
