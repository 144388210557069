import { AssetNoteApiItem } from "../../api/note/get-asset-notes";
import { RequestError } from "../../api/types";

export const REQUEST_DATA = "comments.REQUEST_DATA";
export const SET_DATA = "comments.SET_DATA";
export const SET_ERROR = "comments.SET_ERROR";
export const TOGGLE_COMMENTS = "comments.TOGGLE_COMMENTS";

export type CommentsState = {
  items: AssetNoteApiItem[];
  fetching: boolean;
  show: boolean;
  error?: RequestError;
};

export type CommentsActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | ToggleCommentsAction;

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    assetId: number;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: AssetNoteApiItem[];
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    error: RequestError;
  };
}

interface ToggleCommentsAction {
  type: typeof TOGGLE_COMMENTS;
  payload: { show: boolean };
}
