import { request, requestFile } from "..";
import { PaginatedResult, TableAPIRequestParams } from "../types";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import {
  ContractType,
  AssetStatus,
  ServiceProviderStatusChangeType,
} from "../../shared/enum/asset";
import { DateString } from "../../shared/helpers/dates";
import { stringify } from "query-string";
import { ProductRateByIdApiItem } from "../product/product-rates";
import { AssetProductApiItem } from "../asset-product";

//@TODO the null values (except for dates) relate to the output of the url query params in the view layer
// they can/should be cleansed before we get to this step
export type QueryAssetFilterParams = {
  assetId?: (number | null)[];
  startDate?: [DateString | null, DateString | null];
  endDate?: [DateString | null, DateString | null];
  dateCreated?: [DateString | null, DateString | null];
  customerName?: (string | null)[];
  thirdPartyId?: (string | null)[];
  status?: (AssetStatus | null)[];
  identifier?: (string | null)[];
  sourceRef?: (string | null)[];
  serviceProvider?: ServiceProvider[];
  contractType?: ContractType[];
  productName?: (string | null)[];
};

export type QueryAssetApiItem = {
  assetId: number;
  startDate: string;
  endDate: string;
  dateCreated: string;
  customerName: string;
  thirdPartyId: string;
  status: AssetStatus;
  identifier: string;
  previousIdentifier: string;
  sourceRef: number;
  serviceProvider: ServiceProvider;
  contractType: ContractType;
  productName: string;
  spAssetStatusChangeDate: string;
};

export type AssetInfoApiItem = {
  id: number;
  fieldName: string;
  fieldValue: any;
  isEditable: boolean;
};

export type DetailAssetApiItem = {
  assetId: number;
  identifier: string;
  status: number;
  startDate: string;
  endDate: string;
  dateCreated: string;
  serviceProvider: string;
  serviceType: string;
  contractName: string;
  sourceRefAssetId: string;
  sourceRef: number;
  thirdPartyId: string;
  advanceTaken: boolean;
  assetInfos: AssetInfoApiItem[];
  productCode: string;
  productName: { key: string; value: number };
  productId: number;
  secondaryProductOptions: ProductRateByIdApiItem[];
  secondaryProducts: AssetProductApiItem[];
  productDuration: number;
  spendCap: string;
  customerName: string;
  customerPostcode: string;
  sourceCustomerId: string;
  spAccountRef: string;
  spAssetStatus: string;
  spAssetStatusChangeType: ServiceProviderStatusChangeType;
  spAssetStatusChangeDate: string;
  subscriberId: string;
  createdById: string;
  createdByName: string;
};

export type QueryAssetRequestParams = {
  tableParams: TableAPIRequestParams<QueryAssetApiItem>;
  filterParams: QueryAssetFilterParams;
  searchTerm: string;
  export?: boolean;
};

export type AssetInfosDictionary = Record<
  number,
  { fieldName: string; fieldValue: string }
>;

export type UpdateAssetParams = {
  assetId: number;
  identifier: string;
  status: number;
  subscriberId: string;
  assetInfos: AssetInfosDictionary;
  startDate: string;
  endDate: string;
  customerName: string;
  customerPostcode: string;
  spAssetStatusChangeDate: string;
  spAssetStatusChangeType: ServiceProviderStatusChangeType;
  spAssetStatus: string;
  spAccountRef: string;
  isAdvanceTaken: boolean;
};

export type AssetProduct = {
  productId: number;
  productRateId?: number;
  isPrimary: boolean;
  duration: number;
};

export type CreateAssetParams = {
  isAdvanceTaken: boolean;
  serviceProviderId: number;
  contractTypeId: number;
  partnerId: number;
  publicIdentifier: string;
  customerName: string;
  startDate: string;
  additionalMonths: number;
  assetProducts: AssetProduct[];
};

// @NOTE - this fetches *multiple* assets
export function getQueryAsset(params: QueryAssetRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const searchTermQS = params.searchTerm
    ? stringify({ searchTerm: params.searchTerm })
    : "";
  return request<PaginatedResult<QueryAssetApiItem>>(
    `asset${combine([tableParamsQS, filterParamsQS, searchTermQS])}`
  );
}

export function getQueryAssetExcel(params: QueryAssetRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const searchTermQS = params.searchTerm
    ? stringify({ searchTerm: params.searchTerm })
    : "";
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `asset${combine([tableParamsQS, filterParamsQS, searchTermQS, exportFlag])}`
  );
}

// @NOTE - this fetches *single* asset
export function getDetailAsset(id: number) {
  return request<DetailAssetApiItem>(`asset/${id}`);
}

export function putUpdateAsset(id: number, updates: UpdateAssetParams) {
  return request<void>(`asset/${id}`, {
    method: "PUT",
    body: JSON.stringify(updates),
  });
}

export function postCreateAsset(params: CreateAssetParams) {
  return request<DetailAssetApiItem>("asset/create-manual", {
    method: "POST",
    body: JSON.stringify(params),
  });
}

export function checkEnableAdvanceTaken(id: number) {
  return request<boolean>(`asset/check-enable-advance-taken/${id}`, {
    method: "GET",
  });
}
