import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import { APIFetchingWrapper } from "../../../../shared/components/APIFetchingWrapper";
import { requestPaymentDataSummary } from "../../../../store/paymentDataSummary/actions";
import { Box, Button } from "@mui/material";
import { Batch } from "../../../../api/payment-data/batches";
import { RevShareSummaryTable } from "./components/RevShareSummaryTable";
import { DetailedTable } from "./components/DetailedTable";
import { Page } from "../../../../layout/components/Page";
import { UpfrontSummaryTable } from "./components/UpFrontSummaryTable";
import { DangerButton } from "../../../../shared/components/DangerButton";
import { RequestError } from "../../../../api/types";
import { requestDeletePaymentImport } from "../../../../api/payment-data/delete";
import { requestRematchByBatchId } from "../../../../api/payment-data/rematch";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import {
  CreateGeneralJournalLinesParams,
  CreateGeneralJournalLinesResult,
  postCreateGeneralJournalLines,
} from "../../../../api/journal-line/general";
import { Alert } from "@mui/lab";
import { PaymentDataBatchStatus } from "../../../../store/paymentDataSummary/types";
import {
  getPartnerThirdPartyIds,
  PartnerThirdPartyIdApiItem,
} from "../../../../api/payment-data/batches/partner-third-party-ids";
import { ModalPartnerThirdPartyIds } from "./components/ModalPartnerThirdPartyIds";
import { CreateJournalEntriesModal } from "./components/CreateJournalEntriesModal";

type Props = {
  batch: Batch;
  onBack: () => any;
  onConfirm: () => any;
};

export const StepReview = ({ batch, onBack, onConfirm }: Props) => {
  const [requestError, setRequestError] = useState<RequestError>();
  const [requestPending, setRequestPending] = useState(false);
  const [rematchRequestJobId, setRematchRequestJobId] = useState("");
  const [journalResult, setJournalResult] = useState<
    CreateGeneralJournalLinesResult | undefined
  >();
  const [thirdPartyIdsResult, setThirdPartyIdsResult] = useState<
    PartnerThirdPartyIdApiItem[]
  >();

  const dispatch = useDispatch();
  const {
    data: summaryData,
    fetching: fetchingSummary,
    error: summaryError,
    enableCreateEntities,
    batchStatus,
  } = useSelector((state: RootState) => state.paymentDataSummary);

  const { success } = useSelector(
    (state: RootState) => state.formRecalculatePartnerRate
  );

  useEffect(() => {
    if (!success) {
      setThirdPartyIdsResult(undefined);
    }
  }, [success]);

  const [viewMode, setViewMode] = useState<"summary" | "detailed">("summary");

  useEffect(() => {
    dispatch(requestPaymentDataSummary(batch.batchId));
  }, [dispatch, batch]);

  const getSummaryDataComponent = () => {
    if (!summaryData) {
      return null;
    }

    if ("totalsByProductType" in summaryData) {
      return <RevShareSummaryTable data={summaryData} />;
    }

    if ("totalsMatchedByPaymentType" in summaryData) {
      return <UpfrontSummaryTable data={summaryData} />;
    }
  };

  const handleDeleteClick = () => {
    setRequestPending(true);
    requestDeletePaymentImport(batch.batchId)
      .then(() => {
        onBack();
      })
      .catch(setRequestError)
      .finally(() => setRequestPending(false));
  };

  const handleRematchClick = () => {
    setRequestPending(true);
    requestRematchByBatchId(batch.batchId)
      .then((response) => {
        setRematchRequestJobId(response.jobId);
      })
      .catch(setRequestError)
      .finally(() => setRequestPending(false));
  };

  const handleCreateJournalEntries = (
    params: CreateGeneralJournalLinesParams
  ) => {
    setRequestPending(true);
    postCreateGeneralJournalLines(params)
      .then((result) => {
        setJournalResult(result);
      })
      .finally(() => setRequestPending(false));
  };

  const handleRecalculateRates = () => {
    setRequestPending(true);

    getPartnerThirdPartyIds(batch.batchId)
      .then((result) => {
        setThirdPartyIdsResult(result);
      })
      .finally(() => setRequestPending(false));
  };

  const shouldDisableActionButtons = () =>
    requestPending ||
    !!requestError ||
    !!summaryError ||
    !summaryData ||
    !!rematchRequestJobId;

  const shouldDisableRematchButton = () => {
    if (!summaryData) {
      return true;
    }

    if ("totalsByProductType" in summaryData) {
      return summaryData.unmatchedLines === 0;
    }

    if ("totalsMatchedByPaymentType" in summaryData) {
      return summaryData.unmatchedLinesNo === 0;
    }
  };

  const enableCreateJournalEntriesButton = () => {
    if (!summaryData) return false;

    if (batchStatus === PaymentDataBatchStatus.Journaled) {
      return false;
    }

    if (summaryData.processedLines === 0) return false;

    if ("totalsByProductType" in summaryData) {
      return summaryData.matchedLines === summaryData.processedLines;
    }

    if ("totalsMatchedByPaymentType" in summaryData) {
      return summaryData.matchedLinesNo === summaryData.processedLines;
    }
  };

  return (
    <Page
      title={`View 
      Existing Payments - Review ${batch.batchRef}`}
      actions={
        <>
          <Button
            size="large"
            variant="outlined"
            onClick={onBack}
            disabled={fetchingSummary || requestPending}
          >
            Back
          </Button>
          <DangerButton
            size="large"
            disabled={
              shouldDisableActionButtons() ||
              Boolean(summaryData && summaryData.processedLines > 0)
            }
            onClick={handleDeleteClick}
          >
            Delete Batch
          </DangerButton>
          <Button
            size="large"
            variant="outlined"
            disabled={
              shouldDisableActionButtons() || shouldDisableRematchButton()
            }
            onClick={handleRematchClick}
          >
            Rematch
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={shouldDisableActionButtons() || !enableCreateEntities}
            onClick={onConfirm}
          >
            Create Entities
          </Button>
          {summaryData && "totalsByProductType" in summaryData && (
            <Button
              size="large"
              color="primary"
              variant="contained"
              disabled={shouldDisableActionButtons()}
              onClick={handleRecalculateRates}
            >
              Recalculate Rates
            </Button>
          )}
          <CreateJournalEntriesModal
            batchId={batch.batchId}
            onSubmit={handleCreateJournalEntries}
            disabled={!enableCreateJournalEntriesButton()}
          />
        </>
      }
    >
      {viewMode === "summary" && (
        <APIFetchingWrapper
          loading={fetchingSummary || requestPending}
          error={summaryError || requestError}
        >
          <Box mb={2}>
            {journalResult && journalResult.success && (
              <Alert severity="success">
                Journal entries created successfully.
              </Alert>
            )}
            {journalResult && !journalResult.success && (
              <Alert severity="error">{journalResult.message}</Alert>
            )}

            {rematchRequestJobId ? (
              <CustomStatusChip
                type="success"
                title="Rematch Request Success"
                message={`A background process will now attempt to rematch records on Batch ${batch.batchRef}.  This process might take a while.  The Job Id for this process is: ${rematchRequestJobId}.`}
              />
            ) : (
              <React.Fragment>
                {getSummaryDataComponent()}
                <Button
                  variant="outlined"
                  onClick={() => setViewMode("detailed")}
                >
                  See detailed view
                </Button>
              </React.Fragment>
            )}
          </Box>
        </APIFetchingWrapper>
      )}
      {viewMode === "detailed" && (
        <>
          <DetailedTable batchId={batch.batchId} />
          <Box mb={2} display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={() => setViewMode("summary")}>
              See summary view
            </Button>
          </Box>
        </>
      )}
      <ModalPartnerThirdPartyIds
        batchId={batch.batchId}
        partnerThirdPartyIds={thirdPartyIdsResult}
      />
    </Page>
  );
};
