import {
  QuerySelfBillApiItem,
  QuerySelfBillRequestParams,
} from "../../api/self-bill/query";
import { PaginatedResult, RequestError } from "../../api/types";

export const SET_DATA = "querySelfBill.SET_DATA";
export const REQUEST_DATA = "querySelfBill.REQUEST_DATA";
export const SET_API_ERROR = "querySelfBill.SET_API_ERROR";

export type QuerySelfBillState = {
  items: QuerySelfBillApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QuerySelfBillRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QuerySelfBillApiItem>;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type QuerySelfBillActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction;
