import {
  PortAndResignReportApiItem,
  PortAndResignReportParams,
} from "../../api/asset-report/generate-port-and-resign-report";
import { RequestError } from "../../api/types";

export const REQUEST_DATA = "portAndResignReport.REQUEST_DATA";
export const SET_DATA = "portAndResignReport.SET_DATA";
export const SET_ERROR = "portAndResignReport.SET_ERROR";

export type PortAndResignReportState = {
  items: PortAndResignReportApiItem[];
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: PortAndResignReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PortAndResignReportApiItem[];
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PortAndResignReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
