import {
  PostedInvoiceListAPIItem,
  PostedInvoiceListParams,
} from "../../api/posted-document/posted-invoice-list";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestPostedInvoiceList(params: PostedInvoiceListParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setPostedInvoiceListData(
  data: PaginatedResult<PostedInvoiceListAPIItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setPostedInvoiceListError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
