import { request } from "../..";
import {
  ContractProofStatus,
  SignatureProofStatus,
} from "../../../shared/enum/asset";

export type UpdateProofsParams = {
  assetIds: number[];
  signatureProofStatus: SignatureProofStatus;
  contractProofStatus: ContractProofStatus;
};

export function postBatchUpdateProofs(params: UpdateProofsParams) {
  return request(
    `proof/batch-update-proofs/${params.signatureProofStatus}/${params.contractProofStatus}`,
    {
      method: "POST",
      body: JSON.stringify(params.assetIds),
    }
  );
}
