import { call, put, takeLatest } from "redux-saga/effects";
import { getSelfBill, QuerySelfBillApiItem } from "../../api/self-bill/query";
import { PaginatedResult, RequestError } from "../../api/types";

import {
  setQuerySelfBillData,
  setQuerySelfBillErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchQuerySelfBill(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QuerySelfBillApiItem> = yield call(
      getSelfBill,
      action.payload
    );

    yield put(setQuerySelfBillData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setQuerySelfBillErrorForDisplay(error));
  }
}

export function* watchRequestQuerySelfBillData() {
  yield takeLatest(REQUEST_DATA, fetchQuerySelfBill);
}
