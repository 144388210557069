import { request } from "../..";

export interface ServiceProviderRateApiItem {
  serviceProviderRateId: number;
  serviceProviderId: number;
  tier: number;
  receivedBillRate: number;
  standardRate: number;
  lowerLimit: number;
  upperLimit: number;
}

export function getServiceProviderRates(
  serviceProviderIds: number[],
  productTypeId: number
) {
  return request<ServiceProviderRateApiItem[]>(
    "service-provider/service-provider-rates",
    {
      method: "POST",
      body: JSON.stringify({
        serviceProviderIds,
        productTypeId,
      }),
    }
  );
}
