export const SET_IS_LOADING = "ui.SET_IS_LOADING";

export type UiState = {
  isLoading: boolean;
};

export interface SetIsLoadingAction {
  type: typeof SET_IS_LOADING;
  payload: {
    isLoading: boolean;
  };
}

export type UiActionTypes = SetIsLoadingAction;
