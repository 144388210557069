import { affinityColors } from "../theme/colors";
import { makeStyles } from "tss-react/mui";
import { CircularProgress } from "@mui/material";
import React /*, { useEffect, useMemo, useState } */ from "react";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/InfoOutlined";

type StatusType = "loading" | "error" | "success" | "info";

interface Props {
  /** The data attribute used for targeting this component in Cypress */
  dataCy?: string;
  title?: string;
  message?: string | React.ReactNode;
  type: StatusType;
  retry?: () => void;
  // TODO: Get rid of link. It's used for one component + inappropriate:
  //  src/products/sales/src/containers/StepChoose/wlrBroadband/Resigns/ResignProductSelector.js
  link?: React.ReactNode;
  style?: React.CSSProperties;
}

const useStyles = () => {
  return makeStyles()((theme) => ({
    loading: {
      background: affinityColors.neutralSilverfox.value,
      color: affinityColors.affinityVioletDarker.value,
    },
    info: {
      background: theme.palette["info"].main,
      color: theme.palette["info"].contrastText,
    },
    success: {
      background: theme.palette["success"].main,
      color: theme.palette["success"].contrastText,
    },
    error: {
      background: theme.palette["error"].main,
      color: theme.palette["error"].contrastText,
    },
    root: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderRadius: 4,
      padding: theme.spacing(1, 1),
    },
    icon: {},
    message: {
      paddingLeft: theme.spacing(1),
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 5,
    },
    retry: {
      marginLeft: 10,
      color: "inherit",
      fontWeight: "bold",
      textDecoration: "underline",
      cursor: "pointer",
    },
    "@keyframes popIn": {
      "0%": {
        transform: "scale(0.8)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  }));
};

export function CustomStatusChip({
  title,
  message,
  type,
  retry,
  link,
  style,
  dataCy,
}: Props) {
  const styles = useStyles();
  const { classes } = styles();

  if (message || title) {
    if (typeof message !== "string" && !React.isValidElement(message)) {
      // Don't blow up if a JS object has been passed by mistake. eg. a weird DC error response.
      message = JSON.stringify(message);
    }
    return (
      <div
        className={`${classes.root} ${classes[type]}`}
        data-cy={dataCy}
        style={style}
      >
        <div className={classes.icon}>
          {type === "loading" && <CircularProgress color="inherit" />}
          {type === "error" && (
            <ErrorIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "success" && (
            <CheckIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "info" && (
            <InfoIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
        </div>
        <div className={classes.message}>
          {title && <div className={classes.title}>{title}</div>}
          {message}
          {retry && (
            <span className={classes.retry} onClick={retry}>
              Please try again
            </span>
          )}
          {link && link}
        </div>
      </div>
    );
  }
  return <div />;
}
