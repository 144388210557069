import { request } from "..";

export interface PermissionApiItem {
  name: string;
  value: boolean;
}

export function getPermissions() {
  return request<PermissionApiItem[]>("permission");
}
