import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  CreditNoteLinesAPIItem,
  getCreditNoteLines,
} from "../../api/posted-document/credit-note-lines/index";
import { setCreditNoteLinesData, setCreditNoteLinesError } from "./actions";

function* fetchCreditNoteLines(action: RequestDataAction) {
  try {
    const data: PaginatedResult<CreditNoteLinesAPIItem> = yield call(
      getCreditNoteLines,
      action.payload.params
    );

    yield put(setCreditNoteLinesData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setCreditNoteLinesError(error));
  }
}

export function* watchCreditNoteLines() {
  yield takeLatest(REQUEST_DATA, fetchCreditNoteLines);
}
