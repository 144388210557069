import { RequestError } from "../../api/types";

export const FORM_SUBMIT = "uploadTemplateAmendments.FORM_SUBMIT";
export const SET_UPLOAD_RESULT = "uploadTemplateAmendments.SET_UPLOAD_RESULT";
export const SET_ERROR = "uploadTemplateAmendments.SET_ERROR";
export const RESET_STATE = "uploadTemplateAmendments.RESET_STATE";

export type UploadTemplateAmendmentsState = {
  message: string;
  isSubmitting: boolean;
  success?: boolean;
  apiError?: RequestError;
};

export type UploadTemplateAmendmentsParams = {
  file: File;
} & UploadTemplateAmendmentsFormState;

export type UploadTemplateAmendmentsFormState = {
  serviceProviderId?: number;
  postingDate?: string;
  journalsOnly?: boolean;
  unallocatedPosting?: boolean;
};

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    params: UploadTemplateAmendmentsParams;
  };
}

export interface SetResultAction {
  type: typeof SET_UPLOAD_RESULT;
  payload: {
    message: string;
    success: boolean;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    error: RequestError;
  };
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export type UploadTemplateAmendmentsActionTypes =
  | FormSubmitAction
  | SetResultAction
  | SetErrorAction
  | ResetStateAction;
