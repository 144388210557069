import {
  DisconRecUpValueReportApiItem,
  DisconRecUpValueReportParams,
} from "../../api/asset-report/generate-discon-rec-up-value-report";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  REQUEST_DATA,
  SET_DATA,
  SET_ERROR,
  CLEAR_DATA_TABLE,
  SET_TABLE_CONTROLS,
} from "./types";

export function requestDisconRecUpValueReport(
  params: DisconRecUpValueReportParams
) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setDisconRecUpValueReportData(
  data: PaginatedResult<DisconRecUpValueReportApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setDisconRecUpValueReportErrorForDisplay(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}

export function clearDisconRecUpValueReportTableData() {
  return {
    type: CLEAR_DATA_TABLE,
  };
}

export function setDisconRecUpValueReportTableControls(
  tableControls: TableAPIRequestParams<DisconRecUpValueReportApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: {
      tableControls,
    },
  };
}
