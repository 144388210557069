import { DataTable } from "../../../shared/components/DataTable";
import { Box, Typography } from "@mui/material";
import React from "react";
import FileDownload from "js-file-download";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import {
  DeferredCostReportApiItem,
  getDeferredCostReportExcel,
} from "../../../api/asset-report";
import { AssetStatus } from "../../../shared/enum/asset";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { formatDecimal } from "../../../shared/helpers/numbers";
import {
  requestDeferredCostReport,
  setDeferredCostReportTableControls,
  clearDeferredCostReportTableData,
} from "../../../store/deferredCostReport/actions";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import {
  BooleanParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { Select } from "../../../shared/components/filter_controls/Select";

export const DeferredCostReport = () => {
  const { items, count, fetching, error, tableControls } = useSelector(
    (state: RootState) => state.deferredCostReport
  );

  const dispatch = useDispatch();
  const [filters, updateFilters] = useQueryParams({
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    status: withDefault(CommaDelimitedNumericArrayParam, []),
    dateRange: dateRangeParser,
    sbInvoiced: withDefault(BooleanParam, null, true),
  });

  const filterMeta = [
    {
      name: "dateRange",
      label: "Date Range",
      isDateRange: true,
    },
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
    {
      name: "status",
      label: "Status",
      enum: AssetStatus,
    },
    {
      name: "sbInvoiced",
      label: "SB Invoiced",
    },
  ];

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
    dispatch(clearDeferredCostReportTableData());
  };

  const handleExport = () => {
    getDeferredCostReportExcel({
      assetStatus: filters.status,
      sbInvoiced: !!filters.sbInvoiced,
      serviceProviderIds: filters.serviceProvider.map(getServiceProviderId),
      startDate: filters.dateRange[0],
      endDate: filters.dateRange[1],
      tableControls,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  const handleSubmit = () => {
    dispatch(
      requestDeferredCostReport({
        assetStatus: filters.status,
        sbInvoiced: !!filters.sbInvoiced,
        serviceProviderIds: filters.serviceProvider.map(getServiceProviderId),
        startDate: filters.dateRange[0],
        endDate: filters.dateRange[1],
        tableControls,
      })
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof DeferredCostReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset ID", align: "right" },
    { name: "sbInvoiced", label: "SB Invoiced", align: "right" },
    { name: "serviceProvider", label: "Service Provider", align: "right" },
    {
      name: "status",
      label: "Status",
      align: "right",
      customBodyRender: (value) => AssetStatus[parseInt(value)],
    },
    { name: "spChangeType", label: "SP Change Type", align: "right" },
    {
      name: "spChangeDate",
      label: "SP Change Date",
      customBodyRender: formatISOForDisplay,
    },
    { name: "publicIdentifier", label: "Public Identifier", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    {
      name: "thirdPartyPartnerId",
      label: "Third Party Partner ID",
      align: "right",
    },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
    },
    { name: "name", label: "Name", align: "right" },
    {
      name: "tariffAdvance",
      label: "Tariff Advance",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "additionalFundingPartner",
      label: "Additional Funding Partner",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "serviceAdvancePartner",
      label: "Service Advance Partner",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "collectedAdvance",
      label: "Collected Advance",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "outstandingAdvance",
      label: "Outstanding Advance",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "amountPaid",
      label: "Amount Paid",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "partnerShare",
      label: "Partner Share",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "cost",
      label: "Cost",
      align: "right",
      customBodyRender: formatDecimal,
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Deferred Cost Report
        </Typography>
        <Box>
          <LoaderButton
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            loading={fetching}
            style={{ marginLeft: 8 }}
            color="primary"
            size="large"
            variant="contained"
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
            onClick={handleSubmit}
          >
            Submit
          </LoaderButton>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={count === 0 ? undefined : count}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <DateRangeControl
              name="Date Range"
              id="start-date-range-filter-control"
              selected={filters.dateRange}
              onChange={(dateRange) => updateFilters({ dateRange })}
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "status")?.label ||
                "Status"
              }
              id="status-filter-control"
              options={getEnumNames(AssetStatus)}
              selected={
                filters.status
                  ?.filter((status) => status !== null)
                  .map((status) => AssetStatus[status as number]) || []
              }
              onChange={(status) =>
                updateFilters({
                  status: status?.map(
                    (status) => AssetStatus[status as keyof typeof AssetStatus]
                  ),
                })
              }
            />
            <Select
              name="SB Invoiced"
              id="sb-invoiced"
              options={["Yes", "No"]}
              selected={
                filters.sbInvoiced === null
                  ? []
                  : filters.sbInvoiced
                  ? ["Yes"]
                  : ["No"]
              }
              onChange={(sbInvoiced) =>
                updateFilters({
                  sbInvoiced:
                    sbInvoiced.length === 0
                      ? null
                      : sbInvoiced[0] === "Yes"
                      ? true
                      : false,
                })
              }
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          dispatch(setDeferredCostReportTableControls(tableControls));
          if (filters.dateRange[0] && filters.dateRange[1]) {
            dispatch(
              requestDeferredCostReport({
                assetStatus: filters.status,
                sbInvoiced: !!filters.sbInvoiced,
                serviceProviderIds: filters.serviceProvider.map(
                  getServiceProviderId
                ),
                startDate: filters.dateRange[0],
                endDate: filters.dateRange[1],
                tableControls,
              })
            );
          }
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
