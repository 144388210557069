import FileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import React from "react";
import { DataTable } from "../../../shared/components/DataTable";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import {
  AssetStatus,
  ServiceProviderStatusChangeType,
} from "../../../shared/enum/asset";
import { formatDecimal } from "../../../shared/helpers/numbers";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
} from "../../../shared/helpers/queryParsing";
import { useQueryParams, withDefault } from "use-query-params";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import {
  DisconRecUpValueReportApiItem,
  getDisconRecUpValueReportExcel,
} from "../../../api/asset-report/generate-discon-rec-up-value-report";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import {
  requestDisconRecUpValueReport,
  clearDisconRecUpValueReportTableData,
  setDisconRecUpValueReportTableControls,
} from "../../../store/disconRecUpValueReport/actions";
import { MultilineTextArea } from "../../../shared/components/filter_controls/MultilineTextArea";

export const DisconRecUpValueReport = () => {
  const { items, fetching, error, tableControls, count } = useSelector(
    (state: RootState) => state.disconRecUpValueReport
  );

  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    identifier: withDefault(CommaDelimitedArrayParam, []),
    status: withDefault(CommaDelimitedNumericArrayParam, []),
  });

  const disabled =
    filters.status.length === 0 || filters.identifier.length === 0;

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );

    dispatch(clearDisconRecUpValueReportTableData());
  };

  const handleSubmit = () => {
    dispatch(
      requestDisconRecUpValueReport({
        tableControls,
        identifiers: filters.identifier,
        assetStatuses: filters.status,
      })
    );
  };

  // useEffect(() => {
  //   if (!filters.identifier || !filters.status) return;

  //   dispatch(
  //     requestDisconRecUpValueReport({
  //       tableControls,
  //       identifiers: filters.identifier,
  //       assetStatuses: filters.status,
  //     })
  //   );
  // }, [dispatch, filters, tableControls]);

  const handleExport = () => {
    getDisconRecUpValueReportExcel({
      tableControls,
      identifiers: filters.identifier,
      assetStatuses: filters.status,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  let columns: {
    [key: string]: any;
    name: keyof DisconRecUpValueReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "identifier", label: "Identifier", align: "right" },
    { name: "assetId", label: "Asset Id", align: "right" },
    { name: "tempCtn", label: "Temp CTN", align: "right" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
    },
    { name: "dealer", label: "Dealer", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    { name: "provider", label: "Provider", align: "right" },
    { name: "productCode", label: "Product Code", align: "right" },
    { name: "productName", label: "Product Name", align: "right" },
    { name: "status", label: "Status", align: "right", enum: AssetStatus },
    {
      name: "serviceProviderStatusChangeType",
      label: "SP Status Change Type",
      enum: ServiceProviderStatusChangeType,
    },
    {
      name: "serviceProviderStatusChangeDate",
      label: "SP Status Change Date",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "outstandingAdvance",
      label: "Outstanding Advance",
      customBodyRender: formatDecimal,
    },
    {
      name: "calculatedCommsClawback",
      label: "Calculated Comms Clawback",
      customBodyRender: formatDecimal,
    },
    {
      name: "payTariffCommission",
      label: "Pay Tariff Commission",
      customBodyRender: formatDecimal,
    },
    {
      name: "payServiceCommission",
      label: "Pay Service Commission",
      customBodyRender: formatDecimal,
    },
    {
      name: "afCommission",
      label: "AF Commission",
      customBodyRender: formatDecimal,
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Discon Rec Up Value Report
        </Typography>
        <Box>
          <LoaderButton
            style={{ marginRight: 8 }}
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            disabled={disabled}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            color="primary"
            size="large"
            variant="contained"
            loading={fetching}
            onClick={handleSubmit}
            disabled={disabled}
          >
            Submit
          </LoaderButton>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={count === 0 ? undefined : count}
        controls={() => (
          <>
            <MultilineTextArea
              id="identifier-input"
              label="Identifier"
              value={filters.identifier.join("\n")}
              helperText="Enter one identifier per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                updateFilters({ identifier: selected });
              }}
            />
            <MultiSelect
              name="Status"
              id="status-filter-control"
              options={getEnumNames(AssetStatus)}
              selected={
                filters.status
                  ?.filter((status) => status !== null)
                  .map((status) => AssetStatus[status as number]) || []
              }
              onChange={(status) =>
                updateFilters({
                  status: status?.map(
                    (status) => AssetStatus[status as keyof typeof AssetStatus]
                  ),
                })
              }
            />
          </>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={[
              {
                name: "identifier",
                label: "Identifier",
              },
              {
                name: "status",
                label: "Status",
                enum: AssetStatus,
              },
            ]}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          dispatch(setDisconRecUpValueReportTableControls(tableControls));
          if (!disabled) {
            dispatch(
              requestDisconRecUpValueReport({
                tableControls,
                identifiers: filters.identifier,
                assetStatuses: filters.status,
              })
            );
          }
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
