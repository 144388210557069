import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
  CLEAR_DATA_TABLE,
  UnraisedRevshareReportActionTypes,
  UnraisedRevshareReportState,
} from "./types";

const initialState: UnraisedRevshareReportState = {
  items: [],
  fetching: false,
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "identifier",
  },
  count: 0,
};

export function unraisedRevshareReportReducer(
  state = initialState,
  action: UnraisedRevshareReportActionTypes
): UnraisedRevshareReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };
    }

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload.tableControls,
      };
    }

    case SET_DATA: {
      return { ...state, fetching: false, ...action.payload };
    }

    case CLEAR_DATA_TABLE: {
      return {
        ...state,
        items: [],
        fetching: false,
        count: 0,
      };
    }

    default:
      return state;
  }
}
