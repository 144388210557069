import { all, call, put, takeLatest } from "redux-saga/effects";
import { getQueryProduct, QueryProductApiItem } from "../../api/product";
import { getProductDurationOptions } from "../../api/product/durations";
import {
  getProductTypeOptions,
  ProductTypesApi,
} from "../../api/product/product-types";
import { RequestError, PaginatedResult } from "../../api/types";
import {
  initializeQueryProductsFinish,
  setQueryProductsData,
  setQueryProductsErrorForDisplay,
} from "./actions";
import { INITIALIZE_START, REQUEST_DATA, RequestDataAction } from "./types";

function* fetchQueryProducts(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryProductApiItem> = yield call(
      getQueryProduct,
      action.payload
    );

    yield put(setQueryProductsData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryProductsErrorForDisplay(error));
  }
}

function* initializeQueryProducts() {
  try {
    const data: {
      productTypes: ProductTypesApi;
      duration: string[];
    } = yield all({
      productTypes: call(getProductTypeOptions),
      duration: call(getProductDurationOptions),
    });

    yield put(initializeQueryProductsFinish(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryProductsErrorForDisplay(error));
  }
}

export function* watchRequestQueryProductsData() {
  yield takeLatest(REQUEST_DATA, fetchQueryProducts);
}

export function* watchInitializeQueryProducts() {
  yield takeLatest(INITIALIZE_START, initializeQueryProducts);
}
