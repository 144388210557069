import { call, put, takeLatest } from "redux-saga/effects";
import { getQueryPartners, QueryPartnerApiItem } from "../../api/partner";
import { RequestError, PaginatedResult } from "../../api/types";
import {
  setQueryPartnersData,
  setQueryPartnersErrorForDisplay,
} from "./actions";
import { REQUEST_DATA, RequestDataAction } from "./types";

function* fetchQueryPartners(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryPartnerApiItem> = yield call(
      getQueryPartners,
      action.payload
    );

    yield put(setQueryPartnersData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryPartnersErrorForDisplay(error));
  }
}

export function* watchRequestQueryPartnersData() {
  yield takeLatest(REQUEST_DATA, fetchQueryPartners);
}
