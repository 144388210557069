import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { FilterControlWrapper } from "./FilterControlWrapper";
import { DeletableChip } from "../DeletableChip";
import { DeletableChipWrapper } from "../DeletableChipWrapper";

type Props<T extends string> = {
  name: string;
  id: string;
  options: T[];
  selected: (string | null)[];
  onChange: (selected: T[]) => any;
};

export function MultiSelect<T extends string>({
  name,
  id,
  options,
  selected,
  onChange,
}: Props<T>) {
  const handleChange = (event: any) => {
    if (Array.isArray(event.target?.value)) {
      onChange(event.target.value);
    }
  };

  const handleReset = () => onChange([]);
  const labelId = `${id}-label`;

  return (
    <FilterControlWrapper
      count={selected.length}
      id={id}
      name={name}
      onReset={handleReset}
      type="select"
    >
      <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
        <InputLabel id={labelId}>Please select an option</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          multiple
          value={selected}
          renderValue={(values) => {
            const _values = values as T[];
            return (
              <DeletableChipWrapper>
                {_values.map((value) => (
                  <DeletableChip
                    size="small"
                    key={value}
                    label={value}
                    onDelete={() => {
                      onChange(_values.filter((x) => x !== value));
                    }}
                  />
                ))}
              </DeletableChipWrapper>
            );
          }}
          onChange={handleChange}
          input={<OutlinedInput label="Please select an option" />}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                color="primary"
                checked={selected.indexOf(option) > -1}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FilterControlWrapper>
  );
}
