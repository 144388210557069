import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { NoMatch } from "../../nav/components/NoMatch";
import { getPermission } from "../../store/permissions/reducers";
import { Permissions } from "../../store/permissions/types";

export const RootRedirect = () => {
  const canAccessAssets = useSelector(getPermission(Permissions.AssetsMenu));
  const canAccessProducts = useSelector(
    getPermission(Permissions.ProductsMenu)
  );
  const canAccessReceivables = useSelector(
    getPermission(Permissions.ReceivableMenu)
  );
  const canAccessPayables = useSelector(getPermission(Permissions.PayableMenu));

  if (canAccessAssets) {
    return <Redirect to="/assets" />;
  }

  if (canAccessProducts) {
    return <Redirect to="/products/primary" />;
  }

  if (canAccessReceivables) {
    return <Redirect to="/receivables/payments/import-revshare" />;
  }

  if (canAccessPayables) {
    return <Redirect to="/self-bill/generate-revshare" />;
  }

  return <NoMatch />;
};
