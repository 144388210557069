import {
  CreateOocRevenueShareActions,
  CreateOocRevenueShareFormState,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_PAYMENT_TERM_OPTIONS,
} from "./types";

const initialState: CreateOocRevenueShareFormState = {
  requestError: undefined,
  fetching: false,
  paymentTermOptions: [],
  values: {
    assetStartDateFrom: "",
    assetStartDateTo: "",
    oocPaymentTermId: 0,
  },
};

export function createOocRevenueShareReducer(
  state = initialState,
  action: CreateOocRevenueShareActions
): CreateOocRevenueShareFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_PAYMENT_TERM_OPTIONS: {
      return {
        ...state,
        paymentTermOptions: action.payload.values,
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return {
        ...state,
        requestError: undefined,
        values: {
          ...initialState.values,
        },
      };
    }

    default:
      return state;
  }
}
