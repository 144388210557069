import { stringify } from "query-string";
import { request, requestFile } from "..";
import { AssetStatus } from "../../shared/enum/asset";
import { DateString } from "../../shared/helpers/dates";
import {
  combine,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../types";

export type AssetFinancialReportParams = {
  assetStatus?: (number | null)[];
  entryTypes?: number[];
  serviceProviderIds?: number[];
  startDate?: DateString;
  endDate?: DateString;
  pageNumber?: number;
  pageSize?: number;
  export?: boolean;
  returnAll?: boolean;
};

export type AssetReportApiItem = {
  assetSummaryId: number;
  assetId: number;
  publicIdentifier: string;
  previousIdentifier: string;
  contractTypeId: string;
  status: AssetStatus;
  createdBy: string;
  name: string;
  imei: string;
  temp_ctn: string;
  simNo: string;
  thirdPartyId: string;
  customerName: string;
  serviceProviderId: string;
  productCode: string;
  startDate: string;
  endDate: string;
  sourceRef: string;
  spAccountRef: string;
  primaryFundingRef: string;
  lineRental: number;
  lineRentalTotal: number;

  // AleType Id columns
  pay_TariffCommission: number | null;
  pay_TariffAdvance: number | null;
  pay_ServiceCommission: number | null;
  pay_ServiceAdvance: number | null;
  pay_AdditionalFundingAdvance: number | null;
  pay_VolumeBonus: number | null;
  pay_AdditionalFundingDws: number | null;
  pay_BcadFund: number | null;
  pay_Da: number | null;
  pay_Air_Qr_Qn: number | null;
  pay_SideLetter_AdditionalFundingNetwork: number | null;
  pay_RevshareAdvanceRecovery: number | null;
  pay_PartnerShareAmount: number | null;
  exp_TariffCommission: number | null;
  exp_TariffAdvance: number | null;
  exp_ServiceCommission: number | null;
  exp_ServiceAdvance: number | null;
  exp_VolumeBonus: number | null;
  exp_BcadFund: number | null;
  exp_Da: number | null;
  exp_Air_Qr_Qn: number | null;
  exp_SideLetter_AdditionalFundingNetwork: number | null;
  exp_Churn: number | null;
  exp_Csi: number | null;
  exp_Digital: number | null;
  rec_TariffCommission: number | null;
  rec_TariffAdvance: number | null;
  rec_ServiceCommission: number | null;
  rec_ServiceAdvance: number | null;
  rec_VolumeBonus: number | null;
  rec_BcadFund: number | null;
  rec_Da: number | null;
  rec_Air_Qr_Qn: number | null;
  rec_SideLetter_AdditionalFundingNetwork: number | null;
  rec_Churn: number | null;
  rec_Csi: number | null;
  rec_Digital: number | null;
  dwsPortionOfRevenue: number | null;
  customerBilledAmount: number | null;
  posted_MonthlyAdvanceReleaseAmount: number | null;
  amountPaid: number | null;
  outstandingAdvanceRepaymentWhenResigned: number | null;
  repaymentOfCommissionWhenTruedUp: number | null;
};

export type DeferredCostReportParams = {
  serviceProviderIds: number[];
  startDate: string | null;
  endDate: string | null;
  assetStatus: (number | null)[];
  sbInvoiced: boolean | null;
  tableControls: TableAPIRequestParams<DeferredCostReportApiItem>;
};

export type DeferredCostReportApiItem = {
  assetId: number;
  sbInvoiced: string; // boolean
  serviceProvider: string;
  status: string;
  spChangeType: number;
  spChangeDate: string;
  publicIdentifier: string;
  customerName: string;
  thirdPartyPartnerId: string;
  startDate: string;
  endDate: string;
  name: string;

  tariffAdvance: number;
  additionalFundingPartner: number;
  serviceAdvancePartner: number;
  collectedAdvance: number;
  outstandingAdvance: number;
  amountPaid: number;
  partnerShare: number;
  cost: number;
};

export function getAssetReport(params: AssetFinancialReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    returnAll: params.returnAll,
  });

  return request<PaginatedResult<AssetReportApiItem>>(
    `asset-report/generate-report${combine([tableParamsQS])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderIds: params.serviceProviderIds,
        assetStatus: params.assetStatus,
      }),
    }
  );
}

export function getAssetReportExcel(params: AssetFinancialReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    returnAll: params.returnAll,
  });

  return requestFile(
    `asset-report/generate-report${combine([
      tableParamsQS,
      stringify({ export: params.export }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderIds: params.serviceProviderIds,
        entryTypes: params.entryTypes,
        assetStatus: params.assetStatus,
      }),
    }
  );
}

export function getDeferredCostReport(params: DeferredCostReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return request<PaginatedResult<DeferredCostReportApiItem>>(
    `asset-report/generate-deferred-cost-report${combine([
      tableParamsQS,
      stringify({ export: false }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        serviceProviderIds: params.serviceProviderIds,
        startDate: params.startDate,
        endDate: params.endDate,
        assetStatus: params.assetStatus,
        sbInvoiced: params.sbInvoiced,
      }),
    }
  );
}

export function getDeferredCostReportExcel(params: DeferredCostReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return requestFile(
    `asset-report/generate-deferred-cost-report${combine([
      tableParamsQS,
      stringify({ export: true, returnAll: true }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        serviceProviderIds: params.serviceProviderIds,
        startDate: params.startDate,
        endDate: params.endDate,
        assetStatus: params.assetStatus,
        sbInvoiced: params.sbInvoiced,
      }),
    }
  );
}
