import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { RequestError } from "../../../../api/types";
import { Page } from "../../../../layout/components/Page";
import { LoaderButton } from "../../../../shared/components/LoaderButton";
import { useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { FormFieldsWrapper } from "../../../../layout/components/FormFieldsWrapper";
import { DeletableChip } from "../../../../shared/components/DeletableChip";
import { AssetStatus } from "../../../../shared/enum/asset";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../../shared/enum/serviceProvider";
import { PaymentTerms } from "../../../../shared/enum/invoice";
import { getEnumNames, getEnumValues } from "../../../../shared/helpers/enum";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import { DeletableChipWrapper } from "../../../../shared/components/DeletableChipWrapper";
import { getThirdPartyIdsByPaymentTerms } from "../../../../api/invoice/payment-terms";
import { APIFetchingWrapper } from "../../../../shared/components/APIFetchingWrapper";
import { MultiSelect } from "../../../../shared/components/filter_controls/MultiSelect";
import { getGenerateUpfrontSchema } from "./formSchema";
import {
  GenerateUpfrontParams,
  postGenerateUpfront,
} from "../../../../api/invoice/upfront";
import { ConfirmationModal } from "../../../../shared/components/ConfirmationModal";

type GenerateUpfrontSelfBillForm = {
  assetStatuses: AssetStatus[];
  assetIds: string;
  reference: string;
  assetIdsAll: boolean;
  thirdPartyIdsAll: boolean;
  thirdPartyIds: string;
  startDate: Date | null;
  endDate: Date | null;
  invoiceDate: Date | null;
  paymentTerms: string[];
  serviceProviders: ServiceProvider[];
};

export function GenerateUpfront() {
  const [error, setError] = useState<RequestError | null>(null);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalText, setModalText] = React.useState("");
  const [
    ignoreDoNotSelfBillWarning,
    setIgnoreDoNotSelfBillWarning,
  ] = React.useState(false);
  const location = useLocation();

  const schema = useMemo(() => getGenerateUpfrontSchema(), []);

  const formInitialState = useMemo(
    () => ({
      assetIdsAll: true,
      thirdPartyIdsAll: true,
      assetStatuses: [AssetStatus.Active],
      serviceProviders: serviceProviders.slice(),
    }),
    []
  );
  const [form, setForm] = useState<Partial<GenerateUpfrontSelfBillForm>>(
    formInitialState
  );

  const resetAll = useCallback(() => {
    setForm(formInitialState);
    setSuccess(false);
    setError(null);
    setFormErrors([]);
  }, [setForm, setSuccess, setError, formInitialState]);

  // Handle case where nav menu clicked if already on same route heirachy
  useEffect(() => {
    resetAll();
  }, [location, resetAll]);

  const handleFormUpdate = (updates: Partial<GenerateUpfrontSelfBillForm>) => {
    setForm({ ...form, ...updates });
  };

  const handleSubmit = () => {
    schema
      .validate(form, { abortEarly: false })
      .then((validForm) => {
        // Note: the POST API accepts empty arrays for assetIds and partnerIds to signal "all"

        const params = {
          ...validForm,
          allServiceProviders:
            validForm.serviceProviders.length === serviceProviders.length,
          assetIdsAll: undefined,
          thirdPartyIdsAll: undefined,
          paymentTerms: undefined,
          assetIds: validForm.assetIdsAll ? [] : validForm.assetIds,
          thirdPartyIds: validForm.thirdPartyIdsAll
            ? []
            : validForm.thirdPartyIds,
          serviceProviders: validForm.serviceProviders.map(
            getServiceProviderId
          ),
          ignoreDoNotSelfBillWarning: ignoreDoNotSelfBillWarning,
        };
        setIsSubmitting(true);
        postGenerateUpfront(params as GenerateUpfrontParams)
          .then(() => {
            setSuccess(true);
          })
          .catch((e) => {
            if (e.status === 422) {
              let errorMessage = e.message
                .map((error: string) => error)
                .join("\n");
              setModalOpen(true);
              setIgnoreDoNotSelfBillWarning(true);
              setModalText(errorMessage);
            } else {
              setError(e);
            }
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch((e) => {
        setFormErrors(e.errors);
      });
  };

  const BackButton = () => (
    <Button onClick={resetAll} variant="outlined">
      Back to Generate Upfront Self Bill Process
    </Button>
  );

  const getTitle = () => {
    let title = "Generate Upfront Self Bill Process";
    if (error || success) {
      title += " - Result";
    }
    return title;
  };

  const getConfirmationModalTitle = () => {
    let title =
      "The following partners are set as ‘Do Not Self Bill’ and will not be generated. \n";
    return title;
  };

  // populate Partner Third Party Ids when a Payment Term is selected
  const [fetchingThirdPartyIds, setFetchingThirdPartyIds] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (
      form.serviceProviders?.length === 0 ||
      form.paymentTerms === undefined
    ) {
      return handleFormUpdate({ thirdPartyIds: "" });
    }

    setFetchingThirdPartyIds(true);
    getThirdPartyIdsByPaymentTerms(form.paymentTerms)
      .then((thirdPartyIds) => {
        if (mounted) {
          handleFormUpdate({
            thirdPartyIds: thirdPartyIds.join("\n"),
          });
          setFetchingThirdPartyIds(false);
        }
      })
      .catch((e) => {
        if (mounted) {
          setError(e);
        }
      });

    return () => {
      mounted = false;
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.paymentTerms, form.serviceProviders]);

  if (error) {
    return (
      <Page title={getTitle()} actions={<BackButton />}>
        <CustomStatusChip
          title={error.type}
          message={error.message}
          type="error"
        />
      </Page>
    );
  }

  if (success) {
    return (
      <Page title={getTitle()} actions={<BackButton />}>
        <CustomStatusChip
          title="Success"
          message="The request to generate a self bill run has been received and will now be processed."
          type="success"
        />
      </Page>
    );
  }

    return (
      <React.Fragment>
        <Box justifyContent="space-between" display="flex" marginBottom={1}>
          <Typography variant="h2" gutterBottom>Self Bill</Typography>
        </Box>
        <Page
          title={getTitle()}
          actions={
            <React.Fragment>
              <LoaderButton
                loading={isSubmitting}
                onClick={handleSubmit}
                color="primary"
                size="large"
              >
                Submit
              </LoaderButton>
            </React.Fragment>
          }
        >
          <FormFieldsWrapper>
            <FormControl variant="outlined" style={{ width: "100%" }} size="small">
              <InputLabel id="select-service-provider-label">
                Service Provider
              </InputLabel>
              <Select
                fullWidth
                labelId="select-service-provider-label"
                id="select-service-provider"
                multiple
                value={form.serviceProviders}
                renderValue={(values) => {
                  const _values = values as ServiceProvider[];
                  return (
                    <DeletableChipWrapper>
                      {_values.map((value) => (
                        <DeletableChip
                          size="small"
                          key={value}
                          label={value}
                          onDelete={() => {
                            handleFormUpdate({
                              serviceProviders: _values.filter((x) => x !== value),
                            });
                          }}
                        />
                      ))}
                    </DeletableChipWrapper>
                  );
                }}
                onChange={(event: any) => {
                  if (Array.isArray(event.target?.value)) {
                    handleFormUpdate({ serviceProviders: event.target.value });
                  }
                }}
                input={<OutlinedInput label="Please select an option" />}
              >
                {serviceProviders.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox
                      color="primary"
                      checked={
                        !!form.serviceProviders &&
                        form.serviceProviders.indexOf(option) > -1
                      }
                    />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              size="small"
              disabled={isSubmitting}
              fullWidth
              variant="outlined"
              id="select-reference"
              label={schema.fields.reference.spec.label + "*"}
              value={form.reference}
              onChange={(e) => {
                const reference = e.target.value;
                handleFormUpdate({ reference });
              }}
            />
            <Box display="flex">
              <Box width="50%" paddingRight={0.5}>
                <DatePicker
                  disabled={isSubmitting}
                  format="dd/MM/yyyy"
                  label={schema.fields.startDate.spec.label + "*"}
                  value={form.startDate || null}
                  onChange={(input) => {
                    handleFormUpdate({
                      startDate: input,
                    });
                  }}
                />
              </Box>
              <Box width="50%" paddingLeft={0.5}>
                <DatePicker
                  disabled={isSubmitting}
                  format="dd/MM/yyyy"
                  label={schema.fields.endDate.spec.label + "*"}
                  value={form.endDate || null}
                  onChange={(input) => {
                    handleFormUpdate({
                      endDate: input,
                    });
                  }}
                />
              </Box>
            </Box>
            <FormControl variant="outlined" style={{ width: "100%" }} size="small">
              <InputLabel id={"select-asset-status-label"}>
                Select {schema.fields.assetStatuses.spec.label}*
              </InputLabel>
              <Select
                disabled={isSubmitting}
                labelId="select-asset-status-label"
                id="select-asset-status"
                multiple
                value={form.assetStatuses || []}
                renderValue={(values) => {
                  const _values = values as AssetStatus[];
                  return (
                    <DeletableChipWrapper>
                      {_values.map((value) => (
                        <DeletableChip
                          size="small"
                          key={value}
                          label={AssetStatus[value]}
                          onDelete={() => {
                            handleFormUpdate({
                              assetStatuses: _values.filter((x) => x !== value),
                            });
                          }}
                        />
                      ))}
                    </DeletableChipWrapper>
                  );
                }}
                onChange={(event) => {
                  const values = event.target.value as any;
                  if (Array.isArray(values)) {
                    if (values.includes("all")) {
                      return handleFormUpdate({
                        assetStatuses: getEnumValues(AssetStatus),
                      });
                    }

                    if (values.includes("none")) {
                      return handleFormUpdate({
                        assetStatuses: [],
                      });
                    }

                    handleFormUpdate({
                      assetStatuses: values,
                    });
                  }
                }}
                input={<OutlinedInput label="Select Asset Statuses" />}
              >
                <MenuItem
                  key="all"
                  value={
                    form.assetStatuses?.length === getEnumNames(AssetStatus).length
                      ? "none"
                      : "all"
                  }
                >
                  <Checkbox
                    color="primary"
                    checked={
                      form.assetStatuses?.length ===
                      getEnumNames(AssetStatus).length
                    }
                  />
                  <ListItemText
                    primary={
                      form.assetStatuses?.length ===
                      getEnumNames(AssetStatus).length
                        ? "Deselect All"
                        : "Select All"
                    }
                  ></ListItemText>
                </MenuItem>
                <Divider />
                {getEnumNames(AssetStatus).map((option) => (
                  <MenuItem
                    key={option}
                    value={AssetStatus[option as keyof typeof AssetStatus]}
                  >
                    <Checkbox
                      color="primary"
                      checked={
                        form.assetStatuses
                          ? form.assetStatuses.includes(
                              AssetStatus[option as keyof typeof AssetStatus]
                            )
                          : false
                      }
                    />
                    <ListItemText primary={option}></ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box width="50%">
              <DatePicker
                disabled={isSubmitting}
                format="dd/MM/yyyy"
                label={schema.fields.invoiceDate.spec.label + "*"}
                value={form.invoiceDate || null}
                onChange={(input) => {
                  handleFormUpdate({
                    invoiceDate: input,
                  });
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                label="Generate for all Assets"
                control={
                  <Checkbox
                    name="checkbox-all-assets"
                    color="primary"
                    checked={form.assetIdsAll}
                    onChange={(e) => {
                      handleFormUpdate({ assetIdsAll: e.target.checked });
                    }}
                  />
                }
              />
            </Box>

            {!form.assetIdsAll && (
              <TextField
                disabled={isSubmitting}
                value={form.assetIds}
                onChange={(event) =>
                  handleFormUpdate({ assetIds: event.target.value })
                }
                fullWidth
                id="asset-ids-input"
                label={`Enter ${schema.fields.assetIds.spec.label}`}
                multiline
                rows={5}
                variant="outlined"
                helperText="Enter one Asset Id per line. Tip: you can paste a column from a spreadsheet here"
              />
            )}
            <Box>
              <FormControlLabel
                label="Generate for all Partners"
                control={
                  <Checkbox
                    name="checkbox-all-partners"
                    color="primary"
                    disabled={!form.assetIdsAll}
                    checked={form.thirdPartyIdsAll}
                    onChange={(e) => {
                      handleFormUpdate({ thirdPartyIdsAll: e.target.checked });
                    }}
                  />
                }
              />
            </Box>
            {!form.thirdPartyIdsAll && (
              <React.Fragment>
                <MultiSelect
                  name={schema.fields.paymentTerms.spec.label || ""}
                  id="select-payment-terms"
                  options={getEnumValues(PaymentTerms).map((v) => PaymentTerms[v])}
                  selected={
                    form.paymentTerms === undefined ? [] : form.paymentTerms
                  }
                  onChange={(paymentTerms) => handleFormUpdate({ paymentTerms })}
                />
                <APIFetchingWrapper loading={fetchingThirdPartyIds}>
                  <TextField
                    disabled={isSubmitting}
                    value={form.thirdPartyIds || ""}
                    onChange={(event) =>
                      handleFormUpdate({ thirdPartyIds: event.target.value })
                    }
                    fullWidth
                    id="partners-input"
                    label={`Enter ${schema.fields.thirdPartyIds.spec.label}`}
                    multiline
                    rows={5}
                    variant="outlined"
                    helperText="Enter one Partner Third Party Id per line. Tip: you can paste a column from a spreadsheet here"
                  />
                </APIFetchingWrapper>
              </React.Fragment>
            )}
          </FormFieldsWrapper>
          {formErrors.length > 0 && (
            <Box my={1}>
              <CustomStatusChip
                title="Please fix the following field errors and try again:"
                message={
                  <List>
                    {formErrors.map((error, i) => (
                      <ListItem disableGutters key={i}>
                        {error}
                      </ListItem>
                    ))}
                  </List>
                }
                type="error"
              />
            </Box>
          )}
          <ConfirmationModal
            open={isModalOpen}
            titleVariant="h3"
            title={getConfirmationModalTitle()}
            text={modalText}
            onClose={() => {
              setModalOpen(false);
              setIgnoreDoNotSelfBillWarning(false);
            }}
            onConfirm={() => {
              setModalOpen(false);
              handleSubmit();
              setIgnoreDoNotSelfBillWarning(false);
            }}
          />
                </Page>
    </React.Fragment>
  );
}
