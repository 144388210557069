import React from "react";
import { Button, CircularProgress, ButtonProps } from "@mui/material";

export interface LoaderButtonProps extends ButtonProps {
  loading: boolean;
}

export const LoaderButton = (props: LoaderButtonProps) => {
  const { loading, ...buttonProps } = props;
  return (
    <Button
      variant="contained"
      {...buttonProps}
      startIcon={
        loading ? <CircularProgress color="inherit" size="1rem" /> : null
      }
      disabled={loading || props.disabled}
    >
      {props.children}
    </Button>
  );
};
