import {
  PartnerRateAdjustmentReportApiItem,
  PartnerRateAdjustmentReportParams,
} from "../../api/partner-report/generate-partner-rate-adjustment-report";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "partnerRateAdjustmentReport.REQUEST_DATA";
export const SET_DATA = "partnerRateAdjustmentReport.SET_DATA";
export const SET_ERROR = "partnerRateAdjustmentReport.SET_ERROR";

export interface PartnerRateAdjustmentReportState {
  items: PartnerRateAdjustmentReportApiItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: PartnerRateAdjustmentReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<PartnerRateAdjustmentReportApiItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PartnerRateAdjustmentReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
