import {
  PortAndResignReportApiItem,
  PortAndResignReportParams,
} from "../../api/asset-report/generate-port-and-resign-report";
import { RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR } from "./types";

export function requestPortAndResignReport(params: PortAndResignReportParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setPortAndResignReportData(data: PortAndResignReportApiItem[]) {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setPortAndResignReportErrorForDisplay(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
