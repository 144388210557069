import { request } from "../..";

export function getRevshareReceivedDrilldown(assetId: number) {
  return request<RevshareReceivedApiItem[]>(
    `asset-ledger-entry/revshare-received?assetId=${assetId}`
  );
}

export type RevshareReceivedApiItem = {
  partnerShare: number;
  revshareReceived: number;
  customerBilledAmount: number;
  billDate: string;
  reference: string;
  aleTypeName: string;
  thirdPartyInvoiceId: string;
  createdByName: string;
};
