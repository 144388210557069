import {
  PartnerAdminInformationReportApiItem,
  PartnerAdminInformationReportParams,
} from "../../api/partner-report/generate-partner-admin-info-report";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestPartnerAdminInformationReport(
  params: PartnerAdminInformationReportParams
) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setPartnerAdminInformationReportData(
  data: PaginatedResult<PartnerAdminInformationReportApiItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setPartnerAdminInformationReportError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
