import { call, put, takeLatest } from "redux-saga/effects";
import {
  getSelfBillToPartners,
  SelfBillToParnerApi,
} from "../../api/partner/self-bill-to-partner-code";
import { RequestError } from "../../api/types";
import {
  setSelfBillToPartnersData,
  setSelfBillToPartnersError,
} from "./actions";
import {
  REQUEST_SELF_BILL_TO_PARTNERS_DATA,
  RequestSelfBillToPartnersDataAction,
} from "./types";

function* fetchSelfBillToPartners(action: RequestSelfBillToPartnersDataAction) {
  try {
    const data: SelfBillToParnerApi[] = yield call(
      getSelfBillToPartners,
      action.payload
    );
    yield put(setSelfBillToPartnersData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setSelfBillToPartnersError(error));
  }
}

export function* watchRequestSelfBillToPartnersData() {
  yield takeLatest(REQUEST_SELF_BILL_TO_PARTNERS_DATA, fetchSelfBillToPartners);
}
