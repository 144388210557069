/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { BooleanExceptZero } from "../../../../shared/helpers/BooleanExceptZero";

export function getGenerateUpfrontSchema() {
  const dateRangePrefix = "Asset";
  return yup.object().shape({
    serviceProviders: yup.array().label("Service Provider").required(),
    reference: yup.string().label("Reference").max(12).required(),
    startDate: yup
      .date()
      .label(`${dateRangePrefix} Start Date`)
      .required()
      // .max(
      //   yup.ref("endDate"),
      //   (meta) => `${meta.label} must be earlier than the End Date`
      // )
      .typeError((meta) => `${meta.label} must be a valid date`),
    endDate: yup
      .date()
      .label(`${dateRangePrefix} End Date`)
      .required()
      // .min(
      //   yup.ref("startDate"),
      //   (meta) => `${meta.label} must be later than the Start Date`
      // )
      .typeError((meta) => `${meta.label} must be a valid date`),
    assetStatuses: yup
      .array()
      .label("Asset Statuses")
      .min(1)
      .of(yup.number().required()),
    invoiceDate: yup
      .date()
      .label("Invoice Date")
      .required()
      .typeError((meta) => `${meta.label} must be a valid date`),
    paymentTerms: yup.array().label("Payment Terms"),
    assetIdsAll: yup.boolean(),
    assetIds: yup
      .array()
      .label("Asset Ids")
      .when("assetIdsAll", {
        is: false,
        then: yup
          .array()
          .of(
            yup
              .number()
              .typeError(
                'Each line of the "Asset Ids" field must be a number - the following path failed the validation: ${path}'
              )
          )
          .required()
          .transform(convertMultiLineInputToArray),
        otherwise: yup.array().transform(() => []),
      }),
    thirdPartyIdsAll: yup.boolean(),
    thirdPartyIds: yup
      .array()
      .label("Partner Third Party Ids")
      .when("thirdPartyIdsAll", {
        is: false,
        then: yup
          .array()
          .of(yup.string())
          .required()
          .transform(convertMultiLineInputToArray),
        otherwise: yup.array().transform(() => []),
      }),
  });
}

function convertMultiLineInputToArray(
  value: string,
  originalValue: string
): string[] {
  return originalValue.split(/\r|\n/).filter(BooleanExceptZero);
}
