import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { NoMatch } from "../../nav/components/NoMatch";
import { getPermissions } from "../../store/permissions/reducers";

interface Props extends RouteProps {
  permissions: string[];
}

export const RestrictedRoute = (props: Props) => {
  const userPermissions = useSelector(getPermissions(), shallowEqual);
  if (
    props.permissions.every((p) =>
      userPermissions?.map((u) => u.name).includes(p)
    )
  ) {
    return <Route {...props} />;
  }

  return <NoMatch />;
};
