import { stringify } from "query-string";
import { request, requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type PostedInvoiceListAPIItem = {
  postedDocumentId: number;
  thirdPartyInvoiceId: string;
  datePosted: string;
  partnerCode: string;
  paymentTerms: number;
  amountIncVat: number;
  amountExcVat: number;
  reference: string;
  paymentType: number;
  vatAmount: number;
};

export type QueryPostedInvoiceListFilterParams = {
  sbReference?: (string | null)[];
  datePosted?: [DateString | null, DateString | null];
  partnerCode?: (string | null)[];
  paymentTerms?: (number | null)[];
  paymentType?: (number | null)[];
};

export interface PostedInvoiceListParams {
  readonly tableControls: TableAPIRequestParams<PostedInvoiceListAPIItem>;
  readonly filterParams: Partial<QueryPostedInvoiceListFilterParams>;
}

export function getPostedInvoiceList(params: PostedInvoiceListParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);
  const filterParamsQS = stringifyFilterParams(params.filterParams);

  return request<PaginatedResult<PostedInvoiceListAPIItem>>(
    `posteddocument/posted-sb-invoice-list${combine([
      tableControlsQS,
      filterParamsQS,
    ])}`
  );
}

export function getPostedInvoiceListExcel(params: PostedInvoiceListParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `posteddocument/posted-sb-invoice-list${combine([
      tableControlsQS,
      filterParamsQS,
      exportFlag,
    ])}`
  );
}
