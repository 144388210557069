import {
  ImportPaymentDataActionTypes,
  ImportPaymentDataState,
  REQUEST_IMPORT,
  RESET_STATE,
  SET_API_ERROR,
  SET_IMPORT_RESULT,
  UPDATE_FORM,
} from "./types";

const initialState: ImportPaymentDataState = {
  isSubmitting: false,
  form: {},
};

export function importPaymentDataReducer(
  state = initialState,
  action: ImportPaymentDataActionTypes
): ImportPaymentDataState {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case REQUEST_IMPORT:
      return {
        ...state,
        isSubmitting: true,
      };
    case SET_API_ERROR:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
      };
    case SET_IMPORT_RESULT:
      return {
        ...initialState,
        importResult: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
