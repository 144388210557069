import { call, put, select, takeLatest } from "redux-saga/effects";

import { RequestError } from "../../api/types";
import { requestDetailPartnerData } from "../detailPartners/actions";
import { RootState } from "../rootReducer";
import {
  setError,
  resetFormValues,
  setFormValues,
  setClientError,
} from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  BulkEditPartnerRateFormState,
  InitFormAction,
  FORM_INITIALIZE_START,
} from "./types";
import { PartnerRate } from "../../api/partner";
import {
  BulkUpdatePartnerRateAdjustmentParams,
  postBulkUpdatePartnerRateAdjustments,
} from "../../api/partner/bulk-update-partner-rate-adjustments";

function* initBulkEditPartnerRateForm(action: InitFormAction) {
  try {
    const { partnerId, partnerRateAdjustmentIds } = action.payload;

    const partnerRateAdjustments: PartnerRate[] = yield select(
      (state: RootState) => state.detailPartners.items[partnerId].rates
    );
    const selectedAdjustments = partnerRateAdjustments.filter((adj) =>
      partnerRateAdjustmentIds.includes(adj.partnerRateAdjustmentId)
    );

    const adjStartDate = new Date(selectedAdjustments[0].adjStartDate);
    const adjEndDate = new Date(selectedAdjustments[0].adjEndDate);

    const sameStartDate = selectedAdjustments.every((adj) => {
      const date = new Date(adj.adjStartDate);
      return (
        date.getFullYear() === adjStartDate.getFullYear() &&
        date.getMonth() === adjStartDate.getMonth() &&
        date.getDate() === adjStartDate.getDate()
      );
    });

    if (sameStartDate) {
      yield put(
        setFormValues({
          adjStartDate: adjStartDate.toDateString(),
          adjEndDate: adjEndDate.toDateString(),
        })
      );
    } else {
      yield put(
        setClientError("Start dates on selected adjustments must match.")
      );
    }
  } catch (e: any) {
    const error: RequestError = e;
    yield put(setError(error));
  }
}

function* submitBulkEditPartnerRateForm(action: FormSubmitAction) {
  try {
    const formState: BulkEditPartnerRateFormState = yield select(
      (state: RootState) => state.bulkEditPartnerRateForm
    );
    const partnerRateAdjustments: PartnerRate[] = yield select(
      (state: RootState) =>
        state.detailPartners.items[action.payload.partnerId].rates
    );

    const { partnerId, partnerRateAdjustmentIds } = action.payload;

    const params: BulkUpdatePartnerRateAdjustmentParams[] =
      partnerRateAdjustments
        .filter((adj) =>
          partnerRateAdjustmentIds.includes(adj.partnerRateAdjustmentId)
        )
        .map((adj) => ({
          ...adj,
          partnerId,
          ...formState.values,
        }));

    yield call(postBulkUpdatePartnerRateAdjustments, params);
    yield put(requestDetailPartnerData(action.payload.partnerId));
    yield put(resetFormValues());

    yield call(action.payload.done);
  } catch (e: any) {
    const error: RequestError = e;

    yield put(setError(error));
    yield call(() => action.payload.done(error));
  }
}

export function* watchSubmitBulkEditPartnerRateForm() {
  yield takeLatest(FORM_SUBMIT, submitBulkEditPartnerRateForm);
}

export function* watchInitBulkEditPartnerRateForm() {
  yield takeLatest(FORM_INITIALIZE_START, initBulkEditPartnerRateForm);
}
