import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "../../../../layout/components/Page";
import { RootState } from "../../../../store/rootReducer";
import { ImportForm } from "./ImportForm";
import { Box, Button, List, ListItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  requestImportPaymentData,
  resetImportPaymentDataState,
} from "../../../../store/importPaymentData/actions";
import { APIFetchingWrapper } from "../../../../shared/components/APIFetchingWrapper";
import { getMonthIndex, MonthString } from "../../../../shared/helpers/dates";
import { ImportPaymentParams } from "../../../../api/payment-data";
import { PaymentType } from "../../../../shared/enum/payments";
import { getImportPaymentsSchema } from "./formSchema";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";

type Props = {
  paymentType: PaymentType;
};

export const Import = ({ paymentType }: Props) => {
  const dispatch = useDispatch();
  const schema = useMemo(() => getImportPaymentsSchema(), []);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const location = useLocation();
  const resetAll = useCallback(() => {
    setFormErrors([]);
    dispatch(resetImportPaymentDataState());
  }, [dispatch, setFormErrors]);
  useEffect(() => {
    return () => {
      resetAll();
    };
  }, [resetAll, location]);

  const { importResult, error, isSubmitting, form } = useSelector(
    (state: RootState) => state.importPaymentData
  );

  const handleSubmit = () => {
    paymentType === "UPFRONT" ? (form.paymentType = "UPFRONT") : <></>;
    schema
      .validate(form, { abortEarly: false })
      .then((validForm) => {
        setFormErrors([]);
        dispatch(
          requestImportPaymentData({
            ...form,
            month: getMonthIndex(form.month as MonthString),
            year: Number(form.year),
          } as ImportPaymentParams) // we know the form fields are defined from the validator but TS does not :-/
        );
      })
      .catch((e) => setFormErrors(e.errors));
  };

  return (
    <Page
      title={`Import Payment Data (${paymentType})`}
      actions={
        importResult || error ? (
          <Button onClick={resetAll} variant="outlined" size="large">
            Back
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            size="large"
          >
            Submit
          </Button>
        )
      }
    >
      <APIFetchingWrapper error={error} loading={isSubmitting}>
        {!importResult && <ImportForm paymentType={paymentType} form={form} />}
        {(formErrors.length > 0 ||
          (importResult && !importResult?.success)) && (
          <CustomStatusChip
            title="Please fix the following field errors and try again:"
            message={
              <List>
                {!importResult?.success && (
                  <ListItem disableGutters>{importResult?.batchRef}</ListItem>
                )}
                {formErrors.map((error, i) => (
                  <ListItem key={i} disableGutters>
                    {error}
                  </ListItem>
                ))}
              </List>
            }
            type="error"
          />
        )}
        {importResult && importResult.success && (
          <React.Fragment>
            <Box mb={2}>
              <CustomStatusChip
                title="Upload received for processing"
                message={`Reference: ${importResult.batchRef}`}
                type="success"
              />
            </Box>
            <Box mb={2}>
              <CustomStatusChip
                message={
                  <>
                    Processing may take some time - when your file is ready, you
                    can view a summary and confirm posting of the Assets at{" "}
                    <Link
                      to={`/receivables/payments/process-${paymentType.toLowerCase()}`}
                    >
                      View Existing Payments
                    </Link>
                  </>
                }
                type="info"
              />
            </Box>
          </React.Fragment>
        )}
      </APIFetchingWrapper>
    </Page>
  );
};
