import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  QueryPostedDocumentApiItem,
  QueryPostedDocumentLinesApiItem,
  getPostedInvoiceLinesExcel,
} from "../../api/posted-document/posted-invoice-lines";
import { request } from "../../api";
import { Box, Typography, Card, Grid, Button } from "@mui/material";
import { LoaderButton } from "../../shared/components/LoaderButton";
import { makeStyles } from "tss-react/mui";
import { CustomStatusChip } from "../../shared/components/CustomStatusChip";
import { formatISOForDisplay } from "../../shared/helpers/dates";
import { Enum } from "../../shared/helpers/enum";
import { TableAPIRequestParams } from "../../api/types";
import { useDispatch, useSelector } from "react-redux";
import { requestPostedDocumentLinesData } from "../../store/queryPostedDocumentsLines/actions";
import { DataTable } from "../../shared/components/DataTable";
import { RootState } from "../../store/rootReducer";
import FileDownload from "js-file-download";
import { formatCurrencyGBP } from "../../shared/helpers/currency";

const useStyles = makeStyles()((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    overflow: "hidden",
  },
  container: {
    padding: theme.spacing(1.5, 2),
  },
  itemContainer: {
    marginBottom: theme.spacing(1),
  },
  label: {
    width: "150px",
    fontWeight: "bold",
  },
  value: {
    flex: 1,
    wordBreak: "break-word",
  },
}));

export function ViewPostedDocument() {
  const { id } = useParams<{ id: string }>();
  const { classes } = useStyles();
  const [errorDocument, setErrorDocument] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<QueryPostedDocumentApiItem | null>(
    null
  );
  const postedDocumentId = Number(id);
  const dispatch = useDispatch();
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.queryPostedDocumentLines
  );

  const [isExporting, setIsExporting] = useState<boolean>(false);

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<QueryPostedDocumentLinesApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  });
  const history = useHistory();
  const handleNavToPostedSbInvoiceList = () => {
    history.push(`/self-bill/invoice-list`);
  };

  const handleExport = () => {
    setIsExporting(true);
    getPostedInvoiceLinesExcel({
      tableParams: tableControls,
      postedDocumentId: postedDocumentId,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
    setIsExporting(false);
  };

  const columns: {
    [key: string]: any;
    name: keyof QueryPostedDocumentLinesApiItem;
    label: string;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    enum?: Enum<any>;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id" },
    { name: "identifier", label: "Identifier" },
    { name: "customer", label: "Customer" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: (val) => formatISOForDisplay(val as string),
      isDateRange: true,
    },
    { name: "serviceProvider", label: "Service Provider" },
    { name: "productCode", label: "Product Code" },
    { name: "description", label: "Description" },
    {
      name: "partnerShareAmount",
      label: "Partner Share Amount",
      align: "right",
      customBodyRender: (val, rowData) =>
        rowData.paymentType === "Upfront"
          ? formatCurrencyGBP(0)
          : formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "monthlyAdvanceRepayment",
      label: "Monthly Advance Repayment",
      align: "right",
      customBodyRender: (val, rowData) =>
        rowData.paymentType === "Upfront"
          ? formatCurrencyGBP(0)
          : formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "totalExcVat",
      label: "Total (Exc VAT)",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
  ];

  useEffect(() => {
    if (!postedDocumentId) return;

    const fetchInvoice = async () => {
      setLoading(true);
      try {
        const response = await request<QueryPostedDocumentApiItem>(
          `posteddocument/self-bill-invoice-lines-header/${postedDocumentId}`
        );
        setInvoice(response);
      } catch (error) {
        setErrorDocument(true);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
    dispatch(
      requestPostedDocumentLinesData({
        tableParams: tableControls,
        postedDocumentId: postedDocumentId,
      })
    );
  }, [dispatch, tableControls, postedDocumentId]);

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <React.Fragment>
      {errorDocument && (
        <Box marginBottom={2}>
          <CustomStatusChip
            title="Error"
            message="There was an error fetching the invoice."
            type="error"
          />
        </Box>
      )}

      {invoice && (
        <>

        <Box justifyContent="space-between" display="flex" marginBottom={1}>
          <Typography variant="h2" gutterBottom>
              Posted SB Invoice Lines
          </Typography>
          <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
              <Box marginRight={1}>
                  <Button
                      size="large"
                      variant="contained"
                      onClick={handleNavToPostedSbInvoiceList}
                  >
                      Back To Posted SB Invoice List
                  </Button>
              </Box>
              <LoaderButton
                  size="large"
                  variant="outlined"
                  color="primary"
                  onClick={handleExport}
                  loading={isExporting}
              >
                  Export
              </LoaderButton>
          </Box>
        </Box>

        <Card className={classes.card}>
          <Grid container className={classes.container}>
            <Grid item xs={4} xl={3}>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  SB Reference:
                </Grid>
                <Grid item className={classes.value}>
                  {invoice.selfBillReference || ""}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  Partner Code:
                </Grid>
                <Grid item className={classes.value}>
                  {invoice.partnerCode || ""}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  Partner Name:
                </Grid>
                <Grid item className={classes.value}>
                  {invoice.partnerName || ""}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} xl={3}>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  Posting Date:
                </Grid>
                <Grid item className={classes.value}>
                  {formatISOForDisplay(invoice.postingDate || "")}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  Reference:
                </Grid>
                <Grid item className={classes.value}>
                  {invoice.reference || ""}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  Payment Type:
                </Grid>
                <Grid item className={classes.value}>
                  {invoice.paymentType || ""}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} xl={3}>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  SB Total (Exc VAT):
                </Grid>
                <Grid item className={classes.value}>
                  {formatCurrencyGBP(invoice.sbTotalExcVat || 0)}
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.itemContainer}
              >
                <Grid item className={classes.label}>
                  SB Total (Inc VAT):
                </Grid>
                <Grid item className={classes.value}>
                  {formatCurrencyGBP(invoice.sbTotalIncVat || 0)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <DataTable
          title=""
          limitHeight
          stickyHeader
          error={error}
          hover
          loading={fetching}
          data={items}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          recordsCount={count}
          onTableControlsChange={(tableControls) =>
            setTableControls(tableControls)
          }
          tableControls={tableControls}
        />
        </>
      )}
    </React.Fragment>
  );
}
