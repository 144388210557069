import React, { useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import {
  Box,
  Card,
  CardHeader,
  IconButton,
  LinearProgress,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { OpenInNewOutlined } from "@mui/icons-material";

import { RequestError, TableAPIRequestParams } from "../../api/types";
import { Enum } from "../helpers/enum";
import { CustomStatusChip } from "./CustomStatusChip";
import ColumnControl from "../../layout/components/ColumnControl";
import { ActionsMenu } from "../../layout/components/ActionsMenu";

interface ActionItem {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  renderComponent?: (row: any) => React.ReactNode;
}

interface Props<T extends { [key: string]: string | number | boolean | null }> {
  hover?: boolean;
  inline?: boolean;
  stickyHeader?: boolean;
  limitHeight?: boolean;
  title?: string;
  loading?: boolean;
  error?: RequestError;
  data: T[];
  columns: {
    name: keyof T;
    label: string | React.ReactNode;
    customBodyRender?: (input: string, row: T) => React.ReactNode;
    enum?: Enum<any>;
    hidden?: boolean;
    align?: "left" | "right" | "center";
    disableSort?: boolean;
    allowControl?: boolean;
  }[];
  rowsPerPageOptions: Array<number | { label: string, value: number }>;
  recordsCount: number;
  onTableControlsChange: (tableControls: TableAPIRequestParams<T>) => any;
  tableControls: TableAPIRequestParams<T>;
  onRowClick?: (row: any) => any;
  showPagination?: boolean;
  allowColumnControl?: boolean;
  hasActions?: boolean;
  actions?: Array<ActionItem>;
}

export function DataTable<
  T extends { [key: string]: string | number | boolean | null }
>({
  error,
  inline = false,
  stickyHeader,
  limitHeight = false,
  title,
  hover,
  loading,
  data,
  columns,
  recordsCount,
  rowsPerPageOptions,
  onTableControlsChange,
  tableControls,
  onRowClick,
  showPagination = true,
  allowColumnControl = false,
  hasActions = false,
  actions,
}: Props<T>) {
  // TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
  const useStyles = makeStyles()((theme) => ({
    root: {
      width: "100%",
    },
    table: {
      minWidth: 750,
    },
    tableContainer: {
      maxHeight: limitHeight ? "500px" : "unset",
      overflow: "auto",
    },
    tableCell: {
      whiteSpace: "nowrap",
    },
  }));
  //action menu visibility
  const actionMenuRefs = useRef(new Map());

  const handleMouseEnter = (index: any) => {
    const element = actionMenuRefs.current.get(index);
    if (element) {
      element.style.visibility = "visible";
    }
  };

  const handleMouseLeave = (index: any) => {
    const element = actionMenuRefs.current.get(index);
    if (element) {
      element.style.visibility = "hidden";
    }
  };

  const { classes } = useStyles();

  const [selectedColumns, setSelectedColumns] = useState<typeof columns>(
    columns
  );

  const handleUpdateTableControls = (
    update: Partial<TableAPIRequestParams<T>>
  ) => {
    const newControls = { ...tableControls, ...update };
    onTableControlsChange({
      ...newControls,
      ...(update.pageSize && { pageNumber: 1 }),
      sortDirection: newControls.sortDirection === 0 ? 0 : 1,
    }); // passed to the API
  };

  const handleRequestSort = (property: keyof T) => {
    const isAsc =
      property === tableControls.sortBy && tableControls.sortDirection === 0;
    const newDirection = isAsc ? 1 : 0;
    handleUpdateTableControls({
      sortBy: property,
      pageNumber: 1,
      sortDirection: newDirection,
    });
  };

  const cols = columns.filter(
    (col) =>
      !col.hidden &&
      selectedColumns.some((selectedColumn) => selectedColumn.name === col.name)
  );
  let colsLength = onRowClick ? cols.length + 1 : cols.length;
  colsLength = hasActions ? colsLength + 1 : colsLength;

  const LoadingPlaceholder = () => (
    <TableRow>
      <TableCell colSpan={colsLength}>
        {loading ? (
          <span>Loading table data...</span>
        ) : (
          <span>No matching records found</span>
        )}
      </TableCell>
    </TableRow>
  );

  const ErrorPlaceholder = () => (
    <TableRow>
      <TableCell colSpan={colsLength} style={{ padding: 0 }}>
        {error && (
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        )}
      </TableCell>
    </TableRow>
  );

  const StandaloneWrapper = ({ children }: { children: React.ReactNode }) => (
    <Card>
      {(title || allowColumnControl) && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {title && <CardHeader title={title} />}
          {allowColumnControl && (
            <ColumnControl
              columns={columns}
              selectedColumns={selectedColumns}
              onSelectedColumnsChange={setSelectedColumns}
            />
          )}
        </Box>
      )}
      {children}
    </Card>
  );

  const Pagination = () => (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={recordsCount}
      rowsPerPage={tableControls.pageSize}
      page={tableControls.pageNumber - 1} // mui page numbers are zero indexed.  Most of our APIs are 1 indexed.
      onPageChange={(_, newPage) =>
        handleUpdateTableControls({ pageNumber: newPage + 1 })
      } // mui page numbers are zero indexed. Most of out APIs are 1 indexed
      onRowsPerPageChange={(event) => {
        const newPageSize = (event.target.value as unknown) as number;
        handleUpdateTableControls({ pageSize: newPageSize });
      }}
      // onChangeRowsPerPage={(event) => {
      //   const newPageSize = (event.target.value as unknown) as number;
      //   handleUpdateTableControls({ pageSize: newPageSize });
      // }}
    />
  );

  const Table = () => (
    <MuiTable className={classes.table} stickyHeader={stickyHeader}>
      <TableHead>
        <TableRow>
          {onRowClick && (
            <TableCell className={classes.tableCell} key="open-item" />
          )}
          {cols.map((column, i) => (
            <TableCell
              className={classes.tableCell}
              key={i}
              align={column.align || "left"}
            >
              <TableSortLabel
                active={
                  column.disableSort
                    ? false
                    : tableControls.sortBy === column.name
                }
                hideSortIcon={column.disableSort ? true : false}
                direction={
                  tableControls.sortBy === column.name
                    ? convertSortDirectionOrder(tableControls.sortDirection)
                    : "asc"
                }
                onClick={(event) =>
                  column.disableSort
                    ? undefined
                    : handleRequestSort(column.name)
                }
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
          {hasActions && <TableCell></TableCell>}
        </TableRow>
        {loading && (
          <TableRow>
            <TableCell colSpan={colsLength} style={{ padding: 0 }}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {error ? (
          <ErrorPlaceholder />
        ) : data.length > 0 ? (
          data.map((row, i) => (
            <TableRow
              key={i}
              hover={hover}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={() => handleMouseLeave(i)}
            >
              {onRowClick && (
                <TableCell>
                  <IconButton
                    style={{ padding: 0 }}
                    color="primary"
                    onClick={() => {
                      onRowClick && onRowClick(row);
                    }}
                    size="large"
                  >
                    <OpenInNewOutlined />
                  </IconButton>
                </TableCell>
              )}
              {cols.map((col, i) => {
                let val: string | number | boolean | null = row[col.name];
                if (val === undefined || val === null) {
                  val = "-";
                } else if (col.enum && typeof val !== "boolean") {
                  val = col.enum[val] || val;
                }
                return (
                  <TableCell
                    className={classes.tableCell}
                    key={i}
                    align={col.align || "left"}
                  >
                    {col.customBodyRender
                      ? col.customBodyRender(val.toString(), row)
                      : val}
                  </TableCell>
                );
              })}
              {actions && (
                <TableCell
                  style={{
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 1,
                    padding: 0,
                  }}
                >
                  <div
                    ref={(el) => {
                      actionMenuRefs.current.set(i, el);
                    }}
                    style={{
                      visibility: "hidden",
                      borderBottom: "none",
                    }}
                  >
                    <ActionsMenu
                      actions={actions.map((action) => ({
                        label: action.label,
                        renderComponent: action.renderComponent
                          ? () => action.renderComponent?.(row)
                          : undefined,
                      }))}
                      rowData={row}
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <LoadingPlaceholder />
        )}
      </TableBody>
    </MuiTable>
  );

  if (!inline) {
    if (limitHeight) {
      return (
        <div className={classes.root}>
          <Card>
            {(title || allowColumnControl) && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {title && <CardHeader title={title} />}
                {allowColumnControl && (
                  <ColumnControl
                    columns={columns}
                    selectedColumns={selectedColumns}
                    onSelectedColumnsChange={setSelectedColumns}
                  />
                )}
              </Box>
            )}
            <TableContainer className={classes.tableContainer}>
              <Table />
            </TableContainer>
            {showPagination ? <Pagination /> : <></>}
          </Card>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <StandaloneWrapper>
          <TableContainer className={classes.tableContainer}>
            <Table />
          </TableContainer>
          {showPagination ? <Pagination /> : <></>}
        </StandaloneWrapper>
      </div>
    );
  }

  return (
    <Box>
      {allowColumnControl && (
        <ColumnControl
          columns={columns}
          selectedColumns={selectedColumns}
          onSelectedColumnsChange={setSelectedColumns}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table />
      </TableContainer>
      {showPagination ? <Pagination /> : <></>}
    </Box>
  );
}

function convertSortDirectionOrder(direction: 0 | 1): "asc" | "desc" {
  return direction === 0 ? "asc" : "desc";
}
