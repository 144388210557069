import { UpdateProofsParams } from "../../api/proof/batch-update-proofs";
import { RequestError } from "../../api/types";

export const SET_FORM_VALUES = "formUpdateProofs.SET_FORM_VALUES";
export const REQUEST_ASSET_IDS = "formUpdateProofs.REQUEST_ASSET_IDS";
export const FORM_SUBMIT = "formUpdateProofs.FORM_SUBMIT";
export const FORM_SUCCESS = "formUpdateProofs.FORM_SUCCESS";
export const SET_ERROR = "formUpdateProofs.SET_ERROR";

export type FormUpdateProofsState = {
  formValues: UpdateProofsParams;
  success: boolean;
  fetching: boolean;
  error?: RequestError;
};

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    formValues: Partial<UpdateProofsParams>;
  };
}

export interface RequestAssetIdsAction {
  type: typeof REQUEST_ASSET_IDS;
  payload: {
    sourceRef: string;
  };
}

export interface UpdateProofsSubmitAction {
  type: typeof FORM_SUBMIT;
}

export interface FormSuccessAction {
  type: typeof FORM_SUCCESS;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    error: RequestError;
  };
}

export type UpdateProofsActionTypes =
  | SetFormValuesAction
  | UpdateProofsSubmitAction
  | FormSuccessAction
  | SetErrorAction
  | RequestAssetIdsAction;
