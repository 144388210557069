import { request } from "../..";

export function postUpdateInterfaceAssetResignOverride(
  interfaceAssetIds: number[],
  resignOverride: boolean
) {
  return request(`interface-asset/update-resign-override/${resignOverride}`, {
    method: "POST",
    body: JSON.stringify(interfaceAssetIds),
  });
}
