import { SpAccountTypeOptionApiItem } from "../../api/partner-service-provider/get-sp-account-type-options";
import { RequestError } from "../../api/types";

export const SET_FORM_VALUES = "editPartnerServiceProviderForm.SET_FORM_VALUES";
export const SET_ACCOUNT_TYPE_OPTIONS =
  "editPartnerServiceProviderForm.SET_ACCOUNT_TYPE_OPTIONS";
export const SET_FORM_ERROR = "editPartnerServiceProviderForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "editPartnerServiceProviderForm.FORM_SUBMIT";
export const FORM_RESET = "editPartnerServiceProviderForm.FORM_RESET";

export type EditPartnerServiceProviderFormValues = {
  serviceProviderAccountTypeId: number;
  serviceProviderAccountTypeName: string;
  serviceProviderId: number;
  spDealerCode: string;
  enabled: boolean;
};

export interface EditPartnerServiceProviderFormOptions {
  accountTypeOptions: SpAccountTypeOptionApiItem[];
}

export interface EditPartnerServiceProviderFormState
  extends EditPartnerServiceProviderFormOptions {
  values: EditPartnerServiceProviderFormValues;
  fetching: boolean;
  requestError?: RequestError;
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<EditPartnerServiceProviderFormValues>;
  };
}

export interface SetAccountTypeOptionsAction {
  type: typeof SET_ACCOUNT_TYPE_OPTIONS;
  payload: {
    accountTypeOptions: SpAccountTypeOptionApiItem[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type EditPartnerServiceProviderActions =
  | SetFormValuesAction
  | SetAccountTypeOptionsAction
  | FormSubmitAction
  | SetFormErrorAction
  | ResetFormAction;
