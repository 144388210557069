import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { QueryPrimaryProducts } from "./QueryPrimaryProducts";
import { ViewPrimaryProduct } from "./ViewPrimaryProduct";
import { ImportProducts } from "./import";
import { GenerateProducts } from "./generate";

export const Products = () => {
  const { path } = useRouteMatch();
  const title = "Products";
  return (
    <Switch>
      <Route exact path={`${path}/primary/`}>
        <QueryPrimaryProducts title={title} />
      </Route>
      <Route path={`${path}/primary/:id`}>
        <ViewPrimaryProduct title={title} />
      </Route>
      <Route exact path={`${path}/import`}>
        <ImportProducts title={title} />
      </Route>
      <Route exact path={`${path}/generate`}>
        <GenerateProducts title={title} />
      </Route>
    </Switch>
  );
};
