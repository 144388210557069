import { request } from "../..";
import { DateString } from "../../../shared/helpers/dates";

export type ProductChangeALEApiItem = {
  assetLedgerEntryId: number;
  dateCreated: DateString;
  amount: number;
  reference: string;
  eligibleForPayment: boolean;
  billDate: DateString;
  aleTypeId: number;
  aleTypeName: string;
  assetId: number;
  invoiceLineId: number;
  paymentDataId: number;
};

export function getALEsByAssetId(assetId: number) {
  return request<ProductChangeALEApiItem[]>(
    `asset-ledger-entry/product-change/${assetId}`
  );
}
