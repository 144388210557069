import { ServiceProviderDistinctProductTypeOption } from "../../api/service-provider/distinct-products";
import { ServiceProviderRateApiItem } from "../../api/service-provider/service-provider-rates";
import { ServiceProviderRate } from "../../api/service-provider/service-provider-rate-values";
import { RequestError } from "../../api/types";

export const FORM_INITIALIZE_START =
  "createPartnerRateForm.FORM_INITIALIZE_START";
export const FORM_INITIALIZE_FINISH =
  "createPartnerRateForm.FORM_INITIALIZE_FINISH";
export const SET_FORM_VALUES = "createPartnerRateForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "createPartnerRateForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "createPartnerRateForm.FORM_SUBMIT";
export const FORM_RESET = "createPartnerRateForm.FORM_RESET";
export const SET_PRODUCT_TYPE_ID = "createPartnerRateForm.SET_PRODUCT_TYPE_ID";
export const SET_SERVICE_PROVIDER_RATES =
  "createPartnerRateForm.SET_SERVICE_PROVIDER_RATES";
export const SET_TIERS = "createPartnerRateForm.SET_TIERS";
export const SET_SERVICE_PROVIDER_RATE =
  "createPartnerRateForm.SET_SERVICE_PROVIDER_RATE";
export const SET_SERVICE_PROVIDER_RATE_DETAILS =
  "createPartnerRateForm.SET_SERVICE_PROVIDER_RATE_DETAILS";

export type CreatePartnerRateFormValues = {
  serviceProviderRateId: number;
  adjustment: number;
  startDate: string;
  endDate: string;
  productTypeId: number;
  standardRate: number;
  receivedRate: number;
  tier: number;
};

export type CreatePartnerRateFormState = {
  values: CreatePartnerRateFormValues;
  serviceProviderRateOptions: ServiceProviderRateApiItem[];
  distinctProductTypeOptions: ServiceProviderDistinctProductTypeOption[];
  tiers: number[];
  fetching: boolean;
  requestError?: RequestError;
};

export interface FormInitializeStartAction {
  type: typeof FORM_INITIALIZE_START;
  payload: {
    serviceProviderId: number;
  };
}

export interface FormInitializeFinishAction {
  type: typeof FORM_INITIALIZE_FINISH;
  payload: {
    distinctProductTypeOptions: ServiceProviderDistinctProductTypeOption[];
  };
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreatePartnerRateFormValues>;
  };
}

export interface SetProductTypeIdAction {
  type: typeof SET_PRODUCT_TYPE_ID;
  payload: {
    productTypeId: number;
    serviceProviderId: number;
  };
}

export interface SetTiers {
  type: typeof SET_TIERS;
  payload: {
    tiers: number[];
  };
}

export interface SetServiceProviderRatesAction {
  type: typeof SET_SERVICE_PROVIDER_RATES;
  payload: {
    serviceProviderRates: ServiceProviderRateApiItem[];
  };
}

export interface SetServiceProviderRateDetails {
  type: typeof SET_SERVICE_PROVIDER_RATE_DETAILS;
  payload: {
    serviceProviderRate: ServiceProviderRate[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    serviceProviderId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export interface SetServiceProviderRateAction {
  type: typeof SET_SERVICE_PROVIDER_RATE;
  payload: {
    serviceProviderId: number;
    productTypeId: number;
    tier: number;
  };
}

export type CreatePartnerRateActions =
  | FormInitializeStartAction
  | FormInitializeFinishAction
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetProductTypeIdAction
  | SetServiceProviderRatesAction
  | ResetFormAction
  | SetTiers
  | SetServiceProviderRateAction
  | SetServiceProviderRateDetails;
