import {
  OutstandingCommissionsApiItem,
  OutstandingCommissionsReportParams,
} from "../../api/asset-report/generate-outstanding-commissions-report";
import {
  PaginatedResult,
  RequestError,
  TableAPIRequestParams,
} from "../../api/types";

export const REQUEST_DATA = "outstandingCommissionsReport.REQUEST_DATA";
export const SET_DATA = "outstandingCommissionsReport.SET_DATA";
export const SET_ERROR = "outstandingCommissionsReport.SET_ERROR";
export const SET_TABLE_CONTROLS =
  "outstandingCommissionsReport.SET_TABLE_CONTROLS";
export const CLEAR_DATA_TABLE = "outstandingCommissionsReport.CLEAR_DATA_TABLE";

export type OutstandingCommissionsReportState = {
  filters: any;
  items: OutstandingCommissionsApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
  tableControls: TableAPIRequestParams<OutstandingCommissionsApiItem>;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: OutstandingCommissionsReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<OutstandingCommissionsApiItem>;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<OutstandingCommissionsApiItem>;
  };
}

interface ClearTableAction {
  type: typeof CLEAR_DATA_TABLE;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type OutstandingCommissionsReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | SetTableControlsAction
  | ClearTableAction;
