import { SET_IS_LOADING, SetIsLoadingAction } from "./types";

export function setIsLoading(isLoading: boolean): SetIsLoadingAction {
  return {
    type: SET_IS_LOADING,
    payload: {
      isLoading,
    },
  };
}
