import { ProductChangeALEApiItem } from "../../api/asset-ledger-entry/product-change";
import { ProductTypeOption } from "../../api/product/product-types";
import { RequestError } from "../../api/types";
import { DateString } from "../../shared/helpers/dates";
import {
  BaseValues,
  INITAILISE_COMPLETE,
  INITIALISE,
  PrimaryOption,
  REQUEST_SUBMIT,
  SET_API_ERROR,
  SET_OPTIONS_ALE,
  SET_OPTIONS_BASE,
  SET_OPTIONS_PRIMARY,
  SET_VALUES_ALE,
  SET_VALUES_BASE,
  SET_VALUE_PRIMARY,
} from "./types";

export function initaliseFormAssetProductChange(
  assetId: number,
  currentProductId: number,
  assetStartDate: DateString
) {
  return {
    type: INITIALISE,
    payload: {
      assetId,
      currentProductId,
      assetStartDate,
    },
  };
}

export function InitialiseCompleteAction() {
  return { type: INITAILISE_COMPLETE };
}

export function setBaseOptions(
  durationOptions: string[],
  productTypeOptions: ProductTypeOption[]
) {
  return {
    type: SET_OPTIONS_BASE,
    payload: {
      durationOptions,
      productTypeOptions,
    },
  };
}

export function setPrimaryOptions(payload: PrimaryOption[]) {
  return {
    type: SET_OPTIONS_PRIMARY,
    payload,
  };
}

export function setALEOptions(payload: ProductChangeALEApiItem[]) {
  return {
    type: SET_OPTIONS_ALE,
    payload,
  };
}

export function setBaseValuesAction(updates: Partial<BaseValues>) {
  return {
    type: SET_VALUES_BASE,
    payload: updates,
  };
}

export function setPrimaryValueAction(payload: PrimaryOption) {
  return {
    type: SET_VALUE_PRIMARY,
    payload,
  };
}

export function setALEValues(payload: ProductChangeALEApiItem[]) {
  return {
    type: SET_VALUES_ALE,
    payload,
  };
}

export function requestSubmitAction(payload: { done: () => any }) {
  return {
    type: REQUEST_SUBMIT,
    payload,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}
