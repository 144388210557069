import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ReviewSelfBill } from "./review";
import { GenerateRevshare } from "./generate/revshare";
import { GenerateUpfront } from "./generate/upfront";
import { CreditListSelfBill } from "./credit-list/PostedCreditList";
import { SelfBillList } from "./list/SelfBillList";
import { InvoiceListSelfBill } from "./invoice-list/PostedInvoiceList";
import { CreditNoteLines } from "./credit-list/CreditNoteLines";

export const SelfBill = () => {
  const { path } = useRouteMatch();
    
  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/generate-revshare`}>
          <GenerateRevshare />
        </Route>
        <Route path={`${path}/generate-upfront`}>
          <GenerateUpfront />
        </Route>
        <Route path={`${path}/review`}>
          <ReviewSelfBill />
        </Route>
        <Route path={`${path}/credit-list`}>
          <CreditListSelfBill />
        </Route>
        <Route path={`${path}/invoice-list`}>
          <InvoiceListSelfBill />
        </Route>
        <Route path={`${path}/list`}>
          <SelfBillList />
        </Route>
        <Route path={`${path}/credit-note-lines`}>
          <CreditNoteLines />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
