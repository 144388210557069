import { UiState, UiActionTypes, SET_IS_LOADING } from "./types";

const initialState: UiState = {
  isLoading: false,
};

export function uiReducer(
  state = initialState,
  action: UiActionTypes
): UiState {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    default:
      return state;
  }
}
