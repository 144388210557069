export type Enum<E> = Record<keyof E, number | string> & {
  [k: number]: string;
};

export function getEnumNames<E extends Enum<E>>(inputEnum: E) {
  return Object.keys(inputEnum).filter((key) => isNaN(Number(key)));
}

export function getEnumValues<E extends Enum<E>>(inputEnum: E) {
  return Object.keys(inputEnum)
    .filter((key) => !isNaN(Number(key)))
    .map(Number);
}
