import { request } from "../..";

export interface CreatePartnerRateAdjustmentParams {
  partnerId: number;
  serviceProviderRateId: number;
  adjustment: number;
  startDate: string;
  endDate: string;
  serviceProviderId: number;
  tier: number;
}

export function postCreatePartnerRateAdjustment(
  params: CreatePartnerRateAdjustmentParams
) {
  return request("partner/create-partner-rate-adjustment", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
