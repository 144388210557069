import React, { useEffect } from "react";
import { LoadingFullScreen } from "./LoadingFullscreen";
import { useAuth } from "oidc-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { requestPermissions } from "../../store/permissions/actions";
import { getPermission } from "../../store/permissions/reducers";
import { Permissions } from "../../store/permissions/types";
import { AccessDenied } from "../../nav/components/AccessDenied";

export const AuthLoading: React.FC = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const tokenExpired = auth.userData && auth.userData.expires_in <= 60;

  // console.log(auth.userData?.expires_in);

  const permissions = useSelector((state: RootState) => state.permissions);
  const canAccessCommissions = useSelector(
    getPermission(Permissions.CanAccessCommissions)
  );

  useEffect(() => {
    // console.log(`Requesting permissions. Token expired: ${tokenExpired}`);
    if (!tokenExpired) {
      dispatch(requestPermissions());
    }
  }, [dispatch, tokenExpired]);

  useEffect(() => {
    // console.log("Attempting to sign-in silently.");
    if (tokenExpired) {
      auth.userManager.signinSilent();
      // console.log("Sign in silent completed.");
    }
  }, [tokenExpired, auth.userManager]);

  if (tokenExpired || tokenExpired === null || permissions.fetching) {
    return <LoadingFullScreen message="Loading..." />;
  }

  if (!canAccessCommissions) {
    return <AccessDenied />;
  }

  return children as React.ReactElement;
};
