import { stringify } from "query-string";
import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type PortAndResignReportApiItem = {
  identifier: string;

  assetId: number;
  dealerCode: string;
  status: number;
  productCode: string;
  customerName: string;
  startDate: string;
  endDate: string;
  provider: string;

  dealerCodeOld: string;
  statusOld: number;
  productCodeOld: string;
  customerNameOld: string;
  startDateOld: string;
  endDateOld: string;
  providerOld: string;
  assetIdOld: number;

  outstandingAdvanceOld: number;
  invoiceStatusOld: number;
  spStatusChangeTypeOld: number;
  spStatusChangeDateOld: string;

  portResign: string;
  dealerChange: string;
};

export type PortAndResignReportParams = {
  startDate: string | null;
  endDate: string | null;
  tableControls: TableAPIRequestParams<PortAndResignReportApiItem>;
};

export function getPortAndResignReport(params: PortAndResignReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return request<PaginatedResult<PortAndResignReportApiItem>>(
    `asset-report/generate-port-and-resign-report${combine([tableParamsQS])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
      }),
    }
  );
}

export function getPortAndResignReportExcel(params: PortAndResignReportParams) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
  });

  return requestFile(
    `asset-report/generate-port-and-resign-report${combine([
      tableParamsQS,
      stringify({ export: true }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
      }),
    }
  );
}
