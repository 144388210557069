import {
  CreatePartnerNoteFormState,
  CreatePartnerNoteActions,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
} from "./types";

const initialState: CreatePartnerNoteFormState = {
  values: {
    description: "",
  },
  requestError: undefined,
  fetching: false,
};

export function createPartnerNoteFormReducer(
  state = initialState,
  action: CreatePartnerNoteActions
): CreatePartnerNoteFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
