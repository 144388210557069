import { PaymentDataSummaryApi } from "../../api/payment-data/summary";
import { RevShareSummaryProductType } from "../../shared/enum/payments";
import {
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  PaymentDataSummaryActionTypes,
  PaymentDataSummaryState,
  PaymentDataSummary,
  PaymentDataRevShareSummary,
  PaymentDataUpfrontSummary,
  SET_ENABLE_CREATE_ENTITIES,
  SET_BATCH_STATUS,
} from "./types";

const initialState: PaymentDataSummaryState = {
  fetching: false,
  enableCreateEntities: false,
  batchStatus: 0,
};

export function PaymentDataSummaryReducer(
  state = initialState,
  action: PaymentDataSummaryActionTypes
): PaymentDataSummaryState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...initialState,
        fetching: true,
      };
    case SET_DATA:
      return {
        ...state,
        data: transformApiData(action.payload),
        fetching: false,
      };
    case SET_ENABLE_CREATE_ENTITIES:
      return {
        ...state,
        enableCreateEntities: action.payload.enable,
      };
    case SET_BATCH_STATUS: {
      return {
        ...state,
        batchStatus: action.payload.batchStatus,
      };
    }
    case SET_API_ERROR:
      return {
        ...initialState,
        error: action.payload,
      };
    default:
      return state;
  }
}

function transformApiData(apiData: PaymentDataSummaryApi): PaymentDataSummary {
  if (apiData.totalAmountMatchedByProductType) {
    //revshare
    return {
      ...apiData,
      totalsByProductType: Object.entries(
        apiData.totalAmountMatchedByProductType
      ).map((entry) => ({
        productType: RevShareSummaryProductType[
          Number(entry[0])
        ] as keyof typeof RevShareSummaryProductType,
        amount: entry[1],
      })),
    } as PaymentDataRevShareSummary;
  } else {
    //upfront
    return {
      ...apiData,
      totalsUnmatchedByReason: Object.entries(
        apiData.totalAmountUnmatchedByReason
      )
        .map(([reason, { key, value }]) => ({
          reason,
          key,
          amount: value,
        }))
        .flat(),
      totalsMatchedByPaymentType: Object.entries(
        apiData.totalAmountMatchedByPaymentType
      )
        .map(([upfrontPaymentType, { key, value }]) => {
          return {
            upfrontPaymentType,
            key,
            amount: value,
          };
        })
        .flat(),
    } as PaymentDataUpfrontSummary;
  }
}
