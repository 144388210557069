import { SUBMIT } from "./types";

export function deleteSpendCap(
  productId: number,
  spendCapId: number,
  done: () => any
) {
  return {
    type: SUBMIT,
    payload: {
      productId,
      spendCapId,
      done,
    },
  };
}
