import { request } from "..";

export type AssetProductApiItem = {
  assetProductId: number;
  productId: number;
  duration: number;
  rsPctPayAdjustment: number;
  rsPctExpAdjustment: number;
  productRateId: number;
  isPrimary: boolean;
};

export function getAssetProducts(assetId: number) {
  return request(`asset-product/${assetId}`, {
    method: "GET",
  });
}
