import { CreditNoteLinesHeaderAPIItem } from "../../api/posted-document/credit-note-lines-header/index";
import { RequestError } from "../../api/types";

export const REQUEST_DATA = "creditNoteLinesHeader.REQUEST_DATA";
export const SET_DATA = "creditNoteLinesHeader.SET_DATA";
export const SET_ERROR = "creditNoteLinesHeader.SET_ERROR";

export type CreditNoteLinesHeaderState = {
  data: CreditNoteLinesHeaderAPIItem;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    postedDocumentId: number;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: CreditNoteLinesHeaderAPIItem;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type CreditNoteLinesHeaderActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
