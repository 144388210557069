import { request } from "../..";

type ProductRateBaseParams = {
  payTariffAdvance: number;
  payTariffCommission: number;
  expTariffCommission: number;
  expTariffAdvance: number;
  lineRental: number;
  startDate: string | null;
  endDate: string | null;
};

export type PostEditProductRateEndDateParams = {
  productRateId: number;
  productId: number;
  startDate: string;
  endDate: string;
};

export type PostEditProductRateParams = ProductRateBaseParams & {
  productRateId: number;
};

export type PostCreateProductRateParams = ProductRateBaseParams & {
  productId: number;
  parentId?: number;
};

export function postCreateProductRate(params: PostCreateProductRateParams) {
  return request("product/product-rate/add", {
    method: "POST",
    body: JSON.stringify(params),
  });
}

export function postEditProductRate(params: PostEditProductRateParams) {
  return request("product/product-rate/edit", {
    method: "POST",
    body: JSON.stringify(params),
  });
}

export function postEditProductRateEndDate(
  params: PostEditProductRateEndDateParams
) {
  return request("product/product-rate/edit-end-date", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
