import { request, requestFile } from "..";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { TableAPIRequestParams } from "../types";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import { PaymentDataItem } from "../../store/paymentData/types";
import {
  PaymentType,
  PaymentDataReason,
  Status,
  UpfrontFileType,
} from "../../shared/enum/payments";
import { stringify } from "query-string";

// GET

export type PaymentDataApiItem = {
  billDate: string;
  paymentDataId: number;
  batchId: string;
  lineNumber: number;
  reason: PaymentDataReason;
  status: Status;
  amountImported: number;
  fileName: string;
  assetId: number | null;
  customerName: string;
  identifier: string;
  ooc: string;
  serviceProvider: string;
};

export type PaymentDataAPIFilterParams = {
  status?: string[];
  reason?: string[];
  identifier?: string[];
};

export type PaymentDataRequestParams = {
  tableParams: TableAPIRequestParams<PaymentDataItem>;
  filterParams: PaymentDataAPIFilterParams;
  batchId: number;
  export?: boolean;
};

export function getPaymentData(params: PaymentDataRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams({
    ...params.filterParams,
    status: params.filterParams.status?.map(
      (status) => Status[status as keyof typeof Status]
    ),
    reason: params.filterParams.reason?.map(
      (reason) => PaymentDataReason[reason as keyof typeof PaymentDataReason]
    ),
  });

  return request(
    `payment-data/${params.batchId}${combine([tableParamsQS, filterParamsQS])}`
  );
}

export function getPaymentDataExcel(params: PaymentDataRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams({
    ...params.filterParams,
    status: params.filterParams.status?.map(
      (status) => Status[status as keyof typeof Status]
    ),
    reason: params.filterParams.reason?.map(
      (reason) => PaymentDataReason[reason as keyof typeof PaymentDataReason]
    ),
  });
  const exportFlag = stringify({ export: true, returnAll: true });

  return requestFile(
    `payment-data/${params.batchId}${combine([
      tableParamsQS,
      filterParamsQS,
      exportFlag,
    ])}`
  );
}

// POST

export type ImportResult = {
  success: boolean;
  batchRef: string;
};

export type ImportPaymentParams = {
  serviceProvider: ServiceProvider;
  file: File;
  month: number;
  year: number;
  paymentType: PaymentType;
  fileType?: UpfrontFileType;
  userReference?: string;
};

export function postPaymentImport({
  serviceProvider,
  file,
  month,
  year,
  paymentType,
  fileType,
  userReference,
}: ImportPaymentParams) {
  let body = new FormData();
  body.append("file", file);

  const fileTypeQS = fileType ? stringify({ fileType }) : "";
  const userReferenceQS = userReference ? stringify({ userReference }) : "";

  return request<ImportResult>(
    `payment-data/${serviceProvider}/${month}/${year}/${paymentType}${combine([
      fileTypeQS,
      userReferenceQS,
    ])}`,
    {
      method: "POST",
      body,
    },
    false
  );
}
