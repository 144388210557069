import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { FilterControlWrapper } from "./FilterControlWrapper";

type Props<T extends string> = {
  name: string;
  id: string;
  options: T[];
  selected: string[];
  onChange: (selected: T[]) => any;
};

export function Select<T extends string>({
  name,
  id,
  options,
  selected,
  onChange,
}: Props<T>) {
  const handleChangeSelected = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange([event.target.value as T]);
  };

  return (
    <FilterControlWrapper
      type="select"
      count={selected.length}
      id={id}
      name={name}
      onReset={() => onChange([])}
    >
      <TextField
        fullWidth
        variant="outlined"
        id={id}
        label="Please select an option"
        select
        value={selected[0] || ""}
        onChange={handleChangeSelected}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FilterControlWrapper>
  );
}
