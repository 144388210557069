import { RequestError } from "../../api/types";

export const SET_FORM_ERROR = "createProductRateForm.SET_FORM_ERROR";
export const SET_FORM_VALUES = "createProductRateForm.SET_FORM_VALUES";
export const FORM_SUBMIT = "createProductRateForm.FORM_SUBMIT";
export const FORM_RESET = "createProductRateForm.FORM_RESET";

export type CreateProductRateFormBaseValues = {
  payTariffAdvance: number;
  payTariffCommission: number;
  expTariffCommission: number;
  expTariffAdvance: number;
  lineRental: number;
  payServiceCommission: number;
  payServiceAdvance: number;
  expServiceCommission: number;
  expServiceAdvance: number;
  serviceLineRental: number;
  payVolumeBonus: number;
  expVolumeBonus: number;
  startDate: string | null;
  endDate: string | null;
};

export type CreateProductRateFormState = {
  requestError?: RequestError;
  baseValues: CreateProductRateFormBaseValues;
};

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreateProductRateFormBaseValues>;
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    productId: number;
    parentProductId?: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type CreateProductRateActions =
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | ResetFormAction;
