import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import { DetailAssetApiItem, getDetailAsset } from "../../api/asset";
import {
  FinancialOverview,
  getFinancialOverview,
} from "../../api/asset-ledger-entry/financial-overview";
import {
  getALEsByAssetId,
  ProductChangeALEApiItem,
} from "../../api/asset-ledger-entry/product-change";
import {
  postChangeAssetProduct,
  PostChangeAssetProductParams,
} from "../../api/asset-product/change-product";
import { getProductById, QueryProductApiItem } from "../../api/product";
import { getProductDurationOptions } from "../../api/product/durations";
import {
  getQueryProductRates,
  ProductRateApiItem,
  QueryProductRatesRequestParams,
} from "../../api/product/product-rates";
import {
  getProductTypeOptions,
  ProductTypesApi,
} from "../../api/product/product-types";
import { PaginatedResult, RequestError } from "../../api/types";
import { AUTOSELECTED_ASSET_PRODUCT_ALE_TYPE_IDS } from "../../shared/constants/ALEs";
import { setDetailAssetData } from "../detailAssets/actions";
import { DetailAssetsState } from "../detailAssets/types";
import { RootState } from "../rootReducer";
import {
  InitialiseCompleteAction,
  setBaseValuesAction,
  setError,
  setBaseOptions,
  setPrimaryOptions,
  setALEOptions,
  setALEValues,
} from "./actions";
import {
  AssetProductChangeState,
  BaseValues,
  INITIALISE,
  InitialiseAction,
  RequestSubmitAssetProductChangeAction,
  REQUEST_SUBMIT,
  SET_VALUES_BASE,
} from "./types";

function* fetchInitialFormData(action: InitialiseAction) {
  try {
    const data: {
      durationOptions: string[];
      productTypeOptions: ProductTypesApi;
      assetLedgerEntryOptions: ProductChangeALEApiItem[];
      valueDefaults: QueryProductApiItem;
    } = yield all({
      durationOptions: call(getProductDurationOptions),
      productTypeOptions: call(getProductTypeOptions),
      assetLedgerEntryOptions: call(getALEsByAssetId, action.payload.assetId),
      valueDefaults: call(getProductById, action.payload.currentProductId),
    });

    yield put(
      setBaseOptions(data.durationOptions, data.productTypeOptions.items)
    );

    yield put(setALEOptions(data.assetLedgerEntryOptions));
    const autoSelectedAles = data.assetLedgerEntryOptions.filter((ale) =>
      AUTOSELECTED_ASSET_PRODUCT_ALE_TYPE_IDS.includes(ale.aleTypeId)
    );
    yield put(setALEValues(autoSelectedAles));

    yield put(
      setBaseValuesAction({
        productType: data.productTypeOptions.items.find(
          (option) => option.name === data.valueDefaults.productType
        ),
        productVariant: data.valueDefaults.productVariant,
        duration: data.valueDefaults.duration.toString(),
        productClass: data.valueDefaults.productClass,
        serviceProvider: data.valueDefaults.serviceProvider,
      })
    );

    yield put(InitialiseCompleteAction());
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* fetchPrimaryOptions() {
  try {
    const baseValues: BaseValues = yield select(
      (state: RootState) => state.formAssetProductChange.base
    );

    const params: QueryProductRatesRequestParams = {
      tableParams: {
        pageSize: 10,
        pageNumber: 1,
        sortBy: "productName",
        sortDirection: 0,
        returnAll: true,
      },
      filterParams: {
        serviceProvider: [baseValues.serviceProvider] || [],
        upgrade: [baseValues.productVariant],
        duration: [baseValues.duration],
        productClass: [baseValues.productClass],
        productType: [baseValues.productType.name],
        startDate: [null, baseValues.assetStartDate], // lte operation
        endDate: [baseValues.assetStartDate, null], // gte operation
      },
    };

    const data: PaginatedResult<ProductRateApiItem> = yield call(
      getQueryProductRates,
      params
    );

    yield put(
      setPrimaryOptions(
        data.items.map((item) => ({
          productCode: item.productCode,
          productName: item.productName,
          productRateId: item.productRateId,
          productId: item.productId,
        }))
      )
    );
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* submitAssetProductChange(
  action: RequestSubmitAssetProductChangeAction
) {
  try {
    const formState: AssetProductChangeState = yield select(
      (state: RootState) => state.formAssetProductChange
    );

    // type guard
    if (!formState.base.assetId || !formState.primary) {
      //eslint-disable-next-line no-throw-literal
      throw {
        type: "api",
        message: "Could not create request due to missing fields",
      };
    }

    const params: PostChangeAssetProductParams = {
      assetId: formState.base.assetId,
      assetUpdateProductRateDtos: [
        {
          productRateId: formState.primary.productRateId,
          productId: formState.primary.productRateId,
          addSecondary: false,
          removeSecondary: false,
        },
      ],
      assetLedgerEntryDtos: formState.ales,
    };

    yield call(postChangeAssetProduct, params);

    // update detailAssetData
    const detailAssetsState: DetailAssetsState = yield select(
      (state: RootState) => state.detailAssets
    );
    const detailAssetItemToUpdate =
      detailAssetsState.items[formState.base.assetId];

    const updatedAssetData: {
      asset: DetailAssetApiItem;
      financial: FinancialOverview;
    } = yield all({
      asset: call(getDetailAsset, formState.base.assetId),
      financial: call(getFinancialOverview, formState.base.assetId),
    });
    yield put(
      setDetailAssetData({
        ...detailAssetItemToUpdate,
        ...updatedAssetData.asset,
        ...updatedAssetData.financial,
      })
    );

    // callback to request action dispatcher
    // this is a little hacky as we shouldn't really be passing functions around in redux actions
    // the "correct" saga way is probably to set a flag in the store and monitor that flag in component
    // since we don't actually need to serialise our redux actions and this works OK, we can just callback to the component
    yield call(action.payload.done);
  } catch (e) {
    const error: RequestError = e;
    yield put(setError(error));
  }
}

export function* watchInitialiseAssetProductChangeForm() {
  yield takeLatest(INITIALISE, fetchInitialFormData);
}

export function* watchSetBaseValuesAssetProductChangeForm() {
  yield takeLatest(SET_VALUES_BASE, fetchPrimaryOptions);
}

export function* watchRequestSubmitAssetProductChange() {
  yield takeLatest(REQUEST_SUBMIT, submitAssetProductChange);
}
