import { capitalize } from "@mui/material";
import { request } from "../..";
import { SuggestionResult } from "../../types";
import {
  stringifyFilterParams,
  combine,
} from "../../../shared/helpers/querySerialisation";

export interface SuggestionAPIParams {
  columnName: string;
  term: string;
  filterParams?: AssetFilterParams;
}

export type AssetFilterParams = {
  partnerId?: (number | undefined)[];
};

export function getSuggestions({
  columnName,
  term,
  filterParams,
}: SuggestionAPIParams) {
  let filterParamsQS = "";
  if (filterParams) {
    filterParamsQS = stringifyFilterParams(filterParams);
  }
  return request<SuggestionResult>(
    `Asset/changes-due/search/${capitalize(columnName)}/${term}${combine([
      filterParamsQS,
    ])}`
  );
}
