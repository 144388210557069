import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { FileDrop } from "../../../shared/components/FileDrop";
import { RootState } from "../../../store/rootReducer";
import {
  resetState,
  submitForm,
} from "../../../store/uploadTemplateAmendments/actions";
import { UploadTemplateAmendmentsFormState } from "../../../store/uploadTemplateAmendments/types";
import { ModalUploadAssetFinancials } from "./ModalUploadAssetFinancials";

type Props = { title: string };

export const ImportAssetFinancials = ({ title }: Props) => {
  const dispatch = useDispatch();

  const { isSubmitting, apiError, success, message } = useSelector(
    (state: RootState) => state.uploadTemplateAmendments
  );
  const [formError, setFormError] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const resetAll = useCallback(() => {
    setFormError(false);
    setFile(undefined);
    dispatch(resetState());
  }, [setFormError, setFile, dispatch]);

  const handleSubmit = (formValues: UploadTemplateAmendmentsFormState) => {
    // simple validation
    if (!file) {
      return setFormError(true);
    } else {
      setFormError(false);
    }

    dispatch(
      submitForm({
        file,
        ...formValues,
      })
    );
  };

  return (
    <Page
      title={title}
      actions={
        success || apiError ? (
          <Button onClick={resetAll} variant="outlined" size="large">
            Back
          </Button>
        ) : (
          <Button
            onClick={() => handleSubmit({})}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </Button>
        )
      }
    >
      <APIFetchingWrapper error={apiError} loading={isSubmitting}>
        {success ? (
          <CustomStatusChip title={message} type="success" />
        ) : (
          <React.Fragment>
            <form>
              <FormFieldsWrapper>
                <FileDrop
                  acceptedFileTypes={[".xlsm"]}
                  onAcceptedDrop={setFile}
                />
              </FormFieldsWrapper>
            </form>
            {formError && (
              <CustomStatusChip
                title="Please add a file and try again"
                type="error"
              />
            )}
            {!success && (
              <ModalUploadAssetFinancials
                open={success === false} /* has to be this way */
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                onCancel={() => dispatch(resetState())}
                message={message}
              />
            )}
          </React.Fragment>
        )}
      </APIFetchingWrapper>
    </Page>
  );
};
