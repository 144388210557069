import {
  QueryAssetsActionTypes,
  QueryAssetsState,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_SEARCH_TERM,
  SET_TABLE_CONTROLS,
  SET_TABLE_FILTERS,
} from "./types";

const initialState: QueryAssetsState = {
  items: [],
  count: 0,
  fetching: false,
  hasNext: false,
  hasPrevious: false,
  searchTerm: "",
  filters: {},
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  },
};

export function queryAssetsReducer(
  state = initialState,
  action: QueryAssetsActionTypes
): QueryAssetsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_API_ERROR:
      return { ...state, error: action.payload, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };

    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload,
      };
    }

    case SET_TABLE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
}
