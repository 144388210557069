import { request } from "../..";

export type ServiceProviderDistinctProductTypeOption = {
  key: number;
  value: string;
};

export function getServiceProviderDistinctProducts(
  serviceProviderId: number
): Promise<ServiceProviderDistinctProductTypeOption[]> {
  return request<ServiceProviderDistinctProductTypeOption[]>(
    "service-provider/distinct-products/" + serviceProviderId
  );
}
