import {
  FinancialBreakdownState,
  FinancialBreakdownActionTypes,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  CANCEL_BREAKDOWN_MODAL,
} from "./types";

const initialState: FinancialBreakdownState = {
  items: [],
  fetching: false,
  show: false,
};

export function financialBreakdownReducer(
  state = initialState,
  action: FinancialBreakdownActionTypes
): FinancialBreakdownState {
  switch (action.type) {
    case REQUEST_DATA:
      const label =
        action.payload.breakdownType === "invoice"
          ? "Total Invoice"
          : "Total Clawback";
      return {
        ...state,
        error: undefined,
        fetching: true,
        show: true,
        label,
      };

    case CANCEL_BREAKDOWN_MODAL: {
      return initialState;
    }

    case SET_ERROR: {
      return { ...state, error: action.payload, fetching: false };
    }

    case SET_DATA:
      return { ...state, fetching: false, ...action.payload };
    default:
      return state;
  }
}
