import {
  REQUEST_UPDATE_PARTNER_GENERAL_INFO,
  SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS,
  SET_UPDATE_PARTNER_GENERAL_INFO_ERROR,
} from "./types";
import { PartnerGeneralInfoTypes, DetailPartnerGeneralInfoType } from "./types";

const initialState: DetailPartnerGeneralInfoType = {
  isFetching: false,
  partnerId: 0,
  doNotSelfBill: false,
};

export function updatePartnerGeneralInfoReducer(
  state = initialState,
  action: PartnerGeneralInfoTypes
): DetailPartnerGeneralInfoType {
  switch (action.type) {
    case REQUEST_UPDATE_PARTNER_GENERAL_INFO:
      return {
        ...state,
        isFetching: true,
      };

    case SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case SET_UPDATE_PARTNER_GENERAL_INFO_ERROR: {
      return {
        ...state,
        isFetching: false,
        doNotSelfBillError: action.payload.err,
      };
    }
    default:
      return state;
  }
}
