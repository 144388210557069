import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { ModalFieldWrapper } from "./ModalFieldWrapper";

type Option<T> = {
  label: string;
  value: T;
};

type Props<T extends string | number> = {
  iconOnly?: boolean;
  label: string;
  options: Option<T>[];
  initialValue: T;
  onCancel?: () => any;
  onSave: (value: T) => Promise<any>;
  id: string;
  disabled?: boolean;
};

export function ModalSelect<T extends string | number>({
  iconOnly,
  label,
  options,
  onCancel,
  onSave,
  id,
  initialValue,
  disabled,
}: Props<T>) {
  const [selected, setSelected] = useState(initialValue);

  return (
    <ModalFieldWrapper
      id={id}
      iconOnly={iconOnly}
      label={label}
      onCancel={onCancel}
      onSave={() => onSave(selected)}
    >
      <TextField
        disabled={disabled}
        autoFocus
        select
        fullWidth
        variant="outlined"
        label="Please select an option"
        value={selected}
        onChange={(event) => setSelected(event.target.value as T)}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </ModalFieldWrapper>
  );
}
