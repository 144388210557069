import { request } from "../..";
import { FinancialBreakdownType } from "../../../store/financialBreakdown/types";
import { PaginatedResult } from "../../types";

export type FinancialOverview = {
  invoiceStatus: string | null;
  tariffCommission: UpfrontSummary[];
  tariffAdvance: UpfrontSummary[];
  serviceCommission: UpfrontSummary[];
  serviceAdvance: UpfrontSummary[];
  afCommission: UpfrontSummary[];
  afAdvance: UpfrontSummary[];
  revShareReceived: UpfrontSummary[];
  outstandingAdvance: number;
  monthlyAdvanceRepayment: number;
  collectedAdvance: UpfrontSummary[];
  revsharePaid: UpfrontSummary[];
  totalInvoice: number;
  totalClawback: number;
};

export type FinancialBreakdown = {
  selfBillId: string;
  postingDate: string;
  description: string;
  reference: string;
  amount: number;
};

export interface UpfrontSummary {
  name: "Expected" | "Pay" | "Received";
  amount: number;
  aleTypeId: number[];
}

export function getFinancialOverview(id: number) {
  return request<FinancialOverview>(
    `asset-ledger-entry/financial-overview/${id}`
  );
}

export function getFinancialBreakdown(
  assetId: number,
  breakdownType: FinancialBreakdownType
) {
  return request<PaginatedResult<FinancialBreakdown>>(
    `asset-ledger-entry/financial-overview/${assetId}/breakdown?breakdownType=${breakdownType}`
  );
}
