import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_UPLOAD_RESULT,
  SET_ERROR,
  FormSubmitAction,
  SetResultAction,
  UploadTemplateAmendmentsParams,
  SetErrorAction,
  RESET_STATE,
} from "./types";

export function submitForm(
  params: UploadTemplateAmendmentsParams
): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload: {
      params,
    },
  };
}

export function setUploadResult(data: {
  message: string;
  success: boolean;
}): SetResultAction {
  return {
    type: SET_UPLOAD_RESULT,
    payload: data,
  };
}

export function setUploadError(error: RequestError): SetErrorAction {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}
