import {
  Button,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { postCloseExistingPeriods } from "../../../api/product/product-rate/close-existing-periods";
import { postCloseExistingSecondaryPeriods } from "../../../api/product/product-rate/close-existing-secondary-periods";
import { postPrimaryProductCsv } from "../../../api/product/product-rate/create-primary";
import { postSecondaryProductCsv } from "../../../api/product/product-rate/create-secondarry";
import { RequestError } from "../../../api/types";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { FileDrop } from "../../../shared/components/FileDrop";
import { SplitButton } from "../../../shared/components/SplitButton";
import { getImportProductsSchema, getCloseProductsSchema } from "./formSchema";
import { postSpendCapsCsv } from "../../../api/spend-cap/import";

type Props = { title: string };
type ImportType = "primary" | "secondary" | "spendCap";

export const ImportProducts = ({ title }: Props) => {
  const schemaImport = useMemo(() => getImportProductsSchema(), []);
  const schemaClose = useMemo(() => getCloseProductsSchema(), []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [apiError, setApiError] = useState<RequestError | undefined>();
  const [importType, setImportType] = useState<ImportType | undefined>();
  const [file, setFile] = useState<File | undefined>();

  // modal controls
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Import");

  const resetAll = useCallback(() => {
    setFormErrors([]);
    setImportType(undefined);
    setFile(undefined);
    setSuccess(false);
    setApiError(undefined);
    setIsSubmitting(false);
    setSelectedOption("Import");
  }, [setFormErrors, setFile, setApiError, setSuccess, setIsSubmitting]);

  const handleSubmitImport = () => {
    setOpen(false);
    schemaImport
      .validate({ importType, file }, { abortEarly: false })
      .then((validForm) => {
        setFormErrors([]);

        let apiCall: (
          file: File
        ) => Promise<{
          message: string;
          success: boolean;
        }>;

        switch (importType) {
          case "primary":
            apiCall = postPrimaryProductCsv;
            break;
          case "secondary":
            apiCall = postSecondaryProductCsv;
            break;
          case "spendCap":
            apiCall = postSpendCapsCsv;
            break;
          default:
            throw new Error("No import type selected.");
        }

        apiCall(validForm.file)
          .then((response) => {
            if (response.success) {
              setSuccess(response.success);
            } else {
              setApiError({ ...response, type: "api" });
            }
          })
          .catch(setApiError);
      })
      .catch((e) => setFormErrors(e.errors));
  };

  const handleSubmitClose = () => {
    setOpen(false);
    let apiCall: (
      file: File
    ) => Promise<{
      success: boolean;
      message: string;
    }>;

    switch (importType) {
      case "primary":
        apiCall = postCloseExistingPeriods;
        break;
      case "secondary":
        apiCall = postCloseExistingSecondaryPeriods;
        break;
      case "spendCap":
        setApiError({
          type: "client",
          message: "Method not implemented.",
        });
        break;
      default:
        throw new Error("No import type selected.");
    }

    schemaClose
      .validate({ file }, { abortEarly: false })
      .then((validForm) => {
        setFormErrors([]);
        apiCall(validForm.file)
          .then((response) => {
            if (response.success) {
              setSuccess(response.success);
            } else {
              setApiError({ ...response, type: "api" });
            }
          })
          .catch(setApiError);
      })
      .catch((e) => setFormErrors(e.errors));
  };

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Page
        title="Import Products"
        actions={
          success || apiError ? (
            <Button onClick={resetAll} variant="outlined" size="large">
              Back
            </Button>
          ) : (
            <SplitButton
              size="large"
              options={
                importType === "spendCap" ? ["Import"] : ["Import", "Close"]
              }
              handlers={
                importType === "spendCap"
                  ? [handleSubmitImport]
                  : [handleSubmitImport, () => setOpen(true)]
              }
              onChange={(value) => setSelectedOption(value)}
            />
          )
        }
      >
        <APIFetchingWrapper error={apiError} loading={isSubmitting}>
          {success ? (
            <CustomStatusChip
              title={
                selectedOption !== "Close"
                  ? "Upload received for processing"
                  : "Upload product rates for closing received"
              }
              type="success"
            />
          ) : (
            <React.Fragment>
              <form>
                <FormFieldsWrapper>
                  {/* {selectedOption !== "Close" && ( */}
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="select-products-type"
                    select
                    label="Select Product Type*"
                    value={importType || ""}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const productType = event.target.value as ImportType;
                      setImportType(productType);
                    }}
                  >
                    <MenuItem value="primary">Primary</MenuItem>
                    <MenuItem value="secondary">Secondary</MenuItem>
                    <MenuItem value="spendCap">Spend Caps</MenuItem>
                  </TextField>
                  <FileDrop
                    acceptedFileTypes={[".csv"]}
                    onAcceptedDrop={setFile}
                  />
                </FormFieldsWrapper>
              </form>

              {formErrors && formErrors.length > 0 && (
                <CustomStatusChip
                  title="Please fix the following field errors and try again:"
                  message={
                    <List>
                      {formErrors.map((error, i) => (
                        <ListItem key={i} disableGutters>
                          {error}
                        </ListItem>
                      ))}
                    </List>
                  }
                  type="error"
                />
              )}
            </React.Fragment>
          )}
        </APIFetchingWrapper>
      </Page>
      <ConfirmationModal
        open={open}
        title="Close product rates"
        text="Do you really want to close these product rates?"
        onClose={() => setOpen(false)}
        onConfirm={handleSubmitClose}
      />
    </React.Fragment>
  );
};
