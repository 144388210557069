const authConfig = {
  onSignIn: async (user: any) => {
    // setCookie("access_token", user.access_token);
    console.log("SIGNED IN", new Date().toISOString());

    window.location.href = process.env.REACT_APP_BASE_URL || "";
  },
  authority: process.env.REACT_APP_IDENTITY_URL,
  clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  responseType: "token id_token",
  redirectUri: process.env.REACT_APP_BASE_URL + "signin-oidc",
  scope: "openid commissionsApi",
  automaticSilentRenew: true,
};

export default authConfig;
