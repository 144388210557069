import { call, put, takeLatest } from "redux-saga/effects";
import {
  getUnraisedRevshareReport,
  UnraisedRevshareApiItem,
} from "../../api/asset-report/generate-unraised-revshare-report";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setUnraisedRevshareReportData,
  setUnraisedRevshareReportErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchUnraisedRevshareReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<UnraisedRevshareApiItem> = yield call(
      getUnraisedRevshareReport,
      action.payload.params
    );

    yield put(setUnraisedRevshareReportData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setUnraisedRevshareReportErrorForDisplay(error));
  }
}

export function* watchRequestUnraisedRevshareReportData() {
  yield takeLatest(REQUEST_DATA, fetchUnraisedRevshareReport);
}
