export const contractTypes = ["New", "Resign", "Migration", "Port"] as const;
export type ContractType = (typeof contractTypes)[number];

export const entryTypes = [
  "Pay",
  "Expected",
  "Received",
  "CustomerBilledAmount",
] as const;
export type EntryType = (typeof entryTypes)[number];

export const getEntryTypeId = (entryType: EntryType | undefined) => {
  if (entryType) {
    return entryTypes.indexOf(entryType);
  }

  return 0;
};

export enum AssetStatus {
  Active = 0,
  Completed = 1,
  Reconciled = 2,
}

export enum InvoiceStatus {
  Pending = 0,
  Raised = 1,
  Posted = 2,
}

export enum ServiceProviderStatusChangeType {
  Active = 0,
  Resigned = 1,
  Disconnected = 2,
  MigrationOut = 3,
  NumberChanged = 4,
  Removed = 5,
}

export enum ServiceProviderAssetStatus {
  Live = 0,
  OutOfContract = 1,
  NonLive = 2,
}

export enum SignatureProofStatus {
  Blank = 0,
  Present = 1,
  NotPresent = 2,
  NotCorrect = 3,
}

export enum ContractProofStatus {
  Blank = 0,
  Present = 1,
  NotPresent = 2,
  WithDealer = 3,
  NotCorrect = 4,
}

export const signatureProofStatuses = {
  Blank: SignatureProofStatus.Blank,
  Present: SignatureProofStatus.Present,
  "Not Present": SignatureProofStatus.NotPresent,
  "Not Correct": SignatureProofStatus.NotCorrect,
};

export const contractProofStatuses = {
  Blank: ContractProofStatus.Blank,
  Present: ContractProofStatus.Present,
  "Not Present": ContractProofStatus.NotPresent,
  "With Dealer": ContractProofStatus.WithDealer,
  "Not Correct": ContractProofStatus.NotCorrect,
};
