import { request } from "../..";

interface Response {
  readonly success: boolean;
  readonly message: string;
}

export function postSpendCapsCsv(file: File) {
  let body = new FormData();
  body.append("file", file);

  return request<Response>("spend-cap/import", { method: "POST", body }, false);
}
