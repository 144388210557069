import {
  AssetFinancialReportParams,
  AssetReportApiItem,
} from "../../api/asset-report";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "assetReport.REQUEST_DATA";
export const SET_DATA = "assetReport.SET_DATA";
export const SET_ERROR = "assetReport.SET_ERROR";

export type AssetReportState = {
  items: AssetReportApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: AssetFinancialReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<AssetReportApiItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    error: RequestError;
  };
}

export type AssetReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
