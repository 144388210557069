import { call, put, select, takeLatest } from "redux-saga/effects";
import { NewProductApiItem, postAddProduct } from "../../api/product";
import {
  getFilteredProductClasses,
  ProductClassApi,
} from "../../api/product/product-class";
import { ProductGroupApi } from "../../api/product/product-group";
import { getFilteredProductGroups } from "../../api/product/product-group-filtered";
import {
  getProductTypeOptions,
  ProductTypesApi,
} from "../../api/product/product-types";
import { BusinessResponseObject, RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import {
  setError,
  resetForm,
  initializeFormFinish,
  setProductClassOptions,
  setProductGroupOptions,
  setProduct,
} from "./actions";
import {
  CreatePrimaryProductFormState,
  FormSubmitAction,
  FORM_INITIALIZE_START,
  FORM_SUBMIT,
  SetFormValuesAction,
  SET_FORM_VALUES,
} from "./types";

function* submitPrimaryProductForm(action: FormSubmitAction) {
  try {
    const formState: CreatePrimaryProductFormState = yield select(
      (state: RootState) => state.createPrimaryProductForm
    );

    const data: BusinessResponseObject<NewProductApiItem> = yield call(
      postAddProduct,
      formState.values
    );
    yield put(setProduct(data));

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

function* initializePrimaryProductForm() {
  try {
    const productTypes: ProductTypesApi = yield call(getProductTypeOptions);

    yield put(
      initializeFormFinish({
        productTypeOptions: productTypes.items,
        productGroupOptions: [],
        productClassOptions: [],
      })
    );
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* setPrimaryProductFormValues(action: SetFormValuesAction) {
  try {
    const { productTypeId, serviceProviderId } = action.payload.values;
    const formState: CreatePrimaryProductFormState = yield select(
      (state: RootState) => state.createPrimaryProductForm
    );

    const spId = serviceProviderId || formState.values.serviceProviderId;
    const ptId = productTypeId || formState.values.productTypeId;

    if (spId && ptId) {
      const productClasses: ProductClassApi[] = yield call(
        getFilteredProductClasses,
        spId,
        ptId
      );
      const productGroups: ProductGroupApi[] = yield call(
        getFilteredProductGroups,
        spId,
        ptId
      );

      yield put(setProductGroupOptions(productGroups));
      yield put(setProductClassOptions(productClasses.map((c) => c.name)));
    }
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

export function* watchInitializeCreatePrimaryProduct() {
  yield takeLatest(FORM_INITIALIZE_START, initializePrimaryProductForm);
}

export function* watchSetValuesCreatePrimaryProduct() {
  yield takeLatest(SET_FORM_VALUES, setPrimaryProductFormValues);
}

export function* watchCreatePrimaryProduct() {
  yield takeLatest(FORM_SUBMIT, submitPrimaryProductForm);
}
