import { RequestError } from "../../api/types";
import {
  InterfaceReportItem,
  REQUEST_DATA,
  ResetCheckedItemsAction,
  RESET_CHECKED_ITEMS,
  SetItemCheckedAction,
  SET_DATA,
  SET_ERROR,
  SET_ITEM_CHECKED,
  SET_RESIGN_OVERRIDE,
  SET_PARTNER_ID,
} from "./types";

export function requestInterfaceAssetReport() {
  return { type: REQUEST_DATA };
}

export function setAssetInterfaceReportData(data: InterfaceReportItem[]) {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setResignOverride(resignOverride: string) {
  return {
    type: SET_RESIGN_OVERRIDE,
    payload: {
      resignOverride,
    },
  };
}

export function setPartnerId(partnerThirdPartyId: string) {
  return {
    type: SET_PARTNER_ID,
    payload: {
      partnerThirdPartyId,
    },
  };
}

export function setAssetInterfaceItemChecked(
  interfaceAssetId: number,
  checked: boolean
): SetItemCheckedAction {
  return {
    type: SET_ITEM_CHECKED,
    payload: {
      interfaceAssetId,
      checked,
    },
  };
}

export function resetAssetInterfaceItemsChecked(): ResetCheckedItemsAction {
  return {
    type: RESET_CHECKED_ITEMS,
  };
}

export function setAssetInterfaceReportErrorForDisplay(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
