import React, { useEffect } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { Box, Checkbox, FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  resetForm,
  setFormValues,
  submitForm,
  setAccountTypeOptions,
} from "../../../store/createPartnerServiceProviderForm/actions";
import {
  getServiceProviderId,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { RequestError } from "../../../api/types";
import { getServiceProviderAccountTypeOptions } from "../../../api/partner-service-provider/get-sp-account-type-options";
import { EditableField } from "../../../shared/components/EditableField";
import { CreatePartnerServiceProviderFormState } from "../../../store/createPartnerServiceProviderForm/types";

interface Props {
  partnerId: number;
}

export const CreatePartnerServiceProviderModal = ({ partnerId }: Props) => {
  const dispatch = useDispatch();

  const { values, accountTypeOptions, fetching, requestError } = useSelector<
    RootState,
    CreatePartnerServiceProviderFormState
  >((state: RootState) => state.createPartnerServiceProviderForm);

  const disableSubmit =
    !values.spDealerCode ||
    !values.serviceProviderId ||
    !values.serviceProviderAccountTypeId ||
    values.serviceProviderAccountTypeId === 0 ||
    values.serviceProviderId === 0 ||
    values.spDealerCode === "";

  useEffect(() => {
    if (!!values.serviceProviderId && values.serviceProviderId !== 0) {
      getServiceProviderAccountTypeOptions(
        partnerId,
        values.serviceProviderId
      ).then((result) => {
        dispatch(setAccountTypeOptions(result));
      });
    }
  }, [values.serviceProviderId, partnerId, dispatch]);

  return (
    <ModalFieldWrapper
      apiFetching={fetching}
      icon={false}
      label="Add Service Provider"
      buttonLabel="Add Service Provider"
      buttonVariant="contained"
      disableSave={disableSubmit}
      onCancel={() => dispatch(resetForm())}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              done: (err?: RequestError) => {
                err ? reject(err) : resolve(null);
              },
            })
          );
        });
      }}
      id="create-partner-service-provider"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider"
            select
            label="Service Provider"
            value={values.serviceProviderId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProviderId = parseInt(event.target.value);
              dispatch(setFormValues({ serviceProviderId }));
            }}
          >
            {serviceProviders.map((option) => (
              <MenuItem key={option} value={getServiceProviderId(option)}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-account-type"
            select
            label="Account Type"
            value={values.serviceProviderAccountTypeId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProviderAccountTypeId = parseInt(event.target.value);
              dispatch(setFormValues({ serviceProviderAccountTypeId }));
            }}
          >
            {accountTypeOptions.map((option) => (
              <MenuItem
                key={option.name}
                value={option.serviceProviderAccountTypeId}
              >
                {option.name}
              </MenuItem>
            ))}
            {accountTypeOptions.length === 0 && (
              <MenuItem key="default" value="" disabled>
                No suitable options available
              </MenuItem>
            )}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Service Provider Code"
            variant="outlined"
            type="text"
            value={values.spDealerCode}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  spDealerCode: event.target.value,
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <Box style={{ width: 96, marginTop: -20 }}>
            <EditableField label="Enabled" value={false}>
              <Checkbox
                color="primary"
                checked={values.enabled}
                onChange={(event) => {
                  dispatch(
                    setFormValues({
                      enabled: event.target.checked,
                    })
                  );
                }}
              />
            </EditableField>
          </Box>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
