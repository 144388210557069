import * as yup from "yup";

export function getImportProductsSchema() {
  return yup.object().shape({
    file: yup.mixed().label("Choose a file").required(),
    importType: yup.string().label("Product Type").required(),
  });
}

export function getCloseProductsSchema() {
  return yup.object().shape({
    file: yup.mixed().label("Choose a file").required(),
  });
}
