import {
  QueryProductApiItem,
  QueryProductRequestParams,
} from "../../api/product";
import { ProductTypesApi } from "../../api/product/product-types";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  INITIALIZE_FINISH,
  INITIALIZE_START,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
} from "./types";

export function initializeQueryProducts() {
  return {
    type: INITIALIZE_START,
  };
}

export function initializeQueryProductsFinish(data: {
  duration: string[];
  productTypes: ProductTypesApi;
}) {
  return {
    type: INITIALIZE_FINISH,
    payload: data,
  };
}

export function requestQueryProductsData(params: QueryProductRequestParams) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQueryProductsErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryProductsTableControls(
  tableControls: TableAPIRequestParams<QueryProductApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: {
      tableControls,
    },
  };
}

export function setQueryProductsData(
  data: PaginatedResult<QueryProductApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
