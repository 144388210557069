import { call, put, select, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  EditPartnerServiceProviderFormState,
} from "./types";
import { requestDetailPartnerData } from "../detailPartners/actions";
import { postUpdatePartnerServiceProvider } from "../../api/partner-service-provider/update";

function* submitEditPartnerServiceProvider(action: FormSubmitAction) {
  try {
    const formState: EditPartnerServiceProviderFormState = yield select(
      (state: RootState) => state.editPartnerServiceProviderForm
    );

    yield call(postUpdatePartnerServiceProvider, {
      partnerId: action.payload.partnerId,
      ...formState.values,
    });

    yield put(requestDetailPartnerData(action.payload.partnerId));

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchEditPartnerServiceProvider() {
  yield takeLatest(FORM_SUBMIT, submitEditPartnerServiceProvider);
}
