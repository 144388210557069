import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  CLEAR_DATA_TABLE,
  SET_TABLE_CONTROLS,
  DeferredCostReportState,
  DeferredCostReportActionTypes,
} from "./types";

const initialState: DeferredCostReportState = {
  items: [],
  count: 0,
  fetching: false,
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  },
};

export function deferredCostReportReducer(
  state = initialState,
  action: DeferredCostReportActionTypes
): DeferredCostReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };
    }
    case SET_DATA: {
      return { ...state, fetching: false, ...action.payload };
    }

    case CLEAR_DATA_TABLE: {
      return {
        ...state,
        items: [],
        fetching: false,
        count: 0,
      };
    }

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload.tableControls,
      };
    }

    default:
      return state;
  }
}
