import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  getProductById,
  putUpdateProduct,
  QueryProductApiItem,
  UpdateProductApiItem,
} from "../../api/product";
import { checkProductLinkedToAsset } from "../../api/product/check-product-linked-to-asset";
import { getProductDurationOptions } from "../../api/product/durations";
import {
  getProductGroups,
  ProductGroupApi,
} from "../../api/product/product-group";
import { deleteProductRate } from "../../api/product/product-rate/delete";
import {
  getProductRatesById,
  ProductRatesByIdApi,
} from "../../api/product/product-rates";
import {
  getProductTypeOptions,
  ProductTypesApi,
} from "../../api/product/product-types";
import { getSecondaryProductsbyPrimaryProductId } from "../../api/product/secondary-products";
import { PaginatedResult, RequestError } from "../../api/types";
import { getServiceProviderId } from "../../shared/enum/serviceProvider";
import {
  DeleteProductRateAction,
  DELETE_PRODUCT_RATE,
  DetailProductsState,
  RequestDataAction,
  RequestUpdateItemAction,
  REQUEST_DATA,
  REQUEST_UPDATE_ITEM,
} from "../detailProducts/types";
import { RootState } from "../rootReducer";
import {
  setDetailProductData,
  setDetailProductRequestError,
  updateItemData,
} from "./actions";
import {
  SpendCapPeriodItem,
  getSpendCapsByProductIdAndDate,
} from "../../api/spend-cap-period/by-productId-date";

function* fetchDetailProductData(action: RequestDataAction) {
  try {
    yield call(getProductGroups);

    const data: {
      primaryProduct: QueryProductApiItem;
      secondaryProducts: PaginatedResult<QueryProductApiItem>;
      primaryRates: ProductRatesByIdApi;
      secondaryRates: ProductRatesByIdApi;
      spendCaps: SpendCapPeriodItem[];
      productTypeOptions: ProductTypesApi;
      durationOptions: string[];
      productGroupOptions: ProductGroupApi[];
      linkedToAsset: boolean;
    } = yield all({
      primaryProduct: call(getProductById, action.payload),
      secondaryProducts: call(
        getSecondaryProductsbyPrimaryProductId,
        action.payload
      ),
      primaryRates: call(getProductRatesById, action.payload),
      secondaryRates: call(getProductRatesById, action.payload, true),
      productTypeOptions: call(getProductTypeOptions),
      durationOptions: call(getProductDurationOptions),
      productGroupOptions: call(getProductGroups),
      linkedToAsset: call(checkProductLinkedToAsset, action.payload),
      spendCaps: call(
        getSpendCapsByProductIdAndDate,
        action.payload,
        new Date().toISOString()
      ),
    });

    yield put(
      setDetailProductData({
        primaryProduct: data.primaryProduct,
        secondaryProducts: data.secondaryProducts.items,
        primaryRates: data.primaryRates.items,
        secondaryRates: data.secondaryRates.items,
        spendCaps: data.spendCaps,
        durationOptions: data.durationOptions,
        productTypeOptions: data.productTypeOptions.items,
        productGroupOptions: data.productGroupOptions,
        linkedToAsset: data.linkedToAsset,
      })
    );
  } catch (e) {
    const error = e as RequestError;
    yield put(setDetailProductRequestError({ id: action.payload, ...error }));
  }
}

function* updateProductData(action: RequestUpdateItemAction) {
  const productId = action.payload.id;
  const state: DetailProductsState = yield select(
    (state: RootState) => state.detailProducts
  );
  const item = state.items[productId].primaryProduct;

  const productTypeId = state.items[productId].productTypeOptions.find(
    (o) => o.name === item.productType
  )?.productTypeId;
  const productGroupId = state.items[productId].productGroupOptions.find(
    (o) => o.name === item.productGroup
  )?.productGroupId;

  const updates: UpdateProductApiItem = {
    productId,
    productName: item.name,
    productCode: item.productCode,
    description: item.description,
    duration: item.duration,
    productGroupId,
    productTypeId,
    customerType: item.customerType,
    serviceProviderId: getServiceProviderId(item.serviceProvider),
    ...action.payload.updates,
  };

  try {
    yield call(putUpdateProduct, productId, updates);
    yield put(updateItemData(productId, action.payload.updates));
  } catch (e) {
    const error = e as RequestError;
    yield put(setDetailProductRequestError({ id: productId, ...error }));
  } finally {
    yield call(action.payload.done);
  }
}

function* fetchDeleteProductRate(action: DeleteProductRateAction) {
  try {
    yield call(deleteProductRate, action.payload.productRateId);
    yield call(fetchDetailProductData, {
      type: REQUEST_DATA,
      payload: action.payload.productId,
    });
  } catch (e) {
    const error = e as RequestError;
    yield call(() => action.payload.done(error));
  } finally {
    yield call(action.payload.done);
  }
}

export function* watchRequestDetailProductData() {
  yield takeLatest(REQUEST_DATA, fetchDetailProductData);
}

export function* watchRequestUpdateProduct() {
  yield takeLatest(REQUEST_UPDATE_ITEM, updateProductData);
}

export function* watchDeleteProductRate() {
  yield takeLatest(DELETE_PRODUCT_RATE, fetchDeleteProductRate);
}
