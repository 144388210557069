import { request } from "../../..";

interface Response {
  readonly success: boolean;
  readonly message: string;
}

export function postCloseExistingPeriods(file: File) {
  let body = new FormData();
  body.append("file", file);

  return request<Response>(
    "product/product-rate/close-existing-periods",
    { method: "POST", body },
    false
  );
}
