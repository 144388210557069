import FileDownload from "js-file-download";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { encodeArray, useQueryParams } from "use-query-params";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { decodePermittedArrayParam } from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { postGenerateSpendCapReport } from "../../../api/spend-cap/generate-spend-cap-report";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";

export const SpendCapReport = () => {
  const [filters, updateFilters] = useQueryParams({
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
  });

  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<string>();

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const handleExport = () => {
    if (filters.serviceProvider.length === 0) {
      setError("All filter values must be specified.");
      return;
    }

    setIsExporting(true);
    setError(undefined);

    postGenerateSpendCapReport({
      serviceProviderIds: filters.serviceProvider.map(getServiceProviderId),
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const filterMeta = [
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Spend Caps Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
          >
            Export
          </LoaderButton>
        </Box>
      </Box>
      {error && (
        <Box mb={1}>
          <CustomStatusChip type="error" title="Error" message={error} />
        </Box>
      )}
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
    </React.Fragment>
  );
};
