import {
  AssetProductChangeState,
  FormAssetProductChangeActionTypes,
  INITAILISE_COMPLETE,
  INITIALISE,
  SET_API_ERROR,
  SET_OPTIONS_ALE,
  SET_OPTIONS_BASE,
  SET_OPTIONS_PRIMARY,
  SET_VALUES_ALE,
  SET_VALUES_BASE,
  SET_VALUE_PRIMARY,
} from "./types";

const initialState: AssetProductChangeState = {
  fetchingInitial: false,
  fetchingPrimaryProductRates: false,
  durationOptions: [],
  productTypeOptions: [],
  primaryProductOptions: [],
  assetLedgerEntryOptions: [],
  ales: [],
  base: {},
};

export function formAssetProductChangeReducer(
  state = initialState,
  action: FormAssetProductChangeActionTypes
): AssetProductChangeState {
  switch (action.type) {
    case INITIALISE:
      return {
        ...initialState,
        fetchingInitial: true,
        fetchingPrimaryProductRates: true,
        base: {
          assetId: action.payload.assetId,
          assetStartDate: action.payload.assetStartDate,
        },
      };
    case INITAILISE_COMPLETE:
      return {
        ...state,
        fetchingInitial: false,
      };
    case SET_OPTIONS_BASE:
      return {
        ...state,
        durationOptions: action.payload.durationOptions,
        productTypeOptions: action.payload.productTypeOptions,
      };
    case SET_OPTIONS_PRIMARY:
      return {
        ...state,
        fetchingPrimaryProductRates: false,
        primaryProductOptions: action.payload,
      };
    case SET_OPTIONS_ALE:
      return {
        ...state,
        assetLedgerEntryOptions: action.payload,
      };
    case SET_VALUES_BASE:
      return {
        ...state,
        fetchingPrimaryProductRates: true,
        primaryProductOptions: [],
        primary: undefined,
        base: {
          ...state.base,
          ...action.payload,
        },
      };
    case SET_VALUE_PRIMARY:
      return {
        ...state,
        primary: action.payload,
      };
    case SET_VALUES_ALE:
      return {
        ...state,
        ales: action.payload,
      };
    case SET_API_ERROR:
      return {
        ...initialState,
        requestError: action.payload,
      };
    default:
      return state;
  }
}
