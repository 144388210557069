import { UpdateProofsParams } from "../../api/proof/batch-update-proofs";
import { RequestError } from "../../api/types";
import {
  SET_ERROR,
  FORM_SUBMIT,
  SET_FORM_VALUES,
  REQUEST_ASSET_IDS,
  UpdateProofsSubmitAction,
  SetErrorAction,
  FormSuccessAction,
  FORM_SUCCESS,
  SetFormValuesAction,
} from "./types";

export function submitUpdateProofs(): UpdateProofsSubmitAction {
  return {
    type: FORM_SUBMIT,
  };
}

export function requestAssetIds(sourceRef: string) {
  return {
    type: REQUEST_ASSET_IDS,
    payload: {
      sourceRef,
    },
  };
}

export function setFormValues(
  formValues: Partial<UpdateProofsParams>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      formValues,
    },
  };
}

export function setFormSuccess(): FormSuccessAction {
  return {
    type: FORM_SUCCESS,
  };
}

export function setFormError(error: RequestError): SetErrorAction {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  };
}
