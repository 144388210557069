import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkProductRateLinkedToAsset } from "../../api/product/check-product-rate-linked-to-asset";
import { postEditProductRate } from "../../api/product/product-rate";
import { PostEditProductRateParams } from "../../api/product/product-rate";
import { RequestError } from "../../api/types";
import {
  // requestDetailProductData,
  updateProductRate,
} from "../detailProducts/actions";
import { RootState } from "../rootReducer";
import { initalizeEditProductRateForm, setError } from "./actions";
import {
  EditProductRateFormState,
  FormSubmitAction,
  InitializeStartAction,
  FORM_SUBMIT,
  INITIALIZE_START,
} from "./types";

function* initializeProductRateForm(action: InitializeStartAction) {
  try {
    const isLinkedToAsset: boolean = yield call(
      checkProductRateLinkedToAsset,
      action.payload.productRate.productRateId
    );
    yield put(
      initalizeEditProductRateForm(action.payload.productRate, isLinkedToAsset)
    );
  } catch (e) {
    const error: RequestError = e;
    yield put(
      setError({
        productRateId: action.payload.productRate.productRateId,
        error,
      })
    );
  }
}

function* submitEditProductRateForm(action: FormSubmitAction) {
  try {
    const formState: EditProductRateFormState = yield select(
      (state: RootState) => state.editProductRateForm
    );

    const params: PostEditProductRateParams = {
      ...formState.baseValues,
    };

    yield call(postEditProductRate, params);
    yield put(updateProductRate(formState.baseValues));

    yield call(action.payload.done);
  } catch (e) {
    const error: RequestError = e;

    const formState: EditProductRateFormState = yield select(
      (state: RootState) => state.editProductRateForm
    );

    yield put(
      setError({
        productRateId: formState.baseValues.productRateId,
        error,
      })
    );
    yield call(() => action.payload.done(error));
  }
}

// function* submitEditProductRateEndDate(action: FormSubmitEndDateAction) {
//   try {
//     const { params } = action.payload;
//     yield call(postEditProductRateEndDate, params);

//     yield put(requestDetailProductData(params.productId));

//     yield call(action.payload.done);
//   } catch (e) {
//     const error: RequestError = e;
//     yield put(setError(error));
//     yield call(() => action.payload.done(error));
//   }
// }

export function* watchSubmitEditProductRateForm() {
  yield takeLatest(FORM_SUBMIT, submitEditProductRateForm);
}

// export function* watchSubmitEditProductRateEndDate() {
//   yield takeLatest(FORM_SUBMIT_END_DATE, submitEditProductRateEndDate);
// }

export function* watchInitializeEditProductRateForm() {
  yield takeLatest(INITIALIZE_START, initializeProductRateForm);
}
