import { QueryAleAPiItem } from "../../api/asset-ledger-entry";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  CANCEL_ALES_VIEW,
  REQUEST_DATA,
  SET_REVSHARE_RECEIVED_DATA,
  SET_API_ERROR,
  SET_DATA,
  REQUEST_REVSHARE_RECEIVED_DATA,
  RequestRevshareReceivedDataAction,
} from "./types";

export function requestQueryAlesData(params: {
  assetId: number[];
  aleTypeId: number[];
  label: string;
}) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function requestRevshareReceived(
  assetId: number
): RequestRevshareReceivedDataAction {
  return {
    type: REQUEST_REVSHARE_RECEIVED_DATA,
    payload: {
      assetId,
      label: "Revshare Received",
    },
  };
}

export function cancelAlesView() {
  return {
    type: CANCEL_ALES_VIEW,
  };
}

export function setQueryAlesErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryAlesData(data: {
  aleTypeId: number[];
  items: PaginatedResult<QueryAleAPiItem>;
}) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setRevshareReceivedData(data: any) {
  return {
    type: SET_REVSHARE_RECEIVED_DATA,
    payload: data,
  };
}
