import { request } from "../..";
import { PaymentType } from "../../../shared/enum/payments";

export type Batch = {
  batchId: number;
  batchRef: string;
  status: string;
  paymentDataProcessed: boolean;
  dateReceived: string;
};

export function getPaymentBatchesByType(paymentType: PaymentType) {
  return request<Batch[]>(`payment-data/batches/${paymentType}`);
}
