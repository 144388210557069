import { request } from "../..";

export interface SpendCapItem {
  readonly spendCapId: number;
  readonly name: string;
}

export interface SpendCapPeriodItem {
  readonly spendCapPeriodId: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly spendCaps: SpendCapItem[];
}

export function getSpendCapsByProductIdAndDate(
  productId: number,
  date: string
) {
  return request<SpendCapPeriodItem[]>(
    `spend-cap-period/by-productId-date/${productId}/${date}`
  );
}
