import React from "react";
import { List, ListItem } from "@mui/material";
import { EditableField } from "../../../../../shared/components/EditableField";
import { formatCurrencyGBP } from "../../../../../shared/helpers/currency";
import { PaymentDataRevShareSummary } from "../../../../../store/paymentDataSummary/types";

type Props = { data: PaymentDataRevShareSummary };

export const RevShareSummaryTable = ({ data }: Props) => (
  <List>
    <ListItem divider>
      <EditableField
        label="Records Matched"
        value={`${data.matchedLines} out of ${
          (data.matchedLines || 0) + (data.unmatchedLines || 0)
        }`}
      />
    </ListItem>
    <ListItem divider>
      <EditableField
        label="Financial Amount in contract"
        value={formatCurrencyGBP(data.totalAmountMatchedInContract)}
      />
    </ListItem>
    <ListItem divider>
      <EditableField
        label="Financial amount that are OOC"
        value={formatCurrencyGBP(data.totalAmountMatchedOoc)}
      />
    </ListItem>
    {data.totalsByProductType.map(({ productType, amount }, i) => (
      <ListItem divider key={i}>
        <EditableField
          label={`Total amount by ${productType}`}
          value={formatCurrencyGBP(amount)}
        />
      </ListItem>
    ))}
    <ListItem divider>
      <EditableField
        label="Total amount matched"
        value={formatCurrencyGBP(data.totalAmountMatched)}
      />
    </ListItem>

    <ListItem divider>
      <EditableField
        label="Total amount not matched"
        value={formatCurrencyGBP(data.totalAmountUnmatched)}
      />
    </ListItem>

    <ListItem>
      <EditableField
        label="Total amount on the original file"
        value={formatCurrencyGBP(data.totalAmountOriginalValue)}
      />
    </ListItem>
  </List>
);
