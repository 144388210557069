import React, { useEffect, useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  resetForm,
  setFormValues,
  setPaymentTermOptions,
  submitForm,
} from "../../../store/editOocRevenueShareForm/actions";
import { RequestError } from "../../../api/types";
import { OocRevenueShares } from "../../../api/partner";
import { DatePicker } from "@mui/x-date-pickers";
import { EditOocRevenueShareFormState } from "../../../store/editOocRevenueShareForm/types";
import { toDateString } from "../../../shared/helpers/dates";
import { getOocPaymentTermOptions } from "../../../api/partner/fetch-ooc-payment-terms";

interface Props {
  readonly partnerId: number;
  readonly oocRevenueShare: OocRevenueShares;
}

export const EditOocRevenueShareModal = ({
  partnerId,
  oocRevenueShare,
}: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { values, fetching, errors, paymentTermOptions } = useSelector<
    RootState,
    EditOocRevenueShareFormState
  >((state: RootState) => state.editOocRevenueShareForm);

  const disableSubmit =
    !values.assetStartDateFrom ||
    !values.assetStartDateTo ||
    values.assetStartDateFrom === "" ||
    values.assetStartDateTo === "" ||
    !values.oocPaymentTermId ||
    values.oocPaymentTermId === 0;

  useEffect(() => {
    if (!open) return;

    getOocPaymentTermOptions().then((result) => {
      dispatch(setPaymentTermOptions(result));
    });
  }, [dispatch, open]);

  useEffect(() => {
    if (open) {
      dispatch(setFormValues(oocRevenueShare));
    }
  }, [oocRevenueShare, open, dispatch]);

  return (
    <ModalFieldWrapper
      apiFetching={fetching}
      icon
      label="Edit OOC Revenue Share"
      buttonLabel="Edit"
      buttonVariant="contained"
      disableSave={disableSubmit}
      onOpen={() => setOpen(true)}
      onCancel={() => {
        setOpen(false);
        dispatch(resetForm());
      }}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId: partnerId,
              partnerServiceProviderOOCPaymentTermId:
                oocRevenueShare.partnerServiceProviderOOCPaymentTermId,
              done: (err?: RequestError) => {
                err ? reject(err) : resolve(null);
              },
            })
          );
        });
      }}
      id="edit-partner-service-provider"
      gutterSize="small"
      apiError={errors.find(
        (e) => e.id === oocRevenueShare.partnerServiceProviderOOCPaymentTermId
      )}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Asset Start Date From"
            format="dd/MM/yyyy"
            value={
              !!values.assetStartDateFrom
                ? new Date(values.assetStartDateFrom)
                : null
            }
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  assetStartDateFrom: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Asset Start Date To"
            format="dd/MM/yyyy"
            value={
              !!values.assetStartDateTo
                ? new Date(values.assetStartDateTo)
                : null
            }
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  assetStartDateTo: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-payment-term"
            select
            label="OOC Payment Term"
            value={values.oocPaymentTermId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const oocPaymentTermId = parseInt(event.target.value);
              dispatch(setFormValues({ oocPaymentTermId }));
            }}
          >
            {paymentTermOptions
              .filter((option) => option.oocPaymentTermId !== 1)
              .map((option) => (
                <MenuItem key={option.name} value={option.oocPaymentTermId}>
                  {option.name}
                </MenuItem>
              ))}
            {paymentTermOptions.length === 0 && (
              <MenuItem key="default" value="" disabled>
                No suitable options available
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
