import {
  QueryInvoiceLinesApiItem,
  QueryInvoiceLinesRequestParams,
} from "../../api/invoice/invoice-lines";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  DELETE_INVOICE_LINE,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
  SET_TABLE_FILTERS,
} from "./types";

export function requestQueryInvoiceLinesData(
  params: QueryInvoiceLinesRequestParams
) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQueryInvoiceLinesErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryInvoiceLinesData(
  data: PaginatedResult<QueryInvoiceLinesApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setQueryInvoiceLinesTableControls(
  data: TableAPIRequestParams<QueryInvoiceLinesApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: data,
  };
}

export function setQueryInvoiceLinesFilters(data: any) {
  return {
    type: SET_TABLE_FILTERS,
    payload: data,
  };
}
export function deleteInvoiceLine(invoiceId: number, invoiceLineNo: number) {
  return {
    type: DELETE_INVOICE_LINE,
    payload: {
      invoiceId,
      invoiceLineNo,
    },
  };
}
