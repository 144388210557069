import { LoaderButton } from "../../../shared/components/LoaderButton";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Card, Modal, TextField, Typography } from "@mui/material";
import { Create } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AssetInfoApiItem } from "../../../api/asset";
import { RequestError } from "../../../api/types";
import { requestUpdateAssetItem } from "../../../store/detailAssets/actions";

type Props = {
  assetId: number;
  assetInfos: AssetInfoApiItem[];
  value: string;
};

const IDENTIFIER = "IDENTIFIER";
const PREVIOUS_IDENTIFIER = "PREVIOUS_IDENTIFIER";

type Mode = "IDENTIFIER" | "PREVIOUS_IDENTIFIER" | undefined;

const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  card: {
    padding: theme.spacing(2),
    width: 500,
  },
  primaryButton: {
    marginLeft: theme.spacing(0.5),
  },
  buttonGroup: {
    marginTop: theme.spacing(0.5),
  },
}));

export const ModalIdentifier = ({ assetId, assetInfos, value }: Props) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const [open, setOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>();
  const [input, setInput] = useState(value);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setMode(undefined);
  };

  const handleSaveClick = () => {
    setLoading(true);
    onSave(input).then((err: RequestError) => {
      if (err) {
        setLoading(false);
        setHasError(true);
        return;
      }

      if (mounted) {
        setOpen(false);
        setLoading(false);
      }

      setMode(undefined);
    });
  };

  const onSave = (identifier: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (mode === IDENTIFIER) {
        dispatch(
          requestUpdateAssetItem(
            assetId,
            {
              identifier,
            },
            () => {
              resolve(null);
            }
          )
        );
      } else if (mode === PREVIOUS_IDENTIFIER) {
        const previousIdentifier = assetInfos.find(
          (ai) => ai.fieldName === "PreviousIdentifier"
        );

        if (!!previousIdentifier) {
          var newAssetInfos = [
            ...assetInfos.filter(
              (ai: AssetInfoApiItem) => ai.fieldName !== "PreviousIdentifier"
            ),
            {
              ...previousIdentifier,
              fieldValue: value,
            },
          ].sort((prev, next) => prev.id - next.id);

          dispatch(
            requestUpdateAssetItem(
              assetId,
              { identifier, assetInfos: newAssetInfos },
              () => {
                resolve(null);
              }
            )
          );
        }
      }

      resolve(null);
    });
  };

  return (
    <React.Fragment>
      <Button
        color="primary"
        startIcon={<Create />}
        onClick={() => setOpen(true)}
        variant="text"
      >
        Edit
      </Button>
      <Modal title="Identifier" open={open} onClose={handleClose}>
        <Box className={classes.container}>
          <Card className={classes.card}>
            <Box mt={2}>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                id="Identifier"
              >
                Identifier
              </Typography>
            </Box>
            {!mode && (
              <Box display="flex" flexDirection="column" alignItems="center">
                Is this a number change?
                <Box display="flex" className={classes.buttonGroup}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setMode(IDENTIFIER)}
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => setMode(PREVIOUS_IDENTIFIER)}
                    className={classes.primaryButton}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            )}
            {!!mode && (
              <Box mx={6} mb={2}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Identifier"
                  variant="outlined"
                  size="small"
                  value={input}
                  onChange={(event) => {
                    setInput(event.target.value);
                  }}
                />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="row-reverse"
              className={classes.buttonGroup}
            >
              {!!mode && (
                <LoaderButton
                  loading={loading}
                  disabled={hasError}
                  size="large"
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.primaryButton}
                  onClick={handleSaveClick}
                >
                  Save
                </LoaderButton>
              )}
              <Button size="large" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Card>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
