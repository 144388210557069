import {
  REQUEST_SELF_BILL_TO_PARTNERS_DATA,
  SET_SELF_BILL_TO_PARTNERS_DATA,
  SET_SELF_BILL_TO_PARTNERS_ERROR,
} from "./types";
import { RequestError } from "../../api/types";
import {
  SelfBillToParnersParams,
  SelfBillToParnerApi,
} from "../../api/partner/self-bill-to-partner-code";

export function requestSelfBillToPartnersData(params: SelfBillToParnersParams) {
  return {
    type: REQUEST_SELF_BILL_TO_PARTNERS_DATA,
    payload: params,
  };
}

export function setSelfBillToPartnersData(
  selfBillPartners: SelfBillToParnerApi[]
) {
  return {
    type: SET_SELF_BILL_TO_PARTNERS_DATA,
    payload: selfBillPartners,
  };
}
export function setSelfBillToPartnersError(error: RequestError) {
  return {
    type: SET_SELF_BILL_TO_PARTNERS_ERROR,
    payload: error,
  };
}
