import {
  UploadTemplateAmendmentsState,
  UploadTemplateAmendmentsActionTypes,
  SET_UPLOAD_RESULT,
  RESET_STATE,
  FORM_SUBMIT,
  SET_ERROR,
} from "./types";

const initialState: UploadTemplateAmendmentsState = {
  isSubmitting: false,
  message: "",
};

export function uploadTemplateAmendmentsReducer(
  state = initialState,
  action: UploadTemplateAmendmentsActionTypes
): UploadTemplateAmendmentsState {
  switch (action.type) {
    case FORM_SUBMIT: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case SET_UPLOAD_RESULT:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        isSubmitting: false,
      };
    case SET_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        apiError: error,
        message: error.message,
        success: false,
      };
    }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
