import {
  CreatePartnerRateActions,
  CreatePartnerRateFormState,
  FORM_INITIALIZE_FINISH,
  FORM_INITIALIZE_START,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_PRODUCT_TYPE_ID,
  SET_SERVICE_PROVIDER_RATES,
  SET_SERVICE_PROVIDER_RATE_DETAILS,
  SET_TIERS,
} from "./types";

const initialState: CreatePartnerRateFormState = {
  values: {
    serviceProviderRateId: 0,
    productTypeId: 0,
    adjustment: 0,
    startDate: "",
    endDate: "",
    standardRate: 0,
    tier: 0,
    receivedRate: 0,
  },
  serviceProviderRateOptions: [],
  distinctProductTypeOptions: [],
  requestError: undefined,
  fetching: false,
  tiers: [],
};

export function createPartnerRateFormReducer(
  state = initialState,
  action: CreatePartnerRateActions
): CreatePartnerRateFormState {
  switch (action.type) {
    case FORM_INITIALIZE_START: {
      return {
        ...initialState,
        fetching: true,
      };
    }

    case FORM_INITIALIZE_FINISH: {
      return {
        ...state,
        fetching: false,
        distinctProductTypeOptions: action.payload.distinctProductTypeOptions,
      };
    }

    case SET_PRODUCT_TYPE_ID: {
      return {
        ...state,
        values: {
          ...state.values,
          productTypeId: action.payload.productTypeId,
        },
      };
    }

    case SET_SERVICE_PROVIDER_RATES: {
      return {
        ...state,
        serviceProviderRateOptions: action.payload.serviceProviderRates,
        values: {
          ...state.values,
          standardRate: 0,
          tier: 0,
          receivedRate: 0,
        },
      };
    }

    case SET_SERVICE_PROVIDER_RATE_DETAILS: {
      return {
        ...state,
        values: {
          ...state.values,
          standardRate: action.payload.serviceProviderRate[0].standardRate,
          receivedRate: action.payload.serviceProviderRate[0].receivedBillRate,
          tier: action.payload.serviceProviderRate[0].tier,
          serviceProviderRateId:
            action.payload.serviceProviderRate[0].serviceProviderRateId,
        },
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    case SET_TIERS: {
      return {
        ...state,
        tiers: action.payload.tiers,
      };
    }

    default:
      return state;
  }
}
