import { call, put, takeLatest } from "redux-saga/effects";
import {
  getInterfaceReport,
  InterfaceReportApiItem,
} from "../../api/interface-asset";
import { RequestError } from "../../api/types";
import {
  setAssetInterfaceReportData,
  setAssetInterfaceReportErrorForDisplay,
} from "./actions";
import { InterfaceReportItem, REQUEST_DATA } from "./types";

function* fetchAssetInterfaceReport() {
  try {
    const data: InterfaceReportApiItem[] = yield call(getInterfaceReport);
    const items: InterfaceReportItem[] = data.map((i) => ({
      ...i,
      checked: false,
    }));

    yield put(setAssetInterfaceReportData(items));
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setAssetInterfaceReportErrorForDisplay(error));
  }
}

export function* watchRequestAssetInterfaceReportData() {
  yield takeLatest(REQUEST_DATA, fetchAssetInterfaceReport);
}
