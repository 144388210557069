import { makeStyles } from "tss-react/mui";
import { Box, Card, Modal, Typography } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    minWidth: "900px",
  },
  card: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  children: React.ReactNode;
  title: string;
  open: boolean;
  onClose?: () => any;
  ariaDescribedby?: string;
  exportButton?: () => React.ReactNode;
};

export const DraggableModalCard = ({
  children,
  ariaDescribedby,
  open,
  onClose,
  title,
  exportButton,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Modal
      aria-describedby={ariaDescribedby}
      aria-labelledby="modal-title"
      open={open}
      onClose={onClose}
    >
      <div className={classes.root}>
        <Draggable>
          <Card className={classes.card}>
            <Box
              marginBottom={1}
              display="flex"
              alignItems="baseline"
              justifyContent={!exportButton ? "center" : "space-between"}
            >
              <Typography
                id="modal-title"
                variant="h3"
                align="center"
                className={classes.title}
              >
                {title}
              </Typography>
              {exportButton && exportButton()}
            </Box>
            {children}
          </Card>
        </Draggable>
      </div>
    </Modal>
  );
};
