import {
  DeferredCostReportApiItem,
  DeferredCostReportParams,
} from "../../api/asset-report";
import {
  PaginatedResult,
  TableAPIRequestParams,
  RequestError,
} from "../../api/types";

export const REQUEST_DATA = "deferredCostReport.REQUEST_DATA";
export const SET_DATA = "deferredCostReport.SET_DATA";
export const SET_ERROR = "deferredCostReport.SET_ERROR";
export const SET_TABLE_CONTROLS = "deferredCostReport.SET_TABLE_CONTROLS";
export const CLEAR_DATA_TABLE = "deferredCostReport.CLEAR_DATA_TABLE";

export type DeferredCostReportState = {
  items: DeferredCostReportApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
  tableControls: TableAPIRequestParams<DeferredCostReportApiItem>;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: DeferredCostReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<DeferredCostReportApiItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

interface ClearTableAction {
  type: typeof CLEAR_DATA_TABLE;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<DeferredCostReportApiItem>;
  };
}

export type DeferredCostReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | ClearTableAction
  | SetTableControlsAction;
