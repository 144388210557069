import React, { useEffect } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  resetForm,
  setFormValues,
  setPaymentTermOptions,
  submitForm,
} from "../../../store/createOocRevenueShareForm/actions";
import { RequestError } from "../../../api/types";
import { CreateOocRevenueShareFormState } from "../../../store/createOocRevenueShareForm/types";
import { DatePicker } from "@mui/x-date-pickers";
import { getOocPaymentTermOptions } from "../../../api/partner/fetch-ooc-payment-terms";
import { toDateString } from "../../../shared/helpers/dates";

interface Props {
  partnerId: number;
  partnerServiceProviderId: number;
}

export const CreateOocRevenueShareModal = ({
  partnerId,
  partnerServiceProviderId,
}: Props) => {
  const dispatch = useDispatch();
  const { values, fetching, requestError, paymentTermOptions } = useSelector<
    RootState,
    CreateOocRevenueShareFormState
  >((state: RootState) => state.createOocRevenueShareForm);

  const disableSubmit =
    !values.assetStartDateFrom ||
    !values.assetStartDateTo ||
    values.assetStartDateFrom === "" ||
    values.assetStartDateTo === "" ||
    !values.oocPaymentTermId ||
    values.oocPaymentTermId === 0;

  useEffect(() => {
    getOocPaymentTermOptions().then((result) => {
      dispatch(setPaymentTermOptions(result));
    });
  }, [dispatch]);

  return (
    <ModalFieldWrapper
      apiFetching={fetching}
      icon={false}
      label="Add OOC Revenue Share"
      buttonLabel="Add"
      buttonVariant="contained"
      disableSave={disableSubmit}
      onCancel={() => dispatch(resetForm())}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              partnerServiceProviderId,
              done: (err?: RequestError) => {
                err ? reject(err) : resolve(null);
              },
            })
          );
        });
      }}
      id="create-ooc-revenue-share"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Asset Start Date From"
            format="dd/MM/yyyy"
            value={
              !!values.assetStartDateFrom
                ? new Date(values.assetStartDateFrom)
                : null
            }
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  assetStartDateFrom: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Asset Start Date To"
            format="dd/MM/yyyy"
            value={
              !!values.assetStartDateTo
                ? new Date(values.assetStartDateTo)
                : null
            }
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  assetStartDateTo: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-payment-term"
            select
            label="OOC Payment Term"
            value={values.oocPaymentTermId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const oocPaymentTermId = parseInt(event.target.value);
              dispatch(setFormValues({ oocPaymentTermId }));
            }}
          >
            {paymentTermOptions
              .filter((option) => option.oocPaymentTermId !== 1)
              .map((option) => (
                <MenuItem key={option.name} value={option.oocPaymentTermId}>
                  {option.name}
                </MenuItem>
              ))}
            {paymentTermOptions.length === 0 && (
              <MenuItem key="default" value="" disabled>
                No suitable options available
              </MenuItem>
            )}
          </TextField>
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
