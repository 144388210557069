import { makeStyles } from "tss-react/mui";
import { Box, Typography, useTheme } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const useStyles = (isDragActive: boolean, isDisabled?: boolean) =>
  makeStyles()((theme) => ({
    root: {
      width: "100%",
      padding: "50px 20px",
      borderStyle: "dashed",
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
      textAlign: "center",
      cursor: isDisabled ? "auto" : "pointer",
      outline: "none",
      transition: `background-color ${
        theme.transitions.duration.standard / 1000
      }s`,
      background: isDisabled
        ? theme.palette.grey[100]
        : isDragActive
        ? theme.palette.grey[50]
        : "",
    },
  }))();

interface Props {
  acceptedFileTypes: string[];
  onAcceptedDrop: (file: File) => any;
  disabled?: boolean;
}

/**
 * @param acceptedFileTypes  eg [".csv", ".xls"] - see https://react-dropzone.js.org/#section-accepting-specific-file-types
 */
export const FileDrop = ({
  acceptedFileTypes,
  onAcceptedDrop,
  disabled,
}: Props) => {
  const [filename, setFilename] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!disabled && acceptedFiles[0]) {
        setFilename(acceptedFiles[0].name);
        onAcceptedDrop(acceptedFiles[0]);
      }
    },
    [onAcceptedDrop, disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop,
    accept: acceptedFileTypes.join(","),
  });
  const { ref, ...rootProps } = getRootProps();

  const theme = useTheme();
  const { classes } = useStyles(isDragActive, disabled);

  return (
    <div ref={ref}>
      <Box {...rootProps} className={classes.root}>
        <input {...getInputProps()} />
        <div style={{ opacity: disabled ? 0.5 : 1 }}>
          <CloudDownload htmlColor={theme.palette.primary.main} />{" "}
          <Typography variant="subtitle2">
            {filename ||
              `Choose a file (${acceptedFileTypes.join(", ")} files accepted)`}
          </Typography>
        </div>
      </Box>
    </div>
  );
};
