import { makeStyles } from "tss-react/mui";
import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Button,
} from "@mui/material";

import { Create } from "@mui/icons-material";
import React, { useState } from "react";
import { UnprocessedEntity } from "../../../../../api/asset-ledger-entry/payment-data";
import { DecimalInput } from "../../../../../shared/components/DecimalInput";
import { BooleanExceptZero } from "../../../../../shared/helpers/BooleanExceptZero";

const useStyles = makeStyles()((theme) => ({
  bodyTableRow: {
    "& > td": {
      paddingTop: `${parseInt(theme.spacing(1)) - 5}px`, // compensate for edit button
      paddingBottom: `${parseInt(theme.spacing(1)) - 5}px`, // // compensate for edit button
    },
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: 0,
  },
  card: {
    padding: theme.spacing(2),
    width: 500,
    display: "flex",
    flexDirection: "column",
  },
}));

type Props = {
  data: UnprocessedEntity[];
  onUpdate: (update: Partial<UnprocessedEntity>, id: number) => any;
};

export const FormPartnerRates = ({ data, onUpdate }: Props) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState<number | undefined>();

  const columns: { name: keyof UnprocessedEntity; label: string }[] = [
    { name: "assetId", label: "Asset ID" },
    { name: "partnerRate", label: "Partner Rate" },
    { name: "identifier", label: "Identifier" },
    { name: "customerBillValue", label: "Customer Bill Value" },
    { name: "postingValue", label: "Posting Value" },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableCell align="right" key={i}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort((a, b) => a.assetId - b.assetId)
            .map((row, i) => (
              <TableRow key={i} className={classes.bodyTableRow}>
                {columns.map((col, i) => {
                  let value: string | number | null = row[col.name];
                  if (col.name === "partnerRate") {
                    return (
                      <TableCell align="right" key={i}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {BooleanExceptZero(value) ? value : "N/A"}{" "}
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(event) => setOpen(row.paymentDataId)}
                          >
                            <Create />
                          </IconButton>
                          <Modal
                            aria-describedby={`title-${row.paymentDataId}`}
                            aria-labelledby={`title-${row.paymentDataId}`}
                            open={open === row.paymentDataId}
                            onClose={() => setOpen(undefined)}
                          >
                            <div className={classes.container}>
                              <Card className={classes.card}>
                                <DecimalInput
                                  value={parseFloat(value?.toString()) || 0}
                                  label="Partner Rate"
                                  onChange={(val) => {
                                    onUpdate(
                                      {
                                        partnerRate: parseFloat(
                                          val.target.value
                                        ),
                                      },
                                      row.paymentDataId
                                    );
                                  }}
                                />
                                <Button
                                  color="primary"
                                  onClick={(event) => setOpen(undefined)}
                                >
                                  Close
                                </Button>
                              </Card>
                            </div>
                          </Modal>
                        </Box>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell align="right" key={i}>
                      {value ?? "N/A"}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
