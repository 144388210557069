import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { RequestError } from "../../api/types";
import { CustomStatusChip } from "./CustomStatusChip";

type Props = {
  children: React.ReactNode;
  error?: RequestError;
  loading?: boolean;
};

export const APIFetchingWrapper = ({ children, error, loading }: Props) => {
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
      {error && (
        <CustomStatusChip type="error" title="Error" message={error.message} />
      )}
      <Box marginTop={1}>{children}</Box>
    </Box>
  );
};
