import { stringify } from "query-string";
import { request, requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type PostedCreditListAPIItem = {
  thirdPartyInvoiceId: string;
  datePosted: string;
  partnerCode: string;
  paymentTerms: number;
  amountIncVat: number;
  amountExcVat: number;
  reference: string;
  paymentType: number;
};

export type QueryPostedCreditListFilterParams = {
  sbReference?: (string | null)[];
  datePosted?: [DateString | null, DateString | null];
  partnerCode?: (string | null)[];
  paymentTerms?: (number | null)[];
};

export interface PostedCreditListParams {
  readonly tableControls: TableAPIRequestParams<PostedCreditListAPIItem>;
  readonly filterParams: QueryPostedCreditListFilterParams;
}

export function getPostedCreditList(params: PostedCreditListParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);
  const filterParamsQS = stringifyFilterParams(params.filterParams);

  return request<PaginatedResult<PostedCreditListParams>>(
    `posteddocument/posted-sb-credit-list${combine([
      tableControlsQS,
      filterParamsQS,
    ])}`
  );
}

export function getPostedCreditListExcel(params: PostedCreditListParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `posteddocument/posted-sb-credit-list${combine([
      tableControlsQS,
      filterParamsQS,
      exportFlag,
    ])}`
  );
}
