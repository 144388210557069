import FileDownload from "js-file-download";
import { DataTable } from "../../../shared/components/DataTable";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import {
  AssetFinancialReportParams,
  AssetReportApiItem,
  getAssetReportExcel,
} from "../../../api/asset-report";
import { TableAPIRequestParams } from "../../../api/types";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import {
  AssetStatus,
  getEntryTypeId,
  EntryType,
  entryTypes,
} from "../../../shared/enum/asset";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import {
  DateString,
  formatISOForDisplay,
  toDateString,
} from "../../../shared/helpers/dates";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import {
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useDispatch, useSelector } from "react-redux";
import { requestAssetReportData } from "../../../store/assetFinancialsReport/actions";
import { RootState } from "../../../store/rootReducer";
import { contractTypes } from "../../../shared/enum/payments";
import { formatDecimal } from "../../../shared/helpers/numbers";
import { LoaderButton } from "../../../shared/components/LoaderButton";

export const AssetFinancialsReport = () => {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.assetReport
  );

  const dispatch = useDispatch();
  const [filters, updateFilters] = useQueryParams({
    date: dateRangeParser,
    status: withDefault(CommaDelimitedNumericArrayParam, []),
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    entryType: {
      encode: (val: EntryType[]) => encodeArray(val),
      decode: (input) => decodePermittedArrayParam(input, entryTypes.slice()),
    },
  });

  const [isExporting, setIsExporting] = useState(false);

  const filterMeta = [
    {
      name: "date",
      label: "Date Range",
      isDateRange: true,
    },
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
    {
      name: "status",
      label: "Status",
      enum: AssetStatus,
    },
    {
      name: "entryType",
      label: "Entry Type",
    },
  ];

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );

    handleSubmit({
      ignoreFilters: true,
    });
  };

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<AssetReportApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  });

  const handleExport = () => {
    setIsExporting(true);
    const startDate = filters.date[0] || toDateString(new Date());
    const endDate = filters.date[1] || toDateString(new Date());
    const serviceProviderIds = filters.serviceProvider.map(
      getServiceProviderId
    );

    const entryTypes = filters.entryType.map(getEntryTypeId);

    getAssetReportExcel({
      entryTypes,
      assetStatus: filters.status,
      startDate,
      endDate,
      serviceProviderIds,
      export: true,
      returnAll: true,
      pageNumber: tableControls.pageNumber,
      pageSize: tableControls.pageSize,
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  type HandleSubmitParams = {
    ignoreFilters?: boolean;
    overrideTableControls?: TableAPIRequestParams<AssetReportApiItem>;
  };

  const handleSubmit = (params?: HandleSubmitParams) => {
    const today = new Date();

    const appliedFilters: {
      startDate: DateString;
      endDate: DateString;
      serviceProviderIds: number[];
      assetStatus: number[];
    } = {
      startDate: toDateString(
        new Date(today.getFullYear(), today.getMonth(), 1)
      ),
      endDate: toDateString(
        new Date(today.getFullYear(), today.getMonth() + 1, 0)
      ),
      serviceProviderIds: [],
      assetStatus: [],
    };

    if (!params?.ignoreFilters) {
      appliedFilters.startDate = filters.date[0] ?? appliedFilters.startDate;
      appliedFilters.endDate = filters.date[1] ?? appliedFilters.endDate;
      appliedFilters.assetStatus = filters.status as number[];
      appliedFilters.serviceProviderIds = filters.serviceProvider.map(
        getServiceProviderId
      );
    }

    dispatch(
      requestAssetReportData({
        startDate: appliedFilters.startDate,
        endDate: appliedFilters.endDate,
        serviceProviderIds: appliedFilters.serviceProviderIds,
        assetStatus: appliedFilters.assetStatus,
        export: false,
        returnAll: false,
        pageNumber:
          params?.overrideTableControls?.pageNumber ?? tableControls.pageNumber,
        pageSize:
          params?.overrideTableControls?.pageSize ?? tableControls.pageSize,
      })
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof AssetReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id", align: "right" },
    { name: "publicIdentifier", label: "Identifier", align: "right" },
    {
      name: "previousIdentifier",
      label: "Previous Identifier",
      align: "right",
    },
    {
      name: "contractTypeId",
      label: "Contract Type",
      align: "right",
      customBodyRender: (value) => contractTypes[parseInt(value)],
    },
    {
      name: "status",
      label: "Status",
      align: "right",
      customBodyRender: (value) => AssetStatus[parseInt(value)],
    },
    // { name: "createdBy", label: "Created By", align: "right" },
    {
      name: "name",
      label: "Product Type",
      align: "right",
    },
    { name: "imei", label: "Imei", align: "right" },
    { name: "temp_ctn", label: "Temp_Ctn", align: "right" },
    { name: "simNo", label: "SIM No.", align: "right" },
    // { name: "thirdPartyId", label: "Third Party Id", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    {
      name: "serviceProviderId",
      label: "Service Provider",
      align: "right",
      customBodyRender: (value) => serviceProviders[parseInt(value) - 1],
    },
    { name: "productCode", label: "Product Code", align: "right" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    { name: "sourceRef", label: "Source Ref", align: "right" },
    { name: "spAccountRef", label: "SP Account Ref", align: "right" },
    { name: "primaryFundingRef", label: "Primary Funding Ref", align: "right" },
    {
      name: "lineRental",
      label: "Line Rental",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "lineRentalTotal",
      label: "Line Rental Total",
      align: "right",
      customBodyRender: formatDecimal,
    },
  ];

  if (filters.entryType.includes("Pay")) {
    columns = [
      ...columns,
      {
        name: "pay_TariffCommission",
        label: "Pay Tariff Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_TariffAdvance",
        label: "Pay Tariff Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_ServiceCommission",
        label: "Pay Service Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_ServiceAdvance",
        label: "Pay Service Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_AdditionalFundingAdvance",
        label: "Pay Additional Funding Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_VolumeBonus",
        label: "Pay Volume Bonus",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_AdditionalFundingDws",
        label: "Pay Additional Funding Dws",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_BcadFund",
        label: "Pay Bcad Fund",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_Da",
        label: "Pay DA",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_Air_Qr_Qn",
        label: "Pay Air QR QN",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_SideLetter_AdditionalFundingNetwork",
        label: "Pay Side Letter Additional Funding Network",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_RevshareAdvanceRecovery",
        label: "Pay Revshare Advance Recovery",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "pay_PartnerShareAmount",
        label: "Pay Partner Share Amount",
        align: "right",
        customBodyRender: formatDecimal,
      },
    ];
  }

  if (filters.entryType.includes("Expected")) {
    columns = [
      ...columns,
      {
        name: "exp_TariffCommission",
        label: "Exp Tariff Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_TariffAdvance",
        label: "Exp Tariff Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_ServiceCommission",
        label: "Exp Service Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_ServiceAdvance",
        label: "Exp Service Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_VolumeBonus",
        label: "Exp Volume Bonus",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_BcadFund",
        label: "Exp Bcad Fund",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_Da",
        label: "Exp DA",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_Air_Qr_Qn",
        label: "Exp Air QR QN",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_SideLetter_AdditionalFundingNetwork",
        label: "Exp Side Letter Additional Funding Network",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_Churn",
        label: "Exp Churn",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_Csi",
        label: "Exp CSI",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "exp_Digital",
        label: "Exp Digital",
        align: "right",
        customBodyRender: formatDecimal,
      },
    ];
  }

  if (filters.entryType.includes("Received")) {
    columns = [
      ...columns,
      {
        name: "rec_TariffCommission",
        label: "Rec Tariff Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_TariffAdvance",
        label: "Rec Tariff Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_ServiceCommission",
        label: "Rec Service Commission",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_ServiceAdvance",
        label: "Rec Service Advance",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_VolumeBonus",
        label: "Rec Volume Bonus",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_BcadFund",
        label: "Rec Bcad Fund",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_Da",
        label: "Rec DA",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_Air_Qr_Qn",
        label: "Rec Air QR QN",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_SideLetter_AdditionalFundingNetwork",
        label: "Rec Side Letter Additional Funding Network",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_Churn",
        label: "Rec Churn",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_Csi",
        label: "Rec CSI",
        align: "right",
        customBodyRender: formatDecimal,
      },
      {
        name: "rec_Digital",
        label: "Rec Digital",
        align: "right",
        customBodyRender: formatDecimal,
      },
    ];
  }

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Asset Financials Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
          >
            Export
          </LoaderButton>
          <Button
            style={{ marginLeft: 8 }}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <MultiSelect
              name="Status"
              id="status-filter-control"
              options={getEnumNames(AssetStatus)}
              selected={
                filters.status
                  ?.filter((status) => status !== null)
                  .map((status) => AssetStatus[status as number]) || []
              }
              onChange={(status) =>
                updateFilters({
                  status: status?.map(
                    (status) => AssetStatus[status as keyof typeof AssetStatus]
                  ),
                })
              }
            />
            <MultiSelect
              name="Entry Type"
              id="type-control"
              options={entryTypes.slice()}
              selected={filters.entryType || []}
              onChange={(entryType) => updateFilters({ entryType })}
            />
            <DateRangeControl
              name="Date Range"
              id="start-date-range-filter-control"
              selected={filters.date}
              onChange={(date) => updateFilters({ date })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as AssetFinancialReportParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
          handleSubmit({
            overrideTableControls: tableControls,
          });
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
