import { Box, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { Dropdown } from "../../../shared/components/Dropdown";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import {
  contractProofStatuses,
  signatureProofStatuses,
} from "../../../shared/enum/asset";
import {
  requestAssetIds,
  setFormValues,
  submitUpdateProofs,
} from "../../../store/formUpdateProofs/actions";
import { RootState } from "../../../store/rootReducer";

export const UpdateProofs = () => {
  const dispatch = useDispatch();
  const { success, error, fetching, formValues } = useSelector(
    (state: RootState) => state.formUpdateProofs
  );

  const [sourceRef, setSourceRef] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (sourceRef) {
      dispatch(requestAssetIds(sourceRef));
    }
  }, [dispatch, sourceRef]);

  useEffect(() => {
    if (success) {
      setSourceRef("");
    }
  }, [success]);

  return (
    <Page
      title="Update Proofs"
      actions={
        <LoaderButton
          disabled={fetching || formValues.assetIds.length === 0}
          onClick={() => dispatch(submitUpdateProofs())}
          variant="contained"
          color="primary"
          size="large"
          loading={fetching}
        >
          Submit
        </LoaderButton>
      }
    >
      {formValues.assetIds.length > 0 && (
        <Box marginBottom={1}>
          <CustomStatusChip
            message={`There will be ${formValues.assetIds.length} assets affected.`}
            title="Update Proofs"
            type="info"
          />
        </Box>
      )}
      {success && (
        <Box marginBottom={1}>
          <CustomStatusChip
            title="Proofs successfully updated"
            type="success"
          />
        </Box>
      )}
      <APIFetchingWrapper error={error} loading={fetching}>
        <React.Fragment>
          <form>
            <FormFieldsWrapper>
              <FormControl fullWidth>
                <TextField
                  onChange={(event) => setSourceRef(event.target.value)}
                  label="Source Ref"
                  value={sourceRef}
                  disabled={fetching}
                />
              </FormControl>
              <FormControl fullWidth>
                <Dropdown
                  disabled={fetching}
                  label="Signature Proof Status"
                  options={signatureProofStatuses}
                  value={formValues.signatureProofStatus}
                  onChange={(signatureProofStatus) =>
                    dispatch(
                      setFormValues({
                        signatureProofStatus,
                      })
                    )
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Dropdown
                  disabled={fetching}
                  label="Contract Proof Status"
                  options={contractProofStatuses}
                  value={formValues.contractProofStatus}
                  onChange={(contractProofStatus) =>
                    dispatch(
                      setFormValues({
                        contractProofStatus,
                      })
                    )
                  }
                />
              </FormControl>
            </FormFieldsWrapper>
          </form>
        </React.Fragment>
      </APIFetchingWrapper>
    </Page>
  );
};
