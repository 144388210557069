import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../shared/components/DataTable";
import {
  Button,
  Checkbox,
  Tooltip,
  Card,
  Typography,
  Box,
} from "@mui/material";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useQueryParams, withDefault } from "use-query-params";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import {
  CommaDelimitedArrayParam,
  dateRangeParser,
} from "../../../shared/helpers/queryParsing";
import { RootState } from "../../../store/rootReducer";
import {
  QuerySelfBillListFilterParams,
  SelfBillListApiItem,
} from "../../../api/invoice/self-bill-list";
import { TableAPIRequestParams } from "../../../api/types";
import {
  requestSelfBillList,
  resetSelfBillListItemsChecked,
  setSelfBillListAllItemsChecked,
  setSelfBillListItemChecked,
  updateSelfBillListOverride,
} from "../../../store/selfBillList/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import {
  formatTrueMeansSuccessBooleanAsIcon,
  anyToBoolean,
} from "../../../shared/helpers/boolean";
import { PaymentTerms } from "../../../shared/enum/invoice";
import { ModalDateUpdate } from "./ModalDateUpdate";
import { formatISOForDisplay } from "./../../../shared/helpers/dates";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { useHistory } from "react-router-dom";
import { ModalDeleteInvoice } from "./ModalDeleteInvoice";
import { ModalRefreshInvoice } from "./ModalRefreshInvoice";
import { makeStyles } from "tss-react/mui";
import PostSelfBillsButton from "./PostSelfBillsButton";
import { DraggableModal } from "../../../shared/components/DraggableModal";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { CheckCircleOutline } from "@mui/icons-material";


const useStyles = makeStyles()((theme) => ({
  checkmark: {
    width: "20px",
    padding: 0,
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiSvgIcon-root": {
        backgroundColor: "#eee",
      },
    },
  },
  button: {
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
}));

export function SelfBillList() {
  const { items, fetching, error, count, checked } = useSelector(
    (state: RootState) => state.selfBillList
  );

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    territoryCode: withDefault(CommaDelimitedArrayParam, []),
    postingDate: dateRangeParser,
    partnerCode: withDefault(CommaDelimitedArrayParam, []),
    postingIssue: withDefault(CommaDelimitedArrayParam, []),
    invoiceId: withDefault(CommaDelimitedArrayParam, []),
    reference: withDefault(CommaDelimitedArrayParam, []),
  });

  const history = useHistory();

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<SelfBillListApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "invoiceId",
  });

  const [overrideState, setOverrideState] = useState<{
    open: boolean;
    item: SelfBillListApiItem | null;
    type: "VAT Status Query" | "Blocked";
    action: "override" | "remove";
  }>({ open: false, item: null, type: "VAT Status Query", action: "override" });

  const filterMeta = [
    {
      name: "territoryCode",
      label: "Territory Code",
    },
    {
      name: "postingDate",
      label: "Posting Date",
      isDateRange: true,
    },
    {
      name: "partnerCode",
      label: "Partner Code",
    },
    {
      name: "postingIssue",
      label: "Posting Issue",
    },
    {
      name: "invoiceId",
      label: "SB No",
    },
    {
      name: "reference",
      label: "Reference",
    },
  ];

  const cleanseRequestSelfBillListData = (
    filters: any
  ): QuerySelfBillListFilterParams => {
    const postingIssue = filters["postingIssue"];
    const { postingIssue: _, ...restFilters } = filters;

    return {
      ...restFilters,
      vatStatusQuery: postingIssue.includes("VAT Status Query") ? [true] : undefined,
      blocked: postingIssue.includes("Blocked") ? [true] : undefined,
      isPostingDateValid: postingIssue.includes("Posting Date")
        ? ["false"] // string is used here to not get filtered out in the cleanse process
        : undefined,
    };
  };

  useEffect(() => {
    dispatch(resetSelfBillListItemsChecked());
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: cleanseRequestSelfBillListData(filters),
      })
    );
  }, [dispatch, tableControls, filters]);

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<number | null>(null);

  const handleDateClick = (date: string, invoiceId: number) => {
    setSelectedDate(new Date(date));
    setCurrentInvoiceId(invoiceId);
    setOpen(true);
  };

  const handleInvoiceIdClick = (invoiceId: number) => {
    history.push({
      pathname: `asset-changes`,
      state: {
        invoiceId: invoiceId,
      },
    });
  };

  const handlePartnerCodeClick = (partnerId: number) => {
    history.push(`/partners/${partnerId}`);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSaveDate = () => {
    setSelectedDate(null);
    setCurrentInvoiceId(null);
    dispatch(
      requestSelfBillList({
        tableControls: tableControls,
        filterParams: filters,
      })
    );
  };

  const handleOverrideClick = (
    item: SelfBillListApiItem,
    type: "VAT Status Query" | "Blocked"
  ) => {
    setOverrideState({
      open: true,
      item,
      type,
      action:
        type === "VAT Status Query"
          ? item.vatStatusQueryCanOverride
            ? "override"
            : "remove"
          : item.blockedCanOverride
          ? "override"
          : "remove",
    });
  };

  const handleOverrideConfirm = () => {
    if (overrideState.item) {
      const { invoiceId } = overrideState.item;
      const updatePayload =
        overrideState.type === "VAT Status Query"
          ? { overrideVatStatusQuery: overrideState.action === "override" }
          : { overrideBlocked: overrideState.action === "override" };
      const pageParams = {
        tableControls: tableControls,
        filterParams: cleanseRequestSelfBillListData(filters),
      };
      dispatch(
        updateSelfBillListOverride(invoiceId, updatePayload, pageParams)
      );
    }
    setOverrideState({
      open: false,
      item: null,
      type: "VAT Status Query",
      action: "override",
    });
  };

  function formatAmberAsIcon() {
    return <CheckCircleOutline style={{ color: "orange" }} fontSize="small" />;
  }

  const actions = [
    {
      label: "Delete",
      renderComponent: (row: any) => (
        <ModalDeleteInvoice
          rowData={row}
          params={{
            tableControls: tableControls,
            filterParams: cleanseRequestSelfBillListData(filters),
          }}
        />
      ),
    },
    {
      label: "Refresh",
      renderComponent: (row: any) => (
        <ModalRefreshInvoice
          rowData={row}
          params={{
            tableControls: tableControls,
            filterParams: cleanseRequestSelfBillListData(filters),
          }}
        />
      ),
    },
  ];

  const CustomCheckbox = (_: string, rowData: SelfBillListApiItem) => {
    return (
      <Checkbox
        color="primary"
        size="small"
        className={classes.checkmark}
        data-cy={`filter-item-checkbox-${rowData.invoiceId}`}
        checked={checked.includes(rowData.invoiceId)}
        disabled={!rowData.canPost}
        onChange={(e) =>
          dispatch(
            setSelfBillListItemChecked(rowData.invoiceId, e.target.checked)
          )
        }
      />
    );
  };

  const RowHeaderCheckbox = () => {
    const filteredItems = items.filter((item) => item.canPost);
    return (
      <Checkbox
        color="primary"
        size="small"
        className={classes.checkmark}
        disabled={filteredItems.length === 0}
        data-cy={"row-header-item-checkbox"}
        checked={
          filteredItems.length === 0
            ? false
            : filteredItems.length === checked.length
        }
        onChange={() => dispatch(setSelfBillListAllItemsChecked())}
      />
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof SelfBillListApiItem;
    label: string | React.ReactNode;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    align?: "left" | "right" | "center";
    disableSort?: boolean;
    allowControl?: boolean;
  }[] = [
    {
      name: "checked",
      label: RowHeaderCheckbox(),
      disableSort: true,
      allowControl: false,
      customBodyRender: (val, rowData) =>
        CustomCheckbox(val as string, rowData),
    },
    {
      name: "invoiceId",
      label: "SB No.",
      customBodyRender: (val) => {
        const id = `asset-changes-${val}`;
        return (
          <Button
            id={id}
            onClick={() => handleInvoiceIdClick(val as number)}
            className={classes.button}
          >
            {val}
          </Button>
        );
      },
    },
    { name: "reference", label: "Reference", disableSort: true },
    {
      name: "postingDate",
      label: "Posting Date",
      disableSort: true,
      customBodyRender: (val, rowData) => {
        const id = `posting-document-date-update-${rowData.invoiceId}`;
        return (
          <Button
            id={id}
            className={classes.button}
            onClick={() => handleDateClick(val as string, rowData.invoiceId)}
          >
            {formatISOForDisplay(val as string)}
          </Button>
        );
      },
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      disableSort: true,
      customBodyRender: (val) => PaymentTerms[val as keyof typeof PaymentTerms],
    },
    {
      name: "partnerCode",
      label: "Partner Code",
      disableSort: true,
      customBodyRender: (value, rowData) => {
        return (
          <Button
            onClick={() => handlePartnerCodeClick(rowData.partnerId as number)}
            className={classes.button}
          >
            {value}
          </Button>
        );
      },
    },
    {
      name: "vatRegNo",
      label: "VAT Number",
      disableSort: true,
      customBodyRender: (value, rowData) => {
        if (value === "-") {
          value = "null";
        }
        const style = {
          color: rowData.isVatRegNoValid ? "inherit" : "red",
        };

        const content = <span style={style}>{value}</span>;

        return rowData.isVatRegNoValid ? (
          content
        ) : (
          <Tooltip
            title="VAT number is invalid.  Please update with a valid VAT value of either N/A or GB followed by 9 digits."
            placement="right-end"
          >
            {content}
          </Tooltip>
        );
      },
    },

    {
      name: "amountExcVat",
      label: "Amount (Ex VAT)",
      align: "right",
      disableSort: true,
      customBodyRender: (val, rowData) => {
        return rowData.isVatRegNoValid
          ? formatCurrencyGBP((val as number | null) || 0)
          : `-`;
      },
    },
    {
      name: "amountIncVat",
      label: "Amount (Inc VAT)",
      disableSort: true,
      align: "right",
      customBodyRender: (val, rowData) => {
        return rowData.isVatRegNoValid
          ? formatCurrencyGBP((val as number | null) || 0)
          : `-`;
      },
    },
    {
      name: "territoryCode",
      label: "Territory Code",
      align: "left",
      disableSort: true,
    },
    {
      name: "vatStatusQuery",
      label: "VAT Status Query",
      align: "center",
      customBodyRender: (_: any, rowData: SelfBillListApiItem) => {
        const state = rowData.vatStatusQueryStateEnum;
        const icon =
          state === "FailureButOverriden"
            ? formatAmberAsIcon()
            : formatTrueMeansSuccessBooleanAsIcon(state === "Success");

        return (
          <Tooltip
            title={
              rowData.vatStatusQueryCanOverride
                ? "Click to override VAT Status Query"
                : rowData.vatStatusQueryCanRemoveOverride
                ? "Click to remove override"
                : "Action not available"
            }
          >
            <div
              onClick={() =>
                rowData.vatStatusQueryCanOverride ||
                rowData.vatStatusQueryCanRemoveOverride
                  ? handleOverrideClick(rowData, "VAT Status Query")
                  : undefined
              }
              style={{
                cursor:
                  rowData.vatStatusQueryCanOverride ||
                  rowData.vatStatusQueryCanRemoveOverride
                    ? "pointer"
                    : "not-allowed",
                display: "inline-block",
              }}
            >
              {icon}
            </div>
          </Tooltip>
        );
      },
    },
    {
      name: "blocked",
      label: "Blocked",
      align: "center",
      customBodyRender: (_: any, rowData: SelfBillListApiItem) => {
        const state = rowData.blockedStateEnum;
        const icon =
          state === "FailureButOverriden"
            ? formatAmberAsIcon()
            : formatTrueMeansSuccessBooleanAsIcon(state === "Success");

        return (
          <Tooltip
            title={
              rowData.blockedCanOverride
                ? "Click to override Blocked"
                : rowData.blockedCanRemoveOverride
                ? "Click to remove override"
                : "Action not available"
            }
          >
            <div
              onClick={() =>
                rowData.blockedCanOverride || rowData.blockedCanRemoveOverride
                  ? handleOverrideClick(rowData, "Blocked")
                  : undefined
              }
              style={{
                cursor:
                  rowData.blockedCanOverride || rowData.blockedCanRemoveOverride
                    ? "pointer"
                    : "not-allowed",
                display: "inline-block",
              }}
            >
              {icon}
            </div>
          </Tooltip>
        );
      },
    },
    {
      name: "isPostingDateValid",
      label: "Valid Posting Date",
      align: "center",
      customBodyRender: (val) => {
        return formatTrueMeansSuccessBooleanAsIcon(anyToBoolean(val));
      },
    },
  ];

  return (
    <React.Fragment>

      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">Self Bill List</Typography>
        <Box>
          <PostSelfBillsButton
              tableControls={tableControls}
              filterParams={filters}
              cleanseRequestSelfBillListData={cleanseRequestSelfBillListData}
          />
        </Box>
      </Box>
      
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        allowHidden={true}
        controls={() => (
          <React.Fragment>
            <MultiTextField
              type="text"
              name="Territory Code"
              id="territory-code-filter-control"
              selected={filters.territoryCode || []}
              onChange={(territoryCode) => updateFilters({ territoryCode })}
            />
            <DateRangeControl
              name="Posting Date"
              id="posting-date-filter-control"
              selected={filters.postingDate || [null, null]}
              onChange={(postingDate) => updateFilters({ postingDate })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="posting-code-filter-control"
              selected={filters.partnerCode || []}
              onChange={(partnerCode) => updateFilters({ partnerCode })}
            />
            <MultiSelect
              name="Posting Issue"
              id="posting-issue-filter-control"
              options={["VAT Status Query", "Blocked", "Posting Date"]}
              selected={filters.postingIssue || []}
              onChange={(postingIssue) => updateFilters({ postingIssue })}
            />
            <MultiTextField
              type="text"
              name="SB No"
              id="sb-no-filter-control"
              selected={filters.invoiceId || []}
              onChange={(invoiceId) => updateFilters({ invoiceId })}
            />
            <MultiTextField
              type="text"
              name="Reference"
              id="reference-filter-control"
              selected={filters.reference || []}
              onChange={(reference) => updateFilters({ reference })}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QuerySelfBillListFilterParams}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        title="Self Bill List"
        limitHeight
        stickyHeader
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100, { label: "Show all", value: -1 }]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
        }}
        tableControls={tableControls}
        allowColumnControl={true}
        hasActions={true}
        actions={actions}
      />
      <ModalDateUpdate
        open={open}
        onClose={handleModalClose}
        onSave={handleSaveDate}
        date={selectedDate}
        invoiceId={currentInvoiceId}
        tableControls={tableControls}
        filterParams={filters}
        cleanseRequestSelfBillListData={cleanseRequestSelfBillListData}
      />
      <DraggableModal
        open={overrideState.open}
        onClose={() => setOverrideState({ ...overrideState, open: false })}
      >
        <Card
          style={{
            maxWidth: "400px",
            padding: "20px",
            textAlign: "center",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            {overrideState.action === "override"
              ? `Override ${overrideState.type}`
              : `Remove Override For ${overrideState.type}`}
          </Typography>
          <Typography
            variant="body1"
            style={{ marginBottom: "20px", color: "rgba(0, 0, 0, 0.8)" }}
          >
            Are you sure you want to{" "}
            {overrideState.action === "override"
              ? "override this issue"
              : "remove this override"}
            ?
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <LoaderButton
              id={
                overrideState.action === "override"
                  ? "override-confirm-button"
                  : "remove-override-confirm-button"
              }
              size="large"
              loading={false} // Update if loading state is dynamic
              color="primary"
              variant="contained"
              onClick={handleOverrideConfirm}
            >
              {overrideState.action === "override"
                ? "Override"
                : "Remove Override"}
            </LoaderButton>
            <Button
              id={
                overrideState.action === "override"
                  ? "override-cancel-button"
                  : "remove-override-cancel-button"
              }
              size="large"
              variant="outlined"
              onClick={() =>
                setOverrideState({ ...overrideState, open: false })
              }
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </DraggableModal>
    </React.Fragment>
  );
}
