import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DetailAssetApiItem } from "../../api/asset";
import { FinancialOverview } from "../../api/asset-ledger-entry/financial-overview";
import { ProofApiItem } from "../../api/proof";
import { APIFetchingWrapper } from "../../shared/components/APIFetchingWrapper";
import {
  requestDetailAssetData,
  requestUpdateAssetItem,
} from "../../store/detailAssets/actions";
import {
  requestQueryAlesData,
  requestRevshareReceived,
} from "../../store/queryAles/actions";
import { RootState } from "../../store/rootReducer";
import { AssetCard } from "./components/AssetCard";
import { AssetNav } from "./components/AssetNav";
import { ModalAles } from "./components/ModalAles";
import { FinancialBreakdownModal } from "./components/FinancialBreakdownModal";
import { requestFinancialBreakdownData } from "../../store/financialBreakdown/actions";
import { FinancialBreakdownType } from "../../store/financialBreakdown/types";
import { CommentsModal } from "./components/CommentsModal";

type Props = { title: string };

export const ViewAsset = ({ title }: Props) => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const assetId = Number(id);
  const data = useSelector(
    (state: RootState) => state.detailAssets.items[assetId]
  ) as (DetailAssetApiItem & FinancialOverview & ProofApiItem) | undefined;
  const fetching = useSelector((state: RootState) =>
    state.detailAssets.fetchingIds.includes(assetId)
  );

  const error = useSelector((state: RootState) =>
    state.detailAssets.errors.find((error) => error.id === assetId)
  );

  const handleUpdateAsset = (updates: Partial<DetailAssetApiItem>) => {
    return new Promise((resolve) => {
      dispatch(requestUpdateAssetItem(assetId, updates, () => resolve(null)));
    });
  };

  useEffect(() => {
    // removed: !data &&
    if (!isNaN(assetId)) {
      dispatch(requestDetailAssetData(assetId));
    }
  }, [assetId, dispatch]);

  const handleRequestDrillDown = (aleTypeId: number[], label: string) => {
    dispatch(requestQueryAlesData({ assetId: [assetId], aleTypeId, label }));
  };

  const handleRequestBreakdown = (
    assetId: number,
    breakdownType: FinancialBreakdownType
  ) => {
    dispatch(requestFinancialBreakdownData({ assetId, breakdownType }));
  };

  const handleRequestRevshareReceived = (assetId: number) => {
    dispatch(requestRevshareReceived(assetId));
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h2" gutterBottom>
          {`${title}: #${id}`}
        </Typography>
        <AssetNav currentId={assetId} assetStatus={data?.status} />
      </Box>
      <APIFetchingWrapper loading={fetching} error={error}>
        {data && (
          <AssetCard
            onUpdateData={handleUpdateAsset}
            data={data}
            onRequestDrillDown={handleRequestDrillDown}
            onRequestBreakdown={handleRequestBreakdown}
            onRequestRevshareReceived={handleRequestRevshareReceived}
          />
        )}
      </APIFetchingWrapper>
      <ModalAles assetId={data?.assetId || 0} />
      <FinancialBreakdownModal />
      <CommentsModal assetId={assetId} />
    </React.Fragment>
  );
};
