import { RequestError } from "../../api/types";
import { DetailPartnerGeneralInfo } from "../../api/partner";

export const REQUEST_UPDATE_PARTNER_GENERAL_INFO =
  "partnerGeneralInfo.REQUEST_UPDATE";
export const SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS =
  "partnerGeneralInfo.UPDATE_SUCCESS";
export const SET_UPDATE_PARTNER_GENERAL_INFO_ERROR =
  "partnerGeneralInfo.UPDATE_ERROR";

export type DetailPartnerGeneralInfoType = {
  partnerId: number;
  doNotSelfBill: boolean;
  isFetching: boolean;
  doNotSelfBillError?: RequestError;
};

export interface RequestUpdatePartnerGeneralInfoAction {
  type: typeof REQUEST_UPDATE_PARTNER_GENERAL_INFO;
  payload: DetailPartnerGeneralInfo;
}

export interface SetUpdatePartnerGeneralInfoSuccessAction {
  type: typeof SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS;
}

export interface SetUpdatePartnerGeneralInfoErrorAction {
  type: typeof SET_UPDATE_PARTNER_GENERAL_INFO_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PartnerGeneralInfoTypes =
  | RequestUpdatePartnerGeneralInfoAction
  | SetUpdatePartnerGeneralInfoSuccessAction
  | SetUpdatePartnerGeneralInfoErrorAction;
