import { call, put, takeLatest } from "redux-saga/effects";
import { RequestDataAction, REQUEST_DATA } from "./types";
import { RequestError, PaginatedResult } from "../../api/types";
import {
  getPostedInvoiceLines,
  QueryPostedDocumentLinesApiItem,
} from "../../api/posted-document/posted-invoice-lines";
import {
  setPostedDocumentLinesData,
  setPostedDocumentLinesError,
} from "./actions";

function* fetchPostedDocumentLines(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryPostedDocumentLinesApiItem> = yield call(
      getPostedInvoiceLines,
      action.payload
    );
    yield put(setPostedDocumentLinesData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setPostedDocumentLinesError(error));
  }
}

export function* watchRequestQueryPostedDocumentLinesData() {
  yield takeLatest(REQUEST_DATA, fetchPostedDocumentLines);
}
