import { makeStyles } from "tss-react/mui";
import { Box, Button } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../store/rootReducer";
import { requestAssetTrueUp } from "../../../store/detailAssets/actions";
import { requestQueryAssetsData } from "../../../store/queryAssets/actions";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { AssetStatus } from "../../../shared/enum/asset";
import { toggleComments } from "../../../store/comments/actions";
import { Permissions } from "../../../store/permissions/types";
import { getPermission } from "../../../store/permissions/reducers";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
}));

type Props = {
  currentId: number;
  assetStatus?: AssetStatus;
};

export const AssetNav = ({ currentId, assetStatus }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  const [loading, setLoading] = useState<boolean>(false);
  const [reconcileOpen, setReconcileOpen] = useState<boolean>(false);

  // cache filter selections if passed from QueryAsset view
  const [filterState, setFilterState] = useState<string>();
  const location = useLocation() as { state?: { filterSearch: string } };
  useEffect(() => {
    if (location.state?.filterSearch) {
      setFilterState(location.state.filterSearch);
    }
  }, [location]);

  useEffect(() => {
    setLoading(false);
  }, [assetStatus]);

  const tableAssetIds = useSelector(
    (state: RootState) => state.queryAssets.items
  ).map((item) => item.assetId);

  const { tableControls, filters, searchTerm, hasNext } = useSelector(
    (state: RootState) => state.queryAssets
  );

  const prevId = tableAssetIds[tableAssetIds.indexOf(currentId) - 1];
  const nextId = tableAssetIds[tableAssetIds.indexOf(currentId) + 1];

  useEffect(() => {
    if (hasNext && !nextId) {
      dispatch(
        requestQueryAssetsData({
          tableParams: {
            ...tableControls,
            pageNumber: tableControls.pageNumber + 1,
          },
          filterParams: filters,
          searchTerm,
        })
      );
    }
  }, [hasNext, nextId, filters, searchTerm, tableControls, dispatch]);

  const handleButtonClick = (id?: number) => {
    if (id) {
      history.push(`${id}`);
    } else {
      history.push("/assets" + (filterState || ""));
    }
  };

  const setCommentsModalVisibility = (show: boolean) =>
    dispatch(toggleComments(show));

  const handleTrueUp = () => {
    dispatch(requestAssetTrueUp(currentId));
    setLoading(true);
    setReconcileOpen(false);
  };

  const { classes } = useStyles();
  return (
    <Box display="flex" className={classes.root}>
      <Button
        color="primary"
        disabled={!prevId}
        variant="outlined"
        onClick={() => handleButtonClick(prevId)}
        startIcon={<ChevronLeft />}
      >
        Prev Asset
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => handleButtonClick()}
      >
        Back To Assets
      </Button>
      <Button
        color="primary"
        disabled={!nextId}
        variant="outlined"
        onClick={() => handleButtonClick(nextId)}
        endIcon={<ChevronRight />}
      >
        Next Asset
      </Button>
      <LoaderButton
        color="primary"
        variant="contained"
        loading={loading}
        disabled={
          assetStatus === AssetStatus.Reconciled || loading || !canWriteAssets
        }
        onClick={() => setReconcileOpen(true)}
      >
        Reconcile
      </LoaderButton>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCommentsModalVisibility(true)}
      >
        Comments
      </Button>
      <ConfirmationModal
        open={reconcileOpen}
        title="Reconcile Asset"
        text="Do you really want to reconcile this asset?"
        onClose={() => setReconcileOpen(false)}
        onConfirm={handleTrueUp}
      />
    </Box>
  );
};
