import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  EditPartnerRateFormValues,
  ResetFormValuesAction,
  RESET_FORM_VALUES,
} from "./types";

export function setFormValues(
  values: Partial<EditPartnerRateFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function resetFormValues(): ResetFormValuesAction {
  return {
    type: RESET_FORM_VALUES,
  };
}

export function submitForm(payload: {
  partnerId: number;
  partnerRateAdjustmentId: number;
  done: () => any;
}): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function setError({
  partnerRateAdjustmentId,
  error,
}: {
  partnerRateAdjustmentId: number;
  error: RequestError;
}) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      partnerRateAdjustmentId,
      error,
    },
  };
}
