import { makeStyles } from "tss-react/mui";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReadProductApiItem } from "../../../api/product";
import { FormSearch } from "../../../shared/components/FormSearch";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { setActiveSecondaryProduct } from "../../../store/detailProducts/actions";
import { RootState } from "../../../store/rootReducer";
import { CreateProductRateModal } from "./CreateProductRateModal";
import { CreateSecondaryProductModal } from "./CreateSecondaryProductModal";
import { DeleteProductRateModal } from "./DeleteProductRateModal";
import { EditProductRateModal } from "./EditProductRateModal";

type Props = {
  primaryProductId: number;
  data: ReadProductApiItem[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  row: {
    cursor: "pointer",
  },
}));

export const PanelContentSecondary = ({ data, primaryProductId }: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  const columns: {
    label: string;
    name: keyof ReadProductApiItem;
    align?: "left" | "right";
    customBodyRender?: (input: string | number | null) => React.ReactNode;
  }[] = [
    { name: "productId", label: "Product Id" },
    { name: "productName", label: "Name" },
    {
      name: "serviceProviderProductId",
      label: "Service Provider Product Code",
    },
  ];

  const Row = (props: { item: ReadProductApiItem }) => {
    const { productId } = props.item;
    const activeSecondaryProductId = useSelector(
      (state: RootState) => state.detailProducts.activeSecondaryProductId
    );
    const secondaryRates = useSelector((state: RootState) =>
      state.detailProducts.items[props.item.parentId].secondaryRates.filter(
        (r: any) => r.productId === productId
      )
    );

    const open = activeSecondaryProductId === productId;
    const handleOpen = (productId: number) => {
      if (open) {
        dispatch(setActiveSecondaryProduct(undefined));
      } else {
        dispatch(setActiveSecondaryProduct(productId));
      }
    };

    return (
      <React.Fragment>
        <TableRow
          className={classes.row}
          hover
          onClick={() => handleOpen(productId)}
        >
          <TableCell>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {columns.map((col, i) => {
            let val: string | number | null = props.item[col.name];
            if (val === undefined || val === null) {
              val = "N/A";
            }

            return (
              <TableCell key={i} align={col.align || "left"}>
                {col.customBodyRender ? col.customBodyRender(val) : val}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell colSpan={6} style={{ padding: 0, border: "none" }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="medium" aria-label="purchases">
                  {secondaryRates.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell>Pay - Service Commission</TableCell>
                        <TableCell>Pay - Service Advance</TableCell>
                        <TableCell>Exp - Service Commission</TableCell>
                        <TableCell>Exp - Service Advance</TableCell>
                        <TableCell>Service Line Rental</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {secondaryRates.map((r: any) => (
                      <TableRow key={r.productRateId}>
                        <TableCell>
                          {formatCurrencyGBP(r.payServiceCommission)}
                        </TableCell>
                        <TableCell>
                          {formatCurrencyGBP(r.payServiceAdvance)}
                        </TableCell>
                        <TableCell>
                          {formatCurrencyGBP(r.expServiceCommission)}
                        </TableCell>
                        <TableCell>
                          {formatCurrencyGBP(r.expServiceAdvance)}
                        </TableCell>
                        <TableCell>
                          {formatCurrencyGBP(r.serviceLineRental)}
                        </TableCell>
                        <TableCell>
                          {formatISOForDisplay(r.startDate)}
                        </TableCell>
                        <TableCell>{formatISOForDisplay(r.endDate)}</TableCell>
                        <TableCell>
                          <EditProductRateModal productRate={r} />
                          <DeleteProductRateModal
                            productId={r.parentId as number}
                            productRateId={r.productRateId}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Box textAlign="center" width="100%">
                          <CreateProductRateModal
                            productId={props.item.productId}
                            parentProductId={props.item.parentId}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Box>
      <Box
        className={classes.container}
        display="flex"
        justifyContent="space-between"
      >
        <FormSearch
          placeholder="Product Name"
          initialValue=""
          id="secondary-product-text-search"
          onSubmit={setSearchTerm}
        />
        <CreateSecondaryProductModal primaryProductId={primaryProductId} />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column, i) => (
                <TableCell key={i} align={column.align || "left"}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .filter(
                  (row) =>
                    searchTerm === "" ||
                    row.productName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                )
                .sort((a, b) => a.productName.localeCompare(b.productName))
                .map((row) => <Row item={row} key={row.productId} />)
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <span>No matching records found</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
