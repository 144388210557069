import { request } from "../..";

export type ProductGroupApi = {
  productGroupId: number;
  name: string;
  description: string;
};

export function getProductGroups() {
  return request<ProductGroupApi[]>("product/product-group");
}
