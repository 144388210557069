import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";
import { affinityColors as colors } from "../../shared/theme/colors";
import React from "react";
import { Link } from "react-router-dom";
import commissionsLogo from "../../assets/commisions-custom.svg";
import { AffinityLogomark } from "../../shared/components/AffinityLogomark";

const useStyles = makeStyles()((theme) => ({
  root: {
    flex: "0 0 248px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.15)",
    background: colors.neutralStorm.value,
    zIndex: 10,
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    top: -65,
    color: theme.palette.common.white,
    marginTop: 65,
  },
}));

export const SideBar: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Box mx={2} mt={2} mb={1}>
        <Link to="/">
          <AffinityLogomark width={190} height={50} />
          <img
            src={commissionsLogo}
            alt="commissions logo"
            style={{ width: 190 }}
          />
        </Link>
      </Box>
      {children}
    </div>
  );
};
