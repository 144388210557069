import { request } from "../..";

export type JobProgressApiItem = {
  jobProgressId: number;
  category: string;
  subCategory: string;
  status: number;
  feature: string;
  stage: string;
  numberToProcess: number;
  numberProcessed: number;
  emailNotification: boolean;
  progressMessage: string;
  lastUpdated: string;
  reference: string;
  jobId: string;
  parameters: string;
  startedBy: string;
  startedAt: string;
  endedAt: string;
  isDeleted: boolean;
  dateCreated: string;
};

export type CommentPostParams = {
  assetId: number;
  description: string;
};

export function getJobProgressWithFilter() {
  return request<JobProgressApiItem[]>(`jobprogress/get-with-filter`, {
    method: "POST",
    body: JSON.stringify({
      dateCreatedFilter: null,
      Statuses: [0, 1, 2, 3, 4, 5, 6],
    }),
  });
}
