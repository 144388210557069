import React, { createRef, useEffect } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  initializeFormStart,
  setFormValues,
  submitForm,
  resetForm,
  setPrimaryProductValue,
} from "../../../store/createAssetForm/actions";
import { RequestError } from "../../../api/types";
import { ContractType, contractTypes } from "../../../shared/enum/asset";
import { ProductTypeOption } from "../../../api/product/product-types";
import { ProductClassApi } from "../../../api/product/product-class";
import { DatePicker } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import { ProductClass } from "../../../shared/enum/product";
import { toDateString } from "../../../shared/helpers/dates";
import { ModalEditAssetSecondaryProducts } from "./ModalEditAssetSecondaryProducts";
import { getPermission } from "../../../store/permissions/reducers";
import { Permissions } from "../../../store/permissions/types";
import {
  ServiceProvider,
  getServiceProviderId,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { CreateAssetFormState } from "../../../store/createAssetForm/types";

export const ModalCreateAsset = () => {
  const dispatch = useDispatch();
  const formRef = createRef<HTMLFormElement>();

  const {
    base,
    productTypeOptions,
    primaryProductOptions,
    secondaryProductOptions,
    productClassOptions,
    partnerOptions,
    durationOptions,
    requestError,
    isFetching,
  } = useSelector<RootState, CreateAssetFormState>(
    (state: RootState) => state.createAssetForm
  );
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  useEffect(() => {
    dispatch(initializeFormStart());
  }, [dispatch]);

  const filteredPrimaryOptions = primaryProductOptions.filter((option) => {
    if (!base.startDate) {
      return true;
    }

    const optionStartDate = new Date(option.startDate);
    const optionEndDate = new Date(option.endDate);
    const selectedDate = new Date(base.startDate);

    return optionStartDate <= selectedDate && optionEndDate >= selectedDate;
  });

  const disableSubmit =
    !base.serviceProvider ||
    !base.productType ||
    !base.contractTypeId ||
    !base.productClass ||
    !base.publicIdentifier ||
    !base.customerName ||
    !base.primaryProduct ||
    !base.startDate;

  return (
    <ModalFieldWrapper
      key="Create Asset"
      formRef={formRef}
      icon={false}
      label="Create Asset"
      buttonVariant="contained"
      buttonLabel="Create Asset"
      buttonDisable={!canWriteAssets}
      submitButtonLabel="Create"
      apiFetching={isFetching}
      onCancel={() => dispatch(resetForm())}
      onSave={() => {
        return new Promise((resolve) => {
          dispatch(
            submitForm({
              done: (err?: RequestError) => resolve(err),
            })
          );
        });
      }}
      id="create-asset"
      gutterSize="small"
      apiError={requestError}
      disableSave={disableSubmit}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider"
            select
            label="Select Service Provider"
            value={base.serviceProvider || ""}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const serviceProvider = event.target.value as ServiceProvider;
              dispatch(setFormValues({ serviceProvider }));
            }}
          >
            {serviceProviders.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-product-type"
            select
            label="Select Product Type"
            value={base.productType?.productTypeId || ""}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productType = productTypeOptions.find(
                (o) => o.productTypeId === parseInt(event.target.value)
              );
              dispatch(setFormValues({ productType }));
            }}
          >
            {productTypeOptions.map((option: ProductTypeOption) => (
              <MenuItem key={option.productTypeId} value={option.productTypeId}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Start Date"
            format="dd/MM/yyyy"
            minDate={new Date("01/01/2000")}
            value={!!base.startDate ? new Date(base.startDate) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  startDate: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        {base.serviceProvider && base.productType && base.startDate && (
          <>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="select-partner"
                select
                label="Select Partner"
                value={base.partnerId || ""}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  const partnerId = parseInt(event.target.value);
                  dispatch(setFormValues({ partnerId }));
                }}
              >
                {partnerOptions
                  .sort((a, b) => a.partnerName.localeCompare(b.partnerName))
                  .map((option) => (
                    <MenuItem key={option.partnerId} value={option.partnerId}>
                      {option.partnerName}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="select-contract-type"
                select
                label="Select Contract Type"
                value={base.contractTypeId || ""}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  const contractTypeId = parseInt(event.target.value);
                  dispatch(setFormValues({ contractTypeId }));
                }}
              >
                {contractTypes.map((option: ContractType, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="select-product-class"
                select
                label="Select Product Class"
                value={base.productClass || ""}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  const productClass = event.target.value as ProductClass;
                  dispatch(setFormValues({ productClass }));
                }}
              >
                {productClassOptions
                  .filter(
                    (c) =>
                      c.productTypeId === base.productType?.productTypeId &&
                      c.serviceProviderId ===
                        getServiceProviderId(base.serviceProvider)
                  )
                  .map((option: ProductClassApi) => (
                    <MenuItem key={option.productClassId} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="select-duration"
                select
                label="Select Duration"
                value={base.duration || ""}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  dispatch(setFormValues({ duration: event.target.value }));
                }}
              >
                {durationOptions.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                {durationOptions.length === 0 && (
                  <MenuItem disabled value="">
                    N/A
                  </MenuItem>
                )}
              </TextField>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="select-advance"
                select
                label="Advance"
                value={base.isAdvanceTaken || false}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  dispatch(
                    setFormValues({
                      isAdvanceTaken: event.target.value === "true",
                    })
                  );
                }}
              >
                <MenuItem key="true" value="true">
                  Yes
                </MenuItem>
                <MenuItem key="false" value="false">
                  No
                </MenuItem>
              </TextField>
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                variant="outlined"
                id="additional-months"
                type="number"
                label="Additional Months"
                value={base.additionalMonths}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  dispatch(
                    setFormValues({
                      additionalMonths: Math.abs(parseInt(event.target.value)),
                    })
                  );
                }}
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                label="Identifier"
                variant="outlined"
                value={base.publicIdentifier || ""}
                onChange={(event) =>
                  dispatch(
                    setFormValues({ publicIdentifier: event.target.value })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <TextField
                size="small"
                label="Customer Name"
                variant="outlined"
                type="text"
                value={base.customerName || ""}
                onChange={(event) =>
                  dispatch(setFormValues({ customerName: event.target.value }))
                }
              />
            </FormControl>
            {base.startDate && (
              <Autocomplete
                onOpen={() => {
                  const myReference = formRef.current;
                  if (myReference) {
                    myReference.style.overflowY = "hidden";
                  }
                }}
                onClose={() => {
                  const myReference = formRef.current;
                  if (myReference) {
                    myReference.style.overflowY = "auto";
                  }
                }}
                disableClearable
                disabled={isFetching}
                options={filteredPrimaryOptions}
                getOptionLabel={(option) => option.productName || ""}
                value={base.primaryProduct || (null as any)}
                onChange={(_, value) => {
                  dispatch(setPrimaryProductValue(value));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Primary Product"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <Search fontSize="small" />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            {base.primaryProduct && (
              <>
                <FormControl style={{ width: "100%", marginTop: -2 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography color="textSecondary">
                      Secondary Products
                    </Typography>
                    <ModalEditAssetSecondaryProducts
                      assetId={0}
                      secondaryProducts={base.selectedSecondaryOptions.map(
                        (pr) => ({
                          assetProductId: 0,
                          duration: pr.duration,
                          isPrimary: pr.isPrimary,
                          productRateId: 0,
                          productId: pr.productId,
                          rsPctExpAdjustment: 0,
                          rsPctPayAdjustment: 0,
                        })
                      )}
                      secondaryProductOptions={secondaryProductOptions}
                    />
                  </Box>
                </FormControl>
              </>
            )}
          </>
        )}
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
