import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  PartnerAdminInformationReportApiItem,
  postGeneratePartnerAdminInformationReport,
} from "../../api/partner-report/generate-partner-admin-info-report";
import {
  setPartnerAdminInformationReportData,
  setPartnerAdminInformationReportError,
} from "./actions";

function* fetchPartnerAdminInformationReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PartnerAdminInformationReportApiItem> =
      yield call(
        postGeneratePartnerAdminInformationReport,
        action.payload.params
      );

    yield put(setPartnerAdminInformationReportData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setPartnerAdminInformationReportError(error));
  }
}

export function* watchPartnerAdminInformationReport() {
  yield takeLatest(REQUEST_DATA, fetchPartnerAdminInformationReport);
}
