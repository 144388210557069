import { call, put, takeLatest } from "redux-saga/effects";
import {
  getQueryInvoiceLines,
  QueryInvoiceLinesApiItem,
} from "../../api/invoice/invoice-lines";
import { RequestError, PaginatedResult } from "../../api/types";
import {
  requestQueryInvoiceLinesData,
  setQueryInvoiceLinesData,
  setQueryInvoiceLinesErrorForDisplay,
} from "./actions";
import {
  RequestDataAction,
  REQUEST_DATA,
  DeleteInvoiceLineAction,
  DELETE_INVOICE_LINE,
} from "./types";
import { deleteInvoiceLine } from "../../api/invoice/invoice-lines/delete";

function* fetchQueryInvoiceLines(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryInvoiceLinesApiItem> = yield call(
      getQueryInvoiceLines,
      action.payload
    );
    yield put(setQueryInvoiceLinesData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setQueryInvoiceLinesErrorForDisplay(error));
  }
}
function* deleteInvoiceLineFromList(action: DeleteInvoiceLineAction) {
  try {
    yield call(
      deleteInvoiceLine,
      action.payload.invoiceId,
      action.payload.invoiceLineNo
    );

    yield put(
      requestQueryInvoiceLinesData({
        tableParams: action.payload.tableParams,
        filterParams: action.payload.filterParams,
        invoiceId: action.payload.invoiceId,
      })
    );
  } catch (e) {
    let error: RequestError = e;
    yield put(setQueryInvoiceLinesErrorForDisplay(error));
  }
}

export function* watchRequestQueryInvoiceLinesData() {
  yield takeLatest(REQUEST_DATA, fetchQueryInvoiceLines);
}
export function* watchDeleteInvoiceLineFromList() {
  yield takeLatest(DELETE_INVOICE_LINE, deleteInvoiceLineFromList);
}
