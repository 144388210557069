import FileDownload from "js-file-download";
import { DataTable } from "../../../shared/components/DataTable";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import { TableAPIRequestParams } from "../../../api/types";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import {
  formatISOForDisplay,
  toDateString,
} from "../../../shared/helpers/dates";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { formatDecimal } from "../../../shared/helpers/numbers";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { PartnerStatus } from "../../../shared/enum/invoice";
import {
  PartnerRateAdjustmentReportApiItem,
  postExportPartnerRateAdjustmentReport,
} from "../../../api/partner-report/generate-partner-rate-adjustment-report";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { requestPartnerRateAdjustmentReport } from "../../../store/partnerRateAdjustmentReport/actions";

export const PartnerRateAdjustmentReport = () => {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.partnerRateAdjustmentReport
  );

  const dispatch = useDispatch();
  const [filters, updateFilters] = useQueryParams({
    date: dateRangeParser,
    partnerCode: withDefault(CommaDelimitedArrayParam, []),
    partnerStatus: withDefault(CommaDelimitedNumericArrayParam, []),
    serviceProvider: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
  });

  const [isExporting, setIsExporting] = useState(false);
  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<PartnerRateAdjustmentReportApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "partnerRateAdjustmentId",
  });

  const handleSubmit = (
    tableControlsOverride?: TableAPIRequestParams<
      PartnerRateAdjustmentReportApiItem
    >
  ) => {
    const startDate = filters.date[0] || toDateString(new Date());
    const endDate = filters.date[1] || toDateString(new Date());
    const serviceProviderIds = filters.serviceProvider.map(
      getServiceProviderId
    );

    dispatch(
      requestPartnerRateAdjustmentReport({
        startDate,
        endDate,
        serviceProviderIds,
        partnerCodes: filters.partnerCode,
        partnerStatuses: filters.partnerStatus,
        tableControls: tableControlsOverride ?? tableControls,
      })
    );
  };

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );

    dispatch(
      requestPartnerRateAdjustmentReport({
        startDate: toDateString(new Date()),
        endDate: toDateString(new Date()),
        serviceProviderIds: [],
        partnerCodes: [],
        partnerStatuses: [],
        tableControls,
      })
    );
  };

  const handleExport = () => {
    setIsExporting(true);
    const startDate = filters.date[0] || toDateString(new Date());
    const endDate = filters.date[1] || toDateString(new Date());
    const serviceProviderIds = filters.serviceProvider.map(
      getServiceProviderId
    );

    postExportPartnerRateAdjustmentReport({
      startDate,
      endDate,
      serviceProviderIds,
      partnerCodes: filters.partnerCode,
      partnerStatuses: filters.partnerStatus,
    })
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const filterMeta = [
    {
      name: "date",
      label: "Adj. End Date Range",
      isDateRange: true,
    },
    {
      name: "serviceProvider",
      label: "Service Provider",
    },
    {
      name: "partnerStatus",
      label: "Partner Status",
      enum: PartnerStatus,
    },
    {
      name: "partnerCode",
      label: "Partner Code",
    },
  ];

  let columns: {
    [key: string]: any;
    name: keyof PartnerRateAdjustmentReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "thirdPartyId", label: "Partner Code", align: "right" },
    {
      name: "partnerName",
      label: "Partner Name",
      align: "right",
    },
    {
      name: "partnerStatus",
      label: "Partner Status",
      align: "right",
      // customBodyRender: (value) => PartnerStatus[parseInt(value)],
    },
    {
      name: "serviceProviderName",
      label: "Service Provider",
      align: "right",
    },
    {
      name: "tier",
      label: "Tier",
      align: "right",
    },
    {
      name: "receivedBillRate",
      label: "Received Bill Rate",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "standardRate",
      label: "Standard Rate",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "startDate",
      label: "Start Date",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "endDate",
      label: "End Date",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "adjustment",
      label: "Adjustment",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "adjStartDate",
      label: "Adj. Start Date",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "adjEndDate",
      label: "Adj. End Date",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Partner Rate Adjustment Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
          >
            Export
          </LoaderButton>
          <Button
            style={{ marginLeft: 8 }}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        controls={() => (
          <React.Fragment>
            <MultiSelect
              name="Service Provider"
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProvider || []}
              onChange={(serviceProvider) => updateFilters({ serviceProvider })}
            />
            <DateRangeControl
              name="Adj. End Date Range"
              id="start-date-range-filter-control"
              selected={filters.date}
              onChange={(date) => updateFilters({ date })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="third-party-id-filter-control"
              selected={filters.partnerCode || []}
              onChange={(partnerCode) => updateFilters({ partnerCode })}
            />
            <MultiSelect
              name="Partner Status"
              id="status-filter-control"
              options={getEnumNames(PartnerStatus)}
              selected={
                filters.partnerStatus
                  ?.filter((status) => status !== null)
                  .map((status) => PartnerStatus[status as number]) || []
              }
              onChange={(status) =>
                updateFilters({
                  partnerStatus: status?.map(
                    (status) =>
                      PartnerStatus[status as keyof typeof PartnerStatus]
                  ),
                })
              }
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={filterMeta}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
          handleSubmit(tableControls);
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
