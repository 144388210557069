import React, { useEffect, useState } from "react";
import FileDownload from "js-file-download";
import { DataTable } from "../../../../shared/components/DataTable";
import { Box, Checkbox, Typography } from "@mui/material";
import { formatISOForDisplay } from "../../../../shared/helpers/dates";
import { Enum } from "../../../../shared/helpers/enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/rootReducer";
import {
  getInterfaceReportExcel,
  InterfaceReportApiItem,
} from "../../../../api/interface-asset";
import { LoaderButton } from "../../../../shared/components/LoaderButton";
import {
  requestInterfaceAssetReport,
  resetAssetInterfaceItemsChecked,
  setAssetInterfaceItemChecked,
  setPartnerId,
  setResignOverride,
} from "../../../../store/interfaceAssetReport/actions";
import { RequestError, TableAPIRequestParams } from "../../../../api/types";
import { CustomStatusChip } from "../../../../shared/components/CustomStatusChip";
import { InterfaceReportItem } from "../../../../store/interfaceAssetReport/types";
import { SplitButton } from "../../../../shared/components/SplitButton";
import { ConfirmationModal } from "../../../../shared/components/ConfirmationModal";
import { SetResignOverrideModal } from "./SetResignOverrideModal";
import { postResetInterfaceAssetStatus } from "../../../../api/interface-asset/reset-processing-status";
import { postUpdateInterfaceAssetResignOverride } from "../../../../api/interface-asset/update-resign-override";
import { InterfaceAssetProcessingStatus } from "../../../../shared/enum/interfaceAsset";
import { postResubmitAsResign } from "../../../../api/interface-asset/resubmit-as-resign";
import { ResubmitWithPartnerModal } from "./ResubmitWithPartnerModal";
import { postResubmitInterfaceAssetWithPartner } from "../../../../api/interface-asset/resubmit-with-partner";
import { postResubmitAsNonLegacy } from "../../../../api/interface-asset/resubmit-as-non-legacy";

export const InterfaceReport = () => {
  const {
    items,
    checked,
    fetching,
    resignOverrideFormValue,
    resubmitWithPartnerFormValue,
    error,
  } = useSelector((state: RootState) => state.assetInterfaceReport);

  const [
    resignOverrideError,
    setResignOverrideError,
  ] = useState<RequestError | null>(null);
  const [resetStatusError, setResetStatusError] = useState<RequestError | null>(
    null
  );
  const [
    resubmitAsResignError,
    setResubmitAsResignError,
  ] = useState<RequestError | null>(null);
  const [
    resubmitWithPartnerError,
    setResubmitWithPartnerError,
  ] = useState<RequestError | null>(null);
  const [
    resubmitAsNonLegacyError,
    setResubmitAsNonLegacyError,
  ] = useState<RequestError | null>(null);

  const [resetErroredOpen, setResetErroredOpen] = useState<boolean>(false);
  const [resignOverrideOpen, setResignOverrideOpen] = useState<boolean>(false);
  const [resubmitWithPartnerOpen, setResubmitWithPartnerOpen] = useState<
    boolean
  >(false);
  const [resubmitAsNonLegacyOpen, setResubmitAsNonLegacyOpen] = useState<
    boolean
  >(false);

  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(requestInterfaceAssetReport());
  };

  const handleExport = () => {
    getInterfaceReportExcel().then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  const handleReset = () => {
    setResetStatusError(null);
    postResetInterfaceAssetStatus(checked)
      .then(() => {
        setResetErroredOpen(false);
        dispatch(resetAssetInterfaceItemsChecked());
        dispatch(requestInterfaceAssetReport());
      })
      .catch((error: RequestError) => {
        setResetStatusError(error);
        setResetErroredOpen(false);
      });
  };

  const handleResubmitAsResign = () => {
    setResubmitAsResignError(null);
    setActionButtonLoading(true);
    postResubmitAsResign(checked)
      .then(() => {
        dispatch(resetAssetInterfaceItemsChecked());
        dispatch(requestInterfaceAssetReport());
      })
      .catch((error: RequestError) => {
        setResubmitAsResignError(error);
      })
      .finally(() => {
        setActionButtonLoading(false);
      });
  };

  const handleResubmitAsNonLegacy = () => {
    setResubmitAsNonLegacyError(null);
    postResubmitAsNonLegacy(checked)
      .then(() => {
        dispatch(resetAssetInterfaceItemsChecked());
        dispatch(requestInterfaceAssetReport());
      })
      .catch((error: RequestError) => {
        setResubmitAsNonLegacyError(error);
      })
      .finally(() => {
        setResubmitAsNonLegacyOpen(false);
      });
  };

  const handleResignOverride = () => {
    setResignOverrideError(null);
    postUpdateInterfaceAssetResignOverride(
      checked,
      resignOverrideFormValue === "true"
    )
      .then(() => {
        setResignOverrideOpen(false);
        dispatch(resetAssetInterfaceItemsChecked());
        dispatch(setResignOverride(""));
        dispatch(requestInterfaceAssetReport());
      })
      .catch((error: RequestError) => {
        setResignOverrideError(error);
      });
  };

  const handleResubmitWithPartner = () => {
    const partnerAlreadyPopulated = items
      .filter((i: InterfaceReportItem) => checked.includes(i.interfaceAssetId))
      .some((i: InterfaceReportItem) => !!i.partner);

    if (partnerAlreadyPopulated) {
      setResubmitWithPartnerError({
        type: "api",
        message:
          "'Update Partner Code' can only be used for interface assets where the partner code is blank.  Please remove any assets that have a partner code populated.",
      });

      return;
    }

    setResubmitWithPartnerError(null);
    postResubmitInterfaceAssetWithPartner(checked, resubmitWithPartnerFormValue)
      .then(() => {
        setResubmitWithPartnerOpen(false);
        dispatch(resetAssetInterfaceItemsChecked());
        dispatch(requestInterfaceAssetReport());
        dispatch(setPartnerId(""));
      })
      .catch((error: RequestError) => {
        setResubmitWithPartnerError(error);
      });
  };

  useEffect(() => {
    dispatch(requestInterfaceAssetReport());
  }, [dispatch]);

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<InterfaceReportItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "sourceRef",
  });

  const CustomCheckbox = (_: string, row: InterfaceReportApiItem) => {
    return (
      <Checkbox
        color="primary"
        data-cy={`filter-item-checkbox-${row.interfaceAssetId}`}
        checked={checked.includes(row.interfaceAssetId)}
        onChange={(e) =>
          dispatch(
            setAssetInterfaceItemChecked(row.interfaceAssetId, e.target.checked)
          )
        }
      />
    );
  };

  let columns: {
    [key: string]: any;
    name: keyof InterfaceReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (
      input: string,
      row: InterfaceReportApiItem
    ) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    {
      name: "checked",
      label: "",
      align: "left",
      customBodyRender: CustomCheckbox,
    },
    { name: "sourceRef", label: "Source Ref", align: "left" },
    { name: "publicIdentifier", label: "Public Identifier", align: "left" },
    { name: "createdBy", label: "Created By", align: "left" },
    { name: "partner", label: "Partner", align: "left" },
    { name: "customerName", label: "Customer Name", align: "left" },
    { name: "productName", label: "Product Name", align: "left" },
    { name: "serviceProvider", label: "Service Provider", align: "left" },
    { name: "paymentTerms", label: "Payment Terms", align: "left" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "dateCreated",
      label: "Date Created",
      customBodyRender: formatISOForDisplay,
    },
    { name: "sourceRefAssetId", label: "Source Ref Asset ID", align: "left" },
    { name: "name", label: "Name", align: "left" },
    {
      name: "resignOverride",
      label: "Resign Override",
      align: "left",
    },
    {
      name: "status",
      label: "Processing Status",
      align: "left",
      enum: InterfaceAssetProcessingStatus,
    },
    { name: "addMonths", label: "Add Months", align: "left" },
    { name: "contract", label: "Contract", align: "left" },
    { name: "proof", label: "Proof", align: "left" },
    { name: "errorMessage", label: "Error Message", align: "left" },
  ];

  return (
    <React.Fragment>
      {resetStatusError && (
        <Box style={{ marginBottom: 16 }}>
          <CustomStatusChip
            type="error"
            title={resetStatusError.type}
            message={resetStatusError.message}
          />
        </Box>
      )}
      {resubmitAsResignError && (
        <Box style={{ marginBottom: 16 }}>
          <CustomStatusChip
            type="error"
            title={resubmitAsResignError.type}
            message={resubmitAsResignError.message}
          />
        </Box>
      )}
      {resubmitAsNonLegacyError && (
        <Box style={{ marginBottom: 16 }}>
          <CustomStatusChip
            type="error"
            title={resubmitAsNonLegacyError.type}
            message={resubmitAsNonLegacyError.message}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Asset Interface Report
        </Typography>
        <Box>
          <LoaderButton
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            onClick={handleSubmit}
            style={{ marginLeft: 16, marginRight: 16 }}
          >
            Refresh
          </LoaderButton>
          <SplitButton
            size="large"
            variant="contained"
            options={[
              "Reset Errored",
              "Resubmit As Resign",
              "Resubmit as Non-Legacy Product",
              "Set Resign Override",
              "Update Partner Code",
            ]}
            handlers={[
              () => setResetErroredOpen(true),
              () => handleResubmitAsResign(),
              () => setResubmitAsNonLegacyOpen(true),
              () => setResignOverrideOpen(true),
              () => setResubmitWithPartnerOpen(true),
            ]}
            disabled={checked.length === 0}
            loading={actionButtonLoading}
          />
        </Box>
      </Box>
      <DataTable
        limitHeight
        stickyHeader
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={items.length}
        onTableControlsChange={(controls) => {
          setTableControls(controls);
        }}
        tableControls={tableControls}
      />
      <ConfirmationModal
        open={resetErroredOpen}
        title="Reset Errored Assets"
        text="Do you really want to reset errored assets?"
        onClose={() => setResetErroredOpen(false)}
        onConfirm={handleReset}
      />
      <ConfirmationModal
        open={resubmitAsNonLegacyOpen}
        title="Resubmit as Non-Legacy Product"
        text="Do you really want to resubmit assets as non-legacy product?"
        onClose={() => setResubmitAsNonLegacyOpen(false)}
        onConfirm={handleResubmitAsNonLegacy}
      />
      <SetResignOverrideModal
        open={resignOverrideOpen}
        error={resignOverrideError}
        onClose={() => {
          setResignOverrideOpen(false);
          setResignOverrideError(null);
        }}
        onConfirm={handleResignOverride}
      />
      <ResubmitWithPartnerModal
        open={resubmitWithPartnerOpen}
        error={resubmitWithPartnerError}
        onClose={() => {
          setResubmitWithPartnerOpen(false);
          setResubmitWithPartnerError(null);
          dispatch(setPartnerId(""));
        }}
        onConfirm={handleResubmitWithPartner}
      />
    </React.Fragment>
  );
};
