import { NewProductApiItem } from "../../api/product";
import { ProductGroupApi } from "../../api/product/product-group";
import { ProductTypeOption } from "../../api/product/product-types";
import { BusinessResponseObject, RequestError } from "../../api/types";

export const FORM_INITIALIZE_START =
  "createPrimaryProductForm.FORM_INITIALIZE_START";
export const FORM_INITIALIZE_FINISH =
  "createPrimaryProductForm.FORM_INITIALIZE_FINISH";
export const SET_FORM_VALUES = "createPrimaryProductForm.SET_FORM_VALUES";
export const SET_NEW_PRODUCT = "createPrimaryProductForm.SET_NEW_PRODUCT";
export const SET_PRODUCT_CLASS_OPTIONS =
  "createPrimaryProductForm.SET_PRODUCT_CLASS_OPTIONS";
export const SET_PRODUCT_GROUP_OPTIONS = "SET_PRODUCT_GROUP_OPTIONS";
export const SET_FORM_ERROR = "createPrimaryProductForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "createPrimaryProductForm.FORM_SUBMIT";
export const FORM_RESET = "createPrimaryProductForm.FORM_RESET";

export type CreatePrimaryProductFormValues = {
  productId?: number;
  productCode?: string;
  productName?: string;
  productTypeId?: number;
  productClassName?: string;
  serviceProviderId?: number;
  duration?: number;
  description?: string;
  productVariantId?: number;
  // productVariantName { get; set; }
  customerType?: number;
  productGroupId?: number;
  // public string ProductGroupName { get; set; }
};

export type CreatePrimaryProductsOptions = {
  productTypeOptions: ProductTypeOption[];
  productGroupOptions: ProductGroupApi[];
  productClassOptions: string[];
};

export type CreatePrimaryProductFormState = {
  values: CreatePrimaryProductFormValues;
  durationOptions: string[];
  productClassOptions: string[];
  productGroupOptions: ProductGroupApi[];
  productTypeOptions: ProductTypeOption[];
  newProduct?: BusinessResponseObject<NewProductApiItem>;
  fetching: boolean;
  requestError?: RequestError;
};

export interface FormInitStartAction {
  type: typeof FORM_INITIALIZE_START;
}

export interface FormInitFinishAction {
  type: typeof FORM_INITIALIZE_FINISH;
  payload: {
    durationOptions: string[];
    productTypeOptions: ProductTypeOption[];
    productGroupOptions: ProductGroupApi[];
  };
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreatePrimaryProductFormValues>;
  };
}

export interface SetNewProductAction {
  type: typeof SET_NEW_PRODUCT;
  payload: {
    product: BusinessResponseObject<NewProductApiItem>;
  };
}

export interface SetProductClassOptionsAction {
  type: typeof SET_PRODUCT_CLASS_OPTIONS;
  payload: {
    productClassOptions: string[];
  };
}

export interface SetProductGroupOptionsAction {
  type: typeof SET_PRODUCT_GROUP_OPTIONS;
  payload: {
    productGroupOptions: ProductGroupApi[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type CreatePrimaryProductActions =
  | FormInitStartAction
  | FormInitFinishAction
  | SetFormValuesAction
  | SetProductClassOptionsAction
  | SetProductGroupOptionsAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetNewProductAction
  | ResetFormAction;
