export function formatCurrencyGBP(value: number): string {
  try {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value);
  } catch (e) {
    console.warn();
    return value.toString();
  }
}
