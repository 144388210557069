import {
  ContractProofStatus,
  SignatureProofStatus,
} from "../../shared/enum/asset";
import {
  FORM_SUBMIT,
  SET_ERROR,
  FORM_SUCCESS,
  FormUpdateProofsState,
  UpdateProofsActionTypes,
  SET_FORM_VALUES,
} from "./types";

const initialState: FormUpdateProofsState = {
  formValues: {
    assetIds: [],
    contractProofStatus: ContractProofStatus.Blank,
    signatureProofStatus: SignatureProofStatus.Blank,
  },
  fetching: false,
  success: false,
};

export function formUpdateProofsReducer(
  state = initialState,
  action: UpdateProofsActionTypes
): FormUpdateProofsState {
  switch (action.type) {
    case FORM_SUBMIT:
      return {
        ...state,
        fetching: true,
        success: false,
        error: undefined,
      };

    case FORM_SUCCESS: {
      return {
        ...state,
        fetching: false,
        success: true,
        error: undefined,
        formValues: initialState.formValues,
      };
    }

    case SET_ERROR: {
      return {
        ...state,
        fetching: false,
        success: false,
        error: action.payload.error,
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        fetching: false,
        success: false,
        formValues: {
          ...state.formValues,
          ...action.payload.formValues,
        },
      };
    }

    default:
      return state;
  }
}
