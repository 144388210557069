import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  QueryInvoiceLinesApiItem,
  QueryInvoiceLinesRequestParams,
} from "../../api/invoice/invoice-lines";

export const SET_DATA = "queryInvoiceLines.SET_DATA";
export const SET_TABLE_CONTROLS = "queryInvoiceLines.SET_TABLE_CONTROLS";
export const SET_TABLE_FILTERS = "queryInvoiceLines.SET_TABLE_FILTERS";
export const REQUEST_DATA = "queryInvoiceLines.REQUEST_DATA";
export const SET_API_ERROR = "queryInvoiceLines.SET_API_ERROR";
export const DELETE_INVOICE_LINE = "invoiceLines.DELETE_INVOICE_LINE";

export type QueryInvoiceLinesState = {
  items: QueryInvoiceLinesApiItem[];
  allItems: QueryInvoiceLinesApiItem[];
  count: number;
  fetching: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  tableControls: TableAPIRequestParams<QueryInvoiceLinesApiItem>;
  filters: any;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryInvoiceLinesRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryInvoiceLinesApiItem>;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: TableAPIRequestParams<QueryInvoiceLinesApiItem>;
}

interface SetTableFiltersAction {
  type: typeof SET_TABLE_FILTERS;
  payload: any;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export interface DeleteInvoiceLineAction {
  type: typeof DELETE_INVOICE_LINE;
  payload: {
    invoiceId: number;
    invoiceLineNo: number;
    tableParams: TableAPIRequestParams<QueryInvoiceLinesApiItem>;
    filterParams: any;
  };
}

export type QueryInvoiceLinesActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetTableControlsAction
  | SetTableFiltersAction
  | SetAPIErrorAction
  | DeleteInvoiceLineAction;
