import { request } from "../..";

export interface UpdatePartnerServiceProviderParams {
  readonly partnerId: number;
  readonly serviceProviderId: number;
  readonly serviceProviderAccountTypeId: number;
  readonly spDealerCode: string;
  readonly enabled: boolean;
}

export function postUpdatePartnerServiceProvider(
  params: UpdatePartnerServiceProviderParams
) {
  return request("partner-service-provider/update", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
