import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  QueryAssetChangesApiItem,
  QueryAssetChangesRequestParams,
} from "../../api/asset-changes-due";

export const SET_DATA = "queryAssetChanges.SET_DATA";
export const SET_TABLE_CONTROLS = "queryAssetChanges.SET_TABLE_CONTROLS";
export const SET_TABLE_FILTERS = "queryAssetChanges.SET_TABLE_FILTERS";
export const SET_SEARCH_TERM = "queryAssetChanges.SET_SEARCH_TERM";
export const REQUEST_DATA = "queryAssetChanges.REQUEST_DATA";
export const SET_API_ERROR = "queryAssetChanges.SET_API_ERROR";
export const REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA =
  "queryAssetChanges.REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA";
export const SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA =
  "queryAssetChanges.SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA";
export const SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR =
  "queryAssetChanges.SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR";

export type QueryAssetChangesState = {
  items: QueryAssetChangesApiItem[];
  count: number;
  fetching: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  tableControls: TableAPIRequestParams<QueryAssetChangesApiItem>;
  filters: any;
  searchTerm: string;
  error?: RequestError;
  totalSBAmount: number;
};
export interface SBAmountState {
  totalSBAmount: number;
  totalSbAmountFetching: boolean;
  totalSbAmountError?: RequestError;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryAssetChangesRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryAssetChangesApiItem>;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: TableAPIRequestParams<QueryAssetChangesApiItem>;
}

interface SetTableFiltersAction {
  type: typeof SET_TABLE_FILTERS;
  payload: any;
}

interface SetSearchTermAction {
  type: typeof SET_SEARCH_TERM;
  payload: string;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}
export interface RequestTotalSBAmountOfAssetChangesDueAction {
  type: typeof REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA;
  payload: number;
}

interface SetTotalSBAmountOfAssetChangesDueAction {
  type: typeof SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA;
  payload: number;
}

interface SetTotalSBAmountOfAssetChangesDueErrorAction {
  type: typeof SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR;
  payload: RequestError;
}

export type QueryAssetChangesActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetTableControlsAction
  | SetTableFiltersAction
  | SetSearchTermAction
  | SetAPIErrorAction
  | RequestTotalSBAmountOfAssetChangesDueAction
  | SetTotalSBAmountOfAssetChangesDueAction
  | SetTotalSBAmountOfAssetChangesDueErrorAction;
