import {
  CreatePartnerServiceProviderActions,
  CreatePartnerServiceProviderFormState,
  FORM_RESET,
  SET_ACCOUNT_TYPE_OPTIONS,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
} from "./types";

const initialState: CreatePartnerServiceProviderFormState = {
  requestError: undefined,
  fetching: false,
  values: {
    spDealerCode: "",
    serviceProviderId: 0,
    serviceProviderAccountTypeId: 0,
    enabled: true,
  },
  accountTypeOptions: [],
};

export function createPartnerServiceProviderFormReducer(
  state = initialState,
  action: CreatePartnerServiceProviderActions
): CreatePartnerServiceProviderFormState {
  switch (action.type) {
    case SET_ACCOUNT_TYPE_OPTIONS: {
      return {
        ...state,
        accountTypeOptions: action.payload.accountTypeOptions,
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
