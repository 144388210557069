import React from "react";
import { Typography, CircularProgress, Box, Button } from "@mui/material";
import { styled } from "@mui/material";

export interface LoadingFullScreenProps {
  message: string;
  cancel?: () => any;
  variant?: "overlay";
}

const FullScreenCenter = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100vw",
  height: "100vh",
});

export const LoadingFullScreen = ({
  message,
  cancel,
  variant,
}: LoadingFullScreenProps) => {
  return (
    <FullScreenCenter
      style={
        variant === "overlay"
          ? { background: "rgba(0,0,0,0.5)", color: "white" }
          : undefined
      }
    >
      <CircularProgress />
      {message && (
        <Box m={1}>
          <Typography variant="h4">{message}</Typography>
        </Box>
      )}
      {cancel && (
        <Button variant="contained" onClick={cancel}>
          Cancel
        </Button>
      )}
    </FullScreenCenter>
  );
};
