import { call, put, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { DeleteProductSpendCapAction, SUBMIT } from "./types";
import { requestDetailProductData } from "../detailProducts/actions";
import { deleteSpendCap as apiDeleteSpendCap } from "../../api/spend-cap/delete";

function* deleteSpendCap(action: DeleteProductSpendCapAction) {
  try {
    yield call(apiDeleteSpendCap, action.payload.spendCapId);
    yield put(requestDetailProductData(action.payload.productId));
  } catch (e) {
    const error = e as RequestError;
    yield call(() => action.payload.done(error));
  } finally {
    yield call(action.payload.done);
  }
}

export function* watchDeleteSpendCap() {
  yield takeLatest(SUBMIT, deleteSpendCap);
}
