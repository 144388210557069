import React from "react";
import { ImportSingleFilePage } from "../components/ImportSingleFilePage";
import { postUpdateAssetEndDates } from "../../../api/asset/update-assets-end-date";

export const UpdateEndDates = () => (
  <React.Fragment>
    <ImportSingleFilePage
      uploadService={postUpdateAssetEndDates}
      title="Update Asset End Dates"
      acceptedFiles={[".csv"]}
    />
  </React.Fragment>
);
