import {
  QueryPostedDocumentLinesActionsTypes,
  QueryPosedDocumentLinesState,
  REQUEST_DATA,
  SET_DATA,
  SET_TABLE_CONTROLS,
  SET_API_ERROR,
} from "./types";

const initialState: QueryPosedDocumentLinesState = {
  items: [],
  allItems: [],
  count: 0,
  fetching: false,
  hasNext: false,
  hasPrevious: false,
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  },
};

export function queryPostedDocumentLinesReducer(
  state = initialState,
  action: QueryPostedDocumentLinesActionsTypes
): QueryPosedDocumentLinesState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload,
      };
    }

    case SET_API_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    default:
      return state;
  }
}
