import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableAPIRequestParams } from "../../../../api/types";
import { JobProgressApiItem } from "../../../../api/jobprogress";
import { JobProgressStatus } from "../../../../shared/enum/jobProgress";
import { RootState } from "../../../../store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { Enum } from "../../../../shared/helpers/enum";
import { formatISOForDisplayDateTimeHandlingBST } from "../../../../shared/helpers/dates";
import { DataTable } from "../../../../shared/components/DataTable";
import {
  requestProgressReportData,
  setProgressReportData,
} from "../../../../store/progressReport/actions";
import { LoaderButton } from "../../../../shared/components/LoaderButton";

export const ProgressReport = () => {
  const { items, fetching, error } = useSelector(
    (state: RootState) => state.progressReport
  );

  const dispatch = useDispatch();

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<JobProgressApiItem>
  >({
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 1,
    sortBy: "jobId",
  });

  useEffect(() => {
    dispatch(requestProgressReportData());
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(requestProgressReportData());
  };

  const sortItems = (
    sortingData: TableAPIRequestParams<JobProgressApiItem>
  ) => {
    items.sort((a, b) => {
      if (a[sortingData.sortBy] < b[sortingData.sortBy]) {
        return sortingData.sortDirection === 0 ? -1 : 1;
      }
      if (a[sortingData.sortBy] > b[sortingData.sortBy]) {
        return sortingData.sortDirection === 0 ? 1 : -1;
      }
      return 0;
    });
    setProgressReportData(items);
  };

  let columns: {
    [key: string]: any;
    name: keyof JobProgressApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "startedBy", label: "Started By", align: "left" },
    {
      name: "startedAt",
      label: "Started At",
      align: "left",
      customBodyRender: formatISOForDisplayDateTimeHandlingBST,
    },
    {
      name: "endedAt",
      label: "Ended At",
      align: "left",
      customBodyRender: formatISOForDisplayDateTimeHandlingBST,
    },
    { name: "category", label: "Category", align: "left" },
    { name: "subCategory", label: "Subcategory", align: "left" },
    { name: "status", label: "Status", align: "left", enum: JobProgressStatus },
    { name: "feature", label: "Feature", align: "left" },
    { name: "stage", label: "Stage", align: "left" },
    { name: "numberToProcess", label: "Number To Process", align: "right" },
    { name: "numberProcessed", label: "Number Processed", align: "right" },
    { name: "emailNotification", label: "Email Notification", align: "left" },
    {
      name: "progressMessage",
      label: "Progress Message",
      align: "left",
      // alternative way of controlling render
      // we set the width, allow word wrapping and pre-wrap allows new line characters to be honoured.
      customBodyRender: (value) => {
        return (
          <div
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              width: "750px",
            }}
          >
            <p>{value}</p>
          </div>
        );
      },

      /*customBodyRender: (value) => {
        return <pre>{value}</pre>;
      },
      */
    },
    {
      name: "lastUpdated",
      label: "Last Updated",
      align: "left",
      customBodyRender: formatISOForDisplayDateTimeHandlingBST,
    },
    { name: "reference", label: "Reference", align: "left" },
    { name: "jobId", label: "Job Id", align: "left" },
    {
      name: "parameters",
      label: "Parameters",
      align: "left",
      // pre is overriding the white-space css element that's set to stop wrapping
      customBodyRender: (value) => {
        return <pre>{value}</pre>;
      },
    },
  ];

  let summaryColumns: {
    [key: string]: any;
    name: keyof JobProgressApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "startedBy", label: "Started By", align: "left" },
    {
      name: "startedAt",
      label: "Started At",
      align: "left",
      customBodyRender: formatISOForDisplayDateTimeHandlingBST,
    },
    {
      name: "endedAt",
      label: "Ended At",
      align: "left",
      customBodyRender: formatISOForDisplayDateTimeHandlingBST,
    },
    { name: "feature", label: "Feature", align: "left" },
    { name: "status", label: "Status", align: "left", enum: JobProgressStatus },
    { name: "numberToProcess", label: "Number To Process", align: "right" },
    { name: "numberProcessed", label: "Number Processed", align: "right" },

    // hidden columns need to be here for correct switching to the detailed view
    { name: "category", label: "Category", hidden: true },
    { name: "subCategory", label: "Subcategory", hidden: true },
    { name: "stage", label: "Stage", hidden: true },
    { name: "emailNotification", label: "Email Notification", hidden: true },
    { name: "progressMessage", label: "Progress Message", hidden: true },
    { name: "lastUpdated", label: "Last Updated", hidden: true },
    { name: "reference", label: "Reference", hidden: true },
    { name: "jobId", label: "Job Id", hidden: true },
    { name: "parameters", label: "Parameters", hidden: true },
  ];

  const [detailed, setDetailed] = useState(false);

  let DetailedText = "Detailed";
  let SummaryText = "Summary";

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Progress Page - {detailed ? DetailedText : SummaryText}
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={() => setDetailed(!detailed)}
            loading={false}
          >
            Show {detailed ? SummaryText : DetailedText}
          </LoaderButton>

          <Button
            style={{ marginLeft: 8 }}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Refresh
          </Button>
        </Box>
      </Box>

      <DataTable
        limitHeight
        stickyHeader
        error={error}
        hover
        showPagination={false}
        loading={fetching}
        data={items}
        columns={detailed ? columns : summaryColumns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={items.length}
        onTableControlsChange={(tableControls: any) => {
          setTableControls(tableControls);
          sortItems(tableControls);
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
