import { List, ListItem } from "@mui/material";
import React from "react";
import { FinancialOverview } from "../../../api/asset-ledger-entry/financial-overview";
import { EditableField } from "../../../shared/components/EditableField";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";

type Props = Pick<
  FinancialOverview,
  | "revShareReceived"
  | "outstandingAdvance"
  | "monthlyAdvanceRepayment"
  | "collectedAdvance"
  | "revsharePaid"
> & {
  assetId: number;
  onRequestDrilldown: (assetId: number[], label: string) => any;
  onRequestRevshareReceived: (assetId: number) => any;
};

export const PanelContentRevShare = ({
  assetId,
  revShareReceived,
  outstandingAdvance,
  monthlyAdvanceRepayment,
  collectedAdvance,
  revsharePaid,
  onRequestDrilldown,
  onRequestRevshareReceived,
}: Props) => {
  const revShareReceivedItem = revShareReceived.find(
    (item) => item.name === "Pay"
  );
  const collectedAdvanceItem = collectedAdvance.find(
    (item) => item.name === "Pay"
  );
  const revsharePaidItem = revsharePaid.find((item) => item.name === "Pay");

  return (
    <>
      <List>
        <ListItem divider>
          <EditableField
            label="Partner Share"
            onValueClick={() =>
              revShareReceivedItem && onRequestRevshareReceived(assetId)
            }
            value={
              revShareReceivedItem
                ? formatCurrencyGBP(revShareReceivedItem.amount)
                : "N/A"
            }
          />
        </ListItem>
        <ListItem divider>
          <EditableField
            label="Outstanding Advance"
            value={formatCurrencyGBP(outstandingAdvance)}
          />
        </ListItem>
        <ListItem divider>
          <EditableField
            label="Monthly Advance Repayment"
            value={formatCurrencyGBP(monthlyAdvanceRepayment)}
          />
        </ListItem>
        <ListItem divider>
          <EditableField
            label="Collected Advance"
            onValueClick={() =>
              collectedAdvanceItem &&
              onRequestDrilldown(
                collectedAdvanceItem.aleTypeId,
                "Collected Advance"
              )
            }
            value={
              collectedAdvanceItem
                ? formatCurrencyGBP(collectedAdvanceItem.amount)
                : undefined
            }
          />
        </ListItem>
        <ListItem>
          <EditableField
            label="Revshare Paid"
            onValueClick={() =>
              revsharePaidItem &&
              onRequestDrilldown(revsharePaidItem.aleTypeId, "Revshare Paid")
            }
            value={
              revsharePaidItem
                ? formatCurrencyGBP(revsharePaidItem.amount)
                : undefined
            }
          />
        </ListItem>
      </List>
    </>
  );
};
