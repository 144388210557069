import {
  DetailSelfBillToPartnerType,
  SelfBillToPartnerTypes,
  REQUEST_UPDATE_SELF_BILL_TO_PARTNER,
  SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR,
  SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS,
} from "./types";

const initialState: DetailSelfBillToPartnerType = {
  isFetching: false,
  partnerId: 0,
  selfBillToPartnerID: 0,
};

export function updateSelfBillToPartnerReducer(
  state = initialState,
  action: SelfBillToPartnerTypes
): DetailSelfBillToPartnerType {
  switch (action.type) {
    case REQUEST_UPDATE_SELF_BILL_TO_PARTNER:
      return {
        ...state,
        isFetching: true,
      };

    case SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR: {
      return {
        ...state,
        isFetching: false,
        selfBillToPartnerError: action.payload.err,
      };
    }
    default:
      return state;
  }
}
