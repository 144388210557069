import { request } from "../..";
import { SecondaryProductApiItem } from "../secondary-products";

export type PostCreateSecondaryProductParams = {
  parentId: number;
  name: string;
  description: string;
};

export function postCreateSecondaryProduct(
  params: PostCreateSecondaryProductParams
) {
  return request<SecondaryProductApiItem>("product/secondary-product/add", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
