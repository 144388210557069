import {
  ProgressReportActionTypes,
  ProgressReportState,
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
} from "./types";

const initialState: ProgressReportState = {
  items: [],
  count: 0,
  fetching: false,
};

export function progressReportReducer(
  state = initialState,
  action: ProgressReportActionTypes
): ProgressReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_API_ERROR:
      return { ...state, error: action.payload, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload,
      };

    default:
      return state;
  }
}
