import { LoaderButton } from "./LoaderButton";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import * as React from "react";

type SplitButtonProps = {
  options: string[];
  handlers: (() => void)[];
  onChange?: (value: string) => void;
  size?: "small" | "medium" | "large";
  variant?: "outlined" | "contained";
  loading?: boolean;
  disabled?: boolean;
};

export const SplitButton = ({
  options,
  handlers,
  onChange,
  size,
  variant,
  disabled,
  loading,
}: SplitButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    handlers[selectedIndex]();
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    onChange && onChange(options[index]);
    setSelectedIndex(index);
    handlers[index]();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        color="primary"
        variant={variant || "contained"}
        ref={anchorRef}
        aria-label="split button"
        size={size}
      >
        <LoaderButton
          style={{ fontSize: "0.875rem" }}
          onClick={handleClick}
          disabled={disabled}
          loading={!!loading}
        >
          {options[selectedIndex]}
        </LoaderButton>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom"
        style={{
          zIndex: 10,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
