import { request } from "../..";

export interface UpdatePartnerServiceProviderOOCPaymentTermParams {
  partnerId: number;
  serviceProviderId: number;
  oocPaymentTermId: number;
}

export function postUpdatePartnerServiceProviderOOCPaymentTerm(
  params: UpdatePartnerServiceProviderOOCPaymentTermParams
) {
  return request("partner/update-partner-service-provider-ooc-payment-term", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
