import { RecalculatePartnerRateParams } from "../../api/asset-ledger-entry/recalculate-partner-rate";
import { RequestError } from "../../api/types";
import {
  SET_ERROR,
  FORM_SUBMIT,
  RecalculatePartnerRateAction,
  SetErrorAction,
  FormSuccessAction,
  FORM_SUCCESS,
  FORM_RESET,
  FormResetAction,
} from "./types";

export function submitRecalculatePartnerRate(
  params: RecalculatePartnerRateParams
): RecalculatePartnerRateAction {
  return {
    type: FORM_SUBMIT,
    payload: {
      params,
    },
  };
}

export function formSuccess(): FormSuccessAction {
  return {
    type: FORM_SUCCESS,
  };
}

export function formReset(): FormResetAction {
  return {
    type: FORM_RESET,
  };
}

export function setRecalculatePartnerRateError(
  error: RequestError
): SetErrorAction {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  };
}
