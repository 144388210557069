import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import { DataTable } from "../../../../../shared/components/DataTable";
import { TableAPIRequestParams } from "../../../../../api/types";
import { RootState } from "../../../../../store/rootReducer";
import { requestPaymentData } from "../../../../../store/paymentData/actions";
import {
  getPaymentDataExcel,
  PaymentDataAPIFilterParams,
} from "../../../../../api/payment-data";
import { PaymentDataItem } from "../../../../../store/paymentData/types";
import { formatCurrencyGBP } from "../../../../../shared/helpers/currency";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Grid } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { Select } from "../../../../../shared/components/filter_controls/Select";
import { getEnumNames } from "../../../../../shared/helpers/enum";
import { PaymentDataReason, Status } from "../../../../../shared/enum/payments";
import { MultiSelect } from "../../../../../shared/components/filter_controls/MultiSelect";
import { MultiTextField } from "../../../../../shared/components/filter_controls/MultiTextField";
import { AppliedFilters } from "../../../../../shared/components/filter_controls/AppliedFilters";
import FileDownload from "js-file-download";
import { formatISOForDisplay } from "../../../../../shared/helpers/dates";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  controls: {
    width: "100%",
    justifyContent: "space-between",
    marginTop: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    "& > *": {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

type Props = {
  batchId: number;
};

export const DetailedTable = ({ batchId }: Props) => {
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const { items, count, fetching } = useSelector(
    (state: RootState) => state.paymentData
  );

  // table controls state - sort, pagination etc.
  // need to hold on to this in state to combine with filter changes
  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<PaymentDataItem>
  >({
    pageNumber: 1,
    pageSize: 10,
    sortDirection: 0,
    sortBy: "assetId",
  });

  const [filters, setFilters] = useState<PaymentDataAPIFilterParams>({});
  const handleUpdateFilters = (
    filterUpdates: Partial<PaymentDataAPIFilterParams>
  ) => {
    setFilters({ ...filters, ...filterUpdates });
  };

  useDeepCompareEffect(() => {
    dispatch(
      requestPaymentData({
        tableParams: tableControls,
        filterParams: filters,
        batchId,
      })
    );
  }, [tableControls, filters, batchId]);

  const handleExport = () => {
    getPaymentDataExcel({
      tableParams: tableControls,
      filterParams: filters,
      batchId,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  const columns: {
    name: keyof PaymentDataItem;
    label: string;
    customBodyRender?: (input: any) => React.ReactNode;
    align?: "left" | "right";
  }[] = [
    { name: "serviceProvider", label: "Provider" },
    { name: "assetId", label: "Asset ID", align: "right" },
    { name: "lineNumber", label: "Line Number", align: "right" },
    { name: "fileName", label: "Filename" },
    { name: "batchId", label: "Batch ID", align: "right" },
    {
      name: "customerName",
      label: "Customer Name",
    },
    { name: "identifier", label: "Identifier" },
    { name: "reason", label: "Reason Code" },
    {
      name: "billDate",
      label: "Bill Date",
      customBodyRender: formatISOForDisplay,
    },
    { name: "status", label: "Status" },
    {
      name: "amountImported",
      label: "Amount Imported",
      customBodyRender: formatCurrencyGBP,
      align: "right",
    },
  ];

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <div className={classes.controls}>
          <Box>
            <Box display="flex" alignItems="center">
              <FilterList fontSize="small" />{" "}
              <span style={{ marginLeft: "5px", fontWeight: "bold" }}>
                Filter by...
              </span>
            </Box>
            <Select
              name="Status"
              id="filter-status"
              options={getEnumNames(Status)}
              selected={filters.status || []}
              onChange={(status) => handleUpdateFilters({ status })}
            />
            <MultiSelect
              name="Reason Code"
              id="filter-reason-code"
              options={getEnumNames(PaymentDataReason)}
              selected={filters.reason || []}
              onChange={(reason) => handleUpdateFilters({ reason })}
            />
            <MultiTextField
              name="Identifier"
              id="filter-identifier"
              selected={filters.identifier || []}
              onChange={(identifier) => handleUpdateFilters({ identifier })}
            />
          </Box>
          <Box mr={0}>
            <Button variant="outlined" onClick={handleExport}>
              Export
            </Button>
          </Box>
        </div>
      </Grid>
      <AppliedFilters
        filters={filters}
        meta={columns}
        updateFilters={handleUpdateFilters}
      />
      <DataTable
        stickyHeader
        limitHeight
        inline
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[10, 15, 30]}
        recordsCount={count}
        onTableControlsChange={setTableControls}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
