import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  FORM_RESET,
  FORM_INITIALIZE_START,
  CreateAssetFormValues,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_INITIALIZE_FINISH,
  InitializeCreateAssetFormPayload,
  SET_OPTIONS_PRIMARY,
  SET_VALUE_PRIMARY,
  SET_OPTIONS_SECONDARY,
  SetFormPrimaryProductAction,
  SetFormSecondaryProductsAction,
  SET_VALUE_SECONDARY,
  SetFormSecondaryOptionsAction,
  PrimaryOption,
  SecondaryProductValue,
} from "./types";

export function initializeFormStart() {
  return {
    type: FORM_INITIALIZE_START,
  };
}

export function initializeFormFinish(
  payload: InitializeCreateAssetFormPayload
) {
  return {
    type: FORM_INITIALIZE_FINISH,
    payload,
  };
}

export function setFormValues(
  values: Partial<CreateAssetFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function setPrimaryProductValue(
  primaryOption: PrimaryOption
): SetFormPrimaryProductAction {
  return {
    type: SET_VALUE_PRIMARY,
    payload: {
      primaryProduct: primaryOption,
    },
  };
}

export function setSecondaryProductsValue(
  secondaryProducts: SecondaryProductValue[]
): SetFormSecondaryProductsAction {
  return {
    type: SET_VALUE_SECONDARY,
    payload: {
      secondaryProducts,
    },
  };
}

export function setPrimaryOptions(payload: PrimaryOption[]) {
  return {
    type: SET_OPTIONS_PRIMARY,
    payload,
  };
}

export function setSecondaryOptions(
  payload: ProductRateByIdApiItem[]
): SetFormSecondaryOptionsAction {
  return {
    type: SET_OPTIONS_SECONDARY,
    payload,
  };
}

export function submitForm(payload: {
  done: (err?: RequestError) => any;
}): FormSubmitAction {
  return { type: FORM_SUBMIT, payload };
}

export function resetForm() {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}
