import { Divider, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Panel } from "../../shared/components/tab_panels/Panel";
import { TabPanelWrapper } from "../../shared/components/tab_panels/TabPanelWrapper";
import { ImportAssetFinancials } from "./import-asset-financials";
import { ImportAssetStatus } from "./import-asset-status";
import { ImportSubscriberId } from "./import-subscriber-id";
import { UpdateProofs } from "./update-proofs";
import { UpdateEndDates } from "./update-end-dates";

export const UpdateAsset = () => {
  const [activeTab, setActiveTab] = useState(0);
  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    selectedTab: number
  ) => {
    setActiveTab(selectedTab);
  };

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        Update Assets
      </Typography>
      <TabPanelWrapper>
        <Tabs
          onChange={handleTabChange}
          aria-label="view product tabs"
          indicatorColor="primary"
          value={activeTab}
          variant="fullWidth"
        >
          <Tab label="Import Financials" {...a11yProps} />
          <Tab label="Update Status" {...a11yProps} />
          <Tab label="Update Subscriber Id" {...a11yProps} />
          <Tab label="Update Proofs" {...a11yProps} />
          <Tab label="Update End Dates" {...a11yProps} />
        </Tabs>
        <Divider />
        <Panel value={activeTab} index={0}>
          <ImportAssetFinancials title="Create ALEs via Template" />
        </Panel>
        <Panel value={activeTab} index={1}>
          <ImportAssetStatus />
        </Panel>
        <Panel value={activeTab} index={2}>
          <ImportSubscriberId />
        </Panel>
        <Panel value={activeTab} index={3}>
          <UpdateProofs />
        </Panel>
        <Panel value={activeTab} index={4}>
          <UpdateEndDates />
        </Panel>
      </TabPanelWrapper>
    </React.Fragment>
  );
};
