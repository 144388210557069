import {
  ConnectionCommissionsForAccrualsReportApiItem,
  ConnectionCommissionsForAccrualsReportParams,
} from "../../api/account-report/generate-connection-commission-for-accruals-report";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestConnectionCommissionsForAccrualsReport(
  params: ConnectionCommissionsForAccrualsReportParams
) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setConnectionCommissionsForAccrualsReportData(
  data: PaginatedResult<ConnectionCommissionsForAccrualsReportApiItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setConnectionCommissionsForAccrualsReportError(
  err: RequestError
) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
