import { request } from "../..";

export type OocPaymentTermItem = {
  oocPaymentTermId: number;
  name: string;
  description: string;
};

export function getOocPaymentTermOptions(): Promise<OocPaymentTermItem[]> {
  return request<OocPaymentTermItem[]>("partner/fetch-ooc-payment-terms");
}
