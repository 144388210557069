import { request } from "..";

export type ProofApiItem = {
  assetId: number;
  proofId: number;
  signatureProof: boolean;
  signatureProofStatus: number;
  contractProof: boolean;
  contractProofStatus: number;
};

export function getProof(id: number) {
  return request<ProofApiItem>(`proof/${id}`);
}

export function putProof(item: ProofApiItem) {
  return request<ProofApiItem>(`proof/${item.assetId}`, {
    method: "PUT",
    body: JSON.stringify(item),
  });
}
