import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Card, Typography } from "@mui/material";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1600,
  },
  actions: {
    display: "flex",
    "& > *": {
      marginRight: "16px !important",
    },
  },
}));

interface Props {
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
}

export const Page = ({ children, title, actions }: Props) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <Box flex={1}>
        <Box padding={2}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Box paddingX={2}>{children}</Box>
      </Box>

      {actions && (
        <React.Fragment>
          <Box padding={2} pt={1} className={classes.actions}>
            {actions}
          </Box>
        </React.Fragment>
      )}
    </Card>
  );
};
