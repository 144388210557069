import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Card,
  Typography,
} from "@mui/material";
import React from "react";
import { QuerySelfBillFilterParams } from "../../../api/self-bill/query";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { paymentTypes } from "../../../shared/enum/payments";
import { DateString, formatISOForDisplay } from "../../../shared/helpers/dates";

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  controls: {
    marginTop: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  chipWrapper: {
    background: theme.palette.grey[100],
    padding: 6,
    borderRadius: 16, // this is the border radius of the contained "chips".  Can't do pill shaped as multiple lines need to be supported.
    width: "100%",
  },
}));

type Props = {
  labels: { name: string; label: string }[];
  filters: QuerySelfBillFilterParams;
  updateFilters: (filters: Partial<QuerySelfBillFilterParams>) => any;
  onResetAll: () => any;
};

export const FilterControls = ({
  labels,
  filters,
  updateFilters,
  onResetAll,
}: Props) => {
  const { classes } = useStyles();

  const getChips = () => {
    const chips: React.ReactNode[] = [];

    for (let filterKey in filters) {
      const filterCategory = filters[filterKey as keyof typeof filters];

      if (Array.isArray(filterCategory) && filterCategory.length > 0) {
        // handle Date ranges
        if (["dateProcessed"].includes(filterKey)) {
          const dateParam = (filterCategory as (DateString | null)[]).filter(
            (x) => x !== null
          );
          if (dateParam.length > 0) {
            const from =
              dateParam[0] !== null &&
              `From: ${formatISOForDisplay(dateParam[0])}`;
            const to =
              dateParam[1] && `To: ${formatISOForDisplay(dateParam[1])}`;
            const labelDates =
              from && to ? [from, to].join(", ") : [from, to].join("");

            chips.push(
              <Chip
                label={`${
                  labels.find((label) => label.name === filterKey)?.label ||
                  filterKey
                } ${labelDates}`}
                key={filterKey}
                onDelete={() => {
                  updateFilters({ [filterKey]: undefined });
                }}
              />
            );
          }
        } else {
          // handle everything else
          (filterCategory as (string | number)[]).forEach((filterValue) => {
            chips.push(
              <Chip
                onDelete={() => {
                  if (filterCategory.length <= 1) {
                    updateFilters({ [filterKey]: undefined });
                  } else {
                    updateFilters({
                      [filterKey]: (filterCategory as (
                        | string
                        | number
                      )[]).filter((value) => value !== filterValue),
                    });
                  }
                }}
                key={`${filterKey}-${filterValue}`}
                label={`${
                  labels.find((label) => label.name === filterKey)?.label ||
                  filterKey
                }: ${filterValue}`}
              />
            );
          });
        }
      }
    }
    return chips;
  };

  const chips = getChips();

  return (
    <React.Fragment>
      <Card className={classes.paper}>
        <Grid container className={classes.container}>
          <Grid item xs={9}>
            <Typography variant="h3">Filters</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box mr={1} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                color="primary"
                onClick={onResetAll}
                size="small"
              >
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.container}>
          <div className={classes.controls}>
            <MultiSelect
              name={
                labels.find((label) => label.name === "type")?.label || "type"
              }
              id="type-control"
              options={paymentTypes.slice()}
              selected={filters.type || []}
              onChange={(type) => updateFilters({ type })}
            />
            <DateRangeControl
              name={`${
                labels.find((label) => label.name === "dateProcessed")?.label ||
                "dateProcessed"
              } Range`}
              id="processed-date-range-filter-control"
              selected={filters.dateProcessed || [null, null]}
              onChange={(dateProcessed) => updateFilters({ dateProcessed })}
            />
          </div>
        </Grid>
        {chips.length > 0 && (
          <Grid className={classes.container} container>
            <div className={classes.chipWrapper}>
              <div className={classes.controls}> {chips}</div>
            </div>
          </Grid>
        )}
      </Card>
    </React.Fragment>
  );
};
