import { call, put, takeLatest } from "redux-saga/effects";
import {
  getPortAndResignReport,
  PortAndResignReportApiItem,
} from "../../api/asset-report/generate-port-and-resign-report";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setPortAndResignReportData,
  setPortAndResignReportErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchPortAndResignReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PortAndResignReportApiItem> = yield call(
      getPortAndResignReport,
      action.payload.params
    );

    yield put(setPortAndResignReportData(data.items));
  } catch (e) {
    let error: RequestError = e;
    yield put(setPortAndResignReportErrorForDisplay(error));
  }
}

export function* watchRequestPortAndResignReportData() {
  yield takeLatest(REQUEST_DATA, fetchPortAndResignReport);
}
