import { request } from "../..";
import { DateString } from "../../../shared/helpers/dates";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type SelfBillListApiItem = {
  invoiceId: number;
  thirdPartyInvoiceId: string;
  reference: string;
  postingDate: string;
  isPostingDateValid: boolean;
  paymentTerms: number;
  partnerCode: string;
  partnerVatStatusQuery: boolean;
  vatRegNo: string;
  amountIncVat: number;
  amountExcVat: number;
  territoryCode: string;
  partnerBlocked: boolean;
  blocked: boolean;
  vatStatusQuery: boolean;
  overrideBlocked: boolean;
  overrideVatStatusQuery: boolean;
  checked: boolean;
  isVatRegNoValid: boolean;
  vatStatusQueryCanOverride: boolean;
  vatStatusQueryCanRemoveOverride: boolean;
  blockedCanOverride: boolean;
  blockedCanRemoveOverride: boolean;
  vatStatusQueryStateEnum: string;
  blockedStateEnum: string;
  canPost: boolean;
};

export type QuerySelfBillListFilterParams = {
  territoryCode?: (string | null)[];
  postingDate?: [DateString | null, DateString | null];
  partnerCode?: (string | null)[];
  vatStatusQuery?: (boolean | null)[];
  blocked?: (boolean | null)[];
  isPostingDateValid?: (boolean | null)[];
};

export interface SelfBillListParams {
  readonly tableControls: TableAPIRequestParams<SelfBillListApiItem>;
  readonly filterParams: QuerySelfBillListFilterParams;
}

export function getSelfBillList(params: SelfBillListParams) {
  const tableControlsQS = stringifyTableParams(params.tableControls);
  const filterParamsQS = stringifyFilterParams(params.filterParams);

  return request<PaginatedResult<SelfBillListApiItem>>(
    `invoice/self-bill-list${combine([tableControlsQS, filterParamsQS])}`
  );
}

export function postUpdatePostingDocumentDate(
  invoiceId: number,
  postingDate: Date
) {
  let utcDate = new Date(
    Date.UTC(
      postingDate.getFullYear(),
      postingDate.getMonth(),
      postingDate.getDate(),
      postingDate.getHours(),
      postingDate.getMinutes(),
      postingDate.getSeconds(),
      postingDate.getMilliseconds()
    )
  ).toISOString();
  return request(
    `invoice/update-posting-document-date/${invoiceId}/${utcDate}`,
    {
      method: "POST",
    }
  );
}

export interface SelfBillListOverrideParams {
  overrideBlocked?: boolean;
  overrideVatStatusQuery?: boolean;
}

export function postUpdateSelfBillOverrides(
  invoiceId: number,
  params: SelfBillListOverrideParams
) {
  return request(`invoice/update-self-bill-overrides/${invoiceId}`, {
    body: JSON.stringify(params),
    method: "POST",
  });
}

export function postSelfBillLines(
  invoiceIds: number[]
) {
  const dto = {
    invoiceIds: invoiceIds
  };
  return request(`invoice/post-self-bill-lines`, {
    method: "POST",
    body: JSON.stringify(dto)
  });
}
