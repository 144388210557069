import { call, put, select, takeLatest } from "redux-saga/effects";
import { getAssetIdsByExternalSourceref } from "../../api/asset/get-asset-ids-by-external-sourceref";
import { postBatchUpdateProofs } from "../../api/proof/batch-update-proofs";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setFormError, setFormSuccess, setFormValues } from "./actions";
import {
  FormUpdateProofsState,
  FORM_SUBMIT,
  RequestAssetIdsAction,
  REQUEST_ASSET_IDS,
} from "./types";

function* submitUpdateProofs() {
  try {
    const formState: FormUpdateProofsState = yield select(
      (state: RootState) => state.formUpdateProofs
    );

    yield call(postBatchUpdateProofs, formState.formValues);
    yield put(setFormSuccess());
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setFormError(error));
  }
}

function* handleRequestAssetIds(action: RequestAssetIdsAction) {
  try {
    const assetIds: number[] = yield call(getAssetIdsByExternalSourceref, [
      action.payload.sourceRef,
    ]);
    yield put(
      setFormValues({
        assetIds,
      })
    );
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setFormError(error));
  }
}

export function* watchRequestAssetIds() {
  yield takeLatest(REQUEST_ASSET_IDS, handleRequestAssetIds);
}

export function* watchSubmitUpdateProofs() {
  yield takeLatest(FORM_SUBMIT, submitUpdateProofs);
}
