import { request } from "../..";
import { ProductGroupApi } from "../product-group";

export function getFilteredProductGroups(
  serviceProviderId: number,
  productTypeId: number
) {
  return request<ProductGroupApi[]>(
    `product/product-group-filtered/${serviceProviderId}/${productTypeId}`
  );
}
