import React, { createRef, useEffect, useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { DatePicker } from "@mui/x-date-pickers";
import { toDateString } from "../../../shared/helpers/dates";
import { getServiceProviderId } from "../../../shared/enum/serviceProvider";
import {
  formInitializeStart,
  resetForm,
  setFormValues,
  setProductTypeId,
  setServiceProviderRate,
  submitForm,
} from "../../../store/createPartnerRateForm/actions";
import { DecimalInput } from "../../../shared/components/DecimalInput";
import { RequestError } from "../../../api/types";
import { ServiceProviderDistinctProductTypeOption } from "../../../api/service-provider/distinct-products";
import { CreatePartnerRateFormState } from "../../../store/createPartnerRateForm/types";

type Props = {
  partnerId: number;
  serviceProvider: "O2" | "EE" | "Vodafone";
  // setOpen?: () => void;
  // open?: boolean;
};

export const CreatePartnerRateModal = ({
  serviceProvider,
  partnerId,
}: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const formRef = createRef<HTMLFormElement>();

  const {
    fetching,
    values,
    distinctProductTypeOptions,
    requestError,
    tiers,
  } = useSelector<RootState, CreatePartnerRateFormState>(
    (state: RootState) => state.createPartnerRateForm
  );

  useEffect(() => {
    if (open) {
      dispatch(formInitializeStart(getServiceProviderId(serviceProvider)));
    }
  }, [serviceProvider, open, dispatch]);

  const onCancel = () => {
    setOpen(false);
    dispatch(resetForm());
  };

  return (
    <ModalFieldWrapper
      label="Add Revshare Rate Adjustment"
      onOpen={() => setOpen(true)}
      onCancel={onCancel}
      onSave={() => {
        const serviceProviderId = getServiceProviderId(serviceProvider);
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              serviceProviderId,
              done: (err?: RequestError) =>
                err ? reject(err) : resolve(window.location.reload()),
            })
          );
        });
      }}
      id="create-partner-rate"
      gutterSize="small"
      disableSave={
        values.startDate === "" ||
        values.endDate === "" ||
        fetching ||
        values.serviceProviderRateId === 0
      }
      apiError={requestError}
      apiFetching={fetching}
      formRef={formRef}
      buttonLabel="Add"
      buttonVariant="contained"
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider"
            select
            disabled
            label="Service Provider"
            value={getServiceProviderId(serviceProvider)}
          >
            <MenuItem
              key={serviceProvider}
              value={getServiceProviderId(serviceProvider)}
            >
              {serviceProvider}
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-product-type"
            select
            label="Select Product Type"
            value={values.productTypeId}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const productTypeId = parseInt(event.target.value);
              dispatch(
                setProductTypeId(
                  productTypeId,
                  getServiceProviderId(serviceProvider)
                )
              );
            }}
          >
            {distinctProductTypeOptions ? (
              distinctProductTypeOptions.map(
                (option: ServiceProviderDistinctProductTypeOption) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                )
              )
            ) : (
              <></>
            )}
          </TextField>
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            variant="outlined"
            id="select-provider-rate"
            select
            label="Tier"
            value={values.tier}
            disabled={tiers.length === 0}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const tier = parseInt(event.target.value);
              dispatch(
                setServiceProviderRate(
                  getServiceProviderId(serviceProvider),
                  values.productTypeId,
                  tier
                )
              );
            }}
          >
            {tiers.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl variant="outlined" size="small" fullWidth>
          <DecimalInput
            disabled={true}
            size="small"
            label="Standard Rate"
            variant="outlined"
            value={values.standardRate}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DecimalInput
            disabled={true}
            size="small"
            label="Received Rate"
            variant="outlined"
            value={values.receivedRate}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DecimalInput
            size="small"
            label="Adjustment Amount"
            variant="outlined"
            value={values.adjustment}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  adjustment: parseFloat(event.target.value),
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Adjustment Start Date"
            format="dd/MM/yyyy"
            value={!!values.startDate ? new Date(values.startDate) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  startDate: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Adjustment End Date"
            format="dd/MM/yyyy"
            value={!!values.endDate ? new Date(values.endDate) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  endDate: value ? toDateString(value) : undefined,
                })
              );
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
