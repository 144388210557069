import {
  PartnerAdminInformationReportApiItem,
  PartnerAdminInformationReportParams,
} from "../../api/partner-report/generate-partner-admin-info-report";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "partnerAdminInformationReport.REQUEST_DATA";
export const SET_DATA = "partnerAdminInformationReport.SET_DATA";
export const SET_ERROR = "partnerAdminInformationReport.SET_ERROR";

export type PartnerAdminInformationReportState = {
  items: PartnerAdminInformationReportApiItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: PartnerAdminInformationReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<PartnerAdminInformationReportApiItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PartnerAdminInformationReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
