import { call, put, takeLatest } from "redux-saga/effects";
import { getPermissions, PermissionApiItem } from "../../api/permission";
import { setPermissions } from "./actions";
import { REQUEST_DATA } from "./types";

function* fetchPermissionsData() {
  try {
    const data: PermissionApiItem[] = yield call(getPermissions);
    yield put(setPermissions(data));
  } catch (e) {
    // let error: RequestError = e;
    // yield put(setQueryAlesErrorForDisplay(error));
  }
}

export function* watchRequestPermissionsData() {
  yield takeLatest(REQUEST_DATA, fetchPermissionsData);
}
