import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import { RequestDataAction, REQUEST_DATA } from "./types";
import {
  PartnerRateAdjustmentReportApiItem,
  postGeneratePartnerRateAdjustmentReport,
} from "../../api/partner-report/generate-partner-rate-adjustment-report";
import {
  setPartnerRateAdjustmentReportData,
  setPartnerRateAdjustmentReportError,
} from "./actions";

function* fetchPartnerRateAdjustmentReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PartnerRateAdjustmentReportApiItem> =
      yield call(
        postGeneratePartnerRateAdjustmentReport,
        action.payload.params
      );

    yield put(setPartnerRateAdjustmentReportData(data));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setPartnerRateAdjustmentReportError(error));
  }
}

export function* watchPartnerRateAdjustmentReport() {
  yield takeLatest(REQUEST_DATA, fetchPartnerRateAdjustmentReport);
}
