import React from "react";
import { useDispatch } from "react-redux";
import { RequestError } from "../../../api/types";
import { ModalDeleteWrapper } from "../../../shared/components/modal_field_controls/ModalDeleteWrapper";
import { deleteProductRate } from "../../../store/detailProducts/actions";

type Props = {
  productId: number;
  productRateId: number;
};

export const DeleteProductRateModal = ({ productId, productRateId }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalDeleteWrapper
      label="Delete Product Rate"
      message={() => (
        <p>
          Do you really wish to delete product rate: <b>{productRateId}</b>?
        </p>
      )}
      onDelete={() => {
        return new Promise((resolve) => {
          dispatch(
            deleteProductRate(productId, productRateId, (err?: RequestError) =>
              resolve(err)
            )
          );
        });
      }}
      id="delete-product-rate"
    />
  );
};
