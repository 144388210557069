import { QueryProductApiItem, UpdateProductApiItem } from "../../api/product";
import { ProductGroupApi } from "../../api/product/product-group";
import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { ProductTypeOption } from "../../api/product/product-types";
import { SpendCapPeriodItem } from "../../api/spend-cap-period/by-productId-date";
import { RequestError } from "../../api/types";
import { EditProductRateFormBaseValues } from "../editProductRateForm/types";
import {
  DELETE_PRODUCT_RATE,
  REQUEST_DATA,
  REQUEST_UPDATE_ITEM,
  SET_API_ERROR,
  SET_DATA,
  SET_SECONDARY_PRODUCT,
  SET_TAB,
  UPDATE_ITEM_DATA,
  UPDATE_PRODUCT_RATE,
  SET_ACTIVE_PERIOD,
} from "./types";

export function requestDetailProductData(id: number) {
  return {
    type: REQUEST_DATA,
    payload: id,
  };
}

export function setDetailProductRequestError(
  error: { id: number } & RequestError
) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setDetailProductData(data: {
  primaryProduct: QueryProductApiItem;
  secondaryProducts: QueryProductApiItem[];
  primaryRates: ProductRateByIdApiItem[];
  secondaryRates: ProductRateByIdApiItem[];
  spendCaps: SpendCapPeriodItem[];
  productTypeOptions: ProductTypeOption[];
  durationOptions: string[];
  productGroupOptions: ProductGroupApi[];
  linkedToAsset: boolean;
}) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function requestUpdateProduct(
  id: number,
  updates: Partial<UpdateProductApiItem>,
  done: () => any
) {
  return {
    type: REQUEST_UPDATE_ITEM,
    payload: { id, updates, done },
  };
}

export function updateItemData(
  id: number,
  updates: Partial<UpdateProductApiItem>
) {
  return {
    type: UPDATE_ITEM_DATA,
    payload: { id, updates },
  };
}

export function updateProductRate(productRate: EditProductRateFormBaseValues) {
  return {
    type: UPDATE_PRODUCT_RATE,
    payload: {
      productRate,
    },
  };
}

export function deleteProductRate(
  productId: number,
  productRateId: number,
  done: () => any
) {
  return {
    type: DELETE_PRODUCT_RATE,
    payload: {
      productId,
      productRateId,
      done,
    },
  };
}

export function setActiveTab(tabIndex: number) {
  return {
    type: SET_TAB,
    payload: {
      tabIndex,
    },
  };
}

export function setActiveSecondaryProduct(activeId?: number) {
  return {
    type: SET_SECONDARY_PRODUCT,
    payload: {
      activeId,
    },
  };
}

export function setActiveSpendCapPeriod(activePeriodId?: number) {
  return {
    type: SET_ACTIVE_PERIOD,
    payload: {
      activePeriodId,
    },
  };
}
