import React from "react";
import { Typography } from "@mui/material";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Payments } from "./payments";
import { NoMatch } from "../../nav/components/NoMatch";

export const Receivables = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        Receivables
      </Typography>
      <Switch>
        <Route path={`${path}/payments`}>
          <Payments />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
