import { RequestError } from "../../api/types";

export const SUBMIT = "deleteSpendCapForm.SUBMIT";

export interface DeleteProductSpendCapAction {
  type: typeof SUBMIT;
  payload: {
    productId: number;
    spendCapId: number;
    done: (err?: RequestError) => any;
  };
}
