import { RequestError } from "../../api/types";

export const RESET_FORM_VALUES = "bulkEditPartnerRateForm.RESET_FORM_VALUES";
export const SET_FORM_VALUES = "bulkEditPartnerRateForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "bulkEditPartnerRateForm.SET_FORM_ERROR";
export const SET_FORM_CLIENT_ERROR =
  "bulkEditPartnerRateForm.SET_FORM_CLIENT_ERROR";
export const FORM_SUBMIT = "bulkEditPartnerRateForm.FORM_SUBMIT";
export const FORM_INITIALIZE_START =
  "bulkEditPartnerRateForm.FORM_INITIALIZE_START";

export type BulkEditPartnerRateFormValues = {
  adjStartDate: string;
  adjEndDate: string;
};

export type BulkEditPartnerRateFormState = {
  values: BulkEditPartnerRateFormValues;
  error?: RequestError;
  clientError?: string;
  isFetching: boolean;
};

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<BulkEditPartnerRateFormValues>;
  };
}

export interface InitFormAction {
  type: typeof FORM_INITIALIZE_START;
  payload: {
    partnerId: number;
    partnerRateAdjustmentIds: number[];
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    partnerId: number;
    partnerRateAdjustmentIds: number[];
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    partnerRateAdjustmentId: number;
    error: RequestError;
  };
}

export interface SetFormClientErrorAction {
  type: typeof SET_FORM_CLIENT_ERROR;
  payload: {
    clientError: string;
  };
}

export interface ResetFormValuesAction {
  type: typeof RESET_FORM_VALUES;
}

export type BulkEditPartnerRateActions =
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | SetFormClientErrorAction
  | ResetFormValuesAction;
