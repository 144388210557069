import { call, put, takeLatest } from "redux-saga/effects";
import { getQueryAle, QueryAleAPiItem } from "../../api/asset-ledger-entry";
import { getRevshareReceivedDrilldown } from "../../api/asset-ledger-entry/revshare-received";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setQueryAlesData,
  setQueryAlesErrorForDisplay,
  setRevshareReceivedData,
} from "./actions";
import {
  RequestDataAction,
  RequestRevshareReceivedDataAction,
  REQUEST_DATA,
  REQUEST_REVSHARE_RECEIVED_DATA,
} from "./types";

function* fetchQueryAles(action: RequestDataAction) {
  try {
    const data: PaginatedResult<QueryAleAPiItem> = yield call(
      getQueryAle,
      action.payload.assetId,
      action.payload.aleTypeId
    );
    yield put(
      setQueryAlesData({
        items: data,
        aleTypeId: action.payload.aleTypeId,
      })
    );
  } catch (e) {
    let error: RequestError = e;
    yield put(setQueryAlesErrorForDisplay(error));
  }
}

function* fetchRevshareReceivedDrilldown(
  action: RequestRevshareReceivedDataAction
) {
  try {
    const data: PaginatedResult<QueryAleAPiItem> = yield call(
      getRevshareReceivedDrilldown,
      action.payload.assetId
    );
    yield put(setRevshareReceivedData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setQueryAlesErrorForDisplay(error));
  }
}

export function* watchRequestQueryAlesData() {
  yield takeLatest(REQUEST_DATA, fetchQueryAles);
}

export function* watchRequestRevshareReceivedDrilldown() {
  yield takeLatest(
    REQUEST_REVSHARE_RECEIVED_DATA,
    fetchRevshareReceivedDrilldown
  );
}
