import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FilterControlWrapper } from "./FilterControlWrapper";
import { isBefore, parseISO, isValid, isEqual, isAfter } from "date-fns";
import { DateString, toDateString } from "../../helpers/dates";

type Props = {
  name: string;
  id: string;
  selected: [DateString | null, DateString | null];
  onChange: (
    dateRange: [DateString | null, DateString | null] | undefined
  ) => any;
};

export const DateRangeControl = ({ name, id, selected, onChange }: Props) => {
  const from = selected[0] ? parseISO(selected[0]) : null;
  const to = selected[1] ? parseISO(selected[1]) : null;

  const [inputFrom, setInputFrom] = useState<Date | null>(from);
  const [inputTo, setInputTo] = useState<Date | null>(to);

  useEffect(() => {
    setInputFrom(selected[0] ? parseISO(selected[0]) : null);
    setInputTo(selected[1] ? parseISO(selected[1]) : null);
  }, [selected]);

  let count = selected.filter((x) => x).length ? 1 : 0;

  const handleReset = () => onChange(undefined);
  const isFromInputValid = (input: Date | null) => {
    if (!isValid(input)) {
      return false;
    }

    if (to) {
      return isBefore(input as Date, to) || isEqual(input as Date, to);
    }

    return true;
  };

  const isToInputValid = (input: Date | null) => {
    if (!isValid(input)) {
      return false;
    }

    if (from) {
      return isAfter(input as Date, from) || isEqual(input as Date, from);
    }

    return true;
  };

  return (
    <FilterControlWrapper
      id={id}
      name={name}
      type="date"
      count={count}
      onReset={handleReset}
    >
      <Box display="flex">
        <Box marginRight={0.5}>
          <DatePicker
            format="dd/MM/yyyy"
            label="from"
            value={inputFrom}
            onChange={(input) => {
              setInputFrom(input);
              if (!input) {
                onChange([null, selected[1]]);
              } else if (isFromInputValid(input)) {
                try {
                  const dateString = toDateString(input || "");
                  onChange([dateString, selected[1]]);
                } catch (e) {
                  console.error(e);
                }
              }
            }}
          />
        </Box>
        <Box marginLeft={0.5}>
          <DatePicker
            format="dd/MM/yyyy"
            label="to"
            value={inputTo}
            onChange={(input) => {
              setInputTo(input);
              if (!input) {
                onChange([selected[0], null]);
              } else if (isToInputValid(input)) {
                try {
                  const dateString = toDateString(input || "");
                  onChange([selected[0], dateString]);
                } catch (e) {
                  console.error(e);
                }
              }
            }}
          />
        </Box>
      </Box>
    </FilterControlWrapper>
  );
};
