import { makeStyles } from "tss-react/mui";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { PartnerServiceProvider } from "../../../api/partner";
import { EditPartnerServiceProviderModal } from "./EditPartnerServiceProviderModal";

type Props = {
  data: PartnerServiceProvider[];
};

const useStyles = makeStyles()(() => ({
  dealerCode: {
    maxWidth: 150,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export const LinkedServiceProvidersTable = ({ data }: Props) => {
  const { classes } = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Service Provider</TableCell>
          <TableCell>Account Type</TableCell>
          <TableCell>Service Provider Code</TableCell>
          <TableCell>Enabled</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((sp) => (
          <TableRow>
            <TableCell>{sp.serviceProviderName}</TableCell>
            <TableCell>{sp.serviceProviderAccountTypeName}</TableCell>
            <TableCell title={sp.spDealerCode} className={classes.dealerCode}>
              {sp.spDealerCode}
            </TableCell>
            <TableCell>
              <Checkbox color="primary" disabled checked={sp.enabled} />
            </TableCell>
            <TableCell align="right" sx={{ paddingRight: 0 }}>
              <EditPartnerServiceProviderModal partnerServiceProvider={sp} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
