import { List } from "@mui/material";
import {
  AccountTree,
  Contacts,
  Description,
  DoubleArrow,
  FormatListBulleted,
  MonetizationOn,
  MoveToInbox,
  ShoppingBasket,
  Calculate,
} from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import { getPermission } from "../store/permissions/reducers";
import { Permissions } from "../store/permissions/types";
import { NavGroup } from "./components/NavGroup";
import { NavGroupItem } from "./components/NavGroupItem";

export const NavMenu = () => {
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));
  return (
    <List component="nav">
      <NavGroup
        title="Assets"
        disabled={!useSelector(getPermission(Permissions.AssetsMenu))}
        icon={<AccountTree fontSize="small" />}
      >
        <NavGroupItem to="/assets">View Assets</NavGroupItem>
        {canWriteAssets && (
          <>
            <NavGroupItem to="/assets/update">Update Assets</NavGroupItem>
          </>
        )}
      </NavGroup>
      <NavGroup
        title="Products"
        disabled={!useSelector(getPermission(Permissions.ProductsMenu))}
        icon={<ShoppingBasket fontSize="small" />}
      >
        <NavGroupItem to={"/products/primary"}>Primary Products</NavGroupItem>
        <NavGroupItem to={"/products/generate"}>Generate Products</NavGroupItem>
        <NavGroupItem to="/products/import">Import Products</NavGroupItem>
      </NavGroup>
      <NavGroup
        title="Partners"
        // disabled={!useSelector(getPermission(Permissions.ProductsMenu))}
        icon={<Contacts fontSize="small" />}
      >
        <NavGroupItem to={"/partners"}>View Partners</NavGroupItem>
      </NavGroup>
      <NavGroup
        title="Receivables"
        disabled={!useSelector(getPermission(Permissions.ReceivableMenu))}
        icon={<MoveToInbox fontSize="small" />}
      >
        <NavGroupItem to="/receivables/payments/import-revshare">
          Import Revshare Payments
        </NavGroupItem>
        <NavGroupItem to="/receivables/payments/import-upfront">
          Import Upfront Payments
        </NavGroupItem>
        <NavGroupItem to="/receivables/payments/process-revshare">
          View Existing RevShare Payments
        </NavGroupItem>
        <NavGroupItem to="/receivables/payments/process-upfront">
          View Existing Upfront Payments
        </NavGroupItem>
      </NavGroup>
      <NavGroup
        title="Self Bill"
        disabled={!useSelector(getPermission(Permissions.PayableMenu))}
        icon={<Description fontSize="small" />}
      >
        <NavGroupItem to="/self-bill/generate-revshare">
          Generate Revshare
        </NavGroupItem>
        <NavGroupItem to="/self-bill/generate-upfront">
          Generate Upfront
        </NavGroupItem>
        <NavGroupItem to="/self-bill/list">Self Bill List</NavGroupItem>
        <NavGroupItem to="/self-bill/credit-list">
          Posted SB Credit List
        </NavGroupItem>
        <NavGroupItem to="/self-bill/invoice-list">
          Posted SB Invoice List
        </NavGroupItem>
      </NavGroup>
      <NavGroup title="Reports" icon={<FormatListBulleted fontSize="small" />}>
        <NavGroup
          menuLevel={1}
          title="Assets"
          icon={<AccountTree fontSize="small" />}
        >
          <NavGroupItem to="/financials-report">
            Asset Financials Report
          </NavGroupItem>
          <NavGroupItem to="/port-and-resign-report">
            Port And Resign Report
          </NavGroupItem>
          <NavGroupItem to="/mpn-level-asset-report">
            MPN Level Asset Report
          </NavGroupItem>
        </NavGroup>
        <NavGroup
          menuLevel={1}
          title="Products"
          icon={<ShoppingBasket fontSize="small" />}
        >
          <NavGroupItem to="/primary-product-report">
            Primary Product Report
          </NavGroupItem>
          <NavGroupItem to="/secondary-product-report">
            Secondary Product - Asset Level Report
          </NavGroupItem>
          <NavGroupItem to="/spend-cap-report">Spend Caps Report</NavGroupItem>
        </NavGroup>
        <NavGroup
          menuLevel={1}
          title="Financial"
          icon={<MonetizationOn fontSize="small" />}
        >
          <NavGroupItem to="/deferred-cost-report">
            Deferred Cost Report
          </NavGroupItem>
          <NavGroupItem to="/outstanding-commissions-report">
            Outstanding Commissions Report
          </NavGroupItem>
          <NavGroupItem to="/unraised-revshare-report">
            Unraised Revshare Report
          </NavGroupItem>
          <NavGroupItem to="/discon-rec-up-value-report">
            Discon Rec Up Value Report
          </NavGroupItem>
        </NavGroup>
        <NavGroup
          menuLevel={1}
          title="Processing"
          icon={<DoubleArrow fontSize="small" />}
        >
          <NavGroupItem to="/progress">Progress Page</NavGroupItem>
          <NavGroupItem to="/interface-report">
            Asset Interface Report
          </NavGroupItem>
        </NavGroup>
        <NavGroup
          menuLevel={1}
          title="Partners"
          icon={<Contacts fontSize="small" />}
        >
          <NavGroupItem to="/partner-admin-information-report">
            Partner Admin Information Report
          </NavGroupItem>
          <NavGroupItem to="/partner-rate-adjustment-report">
            Partner Rate Adj. Report
          </NavGroupItem>
        </NavGroup>
        <NavGroup
          menuLevel={1}
          title="Accounts"
          icon={<Calculate fontSize="small" />}
        >
          <NavGroupItem to="/connection-commission-for-accruals-report">
            Connection Commissions For Accruals Report
          </NavGroupItem>
        </NavGroup>
      </NavGroup>
    </List>
  );
};
