import { makeStyles } from "tss-react/mui";

import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailPartnerApiItem, PartnerRate } from "../../../api/partner";
import { EditableField } from "../../../shared/components/EditableField";
import { Panel } from "../../../shared/components/tab_panels/Panel";
import { TabPanelWrapper } from "../../../shared/components/tab_panels/TabPanelWrapper";
import {
  getServiceProviderId,
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { PartnerStatus, PaymentTerms } from "../../../shared/enum/invoice";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { formatDecimal } from "../../../shared/helpers/numbers";
import {
  CHANGE_TAB,
  DetailPartnersState,
} from "../../../store/detailPartners/types";
import { RootState } from "../../../store/rootReducer";
import { CreatePartnerRateModal } from "./CreatePartnerRateModal";
import { EditPartnerRateModal } from "./EditPartnerRateModal";
import { BulkEditPartnerRateModal } from "./BulkEditPartnerRateModal";
import { CreatePartnerServiceProviderModal } from "./CreatePartnerServiceProviderModal";
import { LinkedServiceProvidersTable } from "./LinkedServiceProvidersTable";
import { OutOfContractTable } from "./OutOfContractTable";
import { CreatePartnerNoteModal } from "./CreatePartnerNoteModal";
import { CommentsTable } from "./CommentsTable";
import EditableFieldWithDropdown from "../../../shared/components/EditableFieldWithDropdown";
import { requestSelfBillToPartnersData } from "../../../store/querySelfBillToPartners/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { useHistory, useRouteMatch } from "react-router-dom";
import { requestTotalSBAmountOfAssetChangesDueData } from "../../../store/queryAssetChangesDue/actions";
import { postUpdatePartnerGeneralInfoRequest } from "../../../store/updatePartnerGeneralInfo/actions";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { DetailPartnerGeneralInfoType } from "../../../store/updatePartnerGeneralInfo/types";
import { postUpdateSelfBillToPartnerRequest } from "../../../store/updateSelfBillToPartner/actions";

type Props = {
  data: DetailPartnerApiItem;
};

const useStyles = makeStyles()(() => ({
  serviceProviderRow: {
    backgroundColor: "#fafafa",
    cursor: "pointer",
  },
  serviceProviderCell: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  rates: {
    "&:nth-of-type(even)": {
      backgroundColor: "#fcfcfc",
    },
  },
  cell: {
    width: "11.1111%",
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  checkbox: {
    marginLeft: -300,
  },
}));

interface ICustomCheckboxProps {
  row: PartnerRate;
  sp: ServiceProvider;
}

type ICheckedState = { [key: string]: number[] };

export const PartnerCard = ({ data }: Props) => {
  const initialCheckedState: ICheckedState = {
    EE: [],
    O2: [],
    Vodafone: [],
  };

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [expandedProviders, setExpandedProviders] = useState<ServiceProvider[]>(
    []
  );
  const [checkedState, setCheckedState] = useState<ICheckedState>(
    initialCheckedState
  );

  const [doNotSelfBill, setDoNotSelfBill] = useState(data.doNotSelfBill);

  const { mainTabIndex } = useSelector<RootState, DetailPartnersState>(
    (state: RootState) => state.detailPartners
  );

  const [confirmDoNotSelfBillStatus, setConfirmDoNotSelfBillStatus] = useState<
    boolean
  >(false);
  const [pendingDoNotSelfBillValue, setPendingDoNotSelfBillValue] = useState<
    boolean
  >(false);

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, tabIndex: number) => {
    dispatch({
      type: CHANGE_TAB,
      payload: { tabIndex, tabName: "main" },
    });
  };

  const handleOpen = (serviceProvider: ServiceProvider) => {
    if (expandedProviders.includes(serviceProvider)) {
      setExpandedProviders(
        expandedProviders.filter((sp) => sp !== serviceProvider)
      );
    } else {
      setExpandedProviders([...expandedProviders, serviceProvider]);
    }
  };

  const updateChecked = (
    row: PartnerRate,
    sp: ServiceProvider,
    checked: boolean
  ) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      for (const serviceProvider of Object.keys(prevState)) {
        if (sp === serviceProvider) {
          if (checked) {
            newState[serviceProvider] = [
              ...newState[serviceProvider],
              row.partnerRateAdjustmentId,
            ];
          } else {
            newState[serviceProvider] = [
              ...newState[serviceProvider].filter(
                (i) => i !== row.partnerRateAdjustmentId
              ),
            ];
          }
        }
      }

      return newState;
    });
  };

  const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({ row, sp }) => {
    if (new Date(row.adjEndDate) < new Date()) return null;
    return (
      <Checkbox
        color="primary"
        data-cy={`filter-item-checkbox-${row.partnerRateAdjustmentId}`}
        checked={checkedState[sp].includes(row.partnerRateAdjustmentId)}
        onChange={(e) => {
          updateChecked(row, sp, e.target.checked);
        }}
      />
    );
  };
  // routing
  const { url } = useRouteMatch();
  const history = useHistory();
  const navToAssetChanges = () => {
    history.push({
      pathname: `${url}/assetchanges`,
      state: {
        partnerId: data.partnerId,
        partnerName: data.partnerName,
        thirdPartyId: data.thirdPartyId,
      },
    });
  };
  const {
    totalSBAmountOfAssetChangesDue: {
      totalSbAmountFetching,
      totalSbAmountError,
      totalSBAmount,
    },
    selfBillToPartnersData: {
      selfBillPartners,
      selfBillToPartnersLoading,
      selfBillToPartnersError,
    },
  } = useSelector((state: RootState) => ({
    totalSBAmountOfAssetChangesDue: state.totalSBAmountForAssetChangesDue,
    selfBillToPartnersData: state.selfBillToPartners,
  }));
  const { doNotSelfBillError } = useSelector<
    RootState,
    DetailPartnerGeneralInfoType
  >((state) => state.updatePartnerGeneralInfo);

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newPartnerId = event.target.value;
    dispatch(
      postUpdateSelfBillToPartnerRequest({
        partnerId: data.partnerId,
        selfBillToPartnerId: parseInt(newPartnerId, 10),
      })
    );
  };
  const handleClearSelfBillToPartner = () => {
    dispatch(
      postUpdateSelfBillToPartnerRequest({
        partnerId: data.partnerId,
        selfBillToPartnerId: null,
      })
    );
  };
  useEffect(() => {
    dispatch(requestSelfBillToPartnersData({ partnerId: data.partnerId }));
  }, [dispatch, data.partnerId]);

  useEffect(() => {
    dispatch(requestTotalSBAmountOfAssetChangesDueData(data.partnerId));
  }, [dispatch, data.partnerId]);
  const handleDoNotSelfBillChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedValue = event.target.checked;
    setPendingDoNotSelfBillValue(updatedValue);
    setConfirmDoNotSelfBillStatus(true);
  };

  const handleConfirmDoNotSelfBillChange = () => {
    try {
      setDoNotSelfBill(pendingDoNotSelfBillValue);
      const generalInfoUpdate = {
        partnerId: data.partnerId,
        doNotSelfBill: pendingDoNotSelfBillValue,
      };
      dispatch(postUpdatePartnerGeneralInfoRequest(generalInfoUpdate));
      setConfirmDoNotSelfBillStatus(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Box>
      <TabPanelWrapper>
        <Tabs
          onChange={handleTabChange}
          aria-label="view partner tabs"
          indicatorColor="primary"
          value={mainTabIndex}
          variant="fullWidth"
        >
          <Tab label="General" {...a11yProps} />
          <Tab label="Revshare Rate Adjustments" {...a11yProps} />
          <Tab label="Service Providers" {...a11yProps} />
          <Tab label="Comments" {...a11yProps} />
        </Tabs>
        <Divider />
        <Panel value={mainTabIndex} index={0}>
          <List>
            <ListItem divider>
              <EditableField label="Partner ID" value={data.partnerId} />
            </ListItem>
            <ListItem divider>
              <EditableField label="Partner Name" value={data.partnerName} />
            </ListItem>
            <ListItem divider>
              <EditableField label="Partner Code" value={data.thirdPartyId} />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Partner Status"
                value={PartnerStatus[data.partnerStatus]}
              />
              <EditableField label="Blocked" value={false}>
                <Checkbox
                  color="primary"
                  disabled={true}
                  className={classes.checkbox}
                  checked={data.blocked}
                />
              </EditableField>
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Payment Terms"
                value={PaymentTerms[data.paymentTerms]}
              />
              <EditableField label="Do Not Self Bill" value={false}>
                <Checkbox
                  color="primary"
                  checked={doNotSelfBill}
                  className={classes.checkbox}
                  onChange={handleDoNotSelfBillChange}
                />
              </EditableField>
            </ListItem>
            <ListItem divider>
              <EditableField label="BDM" value={data.bdm} />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Network Billed Partner Manager"
                value={data.ibdm}
              />
            </ListItem>
            <ListItem divider>
              <EditableField label="Territory" value={data.territory} />
            </ListItem>
            <ListItem>
              <EditableField label="Contact Name" value={data.contactName} />
            </ListItem>
            <ListItem>
              <EditableField label="Contact Email" value={data.contactEmail} />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="Contact Number"
                value={data.contactNumber}
              />
            </ListItem>
            <ListItem divider>
              <EditableField
                label="VAT Registration No."
                value={data.vatRegNo}
                valueStyle={{
                  color: !data.isVatRegNumnberValid ? "red" : "inherit",
                }}
                tooltipText={
                  !data.isVatRegNumnberValid
                    ? "VAT number is invalid.  Please update with a valid VAT value of either N/A or GB followed by 9 digits."
                    : ""
                }
              />
              <EditableField label="VAT Status Query" value={false}>
                <Checkbox
                  color="primary"
                  disabled={true}
                  className={classes.checkbox}
                  checked={data.vatStatusQuery}
                />
              </EditableField>
            </ListItem>
            <ListItem divider>
              <EditableFieldWithDropdown
                items={selfBillPartners}
                selectedItem={data?.selfBillToPartner?.partnerId}
                handleDropdownChange={handleDropdownChange}
                handleClearItem={handleClearSelfBillToPartner}
                error={selfBillToPartnersError}
                loading={selfBillToPartnersLoading}
                label="Self Bill To Partner Code"
                labelForDropdown="Select Partner Code"
                getItemKey={(item) => item.partnerId}
                getItemFirstValueToDisplay={(item) => item.partnerName}
                getItemSecondValueToDisplay={(item) => item.partnerCode}
              />
            </ListItem>
            <ListItem>
              <EditableField
                label="Asset Changes Due"
                value={formatCurrencyGBP(totalSBAmount)}
                onValueClick={() => navToAssetChanges()}
                loading={totalSbAmountFetching}
                error={totalSbAmountError}
              />
            </ListItem>
          </List>
        </Panel>
        <ConfirmationModal
          open={confirmDoNotSelfBillStatus}
          title=""
          text="You have selected to change the do not self bill status of this partner are you sure you want to proceed?"
          confirmButtonText="Save"
          onClose={() => setConfirmDoNotSelfBillStatus(false)}
          onConfirm={handleConfirmDoNotSelfBillChange}
        />
        {doNotSelfBillError && (
          <Box marginBottom={2}>
            <CustomStatusChip
              title="Error"
              message={doNotSelfBillError.message}
              type="error"
            />
          </Box>
        )}
        <Panel value={mainTabIndex} index={1}>
          <Table aria-label="partner-rates">
            <TableBody>
              {serviceProviders.map((sp) => {
                const rates = data.rates.filter(
                  (r) => r.serviceProviderId === getServiceProviderId(sp)
                );
                const open = expandedProviders.includes(sp);

                if (rates.length > 0) {
                  return (
                    <>
                      <TableRow
                        className={classes.serviceProviderRow}
                        onClick={() => handleOpen(sp)}
                      >
                        <TableCell
                          className={classes.serviceProviderCell}
                          colSpan={6}
                        >
                          <Typography variant="h4">{sp}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="expand row" size="small">
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{ padding: 0, border: 0 }}
                        >
                          <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit
                            collapsedSize={0}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell />
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Tier</b>
                                  </TableCell>
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Product Type</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Received Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Standard Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adjustment</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {checkedState[sp].length > 0 ? (
                                      <BulkEditPartnerRateModal
                                        partnerId={data.partnerId}
                                        partnerRateAdjustmentIds={
                                          checkedState[sp]
                                        }
                                      />
                                    ) : (
                                      <CreatePartnerRateModal
                                        partnerId={data.partnerId}
                                        serviceProvider={sp}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                                {rates.map((r, i) => {
                                  const disabled =
                                    new Date(r.adjEndDate) < new Date();
                                  const columnStyle = {
                                    fontWeight: disabled ? 200 : 400,
                                  };

                                  return (
                                    <TableRow className={classes.rates} key={i}>
                                      <TableCell>
                                        <CustomCheckbox row={r} sp={sp} />
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={{
                                          ...columnStyle,
                                          textAlign: "left",
                                        }}
                                      >
                                        {r.tier}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={{
                                          ...columnStyle,
                                          textAlign: "left",
                                        }}
                                      >
                                        {r.productType}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(
                                          r.receivedBillRate.toString()
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(
                                          r.standardRate.toString()
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.startDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.endDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatDecimal(r.adjustment.toString())}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.adjStartDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        {formatISOForDisplay(r.adjEndDate)}
                                      </TableCell>
                                      <TableCell
                                        className={classes.cell}
                                        style={columnStyle}
                                      >
                                        <EditPartnerRateModal
                                          partnerId={data.partnerId}
                                          partnerRate={r}
                                          disabled={disabled}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                } else {
                  return (
                    <>
                      <TableRow
                        className={classes.serviceProviderRow}
                        onClick={() => handleOpen(sp)}
                      >
                        <TableCell
                          className={classes.serviceProviderCell}
                          colSpan={6}
                        >
                          <Typography variant="h4">{sp}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="expand row" size="small">
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          style={{ padding: 0, border: 0 }}
                        >
                          <Collapse
                            in={open}
                            timeout="auto"
                            unmountOnExit
                            collapsedSize={0}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className={classes.cell}
                                    style={{ textAlign: "left" }}
                                  >
                                    <b>Tier</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Received Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Standard Rate</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>End Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adjustment</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. Start Date</b>
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    <b>Adj. End Date</b>
                                  </TableCell>
                                  <TableCell
                                    className={classes.cell}
                                  ></TableCell>
                                  <TableCell className={classes.cell}>
                                    <CreatePartnerRateModal
                                      partnerId={data.partnerId}
                                      serviceProvider={sp}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                }
              })}
            </TableBody>
          </Table>
        </Panel>
        <Panel value={mainTabIndex} index={2}>
          <Box p={2}>
            <Box mb={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                mt={1.5}
                pl={1}
              >
                <Typography variant="h3">Linked Service Providers</Typography>
                <CreatePartnerServiceProviderModal partnerId={data.partnerId} />
              </Box>
              <LinkedServiceProvidersTable
                data={data.partnerServiceProviders}
              />
            </Box>
            <OutOfContractTable data={data} />
          </Box>
        </Panel>
        <Panel value={mainTabIndex} index={3}>
          <Box p={2}>
            <Box mb={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={1}
                mt={1.5}
                pl={1}
              >
                <Typography variant="h3">Comments</Typography>
                <CreatePartnerNoteModal partnerId={data.partnerId} />
              </Box>
            </Box>
            <CommentsTable data={data.notes} />
          </Box>
        </Panel>
      </TabPanelWrapper>
    </Box>
  );
};
