import {
  CreateProductRateActions,
  CreateProductRateFormState,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  FORM_RESET,
} from "./types";

const initialState: CreateProductRateFormState = {
  requestError: undefined,
  baseValues: {
    startDate: null,
    endDate: null,
    expTariffAdvance: 0,
    expTariffCommission: 0,
    payTariffAdvance: 0,
    payTariffCommission: 0,
    payVolumeBonus: 0,
    expVolumeBonus: 0,
    lineRental: 0,
    payServiceCommission: 0,
    payServiceAdvance: 0,
    expServiceCommission: 0,
    expServiceAdvance: 0,
    serviceLineRental: 0,
  },
}; //

export function createProductRateFormReducer(
  state = initialState,
  action: CreateProductRateActions
): CreateProductRateFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        baseValues: {
          ...state.baseValues,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
