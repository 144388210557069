import { UpdateSelfBillToPartnerParams } from "../../api/partner/self-bill-to-partner-code";
import { RequestError } from "../../api/types";

export const REQUEST_UPDATE_SELF_BILL_TO_PARTNER =
  "updateSelfBillToPartner.REQUEST_UPDATE";
export const SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS =
  "updateSelfBillToPartner.UPDATE_SUCCESS";
export const SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR =
  "updateSelfBillToPartner.UPDATE_ERROR";

export type DetailSelfBillToPartnerType = {
  partnerId: number;
  selfBillToPartnerID: number;
  isFetching: boolean;
  selfBillToPartnerError?: RequestError;
};

export interface RequestUpdateSelfBillToPartnerAction {
  type: typeof REQUEST_UPDATE_SELF_BILL_TO_PARTNER;
  payload: UpdateSelfBillToPartnerParams;
}

export interface SetUpdateSelfBillToPartnerSuccessAction {
  type: typeof SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS;
}

export interface SetUpdateSelfBillToPartnerAction {
  type: typeof SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR;
  payload: {
    err: RequestError;
  };
}

export type SelfBillToPartnerTypes =
  | RequestUpdateSelfBillToPartnerAction
  | SetUpdateSelfBillToPartnerSuccessAction
  | SetUpdateSelfBillToPartnerAction;
