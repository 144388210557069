import {
  CreateAssetActions,
  CreateAssetFormState,
  FORM_INITIALIZE_START,
  FORM_INITIALIZE_FINISH,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_OPTIONS_PRIMARY,
  SET_VALUE_PRIMARY,
  SET_OPTIONS_SECONDARY,
  SET_VALUE_SECONDARY,
} from "./types";

const initialState: CreateAssetFormState = {
  base: {
    selectedSecondaryOptions: [],
    isAdvanceTaken: false,
  },
  primaryProductOptions: [],
  secondaryProductOptions: [],
  productTypeOptions: [],
  productClassOptions: [],
  partnerOptions: [],
  durationOptions: [],
  isFetching: false,
};

export function createAssetFormReducer(
  state = initialState,
  action: CreateAssetActions
): CreateAssetFormState {
  switch (action.type) {
    case FORM_INITIALIZE_START: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FORM_INITIALIZE_FINISH: {
      return {
        ...state,
        productTypeOptions: action.payload.productTypes,
        productClassOptions: action.payload.productClasses,
        partnerOptions: action.payload.partners,
        isFetching: false,
      };
    }

    case SET_FORM_VALUES: {
      const { values } = action.payload;
      const contractTypeId = values.contractTypeId || state.base.contractTypeId;
      return {
        ...state,
        base: {
          ...state.base,
          ...values,
          productVariant: contractTypeId === 2 ? "Upgrade" : "Standard",
        },
      };
    }

    case SET_OPTIONS_PRIMARY:
      const durationOptions = action.payload
        .map((o) => o.duration.toString())
        .filter((x, i, a) => a.indexOf(x) === i)
        .sort((a, b) => parseInt(b) - parseInt(a));

      return {
        ...state,
        durationOptions,
        primaryProductOptions: action.payload,
        base: {
          ...state.base,
          primaryProduct: undefined,
        },
      };

    case SET_OPTIONS_SECONDARY:
      return {
        ...state,
        secondaryProductOptions: action.payload,
      };

    case SET_VALUE_PRIMARY: {
      return {
        ...state,
        base: {
          ...state.base,
          primaryProduct: action.payload.primaryProduct,
        },
      };
    }

    case SET_VALUE_SECONDARY: {
      return {
        ...state,
        base: {
          ...state.base,
          selectedSecondaryOptions: action.payload.secondaryProducts,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
        isFetching: false,
      };
    }

    case FORM_RESET: {
      return {
        ...state,
        base: initialState.base,
        requestError: initialState.requestError,
      };
    }
    default:
      return state;
  }
}
