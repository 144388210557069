import { call, put, takeLatest } from "redux-saga/effects";
import {
  FinancialBreakdown,
  getFinancialBreakdown,
} from "../../api/asset-ledger-entry/financial-overview";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setFinancialBreakdownData,
  setFinancialBreakdownError,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchFinancialBreakdown(action: RequestDataAction) {
  try {
    const data: PaginatedResult<FinancialBreakdown> = yield call(
      getFinancialBreakdown,
      action.payload.assetId,
      action.payload.breakdownType
    );
    yield put(setFinancialBreakdownData(data));
  } catch (err) {
    const error: RequestError = err;
    yield put(setFinancialBreakdownError(error));
  }
}

export function* watchRequestFinancialBreakdown() {
  yield takeLatest(REQUEST_DATA, fetchFinancialBreakdown);
}
