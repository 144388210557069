import { SxProps } from "@mui/material";

/**
 * Convert px to rem
 * MUI helper but not accessible here.
 * See packages/material-ui/src/styles/createTypography.js
 *
 * @param size
 * @returns {string}
 */
function pxToRem(size: number) {
  const fontSize = 14;
  const htmlFontSize = 16;
  const coef = fontSize / 14;
  return `${(size / htmlFontSize) * coef}rem`;
}

export interface SxStyleSheet {
  [key: string]: SxProps;
}

// See https://material-ui.com/customization/default-theme/ for available options
export const baseThemeOptions = {
  spacing: 16, // TBC
  shape: {
    borderRadius: 5,
  },
  typography: {
    // Note: 16px default htmlFontSize base.
    // htmlFontSize: 16,
    fontSize: 14, // default base
    fontFamily: "'Open Sans', sans-serif",
    fontWeightBold: 600,
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    h6: {
      // Note: This is "Supporting text" in the DS
      fontSize: "1rem",
      fontWeight: 600, // TODO: this is DialogTitle, which doesn't really fit... change h* assignment
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: pxToRem(12),
    },
  },
};
