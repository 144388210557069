import { request, requestFile } from "../..";
import { ServiceProvider } from "../../../shared/enum/serviceProvider";
import { ContractType, AssetStatus } from "../../../shared/enum/asset";
import { DateString } from "../../../shared/helpers/dates";
import { stringify } from "query-string";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

//@TODO the null values (except for dates) relate to the output of the url query params in the view layer
// they can/should be cleansed before we get to this step
export type QueryInvoiceLinesFilterParams = {
  assetId?: (number | null)[];
  startDate?: [DateString | null, DateString | null];
  endDate?: [DateString | null, DateString | null];
  customerName?: (string | null)[];
  status?: (AssetStatus | null)[];
  identifier?: (string | null)[];
  serviceProvider?: ServiceProvider[];
  contractType?: ContractType[];
  productCode?: (string | null)[];
  amount?: (number | null)[];
  aleTypeDescription?: (string | null)[];
};

export type QueryInvoiceLinesApiItem = {
  assetId: number;
  startDate: string;
  endDate: string;
  customerName: string;
  status: AssetStatus;
  identifier: string;
  serviceProvider: ServiceProvider;
  contractType: ContractType;
  productCode: string;
  amount: number;
  aleTypeDescription: string;
  invoiceLineNo: number;
};
export type QueryInvoiceLinesRequestParams = {
  tableParams: TableAPIRequestParams<QueryInvoiceLinesApiItem>;
  filterParams: QueryInvoiceLinesFilterParams;
  export?: boolean;
  returnAll?: boolean;
  invoiceId: number;
};

export function getQueryInvoiceLines(params: QueryInvoiceLinesRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  return request<PaginatedResult<QueryInvoiceLinesApiItem>>(
    `invoice/lines/${params.invoiceId}${combine([
      tableParamsQS,
      filterParamsQS,
    ])}`
  );
}

export function getQueryInvoiceLinesExcel(
  params: QueryInvoiceLinesRequestParams
) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const exportFlag = stringify({ export: true, returnAll: true });
  return requestFile(
    `invoice/lines/${params.invoiceId}${combine([
      tableParamsQS,
      filterParamsQS,
      exportFlag,
    ])}`
  );
}
