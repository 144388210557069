export enum ProductCustomerType {
  "NotSet" = 0,
  "Business" = 1,
  "Consumer" = 2,
}

export const productClasses = ["Standard", "Single", "Sharer", "Lead"];
export type ProductClass = typeof productClasses[number];

export const productVariants = ["Standard", "Upgrade"];
export type ProductVariant = typeof productVariants[number];

export const getProductVariantId = (
  productVariant: ProductVariant | undefined
) => {
  if (productVariant) {
    return productVariants.indexOf(productVariant) + 1;
  }

  return 1;
};
