import {
  QueryPartnerApiItem,
  QueryPartnersRequestParams,
} from "../../api/partner";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_TABLE_CONTROLS,
} from "./types";

export function requestQueryPartnersData(params: QueryPartnersRequestParams) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setQueryPartnersErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setQueryPartnersTableControls(
  tableControls: TableAPIRequestParams<QueryPartnerApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: {
      tableControls,
    },
  };
}

export function setQueryPartnersData(
  data: PaginatedResult<QueryPartnerApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
