import {
  REQUEST_UPDATE_PARTNER_GENERAL_INFO,
  SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS,
  SET_UPDATE_PARTNER_GENERAL_INFO_ERROR,
} from "./types";
import { RequestError } from "../../api/types";
import { DetailPartnerGeneralInfo } from "../../api/partner";

export function postUpdatePartnerGeneralInfoRequest(
  params: DetailPartnerGeneralInfo
) {
  return {
    type: REQUEST_UPDATE_PARTNER_GENERAL_INFO,
    payload: params,
  };
}

export function setUpdatePartnerGeneralInfoSuccess() {
  return {
    type: SET_UPDATE_PARTNER_GENERAL_INFO_SUCCESS,
  };
}

export function setUpdatePartnerGeneralInfoError(error: RequestError) {
  return {
    type: SET_UPDATE_PARTNER_GENERAL_INFO_ERROR,
    payload: { err: error },
  };
}
