import { request } from "../..";

export type ProductClassApi = {
  productClassId: number;
  serviceProviderId: number;
  productTypeId: number;
  name: string;
  description: string;
};

export function getProductClasses() {
  return request<ProductClassApi[]>("product/product-class");
}

export function getFilteredProductClasses(
  serviceProviderId: number,
  productTypeId: number
) {
  return request<ProductClassApi[]>(
    `product/product-class-filtered/${serviceProviderId}/${productTypeId}`
  );
}

export function getDistinctProductClasses(
  serviceProviderId: number,
  productTypeId: number
) {
  return request<string[]>(
    `product/distinctproductclass/${serviceProviderId}/${productTypeId}`
  );
}
