import {
  ConnectionCommissionsForAccrualsReportApiItem,
  ConnectionCommissionsForAccrualsReportParams,
} from "../../api/account-report/generate-connection-commission-for-accruals-report";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA =
  "connectionCommissionsForAccrualsReport.REQUEST_DATA";
export const SET_DATA = "connectionCommissionsForAccrualsReport.SET_DATA";
export const SET_ERROR = "connectionCommissionsForAccrualsReport.SET_ERROR";

export type ConnectionCommissionsForAccrualsReportState = {
  items: ConnectionCommissionsForAccrualsReportApiItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: ConnectionCommissionsForAccrualsReportParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<ConnectionCommissionsForAccrualsReportApiItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type ConnectionCommissionsForAccrualsReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
