import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  CreateSecondaryProductFormBaseValues,
  SetFormValuesAction,
  FormSubmitAction,
  FORM_RESET,
  ResetFormAction,
} from "./types";

export function setFormValues(
  values: Partial<CreateSecondaryProductFormBaseValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function submitForm(payload: {
  done: () => any;
  primaryProductId: number;
}): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function resetForm(): ResetFormAction {
  return {
    type: FORM_RESET,
  };
}

export function setError(error: RequestError) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}
