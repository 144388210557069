import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { RequestError } from "../../api/types";

export const INITIALIZE_START = "editProductRateForm.INITIALIZE_START";
export const INITIALIZE_FINISH = "editProductRateForm.INITIALIZE_FINISH";
export const SET_FORM_VALUES = "editProductRateForm.SET_FORM_VALUES";
export const FORM_SUBMIT = "editProductRateForm.FORM_SUBMIT";
// export const FORM_SUBMIT_END_DATE = "editProductRateForm.FORM_SUBMIT_END_DATE";
export const SET_FORM_ERROR = "editProductRateForm.SET_FORM_ERROR";

export type EditProductRateFormBaseValues = {
  payTariffAdvance: number;
  payTariffCommission: number;
  expTariffCommission: number;
  expTariffAdvance: number;
  lineRental: number;
  payServiceCommission: number;
  payServiceAdvance: number;
  expServiceCommission: number;
  expServiceAdvance: number;
  serviceLineRental: number;
  payVolumeBonus: number;
  expVolumeBonus: number;
  startDate: string | null;
  endDate: string | null;
  productRateId: number;
  parentId: number | null;
  productId: number;
};

export type EditProductRateFormState = {
  errors: {
    [productRateId: number]: RequestError;
  };
  isFetching: boolean;
  isLinkedToAsset: boolean;
  baseValues: EditProductRateFormBaseValues;
};

export interface InitializeStartAction {
  type: typeof INITIALIZE_START;
  payload: {
    productRate: ProductRateByIdApiItem;
  };
}

export interface InitializeFinishAction {
  type: typeof INITIALIZE_FINISH;
  payload: {
    productRate: ProductRateByIdApiItem;
    isLinkedToAsset: boolean;
  };
}

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<EditProductRateFormBaseValues>;
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    done: (err?: RequestError) => any;
  };
}

// export interface FormSubmitEndDateAction {
//   type: typeof FORM_SUBMIT_END_DATE;
//   payload: {
//     params: PostEditProductRateEndDateParams;
//     done: (err?: RequestError) => any;
//   };
// }

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    productRateId: number;
    error: RequestError;
  };
}

export type EditProductRateActions =
  | InitializeStartAction
  | InitializeFinishAction
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction;
