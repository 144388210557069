import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { APIFetchingWrapper } from "../../shared/components/APIFetchingWrapper";
import { requestDetailProductData } from "../../store/detailProducts/actions";
import { RootState } from "../../store/rootReducer";
import { PrimaryProductCard } from "./components/PrimaryProductCard";

type Props = { title: string };

export const ViewPrimaryProduct = ({ title }: Props) => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const productId = Number(id);

  const data = useSelector(
    (state: RootState) => state.detailProducts.items[productId]
  );
  const fetching = useSelector((state: RootState) =>
    state.detailProducts.fetchingIds.includes(productId)
  );
  const error = useSelector((state: RootState) =>
    state.detailProducts.errors.find((error: any) => error.id === productId)
  );

  useEffect(() => {
    if (!isNaN(productId)) {
      dispatch(requestDetailProductData(productId));
    }
  }, [productId, dispatch]);

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h2" gutterBottom>
          {`${title}: #${id}`}
        </Typography>
      </Box>
      <APIFetchingWrapper loading={fetching} error={error}>
        {data && <PrimaryProductCard data={data} />}
      </APIFetchingWrapper>
    </React.Fragment>
  );
};
