import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SentimentVeryDissatisfied } from "@mui/icons-material";

export const NoMatch = () => {
  const { pathname } = useLocation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ height: "100%" }}
    >
      <Box style={{ fontSize: 100, width: "50%", textAlign: "center" }}>
        <SentimentVeryDissatisfied color="primary" fontSize="inherit" />
      </Box>
      <Typography variant="h3" style={{ width: "50%", textAlign: "center" }}>
        No match found for <code>{pathname}</code>{" "}
      </Typography>
    </Box>
  );
};
