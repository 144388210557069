import React from "react";
import { Wrapper } from "./components/Wrapper";
import { SideBar } from "./components/SideBar";
import { Content } from "./components/Content";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";

type Props = {
  nav: React.ReactNode;
  children: React.ReactNode;
};

export const Layout = ({ children, nav }: Props) => {
  const { isLoading } = useSelector((state: RootState) => state.ui);
  return (
    <>
      <Wrapper>
        <SideBar>{nav}</SideBar>
        <Content>{children}</Content>
      </Wrapper>
      {isLoading && <LoadingOverlay />}
    </>
  );
};
