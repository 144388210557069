import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { FilterControlWrapper } from "./FilterControlWrapper";
import { SuggestionAPIParams } from "../../../api/asset/search/suggestions";
import { SuggestionResult } from "../../../api/types";
import throttle from "lodash.throttle";
import { Search } from "@mui/icons-material";

type Props = {
  name: string;
  suggestionServiceColumn: string;
  id: string;
  selected: (string | null)[];
  onChange: (selected: string[] | undefined) => any;
  suggestionsService: ({
    columnName,
    term,
  }: SuggestionAPIParams) => Promise<SuggestionResult>;
};

export function MultiSelectSuggestions({
  name,
  id,
  selected,
  onChange,
  suggestionsService,
  suggestionServiceColumn,
}: Props) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);

  const handleReset = () => onChange(undefined);

  const throttledSuggestionsService = useCallback(
    () =>
      throttle(
        (term: string) =>
          suggestionsService({ columnName: suggestionServiceColumn, term }),
        300
      ),
    [suggestionsService, suggestionServiceColumn]
  );

  useEffect(() => {
    let active = true;

    if (!open) {
      return;
    }

    if (!inputValue) {
      return;
    }

    setLoading(true);
    throttledSuggestionsService()(inputValue)
      ?.then((results) => {
        if (active) {
          setOptions(results);
        }
      })
      .catch((e) => console.error("e", e))
      .finally(() => {
        if (active) {
          setLoading(false);
        }
      });

    return () => {
      active = false;
    };
  }, [throttledSuggestionsService, open, inputValue]);

  return (
    <FilterControlWrapper
      id={id}
      name={name}
      type="text"
      count={selected.length}
      onReset={handleReset}
    >
      <Autocomplete
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={inputValue}
        onInputChange={(e, x) => setInputValue(x)}
        fullWidth
        size="small"
        multiple
        value={selected}
        options={options.filter(
          (option: string) =>
            inputValue &&
            option.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        )}
        onChange={(e, selected) => {
          if (!selected.length) {
            return handleReset();
          }
          const value = selected.filter(
            (x) => typeof x === "string"
          ) as string[];
          onChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Type to search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={16} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option, state) => {
          return (
            <div {...(props as any)}>
              <Checkbox style={{ marginRight: 8 }} checked={state.selected} />
              {option}
            </div>
          );
        }}
      />
    </FilterControlWrapper>
  );
}
