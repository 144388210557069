export const serviceProviders = ["EE", "O2", "Vodafone"] as const;
export type ServiceProvider = (typeof serviceProviders)[number];

export const getServiceProviderId = (
  serviceProvider: ServiceProvider | undefined
) => {
  if (serviceProvider) {
    return serviceProviders.indexOf(serviceProvider) + 1;
  }

  return 1;
};

export const getServiceProviderName = (serviceProviderId: number) => {
  return serviceProviders[serviceProviderId - 1];
};

export const getServiceProviderIdReturnZeroIfUndefined = (
  serviceProvider: ServiceProvider | undefined
) => {
  if (serviceProvider) {
    return serviceProviders.indexOf(serviceProvider) + 1;
  }

  return 0;
};
