import {
  QueryAssetChangesActionTypes,
  QueryAssetChangesState,
  REQUEST_DATA,
  REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
  SBAmountState,
  SET_API_ERROR,
  SET_DATA,
  SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA,
  SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR,
  SET_SEARCH_TERM,
  SET_TABLE_CONTROLS,
  SET_TABLE_FILTERS,
} from "./types";

const initialState: QueryAssetChangesState = {
  items: [],
  count: 0,
  fetching: false,
  hasNext: false,
  hasPrevious: false,
  searchTerm: "",
  filters: {},
  tableControls: {
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "assetId",
  },
  totalSBAmount: 0,
};
const sbAmountinitialState: SBAmountState = {
  totalSBAmount: 0,
  totalSbAmountFetching: false,
  totalSbAmountError: undefined,
};

export function queryAssetChangesReducer(
  state = initialState,
  action: QueryAssetChangesActionTypes
): QueryAssetChangesState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_API_ERROR:
      return { ...state, error: action.payload, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };

    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }

    case SET_TABLE_CONTROLS: {
      return {
        ...state,
        tableControls: action.payload,
      };
    }

    case SET_TABLE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
}
export function totalSBAmountOfAssetChangesDueReducer(
  state = sbAmountinitialState,
  action: QueryAssetChangesActionTypes
): SBAmountState {
  switch (action.type) {
    case REQUEST_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA:
      return {
        ...state,
        totalSbAmountFetching: true,
        totalSbAmountError: undefined,
      };
    case SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_DATA: {
      return {
        ...state,
        totalSBAmount: action.payload,
        totalSbAmountFetching: false,
      };
    }
    case SET_TOTAL_SB_AMOUNT_OF_ASSET_CHANGES_DUE_ERROR: {
      return {
        ...state,
        totalSbAmountError: action.payload,
        totalSbAmountFetching: false,
      };
    }
    default:
      return state;
  }
}
