import { JobProgressApiItem } from "../../api/jobprogress/get-with-filter";
import { RequestError } from "../../api/types";
import { REQUEST_DATA, SET_API_ERROR, SET_DATA } from "./types";

export function requestProgressReportData() {
  return {
    type: REQUEST_DATA,
  };
}

export function setProgressReportError(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setProgressReportData(data: JobProgressApiItem[]) {
  return {
    type: SET_DATA,
    payload: data,
  };
}
