import React from "react";
import { useDispatch } from "react-redux";
import { RequestError } from "../../../api/types";
import { ModalDeleteWrapper } from "../../../shared/components/modal_field_controls/ModalDeleteWrapper";
import { deleteSpendCap } from "../../../store/deleteSpendCapForm/actions";
import { SpendCapItem } from "../../../api/spend-cap-period/by-productId-date";

type Props = {
  readonly productId: number;
  readonly spendCap: SpendCapItem;
};

export const DeleteSpendCapModal = ({ productId, spendCap }: Props) => {
  const dispatch = useDispatch();

  return (
    <ModalDeleteWrapper
      label="Delete Spend Cap"
      message={() => (
        <p>
          Do you really wish to delete spend cap: <b>{spendCap.name}</b>?
        </p>
      )}
      onDelete={() => {
        return new Promise((resolve) => {
          dispatch(
            deleteSpendCap(
              productId,
              spendCap.spendCapId,
              (err?: RequestError) => resolve(err)
            )
          );
        });
      }}
      id="delete-spend-cap"
    />
  );
};
