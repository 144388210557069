import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryParams, withDefault } from "use-query-params";
import { useHistory } from "react-router-dom";
import FileDownload from "js-file-download";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
} from "../../../shared/helpers/queryParsing";
import { RootState } from "../../../store/rootReducer";
import {
  QueryPostedCreditListFilterParams,
  PostedCreditListAPIItem,
  getPostedCreditListExcel,
} from "../../../api/posted-document/posted-credit-list";
import { TableAPIRequestParams } from "../../../api/types";
import { requestPostedCreditList } from "../../../store/postedCreditList/actions";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { Box, Typography, Button } from "@mui/material";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { DataTable } from "../../../shared/components/DataTable";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { PaymentTerms } from "../../../shared/enum/invoice";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import { SBPaymentType } from "../../../shared/enum/payments";

const useStyles = makeStyles()(() => ({
  exportButton: {
    marginRight: "10px",
  },
}));

export function CreditListSelfBill() {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.postedCreditList
  );

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();

  const handlePostedDocumentClick = (postedDocumentId: number) => {
    history.push({
      pathname: `credit-note-lines`,
      state: {
        postedDocumentId: postedDocumentId,
      },
    });
  };

  const [filters, updateFilters] = useQueryParams({
    thirdPartyInvoiceId: withDefault(CommaDelimitedArrayParam, []),
    datePosted: dateRangeParser,
    partnerCode: withDefault(CommaDelimitedArrayParam, []),
    paymentTerms: withDefault(CommaDelimitedNumericArrayParam, []),
    paymentType: withDefault(CommaDelimitedNumericArrayParam, []),
  });

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<PostedCreditListAPIItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "thirdPartyInvoiceId",
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
    handleSubmit(true);
  };

  const [isExporting, setIsExporting] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      requestPostedCreditList({
        tableControls: tableControls,
        filterParams: {},
      })
    );
  }, [dispatch, tableControls]);

  const handleExport = () => {
    setIsExporting(true);
    getPostedCreditListExcel({
      tableControls: tableControls,
      filterParams: filters,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
    setIsExporting(false);
  };

  const handleSubmit = (ignoreFilters = false) => {
    dispatch(
      requestPostedCreditList({
        tableControls: tableControls,
        filterParams: ignoreFilters ? {} : filters,
      })
    );
  };

  const columns: {
    [key: string]: any;
    name: keyof PostedCreditListAPIItem;
    label: string;
    customBodyRender?: (
      input: string | number | boolean | null,
      rowData: any
    ) => React.ReactNode;
    align?: "left" | "right" | "center";
    enum?: Enum<any>;
    disableSort?: boolean;
  }[] = [
    {
      name: "thirdPartyInvoiceId",
      label: "SB Reference",
      align: "left",
    },
    {
      name: "datePosted",
      label: "Posting Date",
      align: "left",
      customBodyRender: (val) => formatISOForDisplay(val as string),
    },
    {
      name: "partnerCode",
      label: "Partner Code",
      align: "left",
      disableSort: true,
    },
    {
      name: "paymentTerms",
      label: "Payment Terms",
      align: "left",
      enum: PaymentTerms,
      disableSort: true,
    },
    {
      name: "amountExcVat",
      label: "Amount (Ex VAT)",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "amountIncVat",
      label: "Amount (Inc VAT)",
      align: "right",
      customBodyRender: (val) => formatCurrencyGBP((val as number | null) || 0),
      disableSort: true,
    },
    {
      name: "reference",
      label: "Reference",
      align: "left",
      disableSort: true,
    },
    {
      name: "paymentType",
      label: "Payment Type",
      align: "left",
      enum: SBPaymentType,
      disableSort: true,
    },
  ];

  return (
    <React.Fragment>
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">Posted SB Credit List</Typography>
        <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
            <LoaderButton
                size="large"
                variant="outlined"
                onClick={handleExport}
                loading={isExporting}
                className={classes.exportButton}
            >
                Export
            </LoaderButton>
            <Button variant="contained" size="large" onClick={() => handleSubmit()}>
                Submit
            </Button>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        allowHidden={true}
        controls={() => (
          <React.Fragment>
            <MultiTextField
              type="text"
              name="SB Reference"
              id="sb-reference-filter-control"
              selected={filters.thirdPartyInvoiceId || []}
              onChange={(thirdPartyInvoiceId) =>
                updateFilters({ thirdPartyInvoiceId })
              }
            />
            <DateRangeControl
              name="Posting Date"
              id="posting-date-filter-control"
              selected={filters.datePosted || [null, null]}
              onChange={(datePosted) => updateFilters({ datePosted })}
            />
            <MultiTextField
              type="text"
              name="Partner Code"
              id="posting-code-filter-control"
              selected={filters.partnerCode || []}
              onChange={(partnerCode) => updateFilters({ partnerCode })}
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "paymentTerms")?.label ||
                "paymentTerms"
              }
              id="payment-terms-filter-control"
              options={getEnumNames(PaymentTerms)}
              selected={
                filters.paymentTerms
                  ?.filter((paymentTerms) => paymentTerms !== null)
                  .map(
                    (paymentTerms) => PaymentTerms[paymentTerms as number]
                  ) || []
              }
              onChange={(paymentTerms) =>
                updateFilters({
                  paymentTerms: paymentTerms?.map(
                    (paymentTerms) =>
                      PaymentTerms[paymentTerms as keyof typeof PaymentTerms]
                  ),
                })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "paymentType")?.label ||
                "paymentType"
              }
              id="payment-type-filter-control"
              options={getEnumNames(SBPaymentType)}
              selected={
                filters.paymentType
                  ?.filter((paymentType) => paymentType !== null)
                  .map((paymentType) => SBPaymentType[paymentType as number]) ||
                []
              }
              onChange={(paymentType) =>
                updateFilters({
                  paymentType: paymentType?.map(
                    (paymentType) =>
                      SBPaymentType[paymentType as keyof typeof SBPaymentType]
                  ),
                })
              }
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryPostedCreditListFilterParams}
            meta={columns}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        limitHeight
        stickyHeader
        title="Posted SB Credit List"
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) => {
          setTableControls(tableControls);
        }}
        tableControls={tableControls}
        onRowClick={(row) => handlePostedDocumentClick(row.postedDocumentId)}
      />
    </React.Fragment>
  );
}
