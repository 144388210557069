import { capitalize } from "@mui/material";
import { request } from "../..";
import { SuggestionResult } from "../../types";

export interface SuggestionAPIParams {
  columnName: string;
  term: string;
}

export function getSuggestions({ columnName, term }: SuggestionAPIParams) {
  return request<SuggestionResult>(
    `Asset/search/${capitalize(columnName)}/${term}`
  );
}
