import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} 100px ${theme.spacing(
      2
    )}`,
    overflowX: "auto",
    width: "100%",
    minWidth: 600,
  },
}));

export const Content: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
};
