import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import {
  requestQueryAssetChangesData,
  setQueryAssetChangesTableControls,
} from "../../../store/queryAssetChangesDue/actions";
import { useHistory, useLocation } from "react-router-dom";
import { DataTable } from "../../../shared/components/DataTable";
import {
  getQueryAssetChangesExcel,
  QueryAssetChangesApiItem,
  QueryAssetChangesFilterParams,
} from "../../../api/asset-changes-due";
import { Enum, getEnumNames } from "../../../shared/helpers/enum";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import {
  AssetStatus,
  ContractType,
  contractTypes,
} from "../../../shared/enum/asset";
import { encodeArray, useQueryParams, withDefault } from "use-query-params";
import {
  CommaDelimitedArrayParam,
  CommaDelimitedNumericArrayParam,
  dateRangeParser,
  decodePermittedArrayParam,
} from "../../../shared/helpers/queryParsing";
import {
  ServiceProvider,
  serviceProviders,
} from "../../../shared/enum/serviceProvider";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { MultilineTextArea } from "../../../shared/components/filter_controls/MultilineTextArea";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { MultiTextField } from "../../../shared/components/filter_controls/MultiTextField";
import { MultiSelectSuggestions } from "../../../shared/components/filter_controls/MultiSelectSuggestions";
import { MultiSelect } from "../../../shared/components/filter_controls/MultiSelect";
import { getSuggestions } from "../../../api/asset-changes-due/search/index";
import useDeepCompareEffect from "use-deep-compare-effect";
import FileDownload from "js-file-download";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";

type Props = { title: string };
interface LocationState {
  partnerId?: number;
  partnerName?: string;
  thirdPartyId?: string;
}

export const AssetChanges = ({ title }: Props) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { partnerId, partnerName, thirdPartyId } = location.state || {};
  //redux
  const dispatch = useDispatch();
  const { items, fetching, error, tableControls, count } = useSelector(
    (state: RootState) => state.queryAssetChanges
  );

  const columns: {
    [key: string]: any;
    name: keyof QueryAssetChangesApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    { name: "customerName", label: "Customer Name" },
    { name: "assetStatus", label: "Status", enum: AssetStatus },
    { name: "identifier", label: "Identifier" },
    { name: "serviceProviderName", label: "Provider" },
    { name: "contractTypeName", label: "Contract Type" },
    { name: "productName", label: "Product Name" },
    {
      name: "sbAmount",
      label: "SB Amount",
      customBodyRender: (value) => formatCurrencyGBP(Number(value)),
    },
    { name: "aleTypeName", label: "ALE Type" },
  ];
  //filters
  const updateFilters = (filters: any) => {
    setFilters(filters);
  };
  const [filters, setFilters] = useQueryParams({
    assetId: withDefault(CommaDelimitedNumericArrayParam, []),
    startDate: dateRangeParser,
    endDate: dateRangeParser,
    customerName: withDefault(CommaDelimitedArrayParam, []),
    assetStatus: withDefault(CommaDelimitedNumericArrayParam, []),
    identifier: withDefault(CommaDelimitedArrayParam, []),
    serviceProviderName: {
      encode: (val: ServiceProvider[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, serviceProviders.slice()),
    },
    contractTypeName: {
      encode: (val: ContractType[]) => encodeArray(val),
      decode: (input) =>
        decodePermittedArrayParam(input, contractTypes.slice()),
    },

    productName: withDefault(CommaDelimitedArrayParam, []),
    sbAmount: withDefault(CommaDelimitedArrayParam, []),
    aleTypeName: withDefault(CommaDelimitedArrayParam, []),
  });
  const handleResetAllFilters = () => {
    setFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
    if (partnerId) {
      dispatch(
        requestQueryAssetChangesData({
          tableParams: tableControls,
          filterParams: {},
          partnerId: partnerId,
        })
      );
    }
  };

  const cleanseRequestQueryAssetsData = (filters: any): any => {
    const assetId = filters["assetId"] as string[] | undefined;
    const assetIdParam = assetId ? assetId.filter((x) => x !== null) : [];

    return {
      ...filters,
      assetId: assetIdParam,
    };
  };
  const handleExport = () => {
    if (partnerId) {
      getQueryAssetChangesExcel({
        tableParams: tableControls,
        filterParams: cleanseRequestQueryAssetsData(filters),
        partnerId: partnerId,
      }).then((result) => {
        FileDownload(result.file as any, result.fileName);
      });
    }
  };

  const handleNavToPartner = () => {
    history.push(`/partners/${partnerId}`);
  };
  const appliedFiltersMeta: {
    [key: string]: any;
    name: keyof QueryAssetChangesApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [...columns];

  useDeepCompareEffect(() => {
    if (partnerId)
      dispatch(
        requestQueryAssetChangesData({
          tableParams: tableControls,
          filterParams: cleanseRequestQueryAssetsData(filters),
          partnerId: partnerId,
        })
      );
  }, [tableControls, partnerId]);
  const handleSubmit = () => {
    const activeFilters = Object.entries(filters).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        if (value !== null && value !== undefined && value.length !== 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    if (partnerId) {
      tableControls.pageNumber = 1;
      dispatch(
        requestQueryAssetChangesData({
          tableParams: tableControls,
          filterParams: cleanseRequestQueryAssetsData(activeFilters),
          partnerId: partnerId,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h2">{title}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Box marginRight={1}>
            <Button
              size="large"
              variant="contained"
              onClick={handleNavToPartner}
            >
              Back To Partner
            </Button>
          </Box>
          <Box marginRight={1}>
            <Button size="large" variant="outlined" onClick={handleExport}>
              Export
            </Button>
          </Box>
          <Box>
            <Button size="large" variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <Box justifyContent="space-between" display="flex" marginBottom={1}>
        <Typography variant="h3">
          {partnerName} ({thirdPartyId})
        </Typography>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        controls={() => (
          <React.Fragment>
            <MultilineTextArea
              id="asset-id-filter-control"
              label={
                columns.find((label) => label.name === "assetId")?.label ||
                "assetId"
              }
              value={filters.assetId.join("\n")}
              helperText="Enter one Asset Id per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                const assetId = selected.filter((id) => /^$|\d+$/.test(id));
                updateFilters({ assetId });
              }}
            />
            <DateRangeControl
              name={`${
                columns.find((label) => label.name === "startDate")?.label ||
                "startDate"
              }`}
              id="start-date-range-filter-control"
              selected={filters.startDate || [null, null]}
              onChange={(startDate) => updateFilters({ startDate })}
            />
            <DateRangeControl
              name={`${
                columns.find((label) => label.name === "endDate")?.label ||
                "endDate"
              }`}
              id="end-date-range-filter-control"
              selected={filters.endDate || [null, null]}
              onChange={(endDate) => updateFilters({ endDate })}
            />
            <MultiSelectSuggestions
              suggestionServiceColumn="customerName"
              name={
                columns.find((label) => label.name === "customerName")?.label ||
                "customerName"
              }
              id="customer-name-filter-control"
              selected={filters.customerName || []}
              onChange={(customerName) => updateFilters({ customerName })}
              suggestionsService={(suggParams) =>
                getSuggestions({
                  ...suggParams,
                  filterParams: { partnerId: [partnerId] },
                })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "assetStatus")?.label ||
                "assetStatus"
              }
              id="assetStatus-filter-control"
              options={getEnumNames(AssetStatus)}
              selected={
                filters.assetStatus
                  ?.filter((assetStatus) => assetStatus !== null)
                  .map((assetStatus) => AssetStatus[assetStatus as number]) ||
                []
              }
              onChange={(assetStatus) =>
                updateFilters({
                  assetStatus: assetStatus?.map(
                    (assetStatus) =>
                      AssetStatus[assetStatus as keyof typeof AssetStatus]
                  ),
                })
              }
            />
            <MultilineTextArea
              id="identifier-filter-control"
              label={
                columns.find((label) => label.name === "identifier")?.label ||
                "identifier"
              }
              value={filters.identifier.join("\n")}
              helperText="Enter one identifier per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                updateFilters({ identifier: selected });
              }}
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "serviceProviderName")
                  ?.label || "serviceProvider"
              }
              id="service-provider-filter-control"
              options={serviceProviders.slice()}
              selected={filters.serviceProviderName || []}
              onChange={(serviceProviderName) =>
                updateFilters({ serviceProviderName: serviceProviderName })
              }
            />
            <MultiSelect
              name={
                columns.find((label) => label.name === "contractTypeName")
                  ?.label || "contractType"
              }
              id="contract-type-filter-control"
              options={contractTypes.slice()}
              selected={filters.contractTypeName || []}
              onChange={(contractTypeName) =>
                updateFilters({ contractTypeName: contractTypeName })
              }
            />
            <MultiSelectSuggestions
              suggestionServiceColumn="productName"
              name={
                columns.find((label) => label.name === "productName")?.label ||
                "productName"
              }
              id="product-name-filter-control"
              selected={filters.productName || []}
              onChange={(productName) => updateFilters({ productName })}
              suggestionsService={(suggParams) =>
                getSuggestions({
                  ...suggParams,
                  filterParams: { partnerId: [partnerId] },
                })
              }
            />
            <MultiTextField
              type="decimal number"
              name={
                columns.find((label) => label.name === "sbAmount")?.label ||
                "sbAmount"
              }
              id="bAmount-filter-control"
              selected={filters.sbAmount || []}
              onChange={(sbAmount) => updateFilters({ sbAmount })}
            />
            <MultilineTextArea
              id="aleType-filter-control"
              label={
                columns.find((label) => label.name === "aleTypeName")?.label ||
                "aleTypeName"
              }
              value={filters.aleTypeName.join("\n")}
              helperText="Enter one ALE Type per line. Tip: you can paste a column from a spreadsheet here."
              onChange={(selected) => {
                updateFilters({ aleTypeName: selected });
              }}
            />
          </React.Fragment>
        )}
        chips={() => (
          <AppliedFilters
            filters={filters as QueryAssetChangesFilterParams}
            meta={appliedFiltersMeta}
            updateFilters={setFilters}
          />
        )}
      />

      <DataTable
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(tableControls) =>
          dispatch(setQueryAssetChangesTableControls(tableControls))
        }
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
