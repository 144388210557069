import React from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  setFormValues,
  submitForm,
} from "../../../store/createProductRateForm/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { RequestError } from "../../../api/types";
import { resetForm } from "../../../store/createProductRateForm/actions";
import { DecimalInput } from "../../../shared/components/DecimalInput";
import { CreateProductRateFormState } from "../../../store/createProductRateForm/types";

type Props = {
  productId: number;
  parentProductId?: number;
};

export const CreateProductRateModal = ({
  productId,
  parentProductId,
}: Props) => {
  const dispatch = useDispatch();

  const { requestError, baseValues } = useSelector<
    RootState,
    CreateProductRateFormState
  >((state: RootState) => state.createProductRateForm);

  return (
    <ModalFieldWrapper
      icon={false}
      label="Add Product Rate"
      buttonVariant="outlined"
      buttonLabel="Add Product Rate"
      onCancel={() => dispatch(resetForm())}
      disableSave={baseValues.startDate === null || baseValues.endDate === null}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              done: (err?: RequestError) => (err ? reject(err) : resolve(null)),
              productId,
              parentProductId,
            })
          );
        });
      }}
      id="create-secondary-product"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        {!parentProductId && (
          <>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Tariff Commission"
                variant="outlined"
                value={baseValues.payTariffCommission}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payTariffCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Tariff Advance"
                variant="outlined"
                value={baseValues.payTariffAdvance}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payTariffAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Tariff Commission"
                variant="outlined"
                value={baseValues.expTariffCommission || 0}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expTariffCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Tariff Advance"
                variant="outlined"
                value={baseValues.expTariffAdvance}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expTariffAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Line Rental"
                variant="outlined"
                value={baseValues.lineRental}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      lineRental: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay Volume Bonus"
                variant="outlined"
                value={baseValues.payVolumeBonus}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payVolumeBonus: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp Volume Bonus"
                variant="outlined"
                value={baseValues.expVolumeBonus}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expVolumeBonus: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
          </>
        )}
        {parentProductId && (
          <>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Service Commission"
                variant="outlined"
                value={baseValues.payServiceCommission}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payServiceCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Pay - Service Advance"
                variant="outlined"
                value={baseValues.payServiceAdvance}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      payServiceAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Service Commission"
                variant="outlined"
                value={baseValues.expServiceCommission}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expServiceCommission: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Exp - Service Advance"
                variant="outlined"
                value={baseValues.expServiceAdvance}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      expServiceAdvance: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DecimalInput
                size="small"
                label="Service Line Rental"
                variant="outlined"
                value={baseValues.serviceLineRental}
                onChange={(event) =>
                  dispatch(
                    setFormValues({
                      serviceLineRental: parseFloat(event.target.value),
                    })
                  )
                }
              />
            </FormControl>
          </>
        )}
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Start Date"
            format="dd/MM/yyyy"
            value={
              !!baseValues.startDate ? new Date(baseValues.startDate) : null
            }
            onChange={(event: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  startDate: event?.toISOString(),
                })
              );
            }}
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="End Date"
            format="dd/MM/yyyy"
            value={!!baseValues.endDate ? new Date(baseValues.endDate) : null}
            onChange={(event: Date | null, context) => {
              if (context.validationError) return;
              dispatch(
                setFormValues({
                  endDate: event?.toISOString(),
                })
              );
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
