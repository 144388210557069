import { LoaderButton } from "../../../shared/components/LoaderButton";
import { makeStyles } from "tss-react/mui";

import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import FileDownload from "js-file-download";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryAleExcel } from "../../../api/asset-ledger-entry";
import { getCollectedAdvanceExcel } from "../../../api/asset-ledger-entry/collected-advance-export";
import { getRevshareReportExcel } from "../../../api/asset-ledger-entry/revshare-export";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { DraggableModalCard } from "../../../shared/components/DraggableModalCard";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { cancelAlesView } from "../../../store/queryAles/actions";
import { RootState } from "../../../store/rootReducer";

const useStyles = makeStyles()((theme) => ({
  container: {
    maxHeight: 440,
  },
  linearProgress: {
    width: "100%",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

type Props = {
  assetId: number;
};

export const ModalAles = ({ assetId }: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const {
    show,
    items,
    revshareReceived,
    label,
    fetching,
    error,
    aleTypeId,
  } = useSelector((state: RootState) => state.queryAles);
  const handleCancel = () => dispatch(cancelAlesView());
  const ariaDescription = `asset-id-${assetId}`;

  const [isFetching, setIsFetching] = useState(false);

  const handleExport = () => {
    setIsFetching(true);
    getQueryAleExcel([assetId], aleTypeId)
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleCollectedAdvanceExport = () => {
    setIsFetching(true);
    getCollectedAdvanceExcel(assetId)
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  if (label === "Revshare Received") {
    const colSpan = 8;
    const handleExport = () => {
      setIsFetching(true);
      getRevshareReportExcel(assetId)
        .then((result) => {
          FileDownload(result.file as any, result.fileName);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };

    return (
      <DraggableModalCard
        ariaDescribedby={ariaDescription}
        open={show}
        onClose={handleCancel}
        title={label || ""}
        exportButton={() => (
          <LoaderButton
            size="large"
            loading={isFetching}
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleExport}
          >
            Export
          </LoaderButton>
        )}
      >
        <TableContainer className={classes.container}>
          <Table aria-label={ariaDescription} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="right">Partner Share</TableCell>
                <TableCell align="right">Revshare Received</TableCell>
                <TableCell align="right">Customer Billed Amount</TableCell>
                <TableCell>Bill Date</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Type Name</TableCell>
                <TableCell>SB Ref</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error ? (
                <TableCell colSpan={colSpan}>
                  <CustomStatusChip
                    type="error"
                    title={error.type}
                    message={error.message}
                  />
                </TableCell>
              ) : fetching ? (
                <TableCell colSpan={colSpan}>
                  <LinearProgress className={classes.linearProgress} />
                </TableCell>
              ) : revshareReceived.length > 0 ? (
                revshareReceived.map((item: any, i) => (
                  <TableRow key={i}>
                    <TableCell align="right" className={classes.noWrap}>
                      <span
                        style={{
                          color: item.partnerShare < 0 ? "red" : "black",
                        }}
                      >
                        {formatCurrencyGBP(item.partnerShare)}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.noWrap}>
                      <span
                        style={{
                          color: item.revshareReceived < 0 ? "red" : "black",
                        }}
                      >
                        {formatCurrencyGBP(item.revshareReceived)}
                      </span>
                    </TableCell>
                    <TableCell align="right" className={classes.noWrap}>
                      <span
                        style={{
                          color:
                            item.customerBilledAmount < 0 ? "red" : "black",
                        }}
                      >
                        {formatCurrencyGBP(item.customerBilledAmount)}
                      </span>
                    </TableCell>
                    <TableCell>{formatISOForDisplay(item.billDate)}</TableCell>
                    <TableCell>{item.reference}</TableCell>
                    <TableCell>{item.aleTypeName}</TableCell>
                    <TableCell>{item.thirdPartyInvoiceId}</TableCell>
                    <TableCell>{item.createdByName}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell colSpan={colSpan}>No matching records</TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DraggableModalCard>
    );
  }

  const colSpan = 7;

  return (
    <DraggableModalCard
      ariaDescribedby={ariaDescription}
      open={show}
      onClose={handleCancel}
      title={label || ""}
      exportButton={() => {
        return (
          <LoaderButton
            size="large"
            loading={isFetching}
            color="primary"
            variant="contained"
            type="submit"
            onClick={
              label === "Collected Advance"
                ? handleCollectedAdvanceExport
                : handleExport
            }
          >
            Export
          </LoaderButton>
        );
      }}
    >
      <TableContainer className={classes.container}>
        <Table aria-label={ariaDescription} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date Created</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Reference</TableCell>
              {/* <TableCell>Bill Date</TableCell> */}
              <TableCell>Payment Eligibility</TableCell>
              {/* <TableCell>Third Party Id</TableCell> */}
              <TableCell>Type Name</TableCell>
              <TableCell>SB Ref</TableCell>
              <TableCell>Created By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableCell colSpan={colSpan}>
                <CustomStatusChip
                  type="error"
                  title={error.type}
                  message={error.message}
                />
              </TableCell>
            ) : fetching ? (
              <TableCell colSpan={colSpan}>
                <LinearProgress className={classes.linearProgress} />
              </TableCell>
            ) : items.length > 0 ? (
              items.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{formatISOForDisplay(item.dateCreated)}</TableCell>
                  <TableCell align="right" className={classes.noWrap}>
                    <span
                      style={{
                        color: item.amount < 0 ? "red" : "black",
                      }}
                    >
                      {formatCurrencyGBP(item.amount)}
                    </span>
                  </TableCell>
                  <TableCell>{item.reference}</TableCell>
                  {/* <TableCell>{formatISOForDisplay(item.billDate)}</TableCell> */}
                  <TableCell>
                    {item.eligibleForPayment ? "YES" : "NO"}
                  </TableCell>
                  {/* <TableCell>{item.thirdPartyId}</TableCell> */}
                  <TableCell>{item.aleTypeName}</TableCell>
                  <TableCell>{item.thirdInvoicePartyId}</TableCell>
                  <TableCell>{item.createdByName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={colSpan}>No matching records</TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DraggableModalCard>
  );
};
