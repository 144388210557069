import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { NoMatch } from "../../../nav/components/NoMatch";
import { Import } from "./import";
import { ProcessPayments } from "./process";

export const Payments = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/import-revshare`}>
        <Import paymentType="REVSHARE" />
      </Route>
      <Route exact path={`${path}/import-upfront`}>
        <Import paymentType="UPFRONT" />
      </Route>
      <Route exact path={`${path}/process-revshare`}>
        <ProcessPayments paymentType="REVSHARE" />
      </Route>
      <Route exact path={`${path}/process-upfront`}>
        <ProcessPayments paymentType="UPFRONT" />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
};
