import { request } from "..";
import { PaymentType } from "../../shared/enum/payments";
import { DateString } from "../../shared/helpers/dates";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../types";

//@TODO the null values (except for dates) relate to the output of the url query params in the view layer
// they can/should be cleansed before we get to this step
export type QuerySelfBillFilterParams = {
  type?: PaymentType[];
  dateProcessed?: [DateString | null, DateString | null];
};

export type QuerySelfBillApiItem = {
  type: PaymentType;
  dateProcessed: string;
  status: number;
  totalCreated: number;
  totalPartners: number;
  totalAmount: number;
};

export type QuerySelfBillRequestParams = {
  tableParams: TableAPIRequestParams<QuerySelfBillApiItem>;
  filterParams: QuerySelfBillFilterParams;
};

export function getSelfBill({
  tableParams,
  filterParams,
}: QuerySelfBillRequestParams) {
  const tableParamsQS = stringifyTableParams(tableParams);
  const filterParamsQS = stringifyFilterParams(filterParams);
  return request<PaginatedResult<QuerySelfBillApiItem>>(
    `self-bill${combine([tableParamsQS, filterParamsQS])}`
  );
}
