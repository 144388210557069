import { call, put, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { FormSubmitAction } from "./types";
import { setUploadError, setUploadResult } from "./actions";
import { FORM_SUBMIT } from "./types";
import { postTemplateAmendments } from "../../api/asset-ledger-entry/asset-financials";

function* fetchUploadTemplateAmendments(action: FormSubmitAction) {
  try {
    const data: { message: string; success: boolean } = yield call(
      postTemplateAmendments,
      action.payload.params
    );
    yield put(setUploadResult(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setUploadError(error));
  }
}

export function* watchRequestUploadTemplateAmendments() {
  yield takeLatest(FORM_SUBMIT, fetchUploadTemplateAmendments);
}
