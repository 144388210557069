import React, {
  Fragment,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";
import {
  Button,
  Grow,
  Popper,
  Paper,
  List,
  ListItemText,
  ListItem,
  ClickAwayListener,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

const MIN_FILTERS_COUNT = 5;

export type Filter = {
  name: string;
  label: string;
  show: boolean;
};

interface Props {
  filters: Filter[];
  updateFilterVisibility: (filterId: string, value: boolean) => void;
}

const useStyles = makeStyles()(() => {
  return {
    ctr: {
      marginLeft: "auto",
      marginBottom: 16,
    },
    button: {
      padding: "5px 15px",
    },
    popper: {
      zIndex: 1100,
      maxHeight: 400,
      overflow: "auto",
    },
    filterItem: {
      minWidth: "200px",
      height: "36px",
    },
    filterName: {
      float: "left",
    },
    filterBlock: {
      flex: "auto",
    },
    checkbox: {
      float: "right",
    },
  };
});

const MoreFilters = ({ filters, updateFilterVisibility }: Props) => {
  const { classes } = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMoreFiltersClick = (event: ReactMouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  if (filters?.length <= MIN_FILTERS_COUNT) {
    return <Fragment />;
  }

  return (
    <div className={classes.ctr} ref={anchorRef}>
      <Button
        variant="contained"
        color="primary"
        onClick={onMoreFiltersClick}
        data-cy="more-filters-button"
        className={classes.button}
      >
        More filters
      </Button>
      <Popper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <List id="filters-menu" data-cy="all-filters-items">
                  {filters?.map((filter: Filter, index: any) => (
                    <ListItem
                      className={classes.filterItem}
                      key={"filter-" + index}
                      data-cy={"filter-item-" + index}
                    >
                      <div className={classes.filterBlock}>
                        <ListItemText
                          className={classes.filterName}
                          primary={filter.label}
                        />
                        <Checkbox
                          color="primary"
                          className={classes.checkbox}
                          data-cy={"filter-item-checkbox-" + index}
                          checked={filter.show}
                          onChange={(e) =>
                            updateFilterVisibility(
                              filter.name,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MoreFilters;
