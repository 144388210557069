import { RequestError } from "../../api/types";

export const SET_FORM_VALUES = "createSecondaryProductForm.SET_FORM_VALUES";
export const SET_FORM_ERROR = "createSecondaryProductForm.SET_FORM_ERROR";
export const FORM_SUBMIT = "createSecondaryProductForm.FORM_SUBMIT";
export const FORM_RESET = "createSecondaryProductForm.FORM_RESET";

export type CreateSecondaryProductFormBaseValues = {
  name: string;
  description: string;
};

export type CreateSecondaryProductFormState = {
  baseValues: CreateSecondaryProductFormBaseValues;
  requestError?: RequestError;
};

export interface SetFormValuesAction {
  type: typeof SET_FORM_VALUES;
  payload: {
    values: Partial<CreateSecondaryProductFormBaseValues>;
  };
}

export interface FormSubmitAction {
  type: typeof FORM_SUBMIT;
  payload: {
    primaryProductId: number;
    done: (err?: RequestError) => any;
  };
}

export interface SetFormErrorAction {
  type: typeof SET_FORM_ERROR;
  payload: {
    error: RequestError;
  };
}

export interface ResetFormAction {
  type: typeof FORM_RESET;
}

export type CreateSecondaryProductActions =
  | SetFormValuesAction
  | FormSubmitAction
  | SetFormErrorAction
  | ResetFormAction;
