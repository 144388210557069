import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type PartnerAdminInformationReportApiItem = {
  readonly partnerId: number;
  readonly partnerName: string;
  readonly thirdPartyId: string;
  readonly partnerStatusName: string;
  readonly partnerStatus: string;
  readonly paymentTermsName: string;
  readonly paymentTerms: number;
  readonly bdm: string;
  readonly networkBilledPartnerManager: string;
  readonly region: string;
  readonly territory: string;
  readonly serviceProviderEESmb: string;
  readonly eeSmePartnerCode: string;
  readonly serviceProviderEECorp: string;
  readonly eeCorpPartnerCode: string;
  readonly serviceProviderO2: string;
  readonly o2PartnerCode: string;
  readonly serviceProviderVodafone: string;
  readonly vodafonePartnerCode: string;
};

export interface PartnerAdminInformationReportParams {
  readonly partnerStatuses: (number | null)[];
  readonly paymentTerms: (number | null)[];
  readonly bdms: (string | null)[];
  readonly networkBilledPartnerManagers: (string | null)[];
  readonly territories: (string | null)[];
  readonly serviceProviderIds: (number | null)[];
  readonly tableControls: TableAPIRequestParams<
    PartnerAdminInformationReportApiItem
  >;
}

export function postGeneratePartnerAdminInformationReport(
  params: PartnerAdminInformationReportParams
) {
  return request<PaginatedResult<PartnerAdminInformationReportParams>>(
    `partner-report/generate-partner-admin-info-report${combine([
      stringifyTableParams(params.tableControls),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}

export function postExportPartnerAdminInformationReport(
  params: Omit<PartnerAdminInformationReportParams, "tableControls">
) {
  return requestFile(
    "partner-report/generate-partner-admin-info-report?export=true&returnAll=true",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}
