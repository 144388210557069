import { request } from "../..";

export function postUpdateAssetEndDates(file: File) {
  let body = new FormData();
  body.append("file", file);

  return request(
    "asset/update-assets-end-date",
    { method: "POST", body },
    false
  );
}
