import { MenuItem, TextField } from "@mui/material";
import React from "react";

export function Dropdown<T extends number>({
  label,
  value,
  options,
  disabled,
  onChange,
}: {
  label: string;
  value: number;
  options: { [key: string]: T };
  disabled?: boolean;
  onChange: (value: number) => any;
}) {
  return (
    <TextField
      disabled={disabled}
      size="small"
      fullWidth
      variant="outlined"
      select
      label={label}
      value={value}
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        const option = parseInt(event.target.value, 10);
        onChange(option);
      }}
    >
      {Object.keys(options).map((key: string) => (
        <MenuItem key={key} value={options[key]}>
          {key}
        </MenuItem>
      ))}
    </TextField>
  );
}
