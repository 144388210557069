import { request, requestFile } from "..";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import { ContractType, AssetStatus } from "../../shared/enum/asset";
import { DateString } from "../../shared/helpers/dates";
import { stringify } from "query-string";
import {
  combine,
  stringifyFilterParams,
  stringifyTableParams,
} from "../../shared/helpers/querySerialisation";
import { TableAPIRequestParams } from "../types";

//@TODO the null values (except for dates) relate to the output of the url query params in the view layer
// they can/should be cleansed before we get to this step
export type QueryAssetChangesFilterParams = {
  assetId?: (number | null)[];
  startDate?: [DateString | null, DateString | null];
  endDate?: [DateString | null, DateString | null];
  customerName?: (string | null)[];
  assetStatus?: (AssetStatus | null)[];
  identifier?: (string | null)[];
  serviceProvider?: ServiceProvider[];
  contractType?: ContractType[];
  productName?: (string | null)[];
  sbAmount?: (number | null)[];
  aleTypeName?: (string | null)[];
  partnerId?: number;
};

export type QueryAssetChangesApiItem = {
  assetId: number;
  startDate: string;
  endDate: string;
  customerName: string;
  assetStatus: AssetStatus;
  identifier: string;
  serviceProviderName: ServiceProvider;
  contractTypeName: ContractType;
  productName: string;
  sbAmount: number;
  aleTypeName: string;
};
export type QueryAssetChangesRequestParams = {
  tableParams: TableAPIRequestParams<QueryAssetChangesApiItem>;
  filterParams: QueryAssetChangesFilterParams;
  export?: boolean;
  partnerId: number;
  returnAll?: boolean;
};
export interface PaginatedChangesResult<T> {
  items: T[];
  pageNumber: number;
  pageSize: number;
  count: number;
  TotalSBAmount: number;
}
export function getTotalSBAmountOfAssetChangesDue(partnerId: number) {
  return request<number>(
    `asset/changes-due-sb-amount-sum?partnerId=${partnerId}`
  );
}

export function getQueryAssetChanges(params: QueryAssetChangesRequestParams) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const partnerIdQS = `partnerId=${params.partnerId.toString()}`;
  return request<PaginatedChangesResult<QueryAssetChangesApiItem>>(
    `asset/changes-due${combine([tableParamsQS, filterParamsQS, partnerIdQS])}`
  );
}

export function getQueryAssetChangesExcel(
  params: QueryAssetChangesRequestParams
) {
  const tableParamsQS = stringifyTableParams(params.tableParams);
  const filterParamsQS = stringifyFilterParams(params.filterParams);
  const exportFlag = stringify({ export: true, returnAll: true });
  const partnerIdQS = `partnerId=${params.partnerId.toString()}`;
  return requestFile(
    `asset/changes-due${combine([
      tableParamsQS,
      filterParamsQS,
      exportFlag,
      partnerIdQS,
    ])}`
  );
}
