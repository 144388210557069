import { request } from "../../..";

export interface UpdateOocPaymentTermParams {
  readonly partnerServiceProviderOOCPaymentTermId: number;
  readonly oocPaymentTermId: number;
  readonly assetStartDateFrom: string;
  readonly assetStartDateTo: string;
}

export function postUpdateOocPaymentTerm(params: UpdateOocPaymentTermParams) {
  return request("partner-service-provider/ooc-payment-term/update", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
