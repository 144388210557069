import React, { createRef, useEffect } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { DatePicker } from "@mui/x-date-pickers";
import { toDateString } from "../../../shared/helpers/dates";
import {
  setFormValues,
  submitForm,
  initForm,
  setError,
  setClientError,
} from "../../../store/bulkEditPartnerRateForm/actions";
import { RequestError } from "../../../api/types";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";

type Props = {
  partnerId: number;
  partnerRateAdjustmentIds: number[];
  disabled?: boolean;
};

export const BulkEditPartnerRateModal = ({
  partnerId,
  partnerRateAdjustmentIds,
  disabled,
}: Props) => {
  const dispatch = useDispatch();
  const formRef = createRef<HTMLFormElement>();

  const { isFetching, clientError, error, values } = useSelector(
    (state: RootState) => state.bulkEditPartnerRateForm
  );

  useEffect(() => {
    dispatch(
      initForm({
        partnerId,
        partnerRateAdjustmentIds,
      })
    );
  }, [partnerId, partnerRateAdjustmentIds, dispatch]);

  const onCancel = () => {
    dispatch(setError(undefined));
    dispatch(setClientError(undefined));
  };

  return (
    <ModalFieldWrapper
      label="Edit Partner Rates"
      onCancel={onCancel}
      onSave={() => {
        return new Promise((resolve, reject) => {
          dispatch(
            submitForm({
              partnerId,
              partnerRateAdjustmentIds,
              done: (err?: RequestError) =>
                err ? reject(err) : resolve(window.location.reload()),
            })
          );
        });
      }}
      id={`bulk-edit-partner-rate-${partnerId}`}
      gutterSize="small"
      disableSave={
        values.adjStartDate === null ||
        values.adjEndDate === null ||
        !!clientError
      }
      buttonDisable={disabled}
      buttonVariant="outlined"
      buttonLabel="Edit all"
      apiError={error}
      apiFetching={isFetching}
      formRef={formRef}
    >
      <>
        {!clientError && (
          <FormFieldsWrapper>
            <FormControl variant="outlined" size="small" fullWidth>
              <DatePicker
                label="Adjustment Start Date"
                format="dd/MM/yyyy"
                value={
                  !!values.adjStartDate ? new Date(values.adjStartDate) : null
                }
                disabled
                onChange={(value: Date | null, context) => {
                  if (context.validationError) return;
                  dispatch(
                    setFormValues({
                      adjStartDate: value ? toDateString(value) : undefined,
                    })
                  );
                }}
              />
            </FormControl>
            <FormControl variant="outlined" size="small" fullWidth>
              <DatePicker
                label="Adjustment End Date"
                format="dd/MM/yyyy"
                value={!!values.adjEndDate ? new Date(values.adjEndDate) : null}
                onChange={(value: Date | null, context) => {
                  if (context.validationError) return;
                  dispatch(
                    setFormValues({
                      adjEndDate: value ? toDateString(value) : undefined,
                    })
                  );
                }}
              />
            </FormControl>
          </FormFieldsWrapper>
        )}
        {!!clientError && (
          <CustomStatusChip type="error" title="Error" message={clientError} />
        )}
      </>
    </ModalFieldWrapper>
  );
};
