import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import * as React from "react";
import { AssetProductApiItem } from "../../../api/asset-product";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";

type Props = {
  handleAdd: (product: ProductRateByIdApiItem) => void;
  handleDelete: (productId: number) => void;
  newProducts: ProductRateByIdApiItem[];
  secondaryProducts: AssetProductApiItem[];
  options: ProductRateByIdApiItem[];
};

export default function SecondaryProductSelect({
  options,
  secondaryProducts,
  newProducts,
  handleAdd,
  handleDelete,
}: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Paper
        style={{
          width: "100%",
          height: 150,
          overflow: "auto",
          marginBottom: 16,
        }}
      >
        <List dense component="div" role="list">
          {newProducts.map((product: ProductRateByIdApiItem, index: number) => (
            <ListItem
              style={{ width: "100%" }}
              key={index}
              button
              onClick={() => handleDelete(product.productId)}
            >
              <ListItemText
                id={product.productName}
                primary={product.productName}
              />
              <ListItemIcon style={{ minWidth: "unset" }}>
                <Close htmlColor="red" />
              </ListItemIcon>
            </ListItem>
          ))}
          {secondaryProducts.map((product: AssetProductApiItem) => {
            const { productId, assetProductId } = product;
            const productName = options.find(
              (option) => option.productId === productId
            )?.productName;

            return (
              <ListItem
                button
                style={{ width: "100%" }}
                key={assetProductId}
                onClick={() => handleDelete(assetProductId)}
              >
                <ListItemText id={productName} primary={productName} />
                <ListItemIcon style={{ minWidth: "unset" }}>
                  <Close htmlColor="red" />
                </ListItemIcon>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
      <Paper style={{ width: "100%", height: 150, overflow: "auto" }}>
        <List dense component="div" role="list">
          {options.map((option: ProductRateByIdApiItem) => {
            return (
              <ListItem
                style={{ width: "100%" }}
                key={option.productId}
                button
                onClick={() => handleAdd(option)}
              >
                <ListItemText
                  id={option.productName}
                  primary={option.productName}
                />
                <ListItemIcon style={{ minWidth: "unset" }}>
                  <Add />
                </ListItemIcon>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </Box>
  );
}
