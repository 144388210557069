import { FinancialBreakdown } from "../../api/asset-ledger-entry/financial-overview";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  SET_DATA,
  SET_ERROR,
  CANCEL_BREAKDOWN_MODAL,
  REQUEST_DATA,
  FinancialBreakdownType,
} from "./types";

export function requestFinancialBreakdownData(params: {
  assetId: number;
  breakdownType: FinancialBreakdownType;
}) {
  return {
    type: REQUEST_DATA,
    payload: params,
  };
}

export function setFinancialBreakdownData(
  data: PaginatedResult<FinancialBreakdown>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setFinancialBreakdownError(error: RequestError) {
  return {
    type: SET_ERROR,
    payload: error,
  };
}

export function cancelBreakdownModal() {
  return {
    type: CANCEL_BREAKDOWN_MODAL,
  };
}
