import { PaymentDataSummaryApi } from "../../api/payment-data/summary";
import { RequestError } from "../../api/types";
import {
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_ENABLE_CREATE_ENTITIES,
  SET_BATCH_STATUS,
} from "./types";

export function requestPaymentDataSummary(batchId: number) {
  return {
    type: REQUEST_DATA,
    payload: batchId,
  };
}

export function setPaymentDataSummaryErrorForDisplay(error: RequestError) {
  return {
    type: SET_API_ERROR,
    payload: error,
  };
}

export function setPaymentDataSummaryData(data: PaymentDataSummaryApi) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function setEnableCreateEntities(enable: boolean) {
  return {
    type: SET_ENABLE_CREATE_ENTITIES,
    payload: {
      enable,
    },
  };
}

export function setBatchStatus(batchStatus: number) {
  return {
    type: SET_BATCH_STATUS,
    payload: {
      batchStatus,
    },
  };
}
