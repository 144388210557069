import {
  UnraisedRevshareApiItem,
  UnraisedRevshareReportParams,
} from "../../api/asset-report/generate-unraised-revshare-report";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";
import {
  REQUEST_DATA,
  SET_DATA,
  SET_ERROR,
  SET_TABLE_CONTROLS,
  CLEAR_DATA_TABLE,
} from "./types";

export function requestUnraisedRevshareReport(
  params: UnraisedRevshareReportParams
) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setUnraisedRevshareReportTableControls(
  tableControls: TableAPIRequestParams<UnraisedRevshareApiItem>
) {
  return {
    type: SET_TABLE_CONTROLS,
    payload: {
      tableControls,
    },
  };
}

export function setUnraisedRevshareReportData(
  data: PaginatedResult<UnraisedRevshareApiItem>
) {
  return {
    type: SET_DATA,
    payload: data,
  };
}

export function clearUnraisedRevshareReportTableData() {
  return {
    type: CLEAR_DATA_TABLE,
  };
}

export function setUnraisedRevshareReportErrorForDisplay(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
