import {
  CreatePrimaryProductActions,
  CreatePrimaryProductFormState,
  FORM_INITIALIZE_FINISH,
  FORM_INITIALIZE_START,
  FORM_RESET,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
  SET_NEW_PRODUCT,
  SET_PRODUCT_CLASS_OPTIONS,
  SET_PRODUCT_GROUP_OPTIONS,
} from "./types";

const initialState: CreatePrimaryProductFormState = {
  requestError: undefined,
  fetching: false,
  values: {},
  durationOptions: [],
  productTypeOptions: [],
  productClassOptions: [],
  productGroupOptions: [],
};

export function createPrimaryProductFormReducer(
  state = initialState,
  action: CreatePrimaryProductActions
): CreatePrimaryProductFormState {
  switch (action.type) {
    case FORM_INITIALIZE_START: {
      return {
        ...initialState,
        fetching: true,
        newProduct: undefined,
      };
    }

    case FORM_INITIALIZE_FINISH: {
      return {
        ...state,
        fetching: false,
        productTypeOptions: action.payload.productTypeOptions,
        productGroupOptions: action.payload.productGroupOptions,
      };
    }

    case SET_PRODUCT_CLASS_OPTIONS: {
      return {
        ...state,
        productClassOptions: action.payload.productClassOptions,
      };
    }

    case SET_PRODUCT_GROUP_OPTIONS: {
      return {
        ...state,
        productGroupOptions: action.payload.productGroupOptions,
      };
    }

    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_NEW_PRODUCT: {
      return {
        ...state,
        newProduct: action.payload.product,
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        requestError: action.payload.error,
      };
    }

    case FORM_RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
