import { request } from "../..";

export function postUpdateSubscriberId(file: File) {
  let body = new FormData();
  body.append("file", file);

  return request(
    "asset/update-asset-subscriberids",
    { method: "POST", body },
    false
  );
}
