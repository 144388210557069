import {
  CreditNoteLinesAPIItem,
  CreditNoteLinesParams,
} from "../../api/posted-document/credit-note-lines/index";
import { PaginatedResult, RequestError } from "../../api/types";
import { REQUEST_DATA, SET_DATA, SET_ERROR, SetDataAction } from "./types";

export function requestCreditNoteLines(params: CreditNoteLinesParams) {
  return {
    type: REQUEST_DATA,
    payload: {
      params,
    },
  };
}

export function setCreditNoteLinesData(
  data: PaginatedResult<CreditNoteLinesAPIItem>
): SetDataAction {
  return {
    type: SET_DATA,
    payload: {
      data,
    },
  };
}

export function setCreditNoteLinesError(err: RequestError) {
  return {
    type: SET_ERROR,
    payload: {
      err,
    },
  };
}
