import {
  CommentsActionTypes,
  CommentsState,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  TOGGLE_COMMENTS,
} from "./types";

const initialState: CommentsState = {
  items: [],
  fetching: false,
  show: false,
};

export function commentsReducer(
  state = initialState,
  action: CommentsActionTypes
): CommentsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.data,
      };

    case SET_ERROR:
      return { ...state, error: action.payload.error, fetching: false };

    case TOGGLE_COMMENTS: {
      return {
        ...state,
        show: action.payload.show,
      };
    }

    default:
      return state;
  }
}
