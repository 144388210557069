import { RequestError } from "../../api/types";
import { JobProgressApiItem } from "../../api/jobprogress";

export const SET_DATA = "progressReport.SET_DATA";
export const REQUEST_DATA = "progressReport.REQUEST_DATA";
export const SET_API_ERROR = "progressReport.SET_API_ERROR";

export type ProgressReportState = {
  items: JobProgressApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: JobProgressApiItem[];
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type ProgressReportActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction;
