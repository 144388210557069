import React from "react";
import { Box, Button } from "@mui/material";
import { includes, toArray } from "lodash";
import pickBy from "lodash/pickBy";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { SelfBillListItem } from "../../../store/selfBillList/types";
import { TableAPIRequestParams } from "../../../api/types";
import {
  QuerySelfBillListFilterParams,
  SelfBillListApiItem,
} from "../../../api/invoice/self-bill-list";
import { requestPostCheckedSelfBillInvoices } from "../../../store/selfBillList/actions";


interface PostSelfBillsButtonProps {
  tableControls: TableAPIRequestParams<SelfBillListApiItem>;
  filterParams: QuerySelfBillListFilterParams;
  cleanseRequestSelfBillListData: (
    filters: any
  ) => QuerySelfBillListFilterParams;
}

export default function PostSelfBillsButton({
  tableControls,
  filterParams,
  cleanseRequestSelfBillListData
}: PostSelfBillsButtonProps) {
  const { items, checked } = useSelector(
    (state: RootState) => state.selfBillList
  );

  const dispatch = useDispatch();

  const checkedItems = toArray(
    pickBy(items, (i: SelfBillListItem) => includes(checked, i.invoiceId))
  );

  const hasNonPostableItems = checkedItems.some((item) => !item.canPost);

  const handlePostSelfBillsSubmit = () => {
    const pageParams = {
      tableControls: tableControls,
      filterParams: cleanseRequestSelfBillListData(filterParams),
    };
    dispatch(
      requestPostCheckedSelfBillInvoices(checkedItems.map((item) => item.invoiceId), pageParams)
    );
  }


  return (
    <Box display="flex" justifyContent="flex-end" paddingBottom={1}>
      <Button
        variant="contained"
        aria-label="Button for post self bills"
        disabled={checked.length === 0 || hasNonPostableItems}
        size="large"
        onClick={() => handlePostSelfBillsSubmit()}
      >
        Post Self Bills
      </Button>
    </Box>
  );
}
