import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getDetailAsset,
  DetailAssetApiItem,
  putUpdateAsset,
  UpdateAssetParams,
} from "../../api/asset";
import {
  FinancialOverview,
  getFinancialOverview,
} from "../../api/asset-ledger-entry/financial-overview";
import { ProofApiItem, getProof } from "../../api/proof";
import { PaginatedResult, RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import {
  requestDetailAssetData,
  setDetailAssetData,
  setDetailAssetRequestError,
} from "./actions";
import {
  DetailAssetsState,
  RequestDataAction,
  RequestUpdateItemAction,
  RequestTrueUpAssetAction,
  REQUEST_DATA,
  REQUEST_TRUE_UP,
  REQUEST_UPDATE_ITEM,
} from "./types";
import {
  getSecondaryProductRatesByIdAndStartDate,
  ProductRatesByIdApi,
} from "../../api/product/product-rates";
import { postRequestTrueUp } from "../../api/asset/trueup";
import { AssetProductApiItem, getAssetProducts } from "../../api/asset-product";

function* fetchDetailAssetsData(action: RequestDataAction) {
  try {
    const data: {
      asset: DetailAssetApiItem;
      financial: FinancialOverview;
      proof: ProofApiItem;
      assetProducts: PaginatedResult<AssetProductApiItem>;
    } = yield all({
      asset: call(getDetailAsset, action.payload),
      assetProducts: call(getAssetProducts, action.payload),
      financial: call(getFinancialOverview, action.payload),
      proof: call(getProof, action.payload),
    });

    const secondaryProducts: ProductRatesByIdApi = yield call(
      getSecondaryProductRatesByIdAndStartDate,
      data.asset.productId,
      data.asset.startDate
    );
    data.asset.secondaryProductOptions = secondaryProducts.items;

    yield put(
      setDetailAssetData({
        ...data.asset,
        ...data.financial,
        ...data.proof,
        secondaryProducts: data.assetProducts.items.filter(
          (ap) => !ap.isPrimary
        ),
      })
    );
  } catch (e) {
    console.error(e);
    const error: RequestError = e;
    yield put(setDetailAssetRequestError({ id: action.payload, ...error }));
  }
}

function* fetchUpdateAsset(action: RequestUpdateItemAction) {
  const assetId = action.payload.id;
  const state: DetailAssetsState = yield select(
    (state: RootState) => state.detailAssets
  );
  const item = state.items[assetId];

  const update: UpdateAssetParams = {
    assetId: item.assetId,
    status: item.status,
    identifier: item.identifier,
    assetInfos: item.assetInfos,
    subscriberId: item.subscriberId,
    startDate: item.startDate,
    endDate: item.endDate,
    customerName: item.customerName,
    customerPostcode: item.customerPostcode,
    spAssetStatus: item.spAssetStatus,
    spAssetStatusChangeDate: item.spAssetStatusChangeDate,
    spAssetStatusChangeType: item.spAssetStatusChangeType,
    isAdvanceTaken: item.advanceTaken,
    spAccountRef: item.spAccountRef,
    ...action.payload.updates,
  };

  try {
    yield call(putUpdateAsset, assetId, update);
    yield put(requestDetailAssetData(assetId));
    yield call(action.payload.done);
  } catch (e) {
    const error: RequestError = e;
    yield put(setDetailAssetRequestError({ id: assetId, ...error }));
    yield call(() => action.payload.done(error));
  }
}

function* fetchRequestTrueUp(action: RequestTrueUpAssetAction) {
  const { assetId } = action.payload;

  try {
    yield call(postRequestTrueUp, assetId);
    yield put(requestDetailAssetData(assetId));
  } catch (e) {
    console.error(e);
    const error: RequestError = e;
    yield put(setDetailAssetRequestError({ id: assetId, ...error }));
  }
}

export function* watchRequestDetailAssetsData() {
  yield takeLatest(REQUEST_DATA, fetchDetailAssetsData);
}

export function* watchRequestUpdateAssetsData() {
  yield takeLatest(REQUEST_UPDATE_ITEM, fetchUpdateAsset);
}

export function* watchRequestAssetTrueUp() {
  yield takeLatest(REQUEST_TRUE_UP, fetchRequestTrueUp);
}
