import { call, put, select, takeLatest } from "redux-saga/effects";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  EditOocRevenueShareFormState,
} from "./types";
import { requestDetailPartnerData } from "../detailPartners/actions";
import { postUpdateOocPaymentTerm } from "../../api/partner-service-provider/ooc-payment-term/update";

function* submitEditOocPaymentTermForm(action: FormSubmitAction) {
  try {
    const formState: EditOocRevenueShareFormState = yield select(
      (state: RootState) => state.editOocRevenueShareForm
    );

    yield call(postUpdateOocPaymentTerm, {
      partnerServiceProviderOOCPaymentTermId:
        action.payload.partnerServiceProviderOOCPaymentTermId,
      ...formState.values,
    });

    yield put(requestDetailPartnerData(action.payload.partnerId));

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(
      setError({
        id: action.payload.partnerServiceProviderOOCPaymentTermId,
        ...error,
      })
    );
  }
}

export function* watchSubmitEditOocPaymentTermForm() {
  yield takeLatest(FORM_SUBMIT, submitEditOocPaymentTermForm);
}
