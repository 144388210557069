import React from "react";
import {
  TextField,
  MenuItem,
  IconButton,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import { RequestError } from "../../api/types";
import { CustomStatusChip } from "./CustomStatusChip";

type EditableFieldWithDropdownProps<T> = {
  items: T[];
  selectedItem: number;
  handleDropdownChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleClearItem: () => void;
  error?: RequestError;
  loading: boolean;
  label: string;
  labelForDropdown: string;
  getItemKey: (item: T) => string | number;
  getItemFirstValueToDisplay?: (item: T) => string;
  getItemSecondValueToDisplay?: (item: T) => string;
};

const useStyles = makeStyles()((theme) => ({
  label: {
    width: 350,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  textField: {
    width: "auto",
    minWidth: "200px",
    marginTop: "10px",
  },
  iconButton: {
    marginLeft: "8px",
    marginTop: "2px",
  },
}));

const EditableFieldWithDropdown = <T,>({
  items,
  selectedItem,
  handleDropdownChange,
  handleClearItem,
  error,
  loading,
  label,
  labelForDropdown,
  getItemKey,
  getItemFirstValueToDisplay,
  getItemSecondValueToDisplay,
}: EditableFieldWithDropdownProps<T>) => {
  const { classes } = useStyles();

  const ErrorPlaceholder = () => (
    <Box display="flex" width="100%" alignItems="center">
      {error && (
        <>
          <Typography className={classes.label}>{label}</Typography>
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        </>
      )}
    </Box>
  );

  return (
    <Box display="flex" width="100%" alignItems="center">
      {error ? (
        <ErrorPlaceholder />
      ) : (
        <Box display="flex" width="100%" alignItems="center">
          <div className={classes.label}>{label}</div>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <TextField
              className={classes.textField}
              select
              label={labelForDropdown}
              variant="outlined"
              size="small"
              value={
                items.length > 0
                  ? selectedItem
                    ? selectedItem.toString()
                    : ""
                  : ""
              }
              onChange={handleDropdownChange}
            >
              {items.map((item) => (
                <MenuItem
                  key={getItemKey(item)}
                  value={getItemKey(item).toString()}
                >
                  {getItemFirstValueToDisplay
                    ? `${getItemFirstValueToDisplay(item)} (${
                        getItemSecondValueToDisplay
                          ? getItemSecondValueToDisplay(item)
                          : ""
                      })`
                    : getItemKey(item)}
                </MenuItem>
              ))}
            </TextField>
          )}
          <IconButton
            onClick={handleClearItem}
            aria-label="clear selection"
            size="small"
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default EditableFieldWithDropdown;
