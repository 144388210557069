import {
  EditPartnerRateActions,
  EditPartnerRateFormState,
  RESET_FORM_VALUES,
  SET_FORM_ERROR,
  SET_FORM_VALUES,
} from "./types";

const initialState: EditPartnerRateFormState = {
  errors: {},
  isFetching: false,
  values: {
    adjEndDate: "",
    adjStartDate: "",
    adjustment: 0,
  },
};

export function editPartnerRateFormReducer(
  state = initialState,
  action: EditPartnerRateActions
): EditPartnerRateFormState {
  switch (action.type) {
    case SET_FORM_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    }

    case SET_FORM_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.partnerRateAdjustmentId]: action.payload.error,
        },
      };
    }

    case RESET_FORM_VALUES: {
      return initialState;
    }

    default:
      return state;
  }
}
