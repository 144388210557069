import {
  QueryProductApiItem,
  ReadProductApiItem,
  UpdateProductApiItem,
} from "../../api/product";
import { ProductGroupApi } from "../../api/product/product-group";
import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { ProductTypeOption } from "../../api/product/product-types";
import { SpendCapPeriodItem } from "../../api/spend-cap-period/by-productId-date";
import { RequestError } from "../../api/types";
import { EditProductRateFormBaseValues } from "../editProductRateForm/types";

export const SET_DATA = "detailProducts.SET_DATA";
export const REQUEST_DATA = "detailProducts.REQUEST_DATA";
export const REQUEST_UPDATE_ITEM = "detailProducts.REQUEST_UPDATE_ITEM";
export const UPDATE_ITEM_DATA = "detailProducts.UPDATE_ITEM_DATA";
export const SET_API_ERROR = "detailProducts.SET_API_ERROR";
export const SET_TAB = "detailProducts.SET_TAB";
export const SET_SECONDARY_PRODUCT = "detailProducts.SET_SECONDARY_PRODUCT";
export const SET_ACTIVE_PERIOD = "detailProducts.SET_ACTIVE_PERIOD";
export const UPDATE_PRODUCT_RATE = "detailProducts.UPDATE_PRODUCT_RATE";
export const DELETE_PRODUCT_RATE = "detailProducts.DELETE_PRODUCT_RATE";

export interface DetailProductsState {
  items: {
    [id: number]: {
      primaryProduct: QueryProductApiItem;
      secondaryProducts: ReadProductApiItem[];
      primaryRates: ProductRateByIdApiItem[];
      secondaryRates: ProductRateByIdApiItem[];
      spendCaps: SpendCapPeriodItem[];
      productTypeOptions: ProductTypeOption[];
      durationOptions: string[];
      productGroupOptions: ProductGroupApi[];
      linkedToAsset: boolean;
    };
  };
  fetchingIds: number[];
  errors: ({ id: number } & RequestError)[];
  tabIndex: number;
  activeSecondaryProductId?: number;
  activeSpendCapPeriodId?: number;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: number; // id
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    primaryProduct: QueryProductApiItem;
    secondaryProducts: ReadProductApiItem[];
    primaryRates: ProductRateByIdApiItem[];
    secondaryRates: ProductRateByIdApiItem[];
    spendCaps: SpendCapPeriodItem[];
    productTypeOptions: ProductTypeOption[];
    durationOptions: string[];
    productGroupOptions: ProductGroupApi[];
    linkedToAsset: boolean;
  };
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: { id: number } & RequestError;
}

interface SetTabIndexAction {
  type: typeof SET_TAB;
  payload: { tabIndex: number };
}

interface SetSecondaryProductAction {
  type: typeof SET_SECONDARY_PRODUCT;
  payload: { activeId: number };
}

interface SetActivePeriodAction {
  type: typeof SET_ACTIVE_PERIOD;
  payload: {
    activePeriodId: number;
  };
}

export interface RequestUpdateItemAction {
  type: typeof REQUEST_UPDATE_ITEM;
  payload: {
    id: number;
    updates: Partial<UpdateProductApiItem>;
    done: () => any;
  };
}

export interface UpdateItemDataAction {
  type: typeof UPDATE_ITEM_DATA;
  payload: { id: number; updates: Partial<UpdateProductApiItem> };
}

interface UpdateProductRateAction {
  type: typeof UPDATE_PRODUCT_RATE;
  payload: {
    productRate: EditProductRateFormBaseValues;
  };
}

export interface DeleteProductRateAction {
  type: typeof DELETE_PRODUCT_RATE;
  payload: {
    productRateId: number;
    productId: number;
    done: (err?: RequestError) => any;
  };
}

export type DetailProductsActionType =
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction
  | SetTabIndexAction
  | SetSecondaryProductAction
  | SetActivePeriodAction
  | RequestUpdateItemAction
  | UpdateItemDataAction
  | UpdateProductRateAction
  | DeleteProductRateAction;
