import { PaymentDataApiItem } from "../../api/payment-data";
import { PaymentDataReason, Status } from "../../shared/enum/payments";
import {
  REQUEST_DATA,
  SET_API_ERROR,
  SET_DATA,
  PaymentDataActionTypes,
  PaymentDataState,
} from "./types";

const initialState: PaymentDataState = {
  items: [],
  count: 0,
  fetching: false,
};

export function PaymentDataReducer(
  state = initialState,
  action: PaymentDataActionTypes
): PaymentDataState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_API_ERROR:
      return { ...state, error: action.payload, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        count: action.payload.count,
        items: mapAPIDataToState(action.payload.items),
      };
    default:
      return state;
  }
}

function mapAPIDataToState(paymentApiDataItems: PaymentDataApiItem[]) {
  return paymentApiDataItems.map((item) => ({
    ...item,
    reason: PaymentDataReason[item.reason],
    status: Status[item.status],
  }));
}
