import { requestFile } from "../..";
import { DateString } from "../../../shared/helpers/dates";

export type PrimaryProductReportParams = {
  startDate: DateString | null;
  endDate: DateString | null;
  serviceProvider: string;
};

export function getPrimaryProductReport(params: PrimaryProductReportParams) {
  return requestFile("product-report/generate-primary-product-report", {
    method: "POST",
    body: JSON.stringify(params),
  });
}
