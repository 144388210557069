import { call, put, takeLatest } from "redux-saga/effects";
import { AssetReportApiItem, getAssetReport } from "../../api/asset-report";
import { PaginatedResult, RequestError } from "../../api/types";
import { setAssetReportData, setAssetReportErrorForDisplay } from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchAssetReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<AssetReportApiItem> = yield call(
      getAssetReport,
      action.payload.params
    );

    yield put(setAssetReportData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setAssetReportErrorForDisplay(error));
  }
}

export function* watchRequestAssetReportData() {
  yield takeLatest(REQUEST_DATA, fetchAssetReport);
}
