import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "../../shared/components/RestrictedRoute";
import { Permissions } from "../../store/permissions/types";
import { QueryAssets } from "./QueryAssets";
import { UpdateAsset } from "./UpdateAsset";
import { ViewAsset } from "./ViewAsset";

export const Assets = () => {
  const { path } = useRouteMatch();
  const title = "Assets";
  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <QueryAssets title={title} />
      </Route>
      <RestrictedRoute
        permissions={[Permissions.ReadAssets, Permissions.WriteAssets]}
        exact
        path={`${path}/update`}
      >
        <UpdateAsset />
      </RestrictedRoute>
      <Route path={`${path}/:id`}>
        <ViewAsset title={title} />
      </Route>
    </Switch>
  );
};
