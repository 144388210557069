import { stringify } from "query-string";
import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type UnraisedRevshareApiItem = {
  identifier: string;
  assetId: number;
  tempCtn: string;
  startDate: string;
  endDate: string;
  status: number;
  dealer: string;
  customerName: string;
  provider: string;
  productCode: string;
  productName: string;
  partnerCode: string;
  partnerShare: number;
  revshareReceived: number;
  customerBilledAmount: number;
  billDate: string;
  reference: string;
};

export type UnraisedRevshareReportParams = {
  startDate: string | null;
  endDate: string | null;
  serviceProviderId: number;
  thirdPartyIds: (string | null)[];
  tableControls: TableAPIRequestParams<UnraisedRevshareApiItem>;
};

export function getUnraisedRevshareReport(
  params: UnraisedRevshareReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return request<PaginatedResult<UnraisedRevshareApiItem>>(
    `asset-report/generate-unraised-revshare-report${combine([tableParamsQS])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderId: params.serviceProviderId,
        thirdPartyIds: params.thirdPartyIds,
      }),
    }
  );
}

export function getUnraisedRevshareReportExcel(
  params: UnraisedRevshareReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: true,
  });

  return requestFile(
    `asset-report/generate-unraised-revshare-report${combine([
      tableParamsQS,
      stringify({ export: true }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderId: params.serviceProviderId,
        thirdPartyIds: params.thirdPartyIds,
      }),
    }
  );
}
