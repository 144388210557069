import { request } from "../../..";

export type PartnerThirdPartyIdApiItem = {
  readonly partnerId: number;
  readonly thirdPartyId: string;
};

export function getPartnerThirdPartyIds(batchId: number) {
  return request<PartnerThirdPartyIdApiItem[]>(
    `payment-data/batches/partner-third-party-ids/${batchId}`
  );
}
