import { FinancialBreakdown } from "../../api/asset-ledger-entry/financial-overview";
import { PaginatedResult, RequestError } from "../../api/types";

export interface FinancialBreakdownState {
  items: FinancialBreakdown[];
  fetching: boolean;
  show: boolean;
  error?: RequestError;
  label?: string;
}

export type FinancialBreakdownType = "invoice" | "clawback";

export const SET_DATA = "financialBreakdown.SET_DATA";
export const SET_ERROR = "financialBreakdown.SET_ERROR";
export const REQUEST_DATA = "financialBreakdown.REQUEST_DATA";
export const CANCEL_BREAKDOWN_MODAL =
  "financialBreakdown.CANCEL_BREAKDOWN_MODAL";

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: { assetId: number; breakdownType: FinancialBreakdownType };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<FinancialBreakdown>;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: RequestError;
}

export interface CancelModalAction {
  type: typeof CANCEL_BREAKDOWN_MODAL;
}

export type FinancialBreakdownActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction
  | CancelModalAction;
