import { Box, List, ListItem, Switch } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailAssetApiItem } from "../../../api/asset";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";
import { EditableField } from "../../../shared/components/EditableField";
import { toDateString } from "../../../shared/helpers/dates";
import { requestUpdateAssetItem } from "../../../store/detailAssets/actions";
import { getPermission } from "../../../store/permissions/reducers";
import { Permissions } from "../../../store/permissions/types";
import { ModalAmendProducts } from "./amend-products/ModalAmendProducts";
import { ModalEditAssetSecondaryProducts } from "./ModalEditAssetSecondaryProducts";

type Props = Pick<
  DetailAssetApiItem,
  | "advanceTaken"
  | "productDuration"
  | "productName"
  | "productCode"
  | "productId"
  | "secondaryProducts"
> & {
  assetId: number;
  assetStartDate: string;
  secondaryProductOptions: ProductRateByIdApiItem[];
  isAdvanceEnabled: boolean;
};

export const PanelContentProduct = ({
  advanceTaken,
  productDuration,
  productName,
  productCode,
  productId,
  assetId,
  assetStartDate,
  secondaryProductOptions,
  secondaryProducts,
  isAdvanceEnabled,
}: Props) => {
  const dispatch = useDispatch();
  const canWriteAssets = useSelector(getPermission(Permissions.WriteAssets));

  const disabled = !isAdvanceEnabled;
  let className;

  return (
    <React.Fragment>
      <List>
        <ListItem divider>
          <EditableField label="Duration" value={productDuration} />
        </ListItem>
        <ListItem divider>
          <EditableField label="Primary Product" value={productName.key}>
            {canWriteAssets && (
              <ModalAmendProducts
                assetStartDate={toDateString(assetStartDate)}
                assetId={assetId}
                currentProductId={productId}
              />
            )}
          </EditableField>
        </ListItem>
        <ListItem divider>
          <EditableField label="Advance Taken" value={advanceTaken}>
            {canWriteAssets && (
              <Box display="flex" alignItems="center">
                <span>No</span>
                <Switch
                  color="primary"
                  className={className}
                  disabled={disabled}
                  checked={advanceTaken}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(
                      requestUpdateAssetItem(
                        assetId,
                        {
                          isAdvanceTaken: event.target.checked,
                        },
                        () => {}
                      )
                    );
                  }}
                />
                <span>Yes</span>
              </Box>
            )}
          </EditableField>
        </ListItem>
        <ListItem divider>
          <EditableField label="Product Code" value={productCode} />
        </ListItem>
        <ListItem divider>
          <EditableField label="Product Id" value={productId} />
        </ListItem>
        <ListItem>
          <EditableField label="Secondary Products" value={false}>
            {canWriteAssets && (
              <ModalEditAssetSecondaryProducts
                assetId={assetId}
                secondaryProducts={secondaryProducts}
                secondaryProductOptions={secondaryProductOptions}
              />
            )}
          </EditableField>
        </ListItem>
        {!!secondaryProducts.length &&
          secondaryProducts.map((sp) => {
            const name = secondaryProductOptions.find(
              (option) => option.productId === sp.productId
            )?.productName;
            return (
              <ListItem key={sp.assetProductId}>
                <EditableField
                  label={sp.assetProductId.toString()}
                  value={name}
                />
              </ListItem>
            );
          })}
        {!secondaryProducts.length && (
          <ListItem>
            <div>No secondary products on this asset.</div>
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
};
