import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  UpdatePartnerServiceProviderOOCPaymentTermParams,
  postUpdatePartnerServiceProviderOOCPaymentTerm,
} from "../../api/partner/update-partner-service-provider-ooc-payment-term";
import {
  getOocPaymentTermOptions,
  OocPaymentTermItem,
} from "../../api/partner/fetch-ooc-payment-terms";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import { setError, resetForm, formInitializeFinish } from "././actions";
import {
  OutOfContractFormFormState,
  FormSubmitAction,
  FORM_INITIALIZE_START,
  FORM_SUBMIT,
} from "././types";

function* initializeOutOfContractForm() {
  try {
    const oocPaymentTerms: OocPaymentTermItem[] = yield call(
      getOocPaymentTermOptions
    );

    yield put(formInitializeFinish(oocPaymentTerms));
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* submitOutOfContractForm(action: FormSubmitAction) {
  try {
    const formState: OutOfContractFormFormState = yield select(
      (state: RootState) => state.outOfContractForm
    );

    const params: UpdatePartnerServiceProviderOOCPaymentTermParams = {
      ...formState.values,
      partnerId: formState.values.partnerId,
      serviceProviderId: formState.values.serviceProviderId,
      oocPaymentTermId: formState.values.oocPaymentTermId,
    };

    yield call(postUpdatePartnerServiceProviderOOCPaymentTerm, params);

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchInitializeOutOfContractForm() {
  yield takeLatest(FORM_INITIALIZE_START, initializeOutOfContractForm);
}

export function* watchSubmitOutOfContractForm() {
  yield takeLatest(FORM_SUBMIT, submitOutOfContractForm);
}
