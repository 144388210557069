import { capitalize } from "@mui/material";
import { request } from "../../..";
import { SuggestionResult } from "../../../types";
import {
  stringifyFilterParams,
  combine,
} from "../../../../shared/helpers/querySerialisation";

export interface SuggestionAPIParams {
  columnName: string;
  term: string;
  filterParams?: InvoiceLinesFilterParams;
}

export type InvoiceLinesFilterParams = {
  invoiceId?: (number | null)[];
};

export function getSuggestions({
  columnName,
  term,
  filterParams,
}: SuggestionAPIParams) {
  let filterParamsQS = "";
  if (filterParams) {
    filterParamsQS = stringifyFilterParams(filterParams);
  }
  return request<SuggestionResult>(
    `invoice/lines/search/${capitalize(columnName)}/${term}${combine([
      filterParamsQS,
    ])}`
  );
}
