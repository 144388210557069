import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    "& > *": {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export const FormFieldsWrapper: React.FC = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Box maxWidth={500} width="100%" className={classes.wrapper}>
      {children}
    </Box>
  );
};
