import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  postUpdatePartnerRateAdjustment,
  UpdatePartnerRateAdjustmentParams,
} from "../../api/partner/update-partner-rate-adjustment";
import { RequestError } from "../../api/types";
import { requestDetailPartnerData } from "../detailPartners/actions";
import { RootState } from "../rootReducer";
import { setError, resetFormValues } from "./actions";
import {
  FormSubmitAction,
  FORM_SUBMIT,
  EditPartnerRateFormState,
} from "./types";

function* submitEditPartnerRateForm(action: FormSubmitAction) {
  try {
    const formState: EditPartnerRateFormState = yield select(
      (state: RootState) => state.editPartnerRateForm
    );

    const params: UpdatePartnerRateAdjustmentParams = {
      ...formState.values,
      partnerRateAdjustmentId: action.payload.partnerRateAdjustmentId,
      partnerId: action.payload.partnerId,
    };

    yield call(postUpdatePartnerRateAdjustment, params);
    yield put(requestDetailPartnerData(action.payload.partnerId));
    yield put(resetFormValues());

    yield call(action.payload.done);
  } catch (e: any) {
    const error: RequestError = e;

    yield put(
      setError({
        partnerRateAdjustmentId: action.payload.partnerRateAdjustmentId,
        error,
      })
    );
    yield call(() => action.payload.done(error));
  }
}

export function* watchSubmitEditPartnerRateForm() {
  yield takeLatest(FORM_SUBMIT, submitEditPartnerRateForm);
}
