import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";
import { stringify } from "query-string";

export type ConnectionCommissionsForAccrualsReportApiItem = {
  assetId: number;
  publicIdentifier: string;
  previousIdentifier: number;
  contractType: string;
  status: string;
  productName: string;
  dealer: string;
  customerName: string;
  serviceProvider: string;
  productCode: string;
  startDate: string;
  endDate: string;
  pay_TariffCommission: number;
  pay_ServiceCommission: number;
  pay_VolumeBonus: number;
  pay_AdditionalFundingDws: number;
  pay_BcadFund: number;
  pay_Da: number;
  pay_Air_Qr_Qn: number;
  pay_SideLetter_AdditionalFundingNetwork: number;
  totalAmountPaid: number;
  exp_TariffCommission: number;
  exp_ServiceCommission: number;
  exp_VolumeBonusNewMigPort: number;
  exp_VolumeBonusResign: number;
  exp_BcadFund: number;
  exp_Da: number;
  exp_Air_Qr_Qn: number;
  exp_SideLetter_AdditionalFundingNetwork: number;
  exp_Churn: number;
  exp_Csi: number;
  exp_Digital: number;
  totalAmountExpected: number;
};

export interface ConnectionCommissionsForAccrualsReportParams {
  startDate: string | null;
  endDate: string | null;
  tableControls: TableAPIRequestParams<
    ConnectionCommissionsForAccrualsReportApiItem
  >;
}

export function postGenerateConnectionCommissionsForAccrualsReport(
  params: ConnectionCommissionsForAccrualsReportParams
) {
  return request<
    PaginatedResult<ConnectionCommissionsForAccrualsReportApiItem>
  >(
    `account-report/generate-connection-commission-for-accruals-report${combine(
      [stringifyTableParams(params.tableControls)]
    )}`,
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}

export function postExportConnectionCommissionsForAccrualsReport(
  params: Omit<ConnectionCommissionsForAccrualsReportParams, "tableControls">
) {
  return requestFile(
    `account-report/generate-connection-commission-for-accruals-report${combine(
      [stringify({ export: true, returnAll: true })]
    )}`,
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}
