import {
  CHANGE_TAB,
  DetailAssetsActionType,
  DetailAssetsState,
  REQUEST_DATA,
  RESET_API_ERROR,
  SET_API_ERROR,
  SET_DATA,
  UPDATE_ITEM_DATA,
} from "./types";

const initialState: DetailAssetsState = {
  items: {},
  fetchingIds: [],
  errors: [],
  tabIndex: 0,
};

export function detailAssetsReducer(
  state = initialState,
  action: DetailAssetsActionType
): DetailAssetsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.concat(action.payload),
        errors: state.errors.filter((error) => error.id !== action.payload),
      };
    case SET_API_ERROR:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter((id) => id !== action.payload.id),
        errors: state.errors.concat(action.payload),
      };

    case RESET_API_ERROR:
      return {
        ...state,
        errors: state.errors.filter((e) => e.id !== action.payload.id),
      };
    case SET_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.filter(
          (id) => id !== action.payload.assetId
        ),
        errors: state.errors.filter(
          (error) => error.id !== action.payload.assetId
        ),
        items: {
          ...state.items,
          [action.payload.assetId]: action.payload,
        },
      };
    case UPDATE_ITEM_DATA: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...state.items[action.payload.id],
            ...action.payload.updates,
          },
        },
      };
    }
    case CHANGE_TAB: {
      return {
        ...state,
        tabIndex: action.payload.tabIndex,
      };
    }
    default:
      return state;
  }
}
