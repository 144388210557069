import { request } from "../..";
import { DateString } from "../../../shared/helpers/dates";

export type AssetUpdateProductRateApiItem = {
  assetProductId?: number;
  productRateId: number;
  productId: number;
  addSecondary: boolean;
  removeSecondary: boolean;
};

type AssetLedgerEntryApiItem = {
  assetLedgerEntryId: number;
  dateCreated: DateString;
  amount: number;
  reference: string;
  eligibleForPayment: boolean;
  billDate: DateString;
  aleTypeId: number;
  aleTypeName: string;
  assetId: number;
  invoiceLineId: number;
  paymentDataId: number;
};

export type PostChangeAssetProductParams = {
  assetId: number;
  assetUpdateProductRateDtos: AssetUpdateProductRateApiItem[];
  assetLedgerEntryDtos: AssetLedgerEntryApiItem[];
};

export type PostEditAssetProductParams = {
  secondaryProductIds: number[];
  assetId: number;
};

export function postChangeAssetProduct(params: PostChangeAssetProductParams) {
  return request(`asset-product/change-product/${params.assetId}`, {
    method: "POST",
    body: JSON.stringify(params),
  });
}

export function postEditAssetProducts(params: PostEditAssetProductParams) {
  return request(`asset-product/edit/${params.assetId}`, {
    method: "POST",
    body: JSON.stringify(params),
  });
}

export function postChangeSecondaryProducts(
  params: PostChangeAssetProductParams
) {
  return request(`asset-product/change-secondary-products/${params.assetId}`, {
    method: "POST",
    body: JSON.stringify(params),
  });
}
