import {
  REQUEST_UPDATE_SELF_BILL_TO_PARTNER,
  SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR,
  SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS,
} from "./types";
import { RequestError } from "../../api/types";
import { UpdateSelfBillToPartnerParams } from "../../api/partner/self-bill-to-partner-code";

export function postUpdateSelfBillToPartnerRequest(
  params: UpdateSelfBillToPartnerParams
) {
  return {
    type: REQUEST_UPDATE_SELF_BILL_TO_PARTNER,
    payload: params,
  };
}

export function setUpdateSelfBillToPartnerSuccess() {
  return {
    type: SET_UPDATE_SELF_BILL_TO_PARTNER_SUCCESS,
  };
}

export function setUpdateSelfBillToPartnerError(error: RequestError) {
  return {
    type: SET_UPDATE_SELF_BILL_TO_PARTNER_ERROR,
    payload: { err: error },
  };
}
