import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { RequestError } from "../../../api/types";
import { toDateString } from "../../helpers/dates";
import { ModalFieldWrapper } from "./ModalFieldWrapper";
import { FormControl } from "@mui/material";

type Props = {
  id: string;
  label: string;
  onCancel?: () => any;
  onSave: (value: string) => Promise<any>;
  type?: "text" | "number";
  value?: string;
  error?: RequestError;
};

export const ModalDateField = ({
  label,
  onCancel,
  onSave,
  id,
  value,
  error,
}: Props) => {
  const [input, setInput] = useState(
    (value && toDateString(value)) ?? new Date().toISOString()
  );

  const handleSetInput = (input: string) => {
    setInput(input);
  };

  return (
    <ModalFieldWrapper
      id={id}
      label={label}
      onCancel={onCancel}
      apiError={error}
      onSave={() => onSave(input || "")}
    >
      <FormControl variant="outlined" size="small" fullWidth>
        <DatePicker
          label={label}
          format="dd/MM/yyyy"
          value={input ? new Date(input) : null}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleSetInput(value ? toDateString(value) : "");
          }}
        />
      </FormControl>
    </ModalFieldWrapper>
  );
};
