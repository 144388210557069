import { ImportPaymentParams, ImportResult } from "../../api/payment-data";
import { RequestError } from "../../api/types";
import { ServiceProvider } from "../../shared/enum/serviceProvider";
import { PaymentType, UpfrontFileType } from "../../shared/enum/payments";
import { MonthString, YearString } from "../../shared/helpers/dates";

export const SET_API_ERROR = "importPaymentData.SET_ERROR";
export const SET_IMPORT_RESULT = "importPaymentData.SET_IMPORT_RESULT";
export const REQUEST_IMPORT = "importPaymentData.REQUEST_IMPORT";
export const UPDATE_FORM = "importPaymentData.UPDATE_FORM";
export const RESET_STATE = "importPaymentData.RESET_STATE";

export type ImportPaymentDataForm = {
  serviceProvider?: ServiceProvider;
  file?: File;
  month?: MonthString;
  year?: YearString;
  fileType?: UpfrontFileType;
  userReference?: string;
  paymentType?: PaymentType;
};

export type ImportPaymentDataState = {
  form: ImportPaymentDataForm;
  isSubmitting: boolean;
  importResult?: ImportResult;
  error?: RequestError;
};

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface UpdateFormAction {
  type: typeof UPDATE_FORM;
  payload: Partial<ImportPaymentDataForm>;
}

export interface RequestImportAction {
  type: typeof REQUEST_IMPORT;
  payload: ImportPaymentParams;
}

interface ImportResultAction {
  type: typeof SET_IMPORT_RESULT;
  payload: ImportResult;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

export type ImportPaymentDataActionTypes =
  | RequestImportAction
  | ImportResultAction
  | SetAPIErrorAction
  | UpdateFormAction
  | ResetStateAction;
