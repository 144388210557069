import FileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataTable } from "../../../shared/components/DataTable";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { Enum } from "../../../shared/helpers/enum";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { TableAPIRequestParams } from "../../../api/types";
import {
  postExportConnectionCommissionsForAccrualsReport,
  ConnectionCommissionsForAccrualsReportApiItem,
} from "../../../api/account-report/generate-connection-commission-for-accruals-report";
import { formatDecimal } from "../../../shared/helpers/numbers";
import { dateRangeParser } from "../../../shared/helpers/queryParsing";
import { useQueryParams } from "use-query-params";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { requestConnectionCommissionsForAccrualsReport } from "../../../store/connectionCommissionForAccrualsReport/actions";

export const ConnectionCommissionsForAccrualsReport = () => {
  const { items, fetching, error, count } = useSelector(
    (state: RootState) => state.connectionCommissionsForAccrualsReport
  );

  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    dateRange: dateRangeParser,
  });

  const [dateError, setDateError] = useState(Boolean);
  const [isExporting, setIsExporting] = useState(false);
  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<ConnectionCommissionsForAccrualsReportApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 0,
    sortBy: "assetId",
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const handleSubmit = (
    tableControlsOverride?: TableAPIRequestParams<
      ConnectionCommissionsForAccrualsReportApiItem
    >
  ) => {
    if (isValidDateRange(filters.dateRange)) {
      dispatch(
        requestConnectionCommissionsForAccrualsReport({
          tableControls: tableControlsOverride ?? tableControls,
          startDate: filters.dateRange[0],
          endDate: filters.dateRange[1],
        })
      );
    }
  };

  const handleExport = () => {
    if (isValidDateRange(filters.dateRange)) {
      setIsExporting(true);
      postExportConnectionCommissionsForAccrualsReport({
        startDate: filters.dateRange[0],
        endDate: filters.dateRange[1],
      })
        .then((result) => {
          FileDownload(result.file as any, result.fileName);
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  const isValidDateRange = (dateRange: any) => {
    const parsedDate1 = new Date(dateRange[0]);
    const parsedDate2 = new Date(dateRange[1]);

    const isValid =
      parsedDate1 &&
      parsedDate2 &&
      parsedDate1.getFullYear() >= 1900 &&
      parsedDate2.getFullYear() >= 1900 &&
      parsedDate1.getFullYear() <= 2099 &&
      parsedDate2.getFullYear() <= 2099;

    if (!isValid) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    return isValid;
  };

  let columns: {
    [key: string]: any;
    name: keyof ConnectionCommissionsForAccrualsReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "assetId", label: "Asset Id", align: "right" },
    { name: "publicIdentifier", label: "Identifier", align: "right" },
    {
      name: "previousIdentifier",
      label: "Previous Identifier",
      align: "right",
    },
    { name: "contractType", label: "Contract Type", align: "right" },
    { name: "status", label: "Status", align: "right" },
    { name: "productName", label: "Product Name", align: "right" },
    { name: "dealer", label: "Dealer", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    { name: "serviceProvider", label: "Service Provider", align: "right" },
    { name: "productCode", label: "Product Code", align: "right" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
      isDateRange: true,
    },
    {
      name: "pay_TariffCommission",
      label: "Pay Tariff Commission",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_ServiceCommission",
      label: "Pay Service Commission",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_VolumeBonus",
      label: "Pay Volume Bonus",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_AdditionalFundingDws",
      label: "Pay Additional Funding Dws",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_BcadFund",
      label: "Pay Bcad Fund",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_Da",
      label: "Pay Da",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_Air_Qr_Qn",
      label: "Pay Air Qr Qn",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "pay_SideLetter_AdditionalFundingNetwork",
      label: "Pay Side Letter",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "totalAmountPaid",
      label: "Total Amount Paid",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_TariffCommission",
      label: "Exp Tariff Commission",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_ServiceCommission",
      label: "Exp Service Commission",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_VolumeBonusNewMigPort",
      label: "Exp VB (new, mig, port)",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_VolumeBonusResign",
      label: "Exp VB (resign)",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_BcadFund",
      label: "Exp Bcad Fund",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_Da",
      label: "Exp Da",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_Air_Qr_Qn",
      label: "Exp Air Qr Qn",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_SideLetter_AdditionalFundingNetwork",
      label: "Exp Side Letter",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_Churn",
      label: "Exp Churn",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_Csi",
      label: "Exp Csi",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "exp_Digital",
      label: "Exp Digital",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "totalAmountExpected",
      label: "Total Amount Expected",
      align: "right",
      customBodyRender: formatDecimal,
    },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Connection Commissions For Accruals Report
        </Typography>
        <Box>
          <LoaderButton
            style={{ marginRight: 8 }}
            color="primary"
            size="large"
            variant="outlined"
            loading={isExporting}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            color="primary"
            size="large"
            variant="contained"
            loading={fetching}
            onClick={() => handleSubmit()}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
          >
            Submit
          </LoaderButton>
        </Box>
      </Box>
      {dateError && (
        <Box display="flex" justifyContent="space-between">
          <CustomStatusChip
            type="error"
            title="Date range error"
            message="Please select a valid date range between 1900 and 2099."
          />
        </Box>
      )}
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length > 0 ? count : undefined}
        controls={() => (
          <DateRangeControl
            name="Date Range"
            id="date-range-filter-control"
            selected={filters.dateRange}
            onChange={(dateRange) => updateFilters({ dateRange })}
          />
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={[
              {
                name: "dateRange",
                label: "Date Range",
                isDateRange: true,
              },
            ]}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={count}
        onTableControlsChange={(controls) => {
          setTableControls(controls);
          if (filters.dateRange[0] && filters.dateRange[1]) {
            if (isValidDateRange(filters.dateRange)) {
              handleSubmit(controls);
            }
          }
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
