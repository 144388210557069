import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CreatePartnerRateAdjustmentParams,
  postCreatePartnerRateAdjustment,
} from "../../api/partner/create-partner-rate-adjustment";
import {
  getServiceProviderDistinctProducts,
  ServiceProviderDistinctProductTypeOption,
} from "../../api/service-provider/distinct-products";
import { getServiceProviderDistinctTiers } from "../../api/service-provider/distinct-tiers";
import {
  getServiceProviderProduct,
  ServiceProviderRate,
} from "../../api/service-provider/service-provider-rate-values";
import {
  getServiceProviderRates,
  ServiceProviderRateApiItem,
} from "../../api/service-provider/service-provider-rates";
import { RequestError } from "../../api/types";
import { RootState } from "../rootReducer";
import {
  setError,
  resetForm,
  formInitializeFinish,
  setServiceProviderRates,
  setTiers,
  setServiceProviderRateDetails,
} from "./actions";
import {
  CreatePartnerRateFormState,
  FormSubmitAction,
  FORM_INITIALIZE_START,
  FormInitializeStartAction,
  FORM_SUBMIT,
  SetProductTypeIdAction,
  SET_PRODUCT_TYPE_ID,
  SetServiceProviderRateAction,
  SET_SERVICE_PROVIDER_RATE,
} from "./types";

function* initializePartnerRateForm(action: FormInitializeStartAction) {
  try {
    const productTypes: ServiceProviderDistinctProductTypeOption[] = yield call(
      getServiceProviderDistinctProducts,
      action.payload.serviceProviderId
    );
    const tiers: number[] = yield call(
      getServiceProviderDistinctTiers,
      action.payload.serviceProviderId,
      0
    );

    yield put(formInitializeFinish(productTypes));
    yield put(setTiers(tiers));
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* setProductTypeId(action: SetProductTypeIdAction) {
  try {
    const serviceProviderRates: ServiceProviderRateApiItem[] = yield call(
      getServiceProviderRates,
      [action.payload.serviceProviderId],
      action.payload.productTypeId
    );

    const tiers: number[] = yield call(
      getServiceProviderDistinctTiers,
      action.payload.serviceProviderId,
      action.payload.productTypeId
    );

    yield put(setServiceProviderRates(serviceProviderRates));
    yield put(setTiers(tiers));
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* setServiceProviderRate(action: SetServiceProviderRateAction) {
  try {
    const serviceProviderRate: ServiceProviderRate[] = yield call(
      getServiceProviderProduct,
      action.payload.serviceProviderId,
      action.payload.productTypeId,
      action.payload.tier
    );

    yield put(setServiceProviderRateDetails(serviceProviderRate));
  } catch (e: any) {
    let error: RequestError = e;
    yield put(setError(error));
  }
}

function* submitPartnerRateForm(action: FormSubmitAction) {
  try {
    const formState: CreatePartnerRateFormState = yield select(
      (state: RootState) => state.createPartnerRateForm
    );

    const params: CreatePartnerRateAdjustmentParams = {
      ...formState.values,
      partnerId: action.payload.partnerId,
      serviceProviderId: action.payload.serviceProviderId,
    };

    yield call(postCreatePartnerRateAdjustment, params);

    yield call(action.payload.done);
    yield put(resetForm());
  } catch (e: any) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchInitializePartnerRateForm() {
  yield takeLatest(FORM_INITIALIZE_START, initializePartnerRateForm);
}

export function* watchSetProductTypeId() {
  yield takeLatest(SET_PRODUCT_TYPE_ID, setProductTypeId);
}

export function* watchSubmitPartnerRateForm() {
  yield takeLatest(FORM_SUBMIT, submitPartnerRateForm);
}

export function* watchSetServiceProviderRate() {
  yield takeLatest(SET_SERVICE_PROVIDER_RATE, setServiceProviderRate);
}
