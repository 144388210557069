import FileDownload from "js-file-download";
import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { RequestError } from "../../../api/types";
import { getSecondaryProductReport } from "../../../api/product-report/generate-secondary-product-asset-level-report";

export const SecondaryProductReport = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState<RequestError | undefined>(undefined);
  const [value, setValue] = useState<string>("");

  const disabled = !value;

  const handleResetAllFilters = () => {
    setValue("");
  };

  const handleExport = () => {
    setIsExporting(true);
    setError(undefined);

    const assetIds = value?.split(/\r?\n/).map(Number);

    getSecondaryProductReport(assetIds)
      .then((result) => {
        FileDownload(result.file as any, result.fileName);
      })
      .catch((error: RequestError) => {
        setError(error);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Secondary Product Report
        </Typography>
        <Box>
          <LoaderButton
            size="large"
            variant="outlined"
            onClick={handleExport}
            loading={isExporting}
            disabled={disabled}
          >
            Export
          </LoaderButton>
        </Box>
      </Box>
      {error && (
        <Box display="flex" justifyContent="space-between">
          <CustomStatusChip
            type="error"
            title={error.type}
            message={error.message}
          />
        </Box>
      )}
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        controls={() => (
          <React.Fragment>
            <TextField
              disabled={isExporting}
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              fullWidth
              id="asset-ids-input"
              label="Enter Asset Id"
              multiline
              rows={5}
              variant="outlined"
              helperText="Enter one Asset Id per line. Tip: you can paste a column from a spreadsheet here."
            />
          </React.Fragment>
        )}
        chips={() => <></>}
      />
    </React.Fragment>
  );
};
