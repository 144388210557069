import { call, put, takeLatest } from "redux-saga/effects";
import { getPaymentData, PaymentDataApiItem } from "../../api/payment-data";
import { RequestError, PaginatedResult } from "../../api/types";
import { setImportPaymentDataErrorForDisplay } from "../importPaymentData/actions";
import { setPaymentData } from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchPaymentData(action: RequestDataAction) {
  try {
    const data: PaginatedResult<PaymentDataApiItem> = yield call(
      getPaymentData,
      action.payload
    );
    yield put(setPaymentData(data));
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setImportPaymentDataErrorForDisplay(error));
  }
}

export function* watchRequestPaymentData() {
  yield takeLatest(REQUEST_DATA, fetchPaymentData);
}
