import FileDownload from "js-file-download";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { DataTable } from "../../../shared/components/DataTable";
import { formatISOForDisplay } from "../../../shared/helpers/dates";
import { Enum } from "../../../shared/helpers/enum";
import { RootState } from "../../../store/rootReducer";
import { LoaderButton } from "../../../shared/components/LoaderButton";
import { TableAPIRequestParams } from "../../../api/types";
import {
  getPortAndResignReportExcel,
  PortAndResignReportApiItem,
} from "../../../api/asset-report/generate-port-and-resign-report";
import {
  AssetStatus,
  InvoiceStatus,
  ServiceProviderStatusChangeType,
} from "../../../shared/enum/asset";
import { formatDecimal } from "../../../shared/helpers/numbers";
import { dateRangeParser } from "../../../shared/helpers/queryParsing";
import { useQueryParams } from "use-query-params";
import { FiltersWrapper } from "../../../shared/components/filter_controls/FiltersWrapper";
import { DateRangeControl } from "../../../shared/components/filter_controls/DateRangeControl";
import { AppliedFilters } from "../../../shared/components/filter_controls/AppliedFilters";
import { requestPortAndResignReport } from "../../../store/portAndResignReport/actions";

export const PortAndResignReport = () => {
  const { items, fetching, error } = useSelector(
    (state: RootState) => state.portAndResignReport
  );

  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    dateRange: dateRangeParser,
  });

  const [tableControls, setTableControls] = useState<
    TableAPIRequestParams<PortAndResignReportApiItem>
  >({
    pageNumber: 1,
    pageSize: 25,
    sortDirection: 1,
    sortBy: "identifier",
  });

  const handleResetAllFilters = () => {
    updateFilters(
      Object.keys(filters).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  const handleSubmit = () => {
    dispatch(
      requestPortAndResignReport({
        tableControls,
        startDate: filters.dateRange[0],
        endDate: filters.dateRange[1],
      })
    );
  };

  const handleExport = () => {
    getPortAndResignReportExcel({
      startDate: filters.dateRange[0],
      endDate: filters.dateRange[1],
      tableControls,
    }).then((result) => {
      FileDownload(result.file as any, result.fileName);
    });
  };

  let columns: {
    [key: string]: any;
    name: keyof PortAndResignReportApiItem;
    label: string;
    enum?: Enum<any>;
    customBodyRender?: (input: string) => React.ReactNode;
    hidden?: boolean;
    align?: "left" | "right";
    isDateRange?: boolean;
  }[] = [
    { name: "identifier", label: "Identifier", align: "right" },
    { name: "assetId", label: "Asset Id", align: "right" },
    { name: "dealerCode", label: "Dealer Code", align: "right" },
    { name: "status", label: "Status", enum: AssetStatus, align: "right" },
    { name: "productCode", label: "Product Code", align: "right" },
    { name: "customerName", label: "Customer Name", align: "right" },
    {
      name: "startDate",
      label: "Start Date",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "endDate",
      label: "End Date",
      customBodyRender: formatISOForDisplay,
    },
    { name: "provider", label: "Provider", align: "right" },
    { name: "dealerCodeOld", label: "Dealer Code Old", align: "right" },
    {
      name: "statusOld",
      label: "Status Old",
      enum: AssetStatus,
      align: "right",
    },
    { name: "productCodeOld", label: "Product Code Old", align: "right" },
    { name: "customerNameOld", label: "Customer Name Old", align: "right" },
    {
      name: "startDateOld",
      label: "Start Date Old",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    {
      name: "endDateOld",
      label: "End Date Old",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    { name: "providerOld", label: "Provider Old", align: "right" },
    { name: "assetIdOld", label: "Asset Id Old", align: "right" },
    {
      name: "outstandingAdvanceOld",
      label: "Outstanding Advance Old",
      align: "right",
      customBodyRender: formatDecimal,
    },
    {
      name: "invoiceStatusOld",
      label: "Invoice Status Old",
      enum: InvoiceStatus,
      align: "right",
    },
    {
      name: "spStatusChangeTypeOld",
      label: "SP Status Change Type Old",
      enum: ServiceProviderStatusChangeType,
      align: "right",
    },
    {
      name: "spStatusChangeDateOld",
      label: "SP Status Change Date Old",
      align: "right",
      customBodyRender: formatISOForDisplay,
    },
    { name: "portResign", label: "Port/Resign", align: "right" },
    { name: "dealerChange", label: "Dealer Change", align: "right" },
  ];

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" gutterBottom>
          Port And Resign Report
        </Typography>
        <Box>
          <LoaderButton
            style={{ marginRight: 8 }}
            color="primary"
            size="large"
            variant="outlined"
            loading={fetching}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
            onClick={handleExport}
          >
            Export
          </LoaderButton>
          <LoaderButton
            color="primary"
            size="large"
            variant="contained"
            loading={fetching}
            onClick={handleSubmit}
            disabled={!filters.dateRange[0] || !filters.dateRange[1]}
          >
            Submit
          </LoaderButton>
        </Box>
      </Box>
      <FiltersWrapper
        onResetAll={handleResetAllFilters}
        countLabel={items.length}
        controls={() => (
          <DateRangeControl
            name="Date Range"
            id="date-range-filter-control"
            selected={filters.dateRange}
            onChange={(dateRange) => updateFilters({ dateRange })}
          />
        )}
        chips={() => (
          <AppliedFilters
            filters={filters}
            meta={[
              {
                name: "dateRange",
                label: "Date Range",
                isDateRange: true,
              },
            ]}
            updateFilters={updateFilters}
          />
        )}
      />
      <DataTable
        stickyHeader
        limitHeight
        error={error}
        hover
        loading={fetching}
        data={items}
        columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        recordsCount={items.length}
        onTableControlsChange={(controls) => {
          setTableControls(controls);
        }}
        tableControls={tableControls}
      />
    </React.Fragment>
  );
};
