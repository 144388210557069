import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CreateGeneralJournalLinesParams } from "../../../../../api/journal-line/general";
import { FormFieldsWrapper } from "../../../../../layout/components/FormFieldsWrapper";
import { ModalFieldWrapper } from "../../../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { RootState } from "../../../../../store/rootReducer";

type Props = {
  batchId: number;
  onSubmit: (params: CreateGeneralJournalLinesParams) => void;
  disabled?: boolean;
};

export const CreateJournalEntriesModal = ({
  batchId,
  onSubmit,
  disabled,
}: Props) => {
  // const dispatch = useDispatch();

  const { requestError } = useSelector(
    (state: RootState) => state.createSecondaryProductForm
  );

  const today = new Date().toISOString();
  const [dateValue, setDateValue] = useState(today);

  return (
    <ModalFieldWrapper
      icon={false}
      label="Create Journal Entries"
      buttonLabel="Create Journal Entries"
      buttonVariant="contained"
      buttonDisable={disabled}
      disableSave={!dateValue}
      onCancel={() => setDateValue(today)}
      onSave={() => {
        return new Promise((resolve) => {
          const params: CreateGeneralJournalLinesParams = {
            batchId,
            documentDate: dateValue,
            postingDate: dateValue,
          };

          onSubmit(params);
          resolve(null);
        });
      }}
      id="create-journal-entries"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <DatePicker
            label="Posting/Document Date"
            format="dd/MM/yyyy"
            value={!!dateValue ? new Date(dateValue) : null}
            onChange={(value: Date | null, context) => {
              if (context.validationError) return;
              setDateValue(value?.toISOString() || "");
            }}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
