import React, { useState } from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch } from "react-redux";
import { FormControl } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import { ProductRateByIdApiItem } from "../../../api/product/product-rates";
import SecondaryProductSelect from "./SecondaryProductSelect";
import { AssetProductApiItem } from "../../../api/asset-product";
import { postChangeSecondaryProducts } from "../../../api/asset-product/change-product";
import { requestDetailAssetData } from "../../../store/detailAssets/actions";
import { setSecondaryProductsValue } from "../../../store/createAssetForm/actions";
import { SecondaryProductValue } from "../../../store/createAssetForm/types";

type Props = {
  assetId?: number;
  secondaryProductOptions: ProductRateByIdApiItem[];
  secondaryProducts: AssetProductApiItem[];
};

export const ModalEditAssetSecondaryProducts = ({
  assetId,
  secondaryProducts,
  secondaryProductOptions,
}: Props) => {
  const dispatch = useDispatch();
  const [newProducts, setNewProducts] = useState<ProductRateByIdApiItem[]>([]);
  const [oldProducts, setOldProducts] = useState<AssetProductApiItem[]>(
    secondaryProducts
  );

  const handleAdd = (product: ProductRateByIdApiItem) => {
    setNewProducts([...newProducts, product]);
  };

  const handleDelete = (id: number) => {
    setNewProducts(newProducts.filter((p) => p.productId !== id));
    setOldProducts(oldProducts.filter((p) => p.assetProductId !== id));
  };

  const handleSubmit = () => {
    if (!assetId) {
      const oldValues: SecondaryProductValue[] = oldProducts.map((op) => ({
        productId: op.productId,
        duration: op.duration,
        isPrimary: op.isPrimary,
      }));

      const newValues: SecondaryProductValue[] = newProducts.map((np) => ({
        productId: np.productId,
        duration: np.duration,
        isPrimary: false,
      }));

      const value = [...oldValues, ...newValues];

      dispatch(setSecondaryProductsValue(value));
      return;
    }

    const oldUpdateDtos = secondaryProducts.map((p) => ({
      assetProductId: p.assetProductId,
      productId: p.productId,
      productRateId: p.productRateId,
      addSecondary: false,
      removeSecondary: !oldProducts.find(
        (op) => op.assetProductId === p.assetProductId
      ),
    }));

    const newUpdateDtos = newProducts.map((p) => ({
      assetProductId: 0,
      productId: p.productId,
      productRateId: p.productRateId,
      addSecondary: true,
      removeSecondary: false,
    }));

    const assetUpdateProductRateDtos = [...oldUpdateDtos, ...newUpdateDtos];

    postChangeSecondaryProducts({
      assetId,
      assetUpdateProductRateDtos,
      assetLedgerEntryDtos: [],
    }).then(() => {
      dispatch(requestDetailAssetData(assetId));
    });
  };

  return (
    <ModalFieldWrapper
      key="Edit Secondary Products"
      label="Edit Secondary Products"
      onSave={() => {
        return new Promise((resolve) => {
          handleSubmit();
          resolve({ done: true });
        });
      }}
      id="edit-product-rate"
      gutterSize="small"
      disableSave={false}
      apiError={undefined}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <SecondaryProductSelect
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            options={secondaryProductOptions}
            secondaryProducts={oldProducts}
            newProducts={newProducts}
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
