import {
  CANCEL_ALES_VIEW,
  QueryAlesActionTypes,
  QueryAlesState,
  REQUEST_DATA,
  REQUEST_REVSHARE_RECEIVED_DATA,
  SET_API_ERROR,
  SET_DATA,
  SET_REVSHARE_RECEIVED_DATA,
} from "./types";

const initialState: QueryAlesState = {
  aleTypeId: [],
  items: [],
  revshareReceived: [],
  fetching: false,
  show: false,
};

export function queryAlesReducer(
  state = initialState,
  action: QueryAlesActionTypes
): QueryAlesState {
  switch (action.type) {
    case REQUEST_DATA:
    case REQUEST_REVSHARE_RECEIVED_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
        show: true,
        label: action.payload.label,
      };
    case CANCEL_ALES_VIEW: {
      return initialState;
    }
    case SET_API_ERROR:
      return { ...state, error: action.payload, fetching: false };
    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.items.items,
        aleTypeId: action.payload.aleTypeId,
      };

    case SET_REVSHARE_RECEIVED_DATA:
      return {
        ...state,
        fetching: false,
        revshareReceived: action.payload,
      };
    default:
      return state;
  }
}
