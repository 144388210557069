import { RootState } from "../rootReducer";
import {
  PermissionsActionTypes,
  PermissionsState,
  REQUEST_DATA,
  SET_DATA,
} from "./types";

const initialState: PermissionsState = {
  items: null,
  fetching: false,
};

export function permissionsReducer(
  state = initialState,
  action: PermissionsActionTypes
): PermissionsState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        fetching: true,
      };

    case SET_DATA: {
      return {
        ...state,
        fetching: false,
        items: action.payload.permissions,
      };
    }

    default:
      return state;
  }
}

export const getPermission = (permissionName: string) => (state: RootState) => {
  const permission = state.permissions.items?.find(
    (p) => p.name === permissionName
  );
  return permission && permission.value;
};

export const getPermissions = () => (state: RootState) =>
  state.permissions.items?.filter((p) => p.value);
