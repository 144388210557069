import { request } from "../..";

export type CreateGeneralJournalLinesResult = {
  success: boolean;
  message: string;
};

export type CreateGeneralJournalLinesParams = {
  batchId: number;
  postingDate: string;
  documentDate: string;
};

export function postCreateGeneralJournalLines(
  params: CreateGeneralJournalLinesParams
) {
  return request<CreateGeneralJournalLinesResult>(
    "journal-line/general/create-journal-lines",
    {
      method: "POST",
      body: JSON.stringify(params),
    }
  );
}
