import { makeStyles } from "tss-react/mui";
import {
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { FinancialOverview } from "../../../api/asset-ledger-entry/financial-overview";
import { EditableField } from "../../../shared/components/EditableField";
import { formatCurrencyGBP } from "../../../shared/helpers/currency";
import { FinancialBreakdownType } from "../../../store/financialBreakdown/types";

const useStyles = makeStyles()((theme) => ({
  table: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    "& th": {
      width: 130, //just spacing by eye
    },
    "& th:first-of-type": {
      width: 350, // to match EditableField label column
    },
    "& th:last-of-type": {
      width: "auto",
    },
  },
}));

type Props = Pick<
  FinancialOverview,
  | "tariffCommission"
  | "tariffAdvance"
  | "serviceCommission"
  | "serviceAdvance"
  | "afCommission"
  | "afAdvance"
  | "totalClawback"
  | "totalInvoice"
  | "invoiceStatus"
> & {
  assetId: number;
  onRequestDrillDown: (aleTypeIds: number[], label: string) => any;
  onRequestBreakdown: (
    assetId: number,
    breakdownType: FinancialBreakdownType
  ) => any;
};

export const PanelContentFinancials = ({
  assetId,
  tariffCommission,
  tariffAdvance,
  serviceCommission,
  serviceAdvance,
  afCommission,
  afAdvance,
  totalClawback,
  totalInvoice,
  invoiceStatus,
  onRequestDrillDown,
  onRequestBreakdown,
}: Props) => {
  const { classes } = useStyles();

  const getTableRow = (
    item: { name: string; amount: number; aleTypeId: number[] }[],
    label: string
  ) => {
    const pay = item.find((x) => x.name === "Pay");
    const expected = item.find((x) => x.name === "Expected");
    const received = item.find((x) => x.name === "Received");

    const items = [pay, expected, received];

    return (
      <TableRow>
        <TableCell style={{ fontWeight: 600 }}>{label}</TableCell>
        {items.map((item, i) => (
          <TableCell key={i}>
            {item && (
              <Link
                component="button"
                onClick={() =>
                  item &&
                  onRequestDrillDown(item.aleTypeId, `${label} - ${item.name}`)
                }
              >
                {formatCurrencyGBP(item.amount)}
              </Link>
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    );
  };

  const getTotalsRow = () => {
    const items = [
      tariffCommission,
      tariffAdvance,
      serviceCommission,
      serviceAdvance,
      afCommission,
      afAdvance,
    ];

    const totalPay = items.reduce(
      (total, currentItem) =>
        (total += currentItem.find((x) => x.name === "Pay")?.amount || 0),
      0
    );
    const totalExpected = items.reduce(
      (total, currentItem) =>
        (total += currentItem.find((x) => x.name === "Expected")?.amount || 0),
      0
    );
    const totalReceived = items.reduce(
      (total, currentItem) =>
        (total += currentItem.find((x) => x.name === "Received")?.amount || 0),
      0
    );

    return (
      <TableRow>
        <TableCell>
          <b>Totals</b>
        </TableCell>
        <TableCell>
          <b>{formatCurrencyGBP(totalPay)}</b>
        </TableCell>
        <TableCell>
          <b>{formatCurrencyGBP(totalExpected)}</b>
        </TableCell>
        <TableCell>
          <b>{formatCurrencyGBP(totalReceived)}</b>
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Pay</TableCell>
            <TableCell>Expected</TableCell>
            <TableCell>Received</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {getTableRow(tariffCommission, "Tariff Commission")}
          {getTableRow(tariffAdvance, "Tariff Advance")}
          {getTableRow(serviceCommission, "Service Commission")}
          {getTableRow(serviceAdvance, "Service Advance")}
          {getTableRow(afCommission, "AF Commission")}
          {getTableRow(afAdvance, "AF Advance")}
          {getTotalsRow()}
        </TableBody>
      </Table>
      <List>
        <ListItem divider>
          <EditableField
            label="Invoice Status"
            value={invoiceStatus || "N/A"}
          />
        </ListItem>
        <ListItem divider>
          <EditableField
            label="Total Invoice"
            value={formatCurrencyGBP(totalInvoice)}
            onValueClick={() => onRequestBreakdown(assetId, "invoice")}
          />
        </ListItem>
        <ListItem divider>
          <EditableField
            label="Total Clawback"
            value={formatCurrencyGBP(totalClawback)}
            onValueClick={() => onRequestBreakdown(assetId, "clawback")}
          />
        </ListItem>
        <ListItem>
          <EditableField
            label="Net Paid"
            value={formatCurrencyGBP(totalInvoice + totalClawback)}
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
