import { stringify } from "query-string";
import { request, requestFile } from "../..";
import {
  combine,
  stringifyTableParams,
} from "../../../shared/helpers/querySerialisation";
import { PaginatedResult, TableAPIRequestParams } from "../../types";

export type OutstandingCommissionsApiItem = {
  identifier: string;
  assetId: number;
  previousIdentifier: string;
  contractType: string;
  status: string;
  productType: string;
  serviceProvider: string;
  productCode: string;
  customerName: string;
  partner: string;
  startDate: string;
  endDate: string;
  spAccountRef: string;
  sourceRef: string;
  payTariffCommission: number;
  payTariffAdvance: number;
  payServiceCommission: number;
  payServiceAdvance: number;
  payAFCommission: number;
  payAdditionalFundingAdvance: number;
  payVolumeBonus: number;
  payAdditionalFundingDWS: number;
  payBcadFund: number;
  payDa: number;
  payAirQrQn: number;
  monthlyAdvanceRepayment: number;
  paySideLetterAdditionalFundingNetwork: number;
  signatureProof: string;
  contractProof: string;
  invoiceStatus: string;
  outstandingAmount: number;
  paymentTerms: string;
  bdm: string;
  ibdm: string;
  region: string;
  territory: string;
};

export type OutstandingCommissionsReportParams = {
  startDate: string | null;
  endDate: string | null;
  serviceProviderId: number;
  thirdPartyIds: (string | null)[];
  tableControls: TableAPIRequestParams<OutstandingCommissionsApiItem>;
};

export function getOutstandingCommissionsReport(
  params: OutstandingCommissionsReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: params.tableControls.returnAll,
  });

  return request<PaginatedResult<OutstandingCommissionsApiItem>>(
    `asset-report/generate-outstanding-commissions-report${combine([
      tableParamsQS,
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderId: params.serviceProviderId,
        thirdPartyIds: params.thirdPartyIds,
      }),
    }
  );
}

export function getOutstandingCommissionsReportExcel(
  params: OutstandingCommissionsReportParams
) {
  const tableParamsQS = stringifyTableParams({
    pageNumber: params.tableControls.pageNumber,
    pageSize: params.tableControls.pageSize,
    returnAll: true,
  });

  return requestFile(
    `asset-report/generate-outstanding-commissions-report${combine([
      tableParamsQS,
      stringify({ export: true }),
    ])}`,
    {
      method: "POST",
      body: JSON.stringify({
        startDate: params.startDate,
        endDate: params.endDate,
        serviceProviderId: params.serviceProviderId,
        thirdPartyIds: params.thirdPartyIds,
      }),
    }
  );
}
