import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  SetFormValuesAction,
  FormSubmitAction,
  BulkEditPartnerRateFormValues,
  ResetFormValuesAction,
  InitFormAction,
  RESET_FORM_VALUES,
  FORM_INITIALIZE_START,
  SET_FORM_CLIENT_ERROR,
} from "./types";

export function setFormValues(
  values: Partial<BulkEditPartnerRateFormValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function resetFormValues(): ResetFormValuesAction {
  return {
    type: RESET_FORM_VALUES,
  };
}

export function initForm(payload: {
  partnerId: number;
  partnerRateAdjustmentIds: number[];
}): InitFormAction {
  return {
    type: FORM_INITIALIZE_START,
    payload,
  };
}

export function submitForm(payload: {
  partnerId: number;
  partnerRateAdjustmentIds: number[];
  done: () => any;
}): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function setError(error: RequestError | undefined) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      error,
    },
  };
}

export function setClientError(clientError: string | undefined) {
  return {
    type: SET_FORM_CLIENT_ERROR,
    payload: {
      clientError,
    },
  };
}
