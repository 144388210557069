import { createTheme } from "@mui/material";
import { affinityColors } from "./styleData";
import { baseThemeOptions } from "./base";

// ===== OLD
// // See https://material-ui.com/customization/default-theme/ for available options
// // TODO: Fix the typescript warnings. Theme def errors
const baseAffinityTheme = createTheme({
  ...baseThemeOptions,
  // add Affinity specific theme options here
  palette: {
    common: {
      black: affinityColors.commonBlack.value,
      white: affinityColors.commonWhite.value,
    },
    primary: {
      light: affinityColors.primaryLight.value,
      main: affinityColors.primary.value,
      dark: affinityColors.primaryDark.value,
      contrastText: "#fff",
    },
    secondary: {
      light: affinityColors.secondaryLight.value,
      main: affinityColors.secondary.value,
      dark: affinityColors.secondaryDark.value,
      contrastText: "#fff",
    },
    success: {
      light: affinityColors.successLight.value,
      main: affinityColors.success.value,
      dark: affinityColors.successDark.value,
      contrastText: "#fff",
    },
    error: {
      light: affinityColors.errorLight.value,
      main: affinityColors.error.value,
      dark: affinityColors.errorDark.value,
      contrastText: "#fff",
    },
    warning: {
      light: affinityColors.warningLight.value,
      main: affinityColors.warning.value,
      dark: affinityColors.warningDark.value,
      contrastText: "#fff",
    },
    info: {
      light: affinityColors.infoLight.value,
      main: affinityColors.info.value,
      dark: affinityColors.infoDark.value,
      contrastText: "#fff",
    },
    grey: {
      // @ts-ignore
      ...[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].reduce(
        (acc, curr) => ({
          // @ts-ignore
          ...acc,
          [curr]: affinityColors[`grey${curr}`].value,
        }),
        50
      ),
    },
    text: {
      primary: affinityColors.textPrimary.value,
      secondary: affinityColors.textSecondary.value,
      disabled: affinityColors.textDisabled.value,
    },
    background: {
      default: affinityColors.neutralGhost.value,
    },
    divider: affinityColors.divider.value,
    action: {
      active: affinityColors.actionActive.value,
      hover: affinityColors.actionHover.value,
      selected: affinityColors.actionSelected.value,
      disabled: affinityColors.actionDisabled.value,
      disabledBackground: affinityColors.actionDisabledBackground.value,
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "h2" },
          style: {
            marginBottom: "1rem",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "&": {
            boxShadow: "0px 2px 7px 2px rgba(0,0,0,0.2)",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& th, & span": {
            color: "rgba(0, 0, 0, 0.54) !important",
            fontSize: ".75rem",
            fontWeight: 600,
            lineHeight: "1.5rem",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&": {
            marginTop: 16,
            marginRight: 16,
            backgroundColor: "#a9a5b2",
          },
        },
      },
    },
  },
} as any);

const affinityTheme = createTheme({
  ...baseAffinityTheme,
});

export { affinityTheme, affinityColors };
