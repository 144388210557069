import {
  PostedInvoiceListAPIItem,
  PostedInvoiceListParams,
} from "../../api/posted-document/posted-invoice-list";
import { PaginatedResult, RequestError } from "../../api/types";

export const REQUEST_DATA = "postedInvoiceList.REQUEST_DATA";
export const SET_DATA = "postedInvoiceList.SET_DATA";
export const SET_ERROR = "postedInvoiceList.SET_ERROR";

export type PostedInvoiceListState = {
  items: PostedInvoiceListAPIItem[];
  fetching: boolean;
  count: number;
  error?: RequestError;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: {
    params: PostedInvoiceListParams;
  };
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: {
    data: PaginatedResult<PostedInvoiceListAPIItem>;
  };
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: {
    err: RequestError;
  };
}

export type PostedInvoiceListActionTypes =
  | RequestDataAction
  | SetDataAction
  | SetErrorAction;
