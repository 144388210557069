import { ProductRateByIdApiItem } from "../../api/product/product-rates";
import { RequestError } from "../../api/types";
import {
  FORM_SUBMIT,
  INITIALIZE_FINISH,
  SET_FORM_VALUES,
  SET_FORM_ERROR,
  EditProductRateFormBaseValues,
  SetFormValuesAction,
  FormSubmitAction,
  InitializeFinishAction,
  INITIALIZE_START,
  InitializeStartAction,
} from "./types";

export function initalizeStartEditProductRateForm(
  productRate: ProductRateByIdApiItem
): InitializeStartAction {
  return {
    type: INITIALIZE_START,
    payload: {
      productRate,
    },
  };
}

export function initalizeEditProductRateForm(
  productRate: ProductRateByIdApiItem,
  isLinkedToAsset: boolean
): InitializeFinishAction {
  return {
    type: INITIALIZE_FINISH,
    payload: {
      productRate,
      isLinkedToAsset,
    },
  };
}

export function setFormValues(
  values: Partial<EditProductRateFormBaseValues>
): SetFormValuesAction {
  return {
    type: SET_FORM_VALUES,
    payload: {
      values,
    },
  };
}

export function submitForm(payload: { done: () => any }): FormSubmitAction {
  return {
    type: FORM_SUBMIT,
    payload,
  };
}

export function setError({
  productRateId,
  error,
}: {
  productRateId: number;
  error: RequestError;
}) {
  return {
    type: SET_FORM_ERROR,
    payload: {
      productRateId,
      error,
    },
  };
}
