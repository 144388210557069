import * as yup from "yup";

export function getAmendProductSchema() {
  return yup.object().shape({
    primary: yup.object().required("primary"),
    ales: yup
      .array()
      .when("noReverseOption", {
        is: false,
        then: yup.array().min(1, "ales"),
      })
      .when("noReverseOption", {
        is: true,
        then: yup.array(),
      }),
    noReverseOption: yup.boolean().required(),
  });
}
