import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";
import { Warning } from "@mui/icons-material";

//@INFO - this is a fixed version of the @akj-dev/design-system DangerButton, to handle disabled variant correctly.
//@TODO - need to port a fix for the disabled style back in to the design system, then delete this component

export interface DangerButtonProps {
  "data-cy"?: string;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function DangerButton({
  children,
  size,
  "data-cy": dataCy,
  disabled,
  fullWidth,
  onClick,
}: DangerButtonProps) {
  // TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
  const useStyles = makeStyles()((theme) => ({
    root: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }));
  const { classes } = useStyles();
  return (
    <Button
      onClick={onClick}
      className={!disabled ? classes.root : ""}
      variant="contained"
      startIcon={<Warning />}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      data-cy={dataCy}
    >
      {children}
    </Button>
  );
}
