import { call, put, takeLatest } from "redux-saga/effects";
import {
  DisconRecUpValueReportApiItem,
  getDisconRecUpValueReport,
} from "../../api/asset-report/generate-discon-rec-up-value-report";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  setDisconRecUpValueReportData,
  setDisconRecUpValueReportErrorForDisplay,
} from "./actions";
import { RequestDataAction, REQUEST_DATA } from "./types";

function* fetchDisconRecUpValueReport(action: RequestDataAction) {
  try {
    const data: PaginatedResult<DisconRecUpValueReportApiItem> = yield call(
      getDisconRecUpValueReport,
      action.payload.params
    );

    yield put(setDisconRecUpValueReportData(data));
  } catch (e) {
    let error: RequestError = e;
    yield put(setDisconRecUpValueReportErrorForDisplay(error));
  }
}

export function* watchRequestDisconRecUpValueReportReportData() {
  yield takeLatest(REQUEST_DATA, fetchDisconRecUpValueReport);
}
