import { requestDetailPartnerData } from "../detailPartners/actions";
import {
  REQUEST_UPDATE_SELF_BILL_TO_PARTNER,
  RequestUpdateSelfBillToPartnerAction,
} from "./types";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  setUpdateSelfBillToPartnerError,
  setUpdateSelfBillToPartnerSuccess,
} from "./actions";
import { RequestError } from "../../api/types";
import { postUpdateSelfBillToPartner } from "../../api/partner/self-bill-to-partner-code";

function* updateSelfBillToPartner(
  action: RequestUpdateSelfBillToPartnerAction
) {
  try {
    yield call(postUpdateSelfBillToPartner, action.payload);
    yield put(requestDetailPartnerData(action.payload.partnerId));
    yield put(setUpdateSelfBillToPartnerSuccess());
  } catch (e) {
    console.error(e);
    let error: RequestError = e;
    yield put(setUpdateSelfBillToPartnerError(error));
  }
}

export function* watchupdateSelfBillToPartner() {
  yield takeLatest(
    REQUEST_UPDATE_SELF_BILL_TO_PARTNER,
    updateSelfBillToPartner
  );
}
