import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  postCreateSecondaryProduct,
  PostCreateSecondaryProductParams,
} from "../../api/product/secondary-product";
import { RequestError } from "../../api/types";
import { requestDetailProductData } from "../detailProducts/actions";
import { RootState } from "../rootReducer";
import { setError, resetForm } from "./actions";
import {
  CreateSecondaryProductFormState,
  FormSubmitAction,
  FORM_SUBMIT,
} from "./types";

function* submitEditProductRateForm(action: FormSubmitAction) {
  try {
    const formState: CreateSecondaryProductFormState = yield select(
      (state: RootState) => state.createSecondaryProductForm
    );

    const parentId = action.payload.primaryProductId;

    const params: PostCreateSecondaryProductParams = {
      ...formState.baseValues,
      parentId,
    };

    yield call(postCreateSecondaryProduct, params);
    yield put(requestDetailProductData(parentId));

    yield call(action.payload.done);
    yield call(resetForm);
  } catch (e) {
    const error: RequestError = e;
    yield call(() => action.payload.done(error));
    yield put(setError(error));
  }
}

export function* watchCreateSecondaryProductRateForm() {
  yield takeLatest(FORM_SUBMIT, submitEditProductRateForm);
}
