import { makeStyles } from "tss-react/mui";
import { Box, Button, Popover } from "@mui/material";
import React, { useState } from "react";

type Props = {
  id: string;
  name: string;
  children: React.ReactNode;
  count: number;
  type: "date" | "select" | "text" | "textarea" | "number" | "decimal number";
  onReset: () => any;
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(0.5),
    minWidth: 320,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
}));

export const FilterControlWrapper = ({
  children,
  name,
  id,
  type,
  count,
  onReset,
}: Props) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null);
  const handleClick = (event: React.MouseEvent) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  const isPopoverOpen = Boolean(popoverAnchorEl);
  const popoverId = isPopoverOpen ? `${id}-filter-popover` : undefined;
  const { classes } = useStyles();

  const countLabel = count > 0 ? ` (${count})` : "";

  return (
    <React.Fragment>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        slotProps={{ paper: { className: classes.paper } }}
      >
        <React.Fragment>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={0.5}
          >
            {type === "date" && "Filter by date range"}
            {type === "select" && "Filter by options"}
            {type === "text" && "Filter by text (multiple)"}
            {type === "number" && "Filter by number (multiple)"}
            {type === "textarea" && "Enter values"}
            {type === "decimal number" && "Filter by decimal number (multiple)"}
            <Button variant="text" color="primary" onClick={onReset}>
              Reset
            </Button>
          </Box>
          {children}
        </React.Fragment>
      </Popover>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        {`${name}${countLabel}`}
      </Button>
    </React.Fragment>
  );
};
