import { capitalize } from "@mui/material";
import { stringify } from "query-string";
import { compose } from "redux";
import { TableAPIRequestParams } from "../../api/types";
import { BooleanExceptZero } from "./BooleanExceptZero";
import { checkValidDateStr } from "./dates";

type FilterQLItem<T> = {
  values: string[];
  column: keyof T;
  operation: "like" | "eq" | "GT" | "GTE" | "LT" | "LTE";
};

function cleanse(filters: { [key: string]: any }): { [key: string]: string[] } {
  let cleansed: { [key: string]: string[] } = {};

  for (const key in filters) {
    let filter = filters[key];

    if (Array.isArray(filter) && filter.some(BooleanExceptZero)) {
      cleansed[key] = filter;
    }
  }

  return cleansed;
}

function transformToQueryObject<T>(filters: {
  [key: string]: string[];
}): FilterQLItem<T>[] {
  const qlItems: FilterQLItem<T>[] = [];

  for (const key in filters) {
    let filter = filters[key];
    if (filter.some((x) => checkValidDateStr(x))) {
      // date type filter
      const from = filter[0];
      const to = filter[1];
      from &&
        qlItems.push({
          values: [from],
          column: key as keyof T,
          operation: "GTE",
        });

      to &&
        qlItems.push({
          values: [to],
          column: key as keyof T,
          operation: "LTE",
        });
    } else {
      qlItems.push({
        values: filter,
        column: key as keyof T,
        operation: "eq",
      });
    }
  }

  return qlItems;
}

function serialiseFilterQuery<T>(filters: FilterQLItem<T>[]): string {
  let strings: string[] = [];
  const add = (str: string, i: number) => {
    strings.push(`filters[${i}].${str}`);
  };

  filters.forEach((filter, i) => {
    add(`operation=${filter.operation}`, i);
    add(`column=${capitalize(filter.column as string)}`, i);
    filter.values.forEach((value, j) => {
      add(`values[${j}]=${encodeURIComponent(value)}`, i); // yes, the param name is still 'values' (plural)
    });
  });

  return strings.join("&");
}

export function stringifyFilterParams(filters: { [key: string]: any }): string {
  return compose(
    serialiseFilterQuery,
    transformToQueryObject,
    cleanse
  )(filters);
}

export function stringifyTableParams<T>(
  params: Partial<TableAPIRequestParams<T>>
) {
  const withCapitalizedSort = {
    ...params,
    sortBy: params.sortBy ? capitalize(params.sortBy.toString()) : undefined,
  };
  return stringify(withCapitalizedSort);
}

export function combine(strings: string[]): string {
  let combined = "";

  strings.filter(Boolean).forEach((str: string, i) => {
    if (i === 0) {
      combined += "?";
    } else {
      combined += "&";
    }

    combined += str;
  });

  return combined;
}
