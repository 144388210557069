import { request } from "../..";
import { PartnerThirdPartyIdApiItem } from "../../payment-data/batches/partner-third-party-ids";

export type RecalculatePartnerRateParams = {
  batchId: number;
  partnerThirdPartyIds: PartnerThirdPartyIdApiItem[];
};

export function postRecalculatePartnerRate(
  params: RecalculatePartnerRateParams
) {
  return request("asset-ledger-entry/recalculate-partner-rate", {
    method: "POST",
    body: JSON.stringify({
      paymentDataBatchId: params.batchId,
      partnerThirdPartyIds: params.partnerThirdPartyIds,
    }),
  });
}
