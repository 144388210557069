import React from "react";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/rootReducer";
import { FormControl, TextField } from "@mui/material";
import { ModalFieldWrapper } from "../../../shared/components/modal_field_controls/ModalFieldWrapper";
import {
  setFormValues,
  submitForm,
} from "../../../store/createSecondaryProductForm/actions";
import { RequestError } from "../../../api/types";

type Props = {
  primaryProductId: number;
};

export const CreateSecondaryProductModal = ({ primaryProductId }: Props) => {
  const dispatch = useDispatch();

  const { requestError, baseValues } = useSelector(
    (state: RootState) => state.createSecondaryProductForm
  );

  return (
    <ModalFieldWrapper
      icon={false}
      label="Create Secondary Product"
      buttonLabel="Create Secondary Product"
      buttonVariant="contained"
      onSave={() => {
        return new Promise((resolve) => {
          dispatch(
            submitForm({
              done: (err?: RequestError) => resolve(err),
              primaryProductId,
            })
          );
        });
      }}
      id="create-secondary-product"
      gutterSize="small"
      apiError={requestError}
    >
      <FormFieldsWrapper>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Name"
            variant="outlined"
            type="text"
            value={baseValues.name}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  name: event.target.value,
                })
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" size="small" fullWidth>
          <TextField
            size="small"
            label="Description"
            variant="outlined"
            type="text"
            value={baseValues.description}
            onChange={(event) =>
              dispatch(
                setFormValues({
                  description: event.target.value,
                })
              )
            }
          />
        </FormControl>
      </FormFieldsWrapper>
    </ModalFieldWrapper>
  );
};
