import { call, put, takeLatest } from "redux-saga/effects";
import { PaginatedResult, RequestError } from "../../api/types";
import {
  RequestDataAction,
  REQUEST_DATA,
  UpdatePostingDocumentDateAction,
  UPDATE_POSTING_DATE,
  DeleteInvoiceAction,
  DELETE_INVOICE,
  RefreshInvoiceAction,
  REFRESH_INVOICE,
  SelfBillListItem,
  UPDATE_OVERRIDES,
  UpdateSelfBillListOverridesAction,
  POST_CHECKED_INVOICES,
  PostCheckedInvoicesAction,
} from "./types";
import {
  getSelfBillList,
  postUpdatePostingDocumentDate,
  postUpdateSelfBillOverrides,
  postSelfBillLines,
} from "../../api/invoice/self-bill-list";
import { deleteInvoice } from "../../api/invoice/delete";
import { refreshExternalInvoice } from "../../api/self-bill/fetch-external-invoice";
import {
  setSelfBillListData,
  setSelfBillListError,
  setUpdatePostingDateError,
  requestSelfBillList,
  setUpdateSelfBillListOverrideError,
  setPostCheckedInvoicesError,
} from "./actions";

function* fetchSelfBillList(action: RequestDataAction) {
  try {
    const data: PaginatedResult<SelfBillListItem> = yield call(
      getSelfBillList,
      action.payload.params
    );
    const items: SelfBillListItem[] = data.items.map((i) => ({
      ...i,
      checked: false,
    }));
    yield put(setSelfBillListData({ ...data, items: items }));
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setSelfBillListError(error));
  }
}

function* updatePostingDocumentDate(action: UpdatePostingDocumentDateAction) {
  try {
    yield call(
      postUpdatePostingDocumentDate,
      action.payload.invoiceId,
      action.payload.postingDate
    );
    yield put(
      requestSelfBillList({
        filterParams: action.payload.pageParams.filterParams,
        tableControls: action.payload.pageParams.tableControls,
      })
    );
  } catch (e) {
    const error = e as RequestError;
    yield put(setUpdatePostingDateError(error));
  }
}

function* deleteSelfBillInvoiceFromList(action: DeleteInvoiceAction) {
  try {
    yield call(deleteInvoice, action.payload.invoiceId);

    yield put(
      requestSelfBillList({
        tableControls: action.payload.params.tableControls,
        filterParams: action.payload.params.filterParams,
      })
    );
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setSelfBillListError(error));
  }
}

function* refreshSelfBillInvoice(action: RefreshInvoiceAction) {
  try {
    yield call(refreshExternalInvoice, action.payload.invoiceId);
    yield put(
      requestSelfBillList({
        filterParams: action.payload.params.filterParams,
        tableControls: action.payload.params.tableControls,
      })
    );
  } catch (e) {
    let error: RequestError = e as any;
    yield put(setSelfBillListError(error));
  }
}

function* updateSelfBillOverrides(action: UpdateSelfBillListOverridesAction) {
  try {
    yield call(
      postUpdateSelfBillOverrides,
      action.payload.invoiceId,
      action.payload.params
    );

    yield put(
      requestSelfBillList({
        filterParams: action.payload.pageParams.filterParams,
        tableControls: action.payload.pageParams.tableControls,
      })
    );
  } catch (e) {
    const error = e as RequestError;
    yield put(setUpdateSelfBillListOverrideError(error));
  }
}

function* postCheckedSelfBillInvoices(action: PostCheckedInvoicesAction) {
  try {
    yield call(postSelfBillLines, action.payload.invoiceIds);

    yield put(
      requestSelfBillList({
        filterParams: action.payload.pageParams.filterParams,
        tableControls: action.payload.pageParams.tableControls
      })
    );
  } catch (e) {
    const error = e as RequestError;
    yield put(setPostCheckedInvoicesError(error));
  }
}

export function* watchSelfBillList() {
  yield takeLatest(REQUEST_DATA, fetchSelfBillList);
}

export function* watchUpdatePostingDocumentDate() {
  yield takeLatest(UPDATE_POSTING_DATE, updatePostingDocumentDate);
}

export function* watchDeleteSelfBillInvoiceFromList() {
  yield takeLatest(DELETE_INVOICE, deleteSelfBillInvoiceFromList);
}

export function* watchRefreshSelfBillInvoice() {
  yield takeLatest(REFRESH_INVOICE, refreshSelfBillInvoice);
}

export function* watchUpdateSelfBillOverrides() {
  yield takeLatest(UPDATE_OVERRIDES, updateSelfBillOverrides);
}

export function* watchPostCheckedSelfBillInvoices() {
  yield takeLatest(POST_CHECKED_INVOICES, postCheckedSelfBillInvoices);
}
