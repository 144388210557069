import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import { RequestError } from "../../../api/types";
import { FormFieldsWrapper } from "../../../layout/components/FormFieldsWrapper";
import { Page } from "../../../layout/components/Page";
import { APIFetchingWrapper } from "../../../shared/components/APIFetchingWrapper";
import { CustomStatusChip } from "../../../shared/components/CustomStatusChip";
import { FileDrop } from "../../../shared/components/FileDrop";

type Props = {
  title: string;
  uploadService: (file: File) => Promise<unknown>;
  acceptedFiles: string[];
};

export const ImportSingleFilePage = ({
  title,
  uploadService,
  acceptedFiles,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [apiError, setApiError] = useState<RequestError | undefined>();
  const [file, setFile] = useState<File | undefined>();

  const resetAll = useCallback(() => {
    setFormError(false);
    setFile(undefined);
    setSuccess(false);
    setApiError(undefined);
    setIsSubmitting(false);
  }, [setFormError, setFile, setApiError, setSuccess, setIsSubmitting]);

  const handleSubmit = () => {
    // simple validation
    if (!file) {
      return setFormError(true);
    } else {
      setFormError(false);
    }

    uploadService(file)
      .then(() => {
        setSuccess(true);
      })
      .catch(setApiError);
  };

  return (
    <Page
      title={title}
      actions={
        success || apiError ? (
          <Button onClick={resetAll} variant="outlined" size="large">
            Back
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </Button>
        )
      }
    >
      <APIFetchingWrapper error={apiError} loading={isSubmitting}>
        {success ? (
          <CustomStatusChip
            title="Upload Received for processing"
            type="success"
          />
        ) : (
          <React.Fragment>
            <form>
              <FormFieldsWrapper>
                <FileDrop
                  acceptedFileTypes={acceptedFiles}
                  onAcceptedDrop={setFile}
                />
              </FormFieldsWrapper>
            </form>
            {formError && (
              <CustomStatusChip
                title="Please add a file and try again"
                type="error"
              />
            )}
          </React.Fragment>
        )}
      </APIFetchingWrapper>
    </Page>
  );
};
