import {
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  PortAndResignReportState,
  PortAndResignReportActionTypes,
} from "./types";

const initialState: PortAndResignReportState = {
  items: [],
  fetching: false,
};

export function portAndResignReportReducer(
  state = initialState,
  action: PortAndResignReportActionTypes
): PortAndResignReportState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };

    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.err,
        fetching: false,
      };
    }

    case SET_DATA:
      return {
        ...state,
        fetching: false,
        items: action.payload.data,
      };

    default:
      return state;
  }
}
