import {
  QueryPartnerApiItem,
  QueryPartnersRequestParams,
} from "../../api/partner";
import {
  RequestError,
  PaginatedResult,
  TableAPIRequestParams,
} from "../../api/types";

export const SET_DATA = "queryPartners.SET_DATA";
export const REQUEST_DATA = "queryPartners.REQUEST_DATA";
export const SET_API_ERROR = "queryPartners.SET_API_ERROR";
export const SET_TABLE_CONTROLS = "queryPartners.SET_TABLE_CONTROLS";

export type QueryPartnersState = {
  filters: any;
  items: QueryPartnerApiItem[];
  count: number;
  fetching: boolean;
  error?: RequestError;
  tableControls: TableAPIRequestParams<QueryPartnerApiItem>;
};

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: QueryPartnersRequestParams;
}

interface SetDataAction {
  type: typeof SET_DATA;
  payload: PaginatedResult<QueryPartnerApiItem>;
}

interface SetAPIErrorAction {
  type: typeof SET_API_ERROR;
  payload: RequestError;
}

interface SetTableControlsAction {
  type: typeof SET_TABLE_CONTROLS;
  payload: {
    tableControls: TableAPIRequestParams<QueryPartnerApiItem>;
  };
}

export type QueryPartnersActionTypes =
  | SetTableControlsAction
  | RequestDataAction
  | SetDataAction
  | SetAPIErrorAction;
