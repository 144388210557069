import React from "react";
import { List, ListItem } from "@mui/material";
import { EditableField } from "../../../../../shared/components/EditableField";
import { formatCurrencyGBP } from "../../../../../shared/helpers/currency";

import { PaymentDataUpfrontSummary } from "../../../../../store/paymentDataSummary/types";

type Props = {
  data: PaymentDataUpfrontSummary;
};

export const UpfrontSummaryTable = ({ data }: Props) => {
  const notFoundStartDate =
    data.totalAmountUnmatchedByReason["unmatchedStartDate"];
  const notFound = data.totalAmountUnmatchedByReason["unmatched"];

  return (
    <List>
      <ListItem divider>
        <EditableField label="Total Records" value={data.totalLines} />
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Total Amount Matched"
          value={`${formatCurrencyGBP(data.totalAmountMatched)} (${
            data.matchedLinesNo
          } ${data.matchedLinesNo === 1 ? "Record" : "Records"})`}
        />
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Total Processed"
          value={`${formatCurrencyGBP(data.totalAmountProcessed)} (${
            data.processedLines
          } ${data.processedLines === 1 ? "Record" : "Records"})`}
        />
      </ListItem>
      <ListItem divider>
        <EditableField
          label="Total Amount Not Matched"
          value={`${formatCurrencyGBP(data.totalAmountUnmatched)} (${
            data.unmatchedLinesNo
          } ${data.unmatchedLinesNo === 1 ? "Record" : "Records"})`}
        />
      </ListItem>
      {notFoundStartDate && (
        <ListItem divider>
          <EditableField
            label="Total Amount (Start date not found)"
            value={`${formatCurrencyGBP(notFoundStartDate?.value)} (${
              notFoundStartDate.key
            } ${notFoundStartDate.key === 1 ? "Record" : "Records"})`}
          />
        </ListItem>
      )}
      {notFound && (
        <ListItem divider>
          <EditableField
            label="Total Amount (Not found)"
            value={`${formatCurrencyGBP(notFound.value)} (${notFound.key} ${
              notFound.key === 1 ? "Record" : "Records"
            })`}
          />
        </ListItem>
      )}
      <ListItem>
        <EditableField
          label="Total amount on the original file"
          value={formatCurrencyGBP(data.totalAmountOriginalValue)}
        />
      </ListItem>
    </List>
  );
};
